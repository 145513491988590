import React, { useEffect } from "react";
import "./deleteAccountPage.css"
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const DeleteAccountPage = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const { i18n } = useTranslation("translation");

    const currentRoute = location.pathname;
    const routeParts = currentRoute.split('/');
    let languageCode = "fr"; // Default to "fr" if no match is found

    if (routeParts.length > 2) {
        const supportedLanguages = ["en", "fr", "it", "pl", "es", "de"];
        const potentialLanguage = routeParts[2];

        if (supportedLanguages.includes(potentialLanguage)) {
            languageCode = potentialLanguage;
        }
    }

    // Set the language in local storage
    useEffect(() => {
        localStorage.setItem("lang", languageCode);
        i18n.changeLanguage(languageCode);
    }, [languageCode, location]);



    return <div className="delete-main" >
        <div className="left">
            <img src="https://cdni.iconscout.com/illustration/premium/thumb/delete-user-3148748-2624925.png?f=webp" alt="" />
        </div>
        <div className="right">
            <h2>{t("Delete Your Account!")}</h2>
            <span>{t("To request the deletion of your account, please contact us at")} <span style={{ color: "#1171b6" }}>support@afomedia.com</span>. {t("Our support team will assist you in the account deletion process.")}</span><br />
            <span>{t("Thank you for choosing AfoMedia.")}</span>
        </div>
    </div>
};

export default DeleteAccountPage;
