import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./videoDescriptionModal.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import axios from "axios";

import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { useTranslation } from "react-i18next";
import { MoviesUpComingSliderApi } from "../../../Services/moviesApi";
import {
  convertSecondsToHoursMinutes,
  dateToWords,
  formatViewCount,
  getDate,
  limitTextToWords,
} from "../../../Services/generalFunctions/generalFunctions";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  generateLinkedinShareUrl,
  generateInstagramShareUrl,
  generateSnapchatShareUrl,
  generateTikTokShareUrl,
  generatePinterestShareUrl,
  generateTelegramShareUrl,
  generateRedditShareUrl,
  generateQuoraShareUrl,
} from "../../../views/backend/home/ShareFunction";
import getGeneralContent from "../../../Services/generalContent";
import getAnalytics from "../../../Services/getAnalytics";

SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
]);
const VideoDescriptionModal = ({ item, handleClose }) => {
  const [upComingData, setUpComingData] = useState([]);
  const [itemData, setItemData] = useState({});
  const [generalContent, setGeneralContent] = useState({});
  const [similarGenreVideos, setSimilarGenreVideos] = useState([]);
  const [genre, setGenre] = useState([]);
  const currentSelectedLanguage = localStorage.getItem("lang");
  // set the main data inside a state
  useEffect(() => {
    setItemData(item);
  }, [item]);

  const id = item?._id; // general content id

  // get generalContent
  useEffect(() => {
    try {
      getGeneralContent({ id })
        .then((data) => {
          setGeneralContent(data);
          setGenre((prevGenre) => data?.generalContentObj?.genre || prevGenre);
        })
        .catch((error) => {});
    } catch (error) {}
  }, [item]);

  // analytics api
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    if (item?.media?.media_id !== "") {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      const analyticsData = {
        media_ids: [item?.media?.media_id],
        start_date: getDate(generalContent?.generalContentObj?.createdAt),
        end_date: formattedDate,
      };
      getAnalytics(analyticsData)
        .then((response) => {
          // Handle the response data

          setDataRows(response.data?.rows);
          // setDataMetrics(response.metadata?.column_headers?.metrics);
        })
        .catch((error) => {
          // Handle the error
        });
    }
  }, [generalContent]);

  // similar content with genre
  useEffect(() => {
    const fetchData = async () => {
      try {
        let concatenatedMovies = [];
        for (let i = 0; i < genre.length; i++) {
          const genreId = genre[i]._id;
          const response = await axios.get(
            `${process.env.REACT_APP_API}/api/general-content/get-genre-movies/${genreId}/en`
          );
          const moviesData = response.data;
          concatenatedMovies = concatenatedMovies.concat(
            moviesData?.upcomingContent
          );
        }
        setSimilarGenreVideos(concatenatedMovies);
      } catch (error) {}
    };

    fetchData();
  }, [genre]);

  const { t } = useTranslation();
  const source = "app";
  useEffect(() => {
    MoviesUpComingSliderApi("en")
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setUpComingData(data.upcomingContent);
        } else {
          setUpComingData([]);
        }
      })
      .catch((error) => {});
  }, []);
  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };

  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );
  return (
    <div className="modal-container">
      <span onClick={() => handleClose()} className="close-modal-icon">
        <i
          className="ri-close-circle-fill"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // color: "white",
          }}
        ></i>
      </span>
      <div className="modal-video-player-wrapper">
        <div className="blurred-overlay"></div>
        {generalContent?.generalContentObj?.availability === "upcoming" ? (
          <img
            src={
              generalContent?.generalContentObj?.thumbnail?.banner_thumbnail_url
            }
            style={{ height: "100%", width: "100%" }}
            className="modal-video-player"
          />
        ) : (
          <ReactPlayer
            className="modal-video-player"
            url={`https://cdn.jwplayer.com/manifests/${item?.media?.media_id}.m3u8`}
            width="100%"
            height="100%"
            pip={false}
            controls={true}
            playing={true}
            muted={false}
          />
        )}
      </div>
      <div className="p-3">
        {/* line 1 */}
        <div>
          <h4
            className="trending-text big-title text-uppercase mt-0 mr-2"
            style={{ width: "100%" }}
          >
            {currentSelectedLanguage === "en" ? itemData?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :itemData?.media?.title } 
          </h4>
          {/* icons */}
        </div>

        <div className="desc-modal-details-container">
          <div className="desc-modal-desc-container">
            <span
              className=""
              dangerouslySetInnerHTML={{
                __html: currentSelectedLanguage === "en" ? item?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  : item?.media?.description ,
              }}
            ></span>
          </div>
          <div className="desc-modal-tags">
            <div className="desc-modal-analytics">
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                {dataRows && dataRows.length > 0 ? (
                  <h6>
                    {formatViewCount(dataRows[0][3])} {t("Views")}
                  </h6>
                ) : (
                  <h6>0 {t("Views")}</h6>
                )}
                <h6
                  style={{
                    border: "1px solid #69b0d3",
                    padding: "6px",
                    display: "inline-block",
                  }}
                >
                  {dateToWords(
                    generalContent?.generalContentObj?.media?.release_year
                  )}
                </h6>
                <h6>
                  {convertSecondsToHoursMinutes(itemData?.media?.duration)}
                </h6>
              </div>
            </div>
            {generalContent?.generalContentObj?.genre?.length > 0 && (
              <p style={{ margin: 0 }}>
                <span
                  style={{
                    color: "gray",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {t("Categories")}:
                </span>{" "}
                {generalContent?.generalContentObj?.genre
                  ?.map((item) => item?.name)
                  .join(", ")}
              </p>
            )}
            <p style={{ margin: 0 }}>
              {generalContent?.generalContentObj?.media?.jw_tags?.length >
                0 && (
                <>
                  <span
                    style={{
                      color: "gray",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {t("tags")}:
                  </span>{" "}
                  {generalContent.generalContentObj.media.jw_tags
                    .map((item) => item)
                    .join(", ")}
                </>
              )}
            </p>
          </div>
        </div>

        <div>
          {similarGenreVideos.length > 0 && (
            <section id="iq-suggestede">
              <div className="">
                <div className="row">
                  <div className="col-sm-12 overflow-hidden">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="main-title">{t("Similar like this")}</h4>
                      {/* <Link className="iq-view-all" to="/">
                    View All
                  </Link> */}
                    </div>
                    <div id="suggestede-contens">
                      <div
                        id="prev3"
                        className="swiper-button swiper-button-prev"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <div
                        id="next3"
                        className="swiper-button swiper-button-next"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                      <Swiper
                        navigation={{
                          prevEl: "#prev3",
                          nextEl: "#next3",
                        }}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          550: { slidesPerView: 2 },
                          991: { slidesPerView: 2 },
                          1400: { slidesPerView: 3 },
                          1600: { slidesPerView: 3 },
                        }}
                        loop={false}
                        slidesPerView={3}
                        spaceBetween={20}
                        as="ul"
                        className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                      >
                        {similarGenreVideos?.map((data) => (
                          <SwiperSlide as="li">
                            {/* <div className=" block-images position-relative slider-img-main">
                            <div className="img-box">
                              <img
                                src={data?.thumbnail?.banner_thumbnail_url}
                                className="img-fluid slider-img"
                                alt=""
                              />
                            </div>
                            <div className="block-description">
                              <h6 className="iq-title title-desc">
                                <Link
                                  to={`/video-details/${source}/${data?._id}`}
                                >
                                  {data?.media?.title}
                                </Link>
                              </h6>
                              <div className="card-slider-desc">
                                <span>
                                  {limitTextToWords(
                                    data?.media?.description,
                                    12
                                  )}
                                </span>
                              </div>
                              <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                <div className="badge badge-secondary p-1 mr-2 ">
                                  {convertSecondsToHoursMinutes(
                                    data?.media?.duration.toFixed(2)
                                  )}
                                </div>
                              </div>
                              <div className="block-social-info-button">
                                <li className="share">
                                  <div className="hover-buttons">
                                    <Link
                                      to={`/video-details/${source}/${data?._id}`}
                                      media_obj_id={data?.media?._id}
                                      category={data?.category}
                                      className="btn btn-hover iq-button"
                                    >
                                      <i
                                        className="fa fa-play mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  </div>
                                </li>
                              </div>
                            </div>
                            <div className="block-social-info">
                              <ul className="list-inline p-0 m-0 music-play-lists">
                                <li className="share">
                                  <span>
                                    <i className="ri-share-fill"></i>
                                  </span>
                                  <div className="share-box">
                                    <div className="d-flex align-items-center">
                                      <Link
                                        to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-facebook-fill"></i>
                                      </Link>
                                      <Link
                                        to="https://twitter.com/intent/tweet?text=Currentlyreading"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-twitter-fill"></i>
                                      </Link>
                                      <Link
                                        to="#"
                                        data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                        className="share-ico iq-copy-link"
                                        tabIndex="0"
                                      >
                                        <i className="ri-links-fill"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    <i
                                      className={`ri-heart-fill ${
                                        favoriteItems.includes(data?._id)
                                          ? "red-heart"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleFavClick(
                                          data?._id,
                                          data?.category
                                        )
                                      }
                                    ></i>
                                  </span>
                                 
                                </li>
                                <li>
                                  <span>
                                    <i
                                      className={`ri-add-line ${
                                        watchListItems.includes(data?._id)
                                          ? "red-heart"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleWatchList(
                                          data?._id,
                                          data?.category
                                        )
                                      }
                                    ></i>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                            <div className="block-images position-relative slider-img-main">
                              <div className="img-box">
                                <img
                                  src={data?.thumbnail?.banner_thumbnail_url}
                                  className="img-fluid slider-img"
                                  alt=""
                                />
                              </div>
                              {/* showing on the top */}
                              <div className="card-slider-badge">
                                <div className="badge badge-secondary p-1 mr-2">
                                  {data?.category === "movie" ||
                                  data?.category === "video"
                                    ? convertSecondsToHoursMinutes(
                                        data?.media?.duration.toFixed(2)
                                      )
                                    : `${data?.episodes.length} ${
                                        data?.episodes.length === 1
                                          ? "Episode"
                                          : "Episodes"
                                      }`}
                                </div>
                              </div>
                              <div className="block-description ">
                                <div className="card-slider-content">
                                  {data?.category === "movie" ||
                                  data?.category === "video" ? (
                                    <h6 className="iq-title title-desc">
                                    {currentSelectedLanguage === "en" ? data?.media.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.title } 
                                    </h6>
                                  ) : (
                                    <h6 className="iq-title title-desc">
                                      {data?.title}

                                      
                                    </h6>
                                  )}
                                </div>
                                <div className="card-slider-desc">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: limitTextToWords(
                                        currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media.description  ,
                                        12
                                      ),
                                    }}
                                  ></span>
                                </div>
                                <div className="block-social-info-button">
                                  <li className="share">
                                    <div className="hover-buttons">
                                      {data?.category === "movie" ||
                                      data?.category === "video" ? (
                                        <Link
                                          to={`/video-details/${source}/${data?._id}`}
                                          className="btn btn-hover iq-button"
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/show-info/${data?._id}`}
                                          className="btn btn-hover iq-button"
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </Link>
                                      )}
                                    </div>
                                  </li>
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href={generateLinkedinShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-linkedin-fill"></i>
                                        </a>
                                        <a
                                          href={generateInstagramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-instagram-fill"></i>
                                        </a>
                                        <a
                                          href={generateSnapchatShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-snapchat-fill"></i>
                                        </a>
                                        <a
                                          href={generateTikTokShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-tiktok-fill"></i>
                                        </a>
                                        <a
                                          href={generateFacebookShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                        <a
                                          href={generatePinterestShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-pinterest-fill"></i>
                                        </a>
                                        <a
                                          href={generateTelegramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-telegram-fill"></i>
                                        </a>
                                        <a
                                          href={generateRedditShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-reddit-fill"></i>
                                        </a>
                                        <a
                                          href={generateQuoraShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-quora-fill"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-heart-fill ${
                                          favoriteItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        // onClick={() =>
                                        //   handleFavClick(
                                        //     data?._id,
                                        //     data?.category
                                        //   )
                                        // }
                                        title={
                                          favoriteItems.includes(data?._id)
                                            ? "Remove From Favourite"
                                            : "Add To Favourite"
                                        }
                                      ></i>
                                    </span>
                                    {/* <span className="count-box">19+</span> */}
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-add-line ${
                                          watchListItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        // onClick={() =>
                                        //   handleWatchList(
                                        //     data?._id,
                                        //     data?.category
                                        //   )
                                        // }
                                        title={
                                          watchListItems.includes(data?._id)
                                            ? "Remove From WatchList"
                                            : "Add To WatchList"
                                        }
                                      ></i>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoDescriptionModal;
