import axios from "axios";

function checkUserCard(user_id) {
  const currentUserToken = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_API}/api/user/stripe/check-card/${user_id}`;
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    })
    .then((response) => {
      return response;
      // return response.data.episodeObj !== null ? response.data.episodeObj : [];
    })
    .catch((error) => {
      return [];
    });
}

export default checkUserCard;
