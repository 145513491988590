import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import footer1 from "../../../../assets/images/footer/01.jpg";
import footer2 from "../../../../assets/images/footer/02.jpg";
import googlePlay from "../../../../assets/images/footer/googleplay.jpeg";
import appStore from "../../../../assets/images/footer/appstore.jpeg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from "../../../../store/mode/rtlmode";
import { useTranslation } from "react-i18next";
import { getSocialChannels } from "../../../../Services/getSocialChannels";

const mapStateToProps = (state) => {
  return {
    rtlMode: getRtlMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      rtlModeAction,
    },
    dispatch
  ),
});

const FooterStyle = (props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  // state for the social media platforms
  const [socialMediaPlatforms, setSocialMediaPlatforms] = useState([]);

  useEffect(() => {
    const fetchSocialMediaData = async () => {
      const data = await getSocialChannels();
      if (data) {
        setSocialMediaPlatforms(data?.data);
      }
    };
    fetchSocialMediaData();
  }, []);

  useEffect(() => {
    const rtlMode = sessionStorage.getItem("rtl-mode");
    if (rtlMode === null) {
      props.rtlModeAction(props.rtlMode);
    } else {
      props.rtlModeAction(rtlMode);
    }
  });

  return (
    <>
      <div className={`rtl-box ${show === true ? "show" : ""}`}>
        <div className="rtl-panel">
          <ul className="modes">
            <li
              className="dir-btn"
              data-mode="rtl"
              data-active="false"
              onClick={() => {
                props.rtlModeAction("ltl");
              }}
              data-value="ltr"
            >
              <Link to="#">LTR</Link>
            </li>
            <li
              className="dir-btn"
              data-mode="rtl"
              data-active="true"
              onClick={() => {
                props.rtlModeAction("rtl");
              }}
              data-value="rtl"
            >
              <Link to="#">RTL</Link>
            </li>
          </ul>
        </div>
      </div>
      <footer id="contact" className="footer-one iq-bg-dark">
        <div className="footer-top">
          <Container fluid>
            <Row className="footer-standard">
              <Col lg="7">
                <div className="widget text-left">
                  <div className="menu-footer-link-1-container">
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li
                        id="menu-item-7312"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314"
                      >
                        <Link to="/about-us">{t("About Us")}</Link>
                      </li>
                      <li
                        id="menu-item-7314"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314"
                      >
                        <Link to="/terms-of-service">{t("Terms Of Use")}</Link>
                      </li>
                      <li
                        id="menu-item-7316"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316"
                      >
                        <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                      >
                        <Link to="/faq">{t("FAQ")}</Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                      >
                        <Link to="/contact">{t("Contact Us")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="widget text-left">
                  <div className="textwidget">
                    <p>
                      <small>
                        © AFO Media.{" "}
                        {t(
                          "All Rights Reserved. All videos and shows on this platform are trademarks of, and all related images and content are the property of, AFO Media. Duplication and copy of this is strictly prohibited. All rights reserved."
                        )}
                      </small>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="6" className="mt-4 mt-lg-0">
                <h6 className="footer-link-title">{t("Follow Us")}:</h6>
                <ul className="info-share">
                  {socialMediaPlatforms?.map((social, index) => (
                    <li key={social?._id}>
                      <a
                        onClick={() => {
                          window.open(social?.link, "_blank");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={social?.icon_url}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                          }}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col lg="3" md="6" className="mt-4 mt-lg-0">
                <div className="widget text-left">
                  <div className="textwidget">
                    <h6 className="footer-link-title">AFO Media</h6>
                    <div className="d-flex align-items-center">
                      <a className="app-image" href="https://play.google.com/store/apps/details?id=com.afo.media" target="_blank" rel="noopener noreferrer">
                        <img src={googlePlay} alt="play-store" />
                      </a>
                      <br />
                      <a className="ml-3 app-image" href="https://apps.apple.com/us/app/afo-media/id6466264011" target="_blank" rel="noopener noreferrer">
                        <img src={appStore} alt="app-store" />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyle);
