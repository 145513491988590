import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HistoryView = () => {
  const { t } = useTranslation();
  const [podCastView, setPodCastView] = useState("1");
  const handlePodCastShowMore = () => {
    setPodCastView(podCastView + 5);
  };
  const { name, genre_image, genre_type, id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch(`${process.env.REACT_APP_API}/api/genres/get-all-genres`)
        .then((res) => res.json())
        .then((result) => {
          setData(result.allGenres);
        })
        .catch((error) => {});
    };

    fetchData();
  }, []);

  const filteredData = data.filter(
    (genre) => genre.genre_type === genre_type && genre._id === id
  );

  return (
    <>
      {filteredData.map((genre) => (
        // <div
        //     className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        //     style={{ backgroundImage: `url(${genre.genre_image.url})` }}
        //     key={genre._id}
        // >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <div
                style={{ marginTop: "100px" }}
                className="text-center iq-genreView-heading"
              >
                <h3 className="big-title title text-uppercase">{genre.name}</h3>
              </div>
            </Col>
          </Row>
        </Container>
        // </div>
      ))}

      <div className="container-fluid">
        <div
          className="d-flex align-items-center justify-content-start"
          style={{ marginTop: "20px" }}
        >
          <h4 className="main-title" style={{ marginLeft: "10px" }}>
            {t("Genres")}
          </h4>
        </div>
        <div className="gallery-container" style={{ marginTop: "20px" }}>
          {data
            .filter((res) => res?.genre_type === genre_type)
            .slice(0, podCastView)

            .map((res) => (
              <Col xl="3" lg="4" md="6" sm="6" xs="6" xxs="12">
                s
                <div className="gallery-item" key={res._id}>
                  <div className=" block-images position-relative slider-img-main">
                    <div className="img-box">
                      <img
                        src={res?.genre_image?.url}
                        width="{100}"
                        height="{100}"
                        className="img-fluid slider-genre-img"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    <div className="block-description-genre">
                      <h6 className="iq-title-genre">
                        <Link to="/episode">{res?.name}</Link>
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </div>
        <div className="text-start" style={{ marginLeft: "10px" }}>
          {podCastView < data.length &&
            data.filter((res) => res.genre_type === genre_type).length >
              podCastView && (
              <button
                onClick={handlePodCastShowMore}
                className="load-more-button"
              >
                {t("Load More")}
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default HistoryView;
