import axios from "axios";
import atob from "atob";
import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);

async function AuthSession() {
  let token = localStorage.getItem("token");

  if (!token) {
    token = localStorage.getItem("myToken");
  }
  if (token) {
    const authUrl = `${process.env.REACT_APP_API}/api/user/auth/${token}`;
    try {
      const authResponse = await axios.get(authUrl);
      let res = atob(authResponse.data.data);
      let jsn = JSON.parse(res);
      const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
        mode: CryptoJS.mode.CBC,
        iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
      });
      const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      if (decrypt.tfa && decrypt.phone !== "") {
        localStorage.setItem("mySession", JSON.stringify(decrypt));
      } else {
        localStorage.setItem("session", JSON.stringify(decrypt));
      }

      return true;
    } catch (authError) {
      localStorage.removeItem("token");
      return false;
    }
  } else {
    return false;
  }
}

export default AuthSession;
