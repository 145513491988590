import axios from "axios";

function FACodeSend(obj) {
  const url = `${process.env.REACT_APP_API}/api/user/sendCode`;

  axios
    .post(url, obj)
    .then((response) => {
      // localStorage.setItem("2FAToken", response.data.token);
      localStorage.setItem("2fatoken", response.data.token);
    })
    .catch((error) => {
      if (error.response.status === 400) {
      } else if (error.response.status === 403) {
      } else {
      }
    });
}

export default FACodeSend;
