// const currentUser = JSON.parse(localStorage.getItem("session"));
// const currentUserId = currentUser?._id;

export const fetchDataFromAPI = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/user/bank/get-details`
    );
    const data = await response.json();
    return data.bankDetails;
  } catch (error) {}
};

export const fetchSubscriptionApi = async (currentUserId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/user/bank/subscription-charge/${currentUserId}`
    );
    const data = await response.json();

    if (data.status === "400" && data.message === "User already paid!") {
      return data;
    } else if (data.status === "200") {
      return data;
    } else {
      return data.error;
    }
  } catch (error) {
    return null;
  }
};
