import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
} from "swiper";
import ReactPlayer from "react-player";

import { Modal } from "react-bootstrap";
import ReactJWPlayer from "react-jw-player";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { activeSlideStyles } from "../backend/podcast/podCast";
import { useTranslation } from "react-i18next";

import {
  MoviesUpComingSliderApi,
  MoviesGetLatestSliderApi,
  MoviesTopRatedApi,
  MoviesByGenre,
  MoviesMainSliderApi,
  ALLSliderApi,
} from "../../Services/moviesApi";
import {
  assignAdToTarget,
  convertSecondsToHoursMinutes,
  handleAnchorClick,
  shuffleArray,
} from "../../Services/generalFunctions/generalFunctions";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../backend/home/FavWatch";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currentUserToken } from "../backend/home/home";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  generateLinkedinShareUrl,
  generateInstagramShareUrl,
  generateSnapchatShareUrl,
  generateTikTokShareUrl,
  generatePinterestShareUrl,
  generateTelegramShareUrl,
  generateRedditShareUrl,
  generateQuoraShareUrl,
  genreFacebookShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
  genreLinkedinShareUrl,
  genreInstagramShareUrl,
  genreSnapchatShareUrl,
  genreTikTokShareUrl,
  genrePinterestShareUrl,
  genreTelegramShareUrl,
  genreRedditShareUrl,
  genreQuoraShareUrl,
} from "../backend/home/ShareFunction";
import { limitTextToWords } from "../../Services/generalFunctions/generalFunctions";
import VideoDescriptionModal from "../../components/partials/UI/VideoDescriptionModal";
import { adClickApi, fetchAdsDataVideo } from "../../Services/adsApi";
import AdsModal from "../../components/partials/UI/AdsModal";
import Loader from "../../components/Loader";
import { Helmet } from 'react-helmet';
// install Swiper modules
SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
]);

const Movies = () => {
  const currentSelectedLanguage = localStorage.getItem("lang") || "fr";
  const [load, setLoad] = useState(20);
  const [MoviesListData, setMoviesListData] = useState([]);

  const [hoveredItem, setHoveredItem] = useState({});
  const [hoveredItemAd, setHoveredItemAd] = useState({});
  const modalTimerRef = useRef(null);
  const adModalTimeRef = useRef(null);

  const handleMouseEnter = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItem(item);

    modalTimerRef.current = setTimeout(() => {
      setShow1(true);
    }, 3000); // 5 seconds
  };

  const handleMouseEnterAd = (item) => {
    // Set the hovered item
    setHoveredItemAd(item);

    // Clear the previous timer, if any
    clearTimeout(adModalTimeRef.current);

    if (item?.autoplay === "instant") {
      // Autoplay with a delay of 1 second
      adModalTimeRef.current = setTimeout(() => {
        setShow2(true);
      }, 500); // half a second
    } else if (item?.autoplay === "3 seconds") {
      // Autoplay with a delay of 3 seconds
      adModalTimeRef.current = setTimeout(() => {
        setShow2(true);
      }, 3000);
    } else if (item?.autoplay === "off") {
      // No autoplay
      setShow2(false);
    }
  };

  const handleMouseLeaveAd = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(adModalTimeRef.current);
    // setHoveredItem(null);
  };

  const handleMouseLeave = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRef.current);
    // setHoveredItem(null);
  };

  const handleModalClose = () => {
    // Close the modal
    setShow1(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { t } = useTranslation();
  const [mainSliderData, setMainSliderData] = useState([]);
  const [trailerMediaId, setTrailerMediaId] = useState("");
  const [toggler1, setToggler1] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [latestApiData, setLatestApiData] = useState([]);
  const [upComingData, setUpComingData] = useState([]);
  const [topRatedData, setTopRatedData] = useState([]);
  const [videosGenre, setVideosGenre] = useState([]);
  const [loader, setLoader] = useState(true);

  const history = useHistory();
  const { id } = useParams();
  const source = "app";

  const location = useLocation();
  const isInitialRender = useRef(true);

  const getMediaInfo = async (mediaId, sourceMediaId) => {


    try {

      console.log("getting media info ")
      const response = await axios.get(`${process.env.REACT_APP_API}/api/admin/get-info-jwplayer-api/${mediaId}`);
      console.log(response);
  
      if (response.status === 200) {
        // set loading false
        // setIsUploading(false);

        const data = {
          duration : response.data.duration,
          mediaId : sourceMediaId
        }
        await updateMediaField(data)
        return response.data;

      } else {
        return false;
      }
    } catch (error) {
      console.error('Error while getting media info:', error);
      return false;
    }
    
  };

  const updateMediaField = async (data) => {
    
    var bodyData = {
      duration: data.duration,
    };

    console.log(bodyData);
      var apiResponse = await axios
        .put(
          `${process.env.REACT_APP_API}/api/media/upload-media-id/${data.mediaId}`,
          bodyData,
          {
            headers: {
              Authorization: `Bearer ${currentUserToken}`,
              source: "administer",
            },
          }
        )
        // onMediaUpload needed in redux state
        .then(async (onMediaUpload) => {
          console.log(onMediaUpload);
        })
        .catch(async (onMediaUploadError) => {
          console.log(onMediaUploadError);
        });
  }



  // useEffect(() => {
  //   if (
  //     !isInitialRender.current &&
  //     currentSelectedLanguage &&
  //     location.pathname.includes("/videos")
  //   ) {
  //     history.push("/");
  //   }
  //   isInitialRender.current = false;
  // }, [currentSelectedLanguage, history, location]);
  useEffect(() => {
    ALLSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent?.length > 0) {
          const allDataWithDurationNull = data.upcomingContent.filter(d => d?.media?.duration === 0);
          console.log(allDataWithDurationNull);
          if(allDataWithDurationNull.length > 0 ){
            allDataWithDurationNull.forEach(da => {
              getMediaInfo(da?.media?.media_id, da?.media?._id)
          });
          }
          setMoviesListData(data.upcomingContent);
        } else {
          setMoviesListData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "Movies List error");
      });
  }, []);
  useEffect(() => {
    setLoader(true);
    MoviesMainSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.allSliders && data.allSliders?.length > 0) {
          setMainSliderData(data.allSliders);
        } else {
          setMainSliderData([]);
        }
        setLoader(false);
      })
      .catch((error) => {
        // console.log(error, "Main Slider error");
      });
  }, []);
  // Latest data
  useEffect(() => {
    MoviesGetLatestSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent?.length > 0) {
          setLatestApiData(data.upcomingContent);
        } else {
          setLatestApiData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "Movies latestApiData Slider error");
      });
  }, []);
  // Upcoming Movies data
  useEffect(() => {
    MoviesUpComingSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent?.length > 0) {
          setUpComingData(data.upcomingContent);
          const randomAds =
            Math.random() * (data?.upcomingContent?.length - 0) + 0;
        } else {
          setUpComingData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "Movies Upcoming Slider error");
      });
  }, []);

  useEffect(() => {
    MoviesTopRatedApi(currentSelectedLanguage)
      .then((data) => {
        if (
          data &&
          data?.general_contents &&
          data?.general_contents?.length > 0
        ) {
          setTopRatedData(data?.general_contents);
        } else {
          setTopRatedData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "Movies Upcoming Slider error");
      });
  }, []);

  useEffect(() => {
    MoviesByGenre(currentSelectedLanguage)
      .then((data) => {
        setVideosGenre(data?.upcomingContent);
      })
      .catch((error) => {
        // console.log(error, "videos By Genre Slider error");
      });
  }, []);
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex);
  };

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };

  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );

  function handleFavClick(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    if (favoriteItems.includes(id)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(id);
      toast.info(t("Content Removed From Favorite list!"), {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== id));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, id]);
          } else {
            toast.error(t("Error occurred at Fav List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  function handleWatchList(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Content Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at Watch List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }

    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item.general_content_id);
        setData(data);
      } catch (error) {
        // console.log(`Error fetching data: ${error}`);
      }
    };

    const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
    fetchData(watchListUrl, setWatchListItems);

    const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
    fetchData(favoriteItemsUrl, setFavoriteItems);
  }, [currentUserId]);

  // const textRef = useRef(null);
  // const [fontSize, setFontSize] = useState("16px");

  // useLayoutEffect(() => {
  //   const applyFontSize = () => {
  //     const textElement = textRef.current;
  //     if (textElement) {
  //       const lineHeight = parseInt(getComputedStyle(textElement).lineHeight);
  //       const maxHeight = lineHeight * 2;
  //       const currentHeight = textElement.clientHeight;

  //       if (currentHeight > maxHeight) {
  //         const currentFontSize = parseInt(
  //           getComputedStyle(textElement).fontSize
  //         );
  //         const newFontSize = Math.floor(
  //           (maxHeight / currentHeight) * currentFontSize
  //         );
  //         setFontSize(`${newFontSize}px`);
  //       }
  //     }
  //   };

  //   applyFontSize(); // Immediately apply font size adjustment

  //   // Re-apply font size adjustment on window resize
  //   const handleResize = () => {
  //     applyFontSize();
  //   };
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // get ads data
  const [adsData, setAdsData] = useState({});
  useEffect(() => {
    // Call the fetchAdsDataTvShow function
    const fetchData = async () => {
      try {
        const data = await fetchAdsDataVideo(currentSelectedLanguage);
        setAdsData(data?.adsList);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [adsCopyCopy, setAdsCopyCopy] = useState([]);
  const [topRatedDataCopy, setTopRatedDataCopy] = useState([]);
  const [upComingDataCopy, setUpComingDataCopy] = useState([]);
  const [latestDataCopy, setLatestDataCopy] = useState([]);

  useEffect(() => {

    const adsCopyCopyTemp = adsData.length > 0 ? [...adsData] : []
    const topRatedDataCopyTemp = [...topRatedData];
    const upComingDataCopyTemp = [...upComingData];
    const latestDataCopyTemp = [...latestApiData];

    // Assign ads from the adsCopyCopy to target
    assignAdToTarget(adsCopyCopy, topRatedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, upComingDataCopyTemp);
    assignAdToTarget(adsCopyCopy, latestDataCopyTemp);
    assignAdToTarget(adsCopyCopy, topRatedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, upComingDataCopyTemp);
    assignAdToTarget(adsCopyCopy, latestDataCopyTemp);

    // Update the state variables to reflect the changes
    setAdsCopyCopy(shuffleArray(adsCopyCopyTemp));
    setTopRatedDataCopy(shuffleArray(topRatedDataCopyTemp));
    setUpComingDataCopy(shuffleArray(upComingDataCopyTemp));
    setLatestDataCopy(shuffleArray(latestDataCopyTemp));

  }, [adsData, topRatedData, upComingData, latestApiData]);

  const handleAdClicked = (event, data) => {
    if (data?.category === "ads") {
      event.preventDefault(); // Prevent the default link behavior for ads category
      adClickApi(data?._id)
        .then(() => {
          window.open(data?.redirect_url, "_blank");
        })
        .catch((error) => {
          // console.error("Error handling after API call:", error);
        });
    }
  };

  return (
    <>
    <Helmet>
        <title>AFO Media | Videos </title>
        <meta property="og:title" content=" Videos "/>
        <meta  name="description" property="og:description" content="Liste des videos" />
        <meta property="og:site_name" content="AFO Media" />
    </Helmet>
      <ToastContainer
        toastStyle={{
          background: "black",
          color: "white",
        }}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ReactJWPlayer
            playerId="rGoke68A"
            playerScript="https://content.jwplatform.com/libraries/rGoke68A.js"
            playlist={`https://cdn.jwplayer.com/v2/media/${trailerMediaId}`}
            controls={false}
            isAutoPlay={false}
            licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
          // file="https://live-hls-web-aje.getaj.net/AJE/05.m3u8"
          // onReady={onReady}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <VideoDescriptionModal
            item={hoveredItem}
            handleClose={handleClose1}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <AdsModal item={hoveredItemAd} handleClose={handleClose2} />
        </Modal.Body>
      </Modal>
      {loader ? (
        <div style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          <section
            id="tvshow"
            className="iq-main-slider"
            style={{ overflow: "hidden", paddingTop: "5rem" }}
          >
            <div id="prev" className="swiper-button swiper-button-prev">
              <i className="ri-arrow-left-s-line"></i>
            </div>
            <div id="next" className="swiper-button swiper-button-next">
              <i className="ri-arrow-right-s-line"></i>
            </div>
            <Swiper
              slidesPerView={2}
              navigation={{
                prevEl: "#prev",
                nextEl: "#next",
              }}
              loop={true}
              centeredSlides={true}
              autoplay={{ delay: 4000 }}
              id="tvshows-slider"
              className="iq-rtl-direction"
              onSlideChange={handleSlideChange}
              initialSlide={1}
              breakpoints={{
                320: { slidesPerView: 1 },
                720: { slidesPerView: 2 },
              }}
            >
              {mainSliderData?.map((mainSlider, index) => (
                <SwiperSlide
                  key={index}
                  className={`mt-5 ${activeSlideIndex === index ? "active" : ""
                    }`}
                  style={{
                    marginTop: "6rem",
                    ...(activeSlideIndex === index ? activeSlideStyles : {}),
                  }}
                >
                  <div
                    style={{
                      minWidth: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="shows-img">
                      <img
                        src={
                          mainSlider?.general_content?.thumbnail
                            ?.banner_thumbnail_url
                        }
                        className="w-100 "
                        alt=""
                      />
                      <Col
                        xl="9"
                        lg="12"
                        md="12"
                        sm="12"
                        style={{
                          position: "absolute",
                          zIndex: "10000",
                          bottom: "10%",
                        }}
                        className="new-video-slider"
                      >
                        <h1
                          // style={{ fontSize: fontSize }}
                          // ref={textRef}
                          className="slider-text big-title title text-uppercase heading-length "
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          {mainSlider?.category === "movie" ||
                            mainSlider?.category === "video"
                            ? mainSlider?.general_content?.media?.title
                            : mainSlider?.show?.title}
                        </h1>

                        <div className="d-flex flex-wrap align-items-center">
                          <p
                            data-iq-gsap="onStart"
                            data-iq-position-y="80"
                            data-iq-delay="0.8"
                            className="paraOverflow"
                            dangerouslySetInnerHTML={{
                              __html:
                                mainSlider?.category === "movie" ||
                                  mainSlider?.category === "video"
                                  ? mainSlider?.general_content?.media
                                    ?.description
                                  : mainSlider?.show?.description,
                            }}
                          >
                            {/* {mainSlider?.category === "movie" ||
                              mainSlider?.category === "video"
                                ? mainSlider?.general_content?.media
                                    ?.description
                                : mainSlider?.show?.description} */}
                          </p>
                        </div>
                        <div
                          className="trending-list"
                          data-wp_object-in="fadeInUp"
                          data-delay-in="1.2"
                        >
                          {mainSlider?.category === "movie" ||
                            mainSlider?.category === "video"
                            ? mainSlider?.general_content?.genre?.length >
                            0 && (
                              <div className="text-primary title genres">
                                {t("Genres")}:
                                {mainSlider?.general_content?.genre?.map(
                                  (genreObj, index) => (
                                    <span
                                      style={{
                                        color: "white",
                                        marginLeft: "5px",
                                      }}
                                      key={genreObj.name}
                                    >
                                      {genreObj.name}
                                      {index !==
                                        mainSlider?.general_content?.genre
                                          ?.length -
                                        1 && ","}
                                    </span>
                                  )
                                )}
                              </div>
                            )
                            : mainSlider?.show?.genre?.length > 0 && (
                              <div className="text-primary title genres">
                                {t("Genres")}:
                                {mainSlider?.show?.genre?.map(
                                  (genreObj, index) => (
                                    <span
                                      style={{
                                        color: "white",
                                        marginLeft: "5px",
                                      }}
                                      key={genreObj.name}
                                    >
                                      {genreObj.name}
                                      {index !==
                                        mainSlider?.show?.genre?.length - 1 &&
                                        ","}
                                    </span>
                                  )
                                )}
                              </div>
                            )}

                          {mainSlider?.category === "movie" ||
                            mainSlider?.category === "video"
                            ? mainSlider?.general_content?.media?.seo_tags
                              ?.length > 0 && (
                              <div className="text-primary title tag">
                                {t("tags").toUpperCase()}:
                                {mainSlider?.general_content?.media?.seo_tags?.map(
                                  (tag, index) => (
                                    <span className="text-body" key={tag}>
                                      {tag}
                                      {index !==
                                        mainSlider?.general_content?.media
                                          ?.seo_tags?.length -
                                        1 && ","}
                                    </span>
                                  )
                                )}
                              </div>
                            )
                            : mainSlider?.show?.media?.seo_tags?.length > 0 && (
                              <div className="text-primary title tag">
                                Tag:
                                {mainSlider?.show?.media?.seo_tags?.map(
                                  (tag, index) => (
                                    <span className="text-body" key={tag}>
                                      {tag}
                                      {index !==
                                        mainSlider?.show?.media?.seo_tags
                                          ?.length -
                                        1 && ","}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                        </div>

                        <div
                          className="buttons-container r-mb-23 video-page-btn"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          <Link
                            to={
                              mainSlider?.category === "movie" ||
                                mainSlider?.category === "video"
                                ? `/video-details/${source}/${mainSlider?.general_content?._id}`
                                : `/show-info/${mainSlider?.show?._id}`
                            }
                            className="btn btn-hover "
                            style={{ marginTop: "5px" }}
                          >
                            <i
                              className="fa fa-solid fa-play fa-beat mr-2"
                              aria-hidden="true"
                            ></i>
                            {t("Play now")}
                          </Link>
                          {mainSlider?.general_content?.trailer?.media_id !==
                            "" && (
                              <div
                                onClick={() => {
                                  setTrailerMediaId(
                                    mainSlider?.general_content?.trailer
                                      ?.media_id || ""
                                  );
                                  handleShow();
                                }}
                                to={`/video-details/${source}/${mainSlider?.general_content?._id}`}
                                className="video-open playbtn "
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  width="70px"
                                  height="70px"
                                  viewBox="0 0 213.7 213.7"
                                  enableBackground="new 0 0 213.7 213.7"
                                  xmlSpace="preserve"
                                >
                                  <polygon
                                    className="triangle"
                                    fill="none"
                                    strokeWidth="7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    points="73.5,62.5 148.5,105.8 73.5,149.1 "
                                  />
                                  <circle
                                    className="circle"
                                    fill="none"
                                    strokeWidth="7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    cx="106.8"
                                    cy="106.8"
                                    r="103.3"
                                  />
                                </svg>
                                <span className="w-trailor-slider">
                                  Watch Trailer
                                </span>
                              </div>
                            )}
                        </div>
                      </Col>
                      {/* <div className="shows-content">
                    <h1
                      className="slider-text-video big-title title text-uppercase "
                      data-iq-gsap="onStart"
                      data-iq-position-x="-200"
                      style={{
                        whiteSpace: "wrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "500px",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                      }}
                    >
                      {mainSlider?.general_content?.media?.title}
                    </h1>
                    <div className="movie-time para-play d-flex align-items-start justify-content-between">
                      <p
                        data-iq-gsap="onStart"
                        data-iq-position-y="80"
                        data-iq-delay="0.8"
                        className="mainSlider-Movie paraOverflow"
                      >
                        {mainSlider?.general_content?.media?.description}
                      </p>
                      <Link
                        onClick={() => {
                          setToggler1(!toggler1);
                          setTrailerMediaId(
                            mainSlider?.general_content?.trailer?.media_id ||
                              " "
                          );
                        }}
                        to={`/video-details/${source}/${mainSlider?.general_content?._id}`}
                        className="video-open playbtn ml-2"
                      >
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="70px"
                          height="70px"
                          viewBox="0 0 213.7 213.7"
                          enableBackground="new 0 0 213.7 213.7"
                          xmlSpace="preserve"
                        >
                          <polygon
                            className="triangle"
                            fill="none"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="73.5,62.5 148.5,105.8 73.5,149.1 "
                          />
                          <circle
                            className="circle"
                            fill="none"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            cx="106.8"
                            cy="106.8"
                            r="103.3"
                          />
                        </svg>
                        <span className="w-trailor-slider">Watch Trailer</span>
                      </Link>
                    </div>
                    <div
                      className="trending-list"
                      data-wp_object-in="fadeInUp"
                      data-delay-in="1.2"
                    >
                      <div className="text-primary title genres">
                        Categories:
                        {mainSlider?.general_content?.genre?.map(
                          (genreObj, index) => (
                            <span
                              style={{
                                color: "white",
                                marginLeft: "5px",
                              }}
                              key={genreObj.name}
                            >
                              {genreObj.name}
                              {index !==
                                mainSlider?.general_content?.genre?.length -
                                  1 && ","}
                            </span>
                          )
                        )}
                      </div>

                      <div className="text-primary title tag">
                        Tags:{" "}
                        {mainSlider?.general_content?.media?.seo_tags.map(
                          (tag, index) => (
                            <span className="text-body" key={tag}>
                              {tag}
                              {index !==
                                mainSlider?.general_content?.media?.seo_tags
                                  ?.length -
                                  1 && ","}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                    <div
                      className="d-flex r-mb-23"
                      data-iq-gsap="onStart"
                      data-iq-position-y="80"
                      data-iq-delay="0.8"
                    >
                      <Link
                        to={`/video-details/${source}/${mainSlider?.general_content?._id}`}
                        className="btnMain btn-hover iq-button"
                      >
                        <i className="fa fa-play mr-2" aria-hidden="true"></i>
                        {t("Play now")}
                      </Link>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
         
          <Container fluid className="mt-5">
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" className="overflow-hidden">
                {MoviesListData?.length > 0 ? (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="main-title pb-3">{t("ALL")}</h4>
                      <Link className="iq-view-all" to="/all-videos">
                        View More
                      </Link>
                    </div>
                    <div className="">
                      <div id="upcoming-contents">
                        <ul className="list-inline mb-0 iq-rtl-direction row">
                          {MoviesListData?.slice(0, load).map((data) => (
                            <li
                              className="all-card-container slide-item  mb-4 col-lg-3 col-md-4 col-sm-6"
                              key={data._id}
                              onMouseEnter={() => handleMouseEnter(data)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <Link
                                to={`/video-details/${source}/${data?._id}`}
                              >
                                <div className="block-images position-relative">
                                  <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {/* {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                          ? "Episode"
                                          : "Episodes"
                                        }`} */}
                                    </div>
                                  </div>
                                  <div className="img-box">
                                    <img
                                      src={
                                        data?.thumbnail?.banner_thumbnail_url
                                      }
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  {/* <div className="block-description">
                                    <h6 className="iq-title all-title-desc">
                                      {currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.media?.title } 
                                    </h6>
                                    <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description  ,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div>
                                    <div className="hover-buttons">
                                      <Link
                                        to={`/video-details/app/${data?._id}`}
                                        role="button"
                                        className="btn btn-hover iq-button btn-typ"
                                      >
                                        <i
                                          className="fa fa-play mr-1"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Play now")}
                                      </Link>
                                    </div>
                                  </div> */}
                                  {/* <div className="block-social-info">
                                    <ul className="list-inline p-0 m-0 music-play-lists">
                                      <li className="share">
                                        <span>
                                          <i className="ri-share-fill"></i>
                                        </span>
                                        <div className="share-box">
                                          <div className="d-flex align-items-center">
                                            <a
                                              href={generateLinkedinShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-linkedin-fill"></i>
                                            </a>
                                            <a
                                              href={generateInstagramShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-instagram-fill"></i>
                                            </a>
                                            <a
                                              href={generateSnapchatShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-snapchat-fill"></i>
                                            </a>
                                            <a
                                              href={generateTikTokShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-tiktok-fill"></i>
                                            </a>
                                            <a
                                              href={generateFacebookShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-facebook-fill"></i>
                                            </a>
                                            <a
                                              href={generateTwitterShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-twitter-fill"></i>
                                            </a>
                                            <a
                                              href={generateWhatsappShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico iq-copy-link"
                                              tabIndex="0"
                                            >
                                              <i className="ri-whatsapp-fill"></i>
                                            </a>
                                            <a
                                              href={generatePinterestShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-pinterest-fill"></i>
                                            </a>
                                            <a
                                              href={generateTelegramShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-telegram-fill"></i>
                                            </a>
                                            <a
                                              href={generateRedditShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-reddit-fill"></i>
                                            </a>
                                            <a
                                              href={generateQuoraShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-quora-fill"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-heart-fill ${favoriteItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                              }`}
                                            onClick={(event) =>
                                              handleFavClick(
                                                event,
                                                data?._id,
                                                data?.category
                                              )
                                            }
                                            title={
                                              favoriteItems.includes(data?._id)
                                                ? t("Remove From Favourite")
                                                : t("Add To Favourite")
                                            }
                                          ></i>
                                        </span>
                                        {/* <span className="count-box">19+</span> */}
                                      {/* </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-add-line ${watchListItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                              }`}
                                            onClick={(event) =>
                                              handleWatchList(
                                                event,
                                                data?._id,
                                                data?.category
                                              )
                                            }
                                            title={
                                              watchListItems.includes(data?._id)
                                                ? t("Remove From WatchList")
                                                : t("Add To WatchList")
                                            }
                                          ></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </div> */} 
                                </div>

                                <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data.media.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>

                                  
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {MoviesListData?.length > load && (
                      <Link to="/all-videos">
                        <button className="btn btn-hover btn-typ" type="button">
                          <span>{t("load more")}</span>
                        </button>
                      </Link>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
          </Container>
          <div className="main-content">
            {upComingDataCopy?.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Upcoming")}</h4>
                        {/* <Link className="iq-view-all" to="/">
                  View All
                </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev2"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next2"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev2",
                            nextEl: "#next2",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                        >
                          {upComingDataCopy?.map((data) => (
                            <SwiperSlide as="li">
                              <Link
                                to={
                                  data?.category !== "ads"
                                    ? `/video-details/${source}/${data?._id}`
                                    : null
                                }
                                onClick={(event) =>
                                  handleAdClicked(event, data)
                                }
                              >
                                <div
                                  className="block-images position-relative slider-img-main"
                                  onMouseEnter={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseEnter(data);
                                    } else {
                                      handleMouseEnterAd(data);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseLeave();
                                    } else {
                                      handleMouseLeaveAd();
                                    }
                                  }}
                                // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                >
                                  <div className="img-box">
                                    {/* Only render the video if the media category is "video" or "movie" */}
                                    {data?.category === "movie" ? (
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    ) : data?.media_id !== "" ? (
                                      <ReactPlayer
                                        className="img-fluid slider-img"
                                        url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                        width="100%"
                                        height="100%"
                                        pip={false}
                                        controls={false}
                                        playing={true}
                                        muted={true}
                                        loop={true}
                                      />
                                    ) : (
                                      <img
                                        src={data?.banner_url}
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  {/* showing on the top */}
                                  <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : data?.category === "ads"
                                          ? null
                                          : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="block-description ">
                                    <div className="card-slider-content">
                                      {data?.category === "movie" ||
                                        data?.category === "video" ? (
                                        <h6 className="iq-title title-desc">
                                       
                                          {currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.media?.title } 
                                        </h6>
                                      ) : (
                                        <h6 className="iq-title title-desc">
                                          {data?.title}
                                        </h6>
                                      )}
                                    </div>
                                    <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                              currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description,
                                            12
                                          ),


                                          // data?.category === "movies"
                                          // ?  (currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description) 
                                          // : data?.description,
                                        }}
                                      ></span>
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info-button">
                                        <li className="share">
                                          <div className="hover-buttons">
                                            {data?.category === "movie" ||
                                              data?.category === "video" ? (
                                              <Link
                                                to={`/video-details/${source}/${data?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            ) : (
                                              <Link
                                                to={`/show-info/${data?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            )}
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div>
                                  {data?.category !== "ads" && (
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              <a
                                                href={generateLinkedinShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-linkedin-fill"></i>
                                              </a>
                                              <a
                                                href={generateInstagramShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-instagram-fill"></i>
                                              </a>
                                              <a
                                                href={generateSnapchatShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-snapchat-fill"></i>
                                              </a>
                                              <a
                                                href={generateTikTokShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-tiktok-fill"></i>
                                              </a>
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                              <a
                                                href={generatePinterestShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-pinterest-fill"></i>
                                              </a>
                                              <a
                                                href={generateTelegramShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-telegram-fill"></i>
                                              </a>
                                              <a
                                                href={generateRedditShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-reddit-fill"></i>
                                              </a>
                                              <a
                                                href={generateQuoraShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-quora-fill"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {topRatedDataCopy?.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Top Rated")}</h4>
                        {/* <Link className="iq-view-all" to="/">
                    View All
                  </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev2"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next2"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev2",
                            nextEl: "#next2",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                        >
                          {topRatedDataCopy?.map((data) => (
                            <SwiperSlide as="li">
                              <Link
                                to={
                                  data?.category !== "ads"
                                    ? `/video-details/${source}/${data?._id}`
                                    : null
                                }
                                onClick={(event) =>
                                  handleAdClicked(event, data)
                                }
                              >
                                <div
                                  className="block-images position-relative slider-img-main"
                                  onMouseEnter={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseEnter(data);
                                    } else {
                                      handleMouseEnterAd(data);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseLeave();
                                    } else {
                                      handleMouseLeaveAd();
                                    }
                                  }}
                                // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                >
                                  <div className="img-box">
                                    {/* Only render the video if the media category is "movie" */}
                                    {data?.category === "movie" ? (
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    ) : data?.media_id !== "" ? (
                                      <ReactPlayer
                                        className="img-fluid slider-img"
                                        url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                        width="100%"
                                        height="100%"
                                        pip={false}
                                        controls={false}
                                        playing={true}
                                        muted={true}
                                        loop={true}
                                      />
                                    ) : (
                                      <img
                                        src={data?.banner_url}
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  {/* showing on the top */}
                                  {/* <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : data?.category === "ads"
                                          ? null
                                          : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="block-description ">
                                    <div className="card-slider-content">
                                      {data?.category === "movie" ||
                                        data?.category === "video" ? (
                                        <h6 className="iq-title title-desc">
                                          {data?.media?.title}
                                          {currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.media?.title } 
                                        </h6>
                                      ) : (
                                        <h6 className="iq-title title-desc">
                                          {data?.title}
                                        </h6>
                                      )}
                                    </div>
                                    <div className="card-slider-desc">
                                      <span
                                         dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                              currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info-button">
                                        <li className="share">
                                          <div className="hover-buttons">
                                            {data?.category === "movie" ||
                                              data?.category === "video" ? (
                                              <Link
                                                to={`/video-details/${source}/${data?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            ) : (
                                              <Link
                                                to={`/show-info/${data?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            )}
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div> */}
                                  {/* {data?.category !== "ads" && (
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              <a
                                                href={generateLinkedinShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-linkedin-fill"></i>
                                              </a>
                                              <a
                                                href={generateInstagramShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-instagram-fill"></i>
                                              </a>
                                              <a
                                                href={generateSnapchatShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-snapchat-fill"></i>
                                              </a>
                                              <a
                                                href={generateTikTokShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-tiktok-fill"></i>
                                              </a>
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                              <a
                                                href={generatePinterestShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-pinterest-fill"></i>
                                              </a>
                                              <a
                                                href={generateTelegramShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-telegram-fill"></i>
                                              </a>
                                              <a
                                                href={generateRedditShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-reddit-fill"></i>
                                              </a>
                                              <a
                                                href={generateQuoraShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-quora-fill"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        {/* </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  )} */} 
                                </div>

                                <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data.media.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {/* <img src={logo} alt="" /> */}
            {latestDataCopy?.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Latest")}</h4>
                        {/* <Link className="iq-view-all" to="/">
                  View All
                </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev2"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next2"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev2",
                            nextEl: "#next2",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                        >
                          {latestDataCopy?.map((data) => (
                            <SwiperSlide as="li">
                              <Link
                                to={
                                  data?.category !== "ads"
                                    ? `/video-details/${source}/${data?._id}`
                                    : null
                                }
                                onClick={(event) =>
                                  handleAdClicked(event, data)
                                }
                              >
                                <div
                                  className="block-images position-relative slider-img-main"
                                  onMouseEnter={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseEnter(data);
                                    } else {
                                      handleMouseEnterAd(data);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseLeave();
                                    } else {
                                      handleMouseLeaveAd();
                                    }
                                  }}
                                // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                >
                                  <div className="img-box">
                                    {/* Only render the video if the media category is "video" or "movie" */}
                                    {data?.category === "movie" ? (
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    ) : data?.media_id !== "" ? (
                                      <ReactPlayer
                                        className="img-fluid slider-img"
                                        url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                        width="100%"
                                        height="100%"
                                        pip={false}
                                        controls={false}
                                        playing={true}
                                        muted={true}
                                        loop={true}
                                      />
                                    ) : (
                                      <img
                                        src={data?.banner_url}
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  {/* showing on the top */}
                                  {/* <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : data?.category === "ads"
                                          ? null
                                          : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="block-description ">
                                    <div className="card-slider-content">
                                      {data?.category === "movie" ||
                                        data?.category === "video" ? (
                                        <h6 className="iq-title title-desc">
                                        {currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.media?.title } 
                                        </h6>
                                      ) : (
                                        <h6 className="iq-title title-desc">
                                          {data?.title}
                                        </h6>
                                      )}
                                    </div>
                                    <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            currentSelectedLanguage === "en" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description_translated
                                              ,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info-button">
                                        <li className="share">
                                          <div className="hover-buttons">
                                            {data?.category === "movie" ||
                                              data?.category === "video" ? (
                                              <Link
                                                to={`/video-details/${source}/${data?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            ) : (
                                              <Link
                                                to={`/show-info/${data?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            )}
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div> */}
                                  {/* {data?.category !== "ads" && (
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              <a
                                                href={generateLinkedinShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-linkedin-fill"></i>
                                              </a>
                                              <a
                                                href={generateInstagramShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-instagram-fill"></i>
                                              </a>
                                              <a
                                                href={generateSnapchatShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-snapchat-fill"></i>
                                              </a>
                                              <a
                                                href={generateTikTokShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-tiktok-fill"></i>
                                              </a>
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                              <a
                                                href={generatePinterestShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-pinterest-fill"></i>
                                              </a>
                                              <a
                                                href={generateTelegramShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-telegram-fill"></i>
                                              </a>
                                              <a
                                                href={generateRedditShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-reddit-fill"></i>
                                              </a>
                                              <a
                                                href={generateQuoraShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-quora-fill"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        {/* </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  )} */} 
                                    <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data.media.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

            {videosGenre?.length > 0 &&
              videosGenre?.map((data, index) => {
                // if (data?.filteredContent?.length > 0) {
                //   return (
                //     <section id="iq-upcoming-movie">
                //       <div className="container-fluid">
                //         <div className="row">
                //           <div className="col-sm-12 overflow-hidden">
                //             <div className="d-flex align-items-center justify-content-between">
                //               <h4 className="main-title">{t(data.genre)}</h4>
                //               {/* <Link className="iq-view-all" to="/">
                //           View All
                //         </Link> */}
                //             </div>

                //             <div id="upcoming-contens">
                //               <div
                //                 id={`prev2${index}`}
                //                 className="swiper-button swiper-button-prev"
                //               >
                //                 <i className="fa fa-chevron-left"></i>
                //               </div>
                //               <div
                //                 id={`next2${index}`}
                //                 className="swiper-button swiper-button-next"
                //               >
                //                 <i className="fa fa-chevron-right"></i>
                //               </div>
                //               <Swiper
                //                 slidesPerView={4}
                //                 spaceBetween={20}
                //                 navigation={{
                //                   nextEl: `#prev2${index}`,
                //                   prevEl: `#next2${index}`,
                //                 }}
                //                 loop={false}
                //                 breakpoints={{
                //                   320: { slidesPerView: 1 },
                //                   550: { slidesPerView: 2 },
                //                   991: { slidesPerView: 3 },
                //                   1400: { slidesPerView: 4 },
                //                   1600: { slidesPerView: 5 },
                //                 }}
                //                 className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                //               >
                //                 {data?.filteredContent?.map((Obj, index) => (
                //                   <SwiperSlide as="li">
                //                     <Link
                //                       to={`/video-details/${source}/${Obj?._id}`}
                //                     >
                //                       <div
                //                         className="block-images position-relative slider-img-main"
                //                         onMouseEnter={() =>
                //                           handleMouseEnter(data)
                //                         }
                //                         onMouseLeave={handleMouseLeave}
                //                       // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                //                       >
                //                         <div className="img-box">
                //                           <img
                //                             src={
                //                               Obj?.thumbnail
                //                                 ?.banner_thumbnail_url
                //                             }
                //                             className="img-fluid slider-img"
                //                             alt=""
                //                           />
                //                         </div>
                //                         {/* showing on the top */}
                //                         <div className="card-slider-badge">
                //                           <div className="badge badge-secondary p-1 mr-2">
                //                             {Obj?.category === "movie" ||
                //                               Obj?.category === "video"
                //                               ? convertSecondsToHoursMinutes(
                //                                 Obj?.media?.duration.toFixed(
                //                                   2
                //                                 )
                //                               )
                //                               : `${Obj?.episodes?.length} ${Obj?.episodes?.length === 1
                //                                 ? "Episode"
                //                                 : "Episodes"
                //                               }`}
                //                           </div>
                //                         </div>
                //                         <div className="block-description ">
                //                           <div className="card-slider-content">
                //                             {Obj?.category === "movie" ||
                //                               Obj?.category === "video" ? (
                //                               <h6 className="iq-title title-desc">
                //                                 {Obj?.media?.title}
                //                               </h6>
                //                             ) : (
                //                               <h6 className="iq-title title-desc">
                //                                 {Obj?.title}
                //                               </h6>
                //                             )}
                //                           </div>
                //                           <div className="card-slider-desc">
                //                             <span
                //                               dangerouslySetInnerHTML={{
                //                                 __html: limitTextToWords(
                //                                   Obj?.media?.description,
                //                                   12
                //                                 ),
                //                               }}
                //                             ></span>
                //                           </div>
                //                           <div className="block-social-info-button">
                //                             <li className="share">
                //                               <div className="hover-buttons">
                //                                 {Obj?.category === "movie" ||
                //                                   Obj?.category === "video" ? (
                //                                   <Link
                //                                     to={`/video-details/${source}/${Obj?._id}`}
                //                                     className="btn btn-hover iq-button"
                //                                   >
                //                                     <i
                //                                       className="fa fa-play mr-2"
                //                                       aria-hidden="true"
                //                                     ></i>
                //                                     {t("Play now")}
                //                                   </Link>
                //                                 ) : (
                //                                   <Link
                //                                     to={`/show-info/${Obj?._id}`}
                //                                     className="btn btn-hover iq-button"
                //                                   >
                //                                     <i
                //                                       className="fa fa-play mr-2"
                //                                       aria-hidden="true"
                //                                     ></i>
                //                                     {t("Play now")}
                //                                   </Link>
                //                                 )}
                //                               </div>
                //                             </li>
                //                           </div>
                //                         </div>
                //                         <div className="block-social-info">
                //                           <ul className="list-inline p-0 m-0 music-play-lists">
                //                             <li className="share">
                //                               <span>
                //                                 <i className="ri-share-fill"></i>
                //                               </span>
                //                               <div className="share-box">
                //                                 <div className="d-flex align-items-center">
                //                                   <a
                //                                     href={genreFacebookShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-facebook-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreTwitterShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-twitter-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreWhatsappShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico iq-copy-link"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-whatsapp-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreLinkedinShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-linkedin-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreInstagramShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-instagram-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreSnapchatShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-snapchat-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreTikTokShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-tiktok-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genrePinterestShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-pinterest-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreTelegramShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-telegram-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreRedditShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-reddit-fill"></i>
                //                                   </a>
                //                                   <a
                //                                     href={genreQuoraShareUrl(
                //                                       Obj,
                //                                       source,
                //                                       Obj?.category,
                //                                       Obj?._id
                //                                     )}
                //                                     onClick={handleAnchorClick}
                //                                     target="_blank"
                //                                     rel="noopener noreferrer"
                //                                     className="share-ico"
                //                                     tabIndex="0"
                //                                   >
                //                                     <i className="ri-quora-fill"></i>
                //                                   </a>
                //                                 </div>
                //                               </div>
                //                             </li>
                //                             <li>
                //                               <span>
                //                                 <i
                //                                   className={`ri-heart-fill ${favoriteItems.includes(
                //                                     Obj?._id
                //                                   )
                //                                     ? "red-heart"
                //                                     : ""
                //                                     }`}
                //                                   onClick={(event) =>
                //                                     handleFavClick(
                //                                       event,
                //                                       Obj?._id,
                //                                       Obj?.category
                //                                     )
                //                                   }
                //                                   title={
                //                                     favoriteItems.includes(
                //                                       Obj?._id
                //                                     )
                //                                       ? t(
                //                                         "Remove From Favourite"
                //                                       )
                //                                       : t("Add To Favourite")
                //                                   }
                //                                 ></i>
                //                               </span>
                //                               {/* <span className="count-box">19+</span> */}
                //                             </li>
                //                             <li>
                //                               <span>
                //                                 <i
                //                                   className={`ri-add-line ${watchListItems.includes(
                //                                     Obj?._id
                //                                   )
                //                                     ? "red-heart"
                //                                     : ""
                //                                     }`}
                //                                   onClick={(event) =>
                //                                     handleWatchList(
                //                                       event,
                //                                       Obj?._id,
                //                                       Obj?.category
                //                                     )
                //                                   }
                //                                   title={
                //                                     watchListItems.includes(
                //                                       Obj?._id
                //                                     )
                //                                       ? t(
                //                                         "Remove From WatchList"
                //                                       )
                //                                       : t("Add To WatchList")
                //                                   }
                //                                 ></i>
                //                               </span>
                //                             </li>
                //                           </ul>
                //                         </div>
                //                       </div>
                //                     </Link>
                //                   </SwiperSlide>
                //                 ))}
                //               </Swiper>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     </section>

                //   );
                // } else {
                //   return null;
                // }
              })}
              



          </div>
        </>
      )}
    </>
  );
};
export default Movies;
