import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";
import { Row, Col, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { LatestSliderApi } from "../../../Services/SliderApi";
import { useTranslation } from "react-i18next";
import { limitTextToWords } from "../../../Services/generalFunctions/generalFunctions";
import "./live-channel.css";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  liveWhatsappShareUrl,
  liveFacebookShareUrl,
  liveTwitterShareUrl,
} from "../home/ShareFunction";

SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
]);

const containerStyle = {
  position: "relative",
  width: "100%",
  // height: "80%",
  paddingTop: "56.25%",
};

const playerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "80vh",
  paddingTop: "8rem",
};
const JwPlayerLiveChannel = () => {
  const lang = localStorage.getItem("lang");
  const history = useHistory();
  const location = useLocation();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (
      !isInitialRender.current &&
      lang &&
      location.pathname.includes("/live-channel/")
    ) {
      history.push("/");
    }
    isInitialRender.current = false;
  }, [lang, history, location]);

  const [channelData, setChannelData] = useState(null);
  const [movies, setMovies] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);

  const { chanelObj } = useParams();
  const { t } = useTranslation();

  const source = "app";
  const [width, setWidth] = useState(window.innerWidth);
  const currentSelectedLanguage = localStorage.getItem("lang");

  const fetchData = () => {
    fetch(
      `${process.env.REACT_APP_API}/api/channel/get-all-active-channels/${currentSelectedLanguage}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllData(result.allChannels);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentSelectedLanguage]);

  const channelsByGenre = allData?.reduce((acc, channel) => {
    channel.genre.forEach((genre) => {
      if (acc[genre._id]) {
        acc[genre._id].push(channel);
      } else {
        acc[genre._id] = [channel];
      }
    });
    return acc;
  }, {});

  useEffect(() => {
    LatestSliderApi("en")
      .then((data) => {
        if (data && data?.upcomingContent && data?.upcomingContent.length > 0) {
          setLatestData(data.upcomingContent);
        } else {
          setLatestData([]);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchChannelData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/channel/get-channel/${chanelObj}`
        );
        const data = await response.json();
        setChannelData(data);
        setLoader(false);
      } catch (error) {
        // console.error("Error fetching channel data:", error);
      }
    };

    fetchChannelData();
  }, []);

  // channel data
  const channelLink = channelData?.channel?.m3u_link;
  const channelTitle = channelData?.channel?.title;
  const channelDescripton = channelData?.channel?.description;
  const genres = channelData?.channel?.genre;
  let channelLanguage = channelData?.channel?.language_code;
  const channelType = channelData?.channel?.channel_type;
  const channelID = channelData?.channel?._id;

  let languageText;

  if (channelLanguage == "en") {
    languageText = "English";
  } else if (channelLanguage == "fr") {
    languageText = "French";
  } else if (channelLanguage == "so") {
    languageText = "Somali";
  } else {
    languageText = "Unknown";
  }

  // filtered channels in slider
  const filterChannelsByGenres = () => {
    return allData?.filter(
      (movie) =>
        movie?.genre &&
        movie?.genre?.some((genre) =>
          genres?.some(
            (desiredGenre) =>
              desiredGenre._id === genre._id || desiredGenre.name === genre.name
          )
        )
    );
  };

  const filteredChannels = filterChannelsByGenres();
  return (
    <>
      {loader ? (
        <div className="loader" style={{ minHeight: "100vh" }}>
          <div className="outer"></div>
          <div className="middle"></div>
          <div className="inner"></div>
        </div>
      ) : (
        <>
          <div
            className="container-fluid"
            style={{
              paddingBottom: "2rem",
              paddingTop: "8rem",
              // display: "flex",
              // justifyContent: "center",
              width: "100%",
            }}
          >
            <ReactJWPlayer
              playerId="4t00MwmP"
              playerScript="https://content.jwplatform.com/libraries/4t00MwmP.js"
              // playlist={`https://cdn.jwplayer.com/v2/media/${channell_id}`}
              controls={false}
              isAutoPlay={true}
              licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
              file={channelLink}
              // onReady={onReady}
            />
            <div className="main-content movi mt-1">
              <section className="movie-detail">
                <Row>
                  <Col lg="12">
                    <div className="trending-info g-border">
                      <div className="d-flex justify-content-between movie-info-container">
                        <div>
                          <div className="d-flex align-items-center gap-2">
                            <h1
                              className="trending-text big-title text-uppercase mt-0 mr-2"
                              style={{ display: "inline-block" }}
                            >
                              {channelTitle}
                            </h1>
                            <ul
                              className="list-inline p-0 mt-4 share-icons music-play-lists"
                              style={{ display: "inline-block" }}
                            >
                              <li className="share">
                                <span>
                                  <i className="ri-share-fill"></i>
                                </span>
                                <div className="share-box">
                                  <div className="d-flex align-items-center">
                                    <a
                                      href={liveFacebookShareUrl(
                                        channelID,
                                        channelType,
                                        channelTitle,
                                        channelDescripton
                                      )}
                                      className="share-ico"
                                    >
                                      <i className="ri-facebook-fill"></i>
                                    </a>
                                    <a
                                      href={liveTwitterShareUrl(
                                        channelID,
                                        channelType,
                                        channelTitle,
                                        channelDescripton
                                      )}
                                      className="share-ico"
                                    >
                                      <i className="ri-twitter-fill"></i>
                                    </a>
                                    <a
                                      href={liveWhatsappShareUrl(
                                        channelID,
                                        channelType,
                                        channelTitle,
                                        channelDescripton
                                      )}
                                      className="share-ico"
                                    >
                                      <i className="ri-whatsapp-fill"></i>
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div>
                            <ul className="p-0 list-inline d-flex movie-content flex-wrap">
                              {channelData?.channel?.genre?.map(
                                (genreObj, index) => (
                                  <li className="text-white" key={index}>
                                    {genreObj.name}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>

                          {/* <div
                        className="live-channel"
                        data-iq-delay="0.5"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            marginLeft: "20px",
                            marginRight: "8px",
                            cursor: "default",
                            transform: "scale(1.1)",
                            marginBottom: "5px",
                            minHeight: "2rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h6 className="text">{languageText}</h6>
                        </div>
                        
                      </div> */}
                          {channelData?.channel?.jw_tags?.length > 0 && (
                            <div className="movie-tags">
                              <div className=" text-white movie-desc-navigator__desc-text">
                                <i
                                  className="fa fa-tags mr-1"
                                  style={{
                                    color: "#21a6e0",
                                    fontSize: "17px",
                                  }}
                                ></i>
                                <span
                                  style={{
                                    color: "#21a6e0",
                                    marginRight: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  TAGS:
                                </span>
                                {channelData?.channel?.jw_tags?.map(
                                  (tag, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && ", "}
                                      <span className="text-white">{tag}</span>
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div>
                          <div
                            className="trailer-container"
                            // onClick={handleShowLoginModal}
                            style={{ cursor: "default" }}
                          >
                            <img
                              src={
                                channelData?.channel?.thumbnail
                                  ?.banner_thumbnail_url
                              }
                              alt="Background"
                              className="trailer-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </section>
              <section>
                <div className="movie-desc-navigator">
                  <button
                    className="movie-desc-navigator__active-btn movie-desc-navigator-btn "
                    style={{ cursor: "default" }}
                  >
                    {t("Description")}
                  </button>
                </div>
              </section>
              <section className="" style={{ marginBottom: "2rem" }}>
                <Row className=" fs-5 mt-5">
                  <Col lg="12" md="12" className="overflow-hidden">
                    <span className="movie-desc-navigator__desc-text ">
                      <p
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: channelDescripton,
                        }}
                      ></p>
                    </span>
                  </Col>
                </Row>
              </section>
            </div>
          </div>
          {filteredChannels?.length > 0 && (
            <section id="iq-favorites">
              <Container fluid>
                <Row>
                  <Col sm="12" className="overflow-hidden mt-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="main-title">{t("More like this")}</h4>
                    </div>
                    <div id="favorites-contens">
                      <div
                        id="prev1"
                        className="swiper-button swiper-button-prev"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <div
                        id="next1"
                        className="swiper-button swiper-button-next"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                      <Swiper
                        navigation={{
                          prevEl: "#prev1",
                          nextEl: "#next1",
                        }}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          550: { slidesPerView: 2 },
                          991: { slidesPerView: 3 },
                          1400: { slidesPerView: 4 },
                          1600: { slidesPerView: 5 },
                        }}
                        loop={false}
                        slidesPerView={4}
                        spaceBetween={20}
                        as="ul"
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                      >
                        {filteredChannels?.map((data) => (
                          <SwiperSlide as="li" key={data.id}>
                            <div
                              className="block-images position-relative slider-img-main"
                              // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                            >
                              <div className="img-box">
                                <img
                                  src={data?.thumbnail?.banner_thumbnail_url}
                                  className="img-fluid slider-img"
                                  alt=""
                                />
                              </div>
                              {/* showing on the top */}

                              <div className="block-description ">
                                <div className="card-slider-content">
                                  {data?.category === "movie" ||
                                  data?.category === "video" ? (
                                    <h6 className="iq-title title-desc">
                                      {data?.media?.title}
                                    </h6>
                                  ) : (
                                    <h6 className="iq-title title-desc">
                                      {data?.title}
                                    </h6>
                                  )}
                                </div>
                                <div className="card-slider-desc">
                                  <span>
                                    {limitTextToWords(data?.description, 12)}
                                  </span>
                                </div>
                                <div className="block-social-info-button">
                                  <li className="share">
                                    <div className="hover-buttons">
                                      {data?.channel_type === "m3u" ? (
                                        <a
                                          href={`/live-channel/${data?._id}`}
                                          className="btn btn-hover iq-button-live livebtn"
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </a>
                                      ) : (
                                        <a
                                          href={`/live/${data?._id}`}
                                          className="btn btn-hover iq-button-live livebtn"
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </a>
                                      )}
                                    </div>
                                  </li>
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href={generateFacebookShareUrl(
                                            data,
                                            source,
                                            data?.channel_type
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            data,
                                            source,
                                            data?.channel_type
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            data,
                                            source,
                                            data?.channel_type
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
          {Object.keys(channelsByGenre).map((genreId) => (
            <section id="iq-favorites" key={genreId}>
              <Container fluid>
                <Row>
                  <Col sm="12" className="overflow-hidden mt-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="main-title">
                        {
                          channelsByGenre[genreId][0].genre.find(
                            (genre) => genre._id === genreId
                          ).name
                        }
                      </h4>
                    </div>
                    <div id="favorites-contens">
                      <div
                        id="prev1"
                        className="swiper-button swiper-button-prev"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <div
                        id="next1"
                        className="swiper-button swiper-button-next"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                      <Swiper
                        navigation={{
                          prevEl: "#prev1",
                          nextEl: "#next1",
                        }}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          550: { slidesPerView: 2 },
                          991: { slidesPerView: 3 },
                          1400: { slidesPerView: 4 },
                          1600: { slidesPerView: 5 },
                        }}
                        loop={false}
                        slidesPerView={4}
                        spaceBetween={20}
                        as="ul"
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                      >
                        {channelsByGenre[genreId].map((channel) => (
                          <SwiperSlide as="li" key={channel.id}>
                            <div
                              className="block-images position-relative slider-img-main"
                              // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                            >
                              <div className="img-box">
                                <img
                                  src={channel.thumbnail.banner_thumbnail_url}
                                  className="img-fluid slider-img"
                                  alt=""
                                />
                              </div>
                              {/* showing on the top */}

                              <div className="block-description ">
                                <div className="card-slider-content">
                                  {channel?.category === "movie" ||
                                  channel?.category === "video" ? (
                                    <h6 className="iq-title title-desc">
                                      {channel?.media?.title}
                                    </h6>
                                  ) : (
                                    <h6 className="iq-title title-desc">
                                      {channel?.title}
                                    </h6>
                                  )}
                                </div>
                                <div className="card-slider-desc">
                                  <span>
                                    {limitTextToWords(channel?.description, 12)}
                                  </span>
                                </div>
                                <div className="block-social-info-button">
                                  <li className="share">
                                    <div className="hover-buttons">
                                      {channel?.channel_type === "m3u" ? (
                                        <a
                                          href={`/live-channel/${channel?._id}`}
                                          className="btn btn-hover iq-button-live livebtn"
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </a>
                                      ) : (
                                        <a
                                          href={`/live/${channel?._id}`}
                                          className="btn btn-hover iq-button-live livebtn"
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </a>
                                      )}
                                    </div>
                                  </li>
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href={generateFacebookShareUrl(
                                            channel,
                                            source,
                                            channel?.channel_type
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            channel,
                                            source,
                                            channel?.channel_type
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            channel,
                                            source,
                                            channel?.channel_type
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          ))}
        </>
      )}
    </>
  );
};

export default JwPlayerLiveChannel;
