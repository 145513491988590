import { Buffer } from "buffer";
import axios from "axios";
import generateRandomIv from "./generateRandomIv";
import CryptoJS from "crypto-js";
import postLoginData from "./postLoginData";
import sendEmailVerification from "./sendEmailVerification";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";

const btoa = (text) => {
  return Buffer.from(text, "binary").toString("base64");
};

const atob = (base64) => {
  return Buffer.from(base64, "base64").toString("binary");
};
function postSignUpData(
  obj,
  history,
  setuserExistError,
  setShowError,
  setShowLoginError,
  setLoginError,
  nextPath,
  setOtpValidated,
  isEmail
) {
  // if(obj['isEmail']){
  //   isEmail = true;
  // }
  // else if(!obj("isEmail"))
  // {
  //   isEmail = false;
  // }
  let msg = JSON.stringify(obj);
  const i = generateRandomIv(16);
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(i);

  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  var transitmessage = JSON.stringify({
    iv: btoa(i),
    value: encrypted.toString(),
  });
  transitmessage = btoa(transitmessage);

  let request = {
    data: transitmessage,
  };
  // // decryption of my input
  // let res = atob(request.data);
  // let jsn = JSON.parse(res);
  // const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
  //   mode: CryptoJS.mode.CBC,
  //   iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
  // });
  // const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  // //
  const url = `${process.env.REACT_APP_API}/api/user`;

  axios
    .post(url, request)
    .then(async (response) => {
      let res = atob(response.data.data);
      let jsn = JSON.parse(res);
      const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
        mode: CryptoJS.mode.CBC,
        iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
      });
      const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      console.log("je suis arrivé ici")
      try {
        await createUserWithEmailAndPassword(auth, decrypt.email, obj.password);
        console.log("user created successfully on firebase"); 
      } catch (err) {
        console.log(err.message);
      }
      postLoginData(
        { email: decrypt.email, password: obj.password },
        setShowLoginError,
        history,
        setLoginError,
        nextPath,
        setOtpValidated
      );
      localStorage.removeItem("email verification token");
      // history.push("/");
    })
    .catch((error) => {
      setuserExistError("Email Already Exists");
      // setShowError(true);
      setTimeout(() => {
        setuserExistError(false);
      }, 3000);
    });
}
export default postSignUpData;
