import React from "react";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(
  "pk_live_51NLC4BD2CJ2L9xz0gc6TtYctQHSR9GpPkOI5cj6GesmcQmKViy4ouTLefub690pZssafykc5ZicWOSireirjzQDF00FCin2OOg"
);
function Stripe({
  updateData,
  message,
  subscription,
  contentType,
  myObj,
  setHasUserPaid,
  donateFn,
  handleDonationClose,
}) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        updateData={updateData}
        message={message}
        subscription={subscription}
        contentType={contentType}
        myObj={myObj}
        setHasUserPaid={setHasUserPaid}
        donateFn={donateFn}
        handleDonationClose={handleDonationClose}
      />
    </Elements>
  );
}

export default Stripe;
