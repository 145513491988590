import axios from "axios";

function uploadProfilePicture(user_id, obj) {
  const url = `${process.env.REACT_APP_API}/api/user/upload-profile-picture/${user_id}`;
  return axios
    .patch(url, obj)
    .then((response) => {})
    .catch((error) => {
      return false;
    });
}

export default uploadProfilePicture;
