import { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PrivateRoute from "./PrivateRoute";
import UserAccountSettingList from "../views/backend/app/usermanagement/useraccountsetting";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Home
import Homepage from "../views/backend/home/home";
// extra
import FAQ from "../views/backend/pages/faq";
import TermsOfUse from "../views/backend/pages/extrapages/termsOfUse";
import PrivacyPolicy from "../views/backend/pages/extrapages/privacyPolicy";
import AboutUs from "../views/backend/pages/about-us";
import Contact from "../views/backend/pages/contact";
//Movie
import AddMovie from "../views/backend/movie/add-movie";
import MovieList from "../views/backend/movie/movie-list";
//Show
import ShowList from "../views/backend/show/show-list";
import GenreView from "./../views/backend/genrePage/genreView";
import Movies from "./../views/moviesPage/movies";
import TvShows from "./../views/backend/tv-shows/tvShows";
import SearchPage from "./../views/backend/SearchPage/searchPage";
import PodCast from "./../views/backend/podcast/podCast";
import Genre from "./../views/backend/genrePage/genre";
import ShowDetails from "../views/backend/show/show-details";
import showInfo from "../views/backend/show/show-info";
import History from "./../views/backend/historyPage/history";
import HistoryView from "./../views/backend/historyPage/historyView";
import LiveChannels from "./../views/backend/livechannels/liveChannels";
import ChannelPlayer from "../views/backend/livechannels/channelPlayer";
import UserFreeAccountSetting from "./../views/backend/app/usermanagement/userfreeaccountsetting";
import JwPlayerLiveChannel from "../views/backend/livechannels/jwPlayerLiveChannel";
import TempPlayer from "../views/backend/livechannels/tempPlayer";
import ALLMovies from "./../views/moviesPage/allMovies";
import AllTvShows from "./../views/backend/tv-shows/allTvShows";
import AllPodCast from "./../views/backend/podcast/allPodCast";
import FavouriteList from "../views/backend/FavWatchedList/favouriteList";
import WatchList from "./../views/backend/FavWatchedList/watchList";
import DonateLink from "../components/DonateLink";
import DeleteAccountPage from "../views/backend/account-delete/DeleteAccountPage";

const Layout1Route = () => {
  let location = useLocation();
  const currentSelectedLanguage = localStorage.getItem("lang");
  const [selectedLanguage, setSelectedLanguage] = useState(
    currentSelectedLanguage
  ); // Default language  as french
  const [userPermissions, setUserPermissions] = useState(null);
  const [permissionData, setPermissionData] = useState({});
  const history = useHistory();

  useEffect(() => {
    setSelectedLanguage(currentSelectedLanguage);
  }, [currentSelectedLanguage, localStorage, location]);

  const selectedLanguagee = localStorage.getItem("lang");
  const [menuItemsPermission, setMenuItemsPermission] = useState({});
  const selectedLanguageCode = selectedLanguagee?.code;
  const hasPermission = (permissionKey) => {
    return (
      userPermissions &&
      userPermissions[permissionKey] &&
      userPermissions[permissionKey][selectedLanguagee]
    );
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/permission/get-permission`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPermissionData(data?.permission);

        // Initialize the menuItemsPermission state based on the fetched data
        const permissionStatus = {};
        Object.keys(data?.permission).forEach((item) => {
          permissionStatus[item] =
            data?.permission[item][selectedLanguagee] === true;
        });
        setMenuItemsPermission(permissionStatus);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }, [selectedLanguagee]);

  return (
    <TransitionGroup>
      <CSSTransition
        // key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Switch location={location}>
          <PrivateRoute
            path="/profile/setting"
            component={UserAccountSettingList}
          />
          <Route
            path="/profile/free-setting"
            component={UserFreeAccountSetting}
          />
          <Route path="/" exact component={Homepage} />
          <Route path="/faq" component={FAQ} />
          <Route path="/terms-of-service" component={TermsOfUse} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/contact" component={Contact} />
          <Route path="/video-details/:source/:id" component={AddMovie} />
          <Route path="/video-category" component={MovieList} />  {/*page interessante qu'on peut utiliser */}
          <Route path="/episode/:id" component={ShowList} />
          <Route path="/videos" component={Movies} />
          {menuItemsPermission?.podcast && (
            <Route path="/podCast" component={PodCast} />
          )}
          {menuItemsPermission?.tvshow && (
            <Route path="/tv-shows" component={TvShows} />
          )}
          <Route path="/all-videos" component={ALLMovies} />
          <Route path="/all-tvshows" component={AllTvShows} />
          <Route path="/searchPage" component={SearchPage} />

          <Route path="/all-podcast" component={AllPodCast} />
          <Route path="/genre" component={Genre} />
          <Route path="/show-detailss/:id" component={ShowDetails} />
          <Route path="/show-info/:id" component={showInfo} />
          <Route path="/genreView/:id" component={GenreView} />
          <Route path="/history" component={History} />
          <Route path="/FavouriteList" component={FavouriteList} />
          <Route path="/WatchList" component={WatchList} />
          <Route path="/historyView/:genre_type/:id" component={HistoryView} />
          {menuItemsPermission?.channel && (
            <Route exact path="/live-channel" component={LiveChannels} />
          )}

          <Route path="/live/:chanelObj" component={TempPlayer} />

          <Route
            path="/live-channel/:chanelObj"
            component={JwPlayerLiveChannel}
          />
          <Route
            path={`/accountdeletion/:${selectedLanguage}`}
            component={DeleteAccountPage}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Layout1Route;
