import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { MdError } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
// services
import confirmPassword from "../../../../../Services/confirmPass";
import FACodeSend from "../../../../../Services/FACodeSend";
import verifyFACode from "../../../../../Services/verifyFACode.js";
// import PhoneInput from "react-phone-number-input";
import { PhoneInput } from "react-contact-number-input";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import updateUserDetails from "../../../../../Services/updateUserDetails";
import AuthSession from "../../../../../Services/getSessionAuth";

const Disable2FA = ({
  show,
  setShow,
  setSwitchState,
  updateData,
  userDetailsData,
}) => {
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  // screen width for otp input
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // phone number
  const [value, setValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneNum = (newValue) => {
    setValue(newValue);
    if (newValue && newValue.countryCode && newValue.phoneNumber) {
      setPhoneNumber(newValue.countryCode.concat(newValue.phoneNumber));
    } else {
      setPhoneNumber("");
    }
  };

  // verfification code

  // session data
  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));
    // Get the current date
    const currentDate = new Date();

    // Subtract 15 years from the current date
    currentDate.setFullYear(currentDate.getFullYear() - 15);

    // Format the date as "YYYY-MM-DD"
    const fifteenYearsAgo = currentDate.toISOString().substring(0, 10);
    return {
      _id: userDetails_Session._id,
      name: userDetails_Session.name,
      email: userDetails_Session.email,
      tfa:
        userDetails_Session.tfa !== undefined ? userDetails_Session.tfa : false,
      phone: userDetails_Session.phone,
      // dob: userDetails_Session.dob
      //   ? userDetails_Session.dob.substring(0, 10)
      //   : "2021-09-02",
      dob: userDetails_Session.dob
        ? userDetails_Session.dob.substring(0, 10)
        : fifteenYearsAgo,
      language: userDetails_Session.language
        ? userDetails_Session.language
        : "unknown",
      gender: userDetails_Session.gender
        ? userDetails_Session.gender
        : "unknown",
      country: userDetails_Session.country
        ? userDetails_Session.country
        : "N/A",
      password: userDetails_Session.password
        ? userDetails_Session.password
        : "N/A",
      profileIMG: userDetails_Session.profile_image?.url
        ? userDetails_Session.profile_image?.url
        : "",
      subscription: userDetails_Session.subscription,
      brand: userDetails_Session.brand,
      last4: userDetails_Session.last4,
    };
  };
  const [userDetails, setUserDetails] = useState(userDetailsData);

  const handleClose = () => {
    setShow(false);
    setForm2(false);
    setForm3(false);
    setSwitchState(userDetailsData.tfa);
    setConfirmPass("");
    setWrongPass(false);
  };
  const handleDone = () => {
    setShow(false);
    setForm2(false);
    setForm3(false);
    setSwitchState(userDetailsData.tfa);
  };
  // email
  const [email, setEmail] = useState(
    localStorage.getItem("session")
      ? JSON.parse(localStorage.getItem("session")).email
      : "name@example.com"
  );
  // password verification==========================
  // confirm password
  const [confirmPass, setConfirmPass] = useState("");
  const [wrongPass, setWrongPass] = useState(false);
  // verify password using login api
  const session = JSON.parse(localStorage.getItem("session") ?? "{}");
  const dataObj = {
    email: session.email,
    password: confirmPass,
  };
  const verifyPass = async () => {
    try {
      const result = await confirmPassword(dataObj);
      if (result) {
        setForm2(true);
        setWrongPass(false);
        setConfirmPass(""); // Clear the password field
        // send otp
        const codeObj = {
          email: userDetailsData?.email,
          phone: userDetailsData?.phone,
        };
        FACodeSend(codeObj);
        setTimer(60);
      }
    } catch (error) {
      setWrongPass(true);
      setTimeout(() => {
        setWrongPass(false);
      }, 3000);
    }
  };

  // resend code
  const resendCode = () => {
    const codeObj = {
      email: userDetailsData?.email,
      phone: userDetailsData?.phone,
    };
    FACodeSend(codeObj);
  };
  // verify otp
  // verfification code - OTP
  const [verificationCode, setVerificationCode] = useState("");
  const [wrongCode, setWrongCode] = useState(false);

  const verifyCode = async () => {
    const verifyObj = {
      email: userDetailsData.email,
      code: verificationCode,
      token: localStorage.getItem("2fatoken"),
    };

    try {
      const result = await verifyFACode(verifyObj);
      if (result) {
        // sending 2fa status
        const FaStatus = {
          _id: userDetailsData._id,
          tfa: false,
        };
        await updateUserDetails(FaStatus);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        updateData(updatedUserDetails);
        setUserDetails(updatedUserDetails);
        setForm3(true);
        setWrongCode(false);
        setVerificationCode("");
        localStorage.removeItem("2fatoken");
      }
    } catch (error) {
      setWrongCode(true);
      setTimeout(() => {
        setWrongCode(false);
      }, 3000);
    }
  };
  // timer
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Stop the interval when timer reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [timer]);

  const handleResendClick = () => {
    if (timer <= 0) {
      resendCode();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="enable-modal"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Disable Two-Step Authentication</Modal.Title>
      </Modal.Header>
      {!form2 ? (
        <>
          {" "}
          <Modal.Body style={{ marginLeft: "0.5rem" }}>
            <p>
              To continue, please enter your password. This will disable
              two-step authentication entirely
            </p>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ color: "var(--iq-body-text)" }}>
                  Email address
                </Form.Label>
                <Form.Control
                  className="dis-email"
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  disabled
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label style={{ color: "var(--iq-body-text)" }}>
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="your password"
                  value={confirmPass}
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                />
              </Form.Group>
              {wrongPass && (
                <span style={{ color: "red" }}> Wrong Password!</span>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {/* CHECK THE PASSWORD FROM API IF TRUE setForm2(true) else show error */}
            <Button
              variant="danger"
              disabled={confirmPass.length < 5 ? true : false}
              onClick={verifyPass}
            >
              Disable
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          {!form3 ? (
            <>
              <Modal.Body style={{ marginLeft: "0.5rem" }}>
                <p>
                  We’ve sent an SMS message containing a code to your mobile
                  device.
                  {/* Enter the authentication code below we sent to
                  {userDetailsData.phone} */}
                </p>
                <Form>
                  <div className="otp-code">
                    {" "}
                    {width > 425 ? (
                      <OtpInput
                        value={verificationCode}
                        onChange={setVerificationCode}
                        numInputs={6}
                        renderInput={(inputProps) => (
                          <input
                            {...inputProps}
                            className="OtpInput__input" // add class here
                          />
                        )}
                        renderSeparator={<span>-</span>}
                        inputStyle={{
                          width: "3rem",
                          height: "3rem",
                          margin: "0 0.2rem",

                          borderRadius: 2,

                          outline: "none",
                          textAlign: "center",
                          fontWeight: "400",
                        }}
                      />
                    ) : (
                      <OtpInput
                        value={verificationCode}
                        onChange={setVerificationCode}
                        numInputs={6}
                        renderInput={(inputProps) => (
                          <input
                            {...inputProps}
                            className="OtpInput__input" // add class here
                          />
                        )}
                        renderSeparator={<span>-</span>}
                        inputStyle={{
                          width: "2.2rem",
                          height: "2.2rem",
                          margin: "0 0.2rem",

                          borderRadius: 2,
                          padding: 0,
                          outline: "none",
                          textAlign: "center",
                          fontWeight: "400",
                        }}
                      />
                    )}
                    {wrongCode && (
                      <div>
                        <MdError style={{ color: "red" }} />{" "}
                        <span style={{ color: "red" }}>
                          Incorrect verification code
                        </span>
                      </div>
                    )}{" "}
                    {timer === 0 ? (
                      <p onClick={handleResendClick}>Resend Code</p>
                    ) : (
                      <p> Resend One Time Password in {timer} seconds</p>
                    )}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                {/* CHECK THE code if its wrong show error else setForm4(true) */}
                <Button variant="primary" onClick={verifyCode}>
                  Verify
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Body style={{ marginLeft: "0.5rem" }}>
                <div className="success2FA">
                  <AiOutlineCheckCircle
                    size={30}
                    style={{ color: "var(--iq-primary)" }}
                  />

                  <span style={{ color: "var(--iq-primary)" }}>
                    Two-step authentication disabled.
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleDone}>
                  Done
                </Button>
              </Modal.Footer>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default Disable2FA;
