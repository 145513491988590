import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import fetchRandomContent from "../../../Services/randomContent";
import FsLightbox from "fslightbox-react";
import Select from "react-select";
import axios from "axios";
import ReactJWPlayer from "react-jw-player";
import heartSvg from "../../../assets/images/heart-icon.svg";

// img
import logo from "../../../assets/images/login/logo.png";
import userIcon from "../../../assets/images/user_icon.png";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import {
  UpComingSliderApi,
  TopRatedHomeSlider,
  fetchLiveData,
  MainSliderApi,
  LatestSliderApi,
  SuggestedApi,
  TrendingApi,
  RecommendedApi,
} from "../../../Services/SliderApi";
import { useHistory, useParams } from "react-router-dom";
import getGeneralContent from "../../../Services/generalContent";
// import {addToFavorites} from "../../../Services/ListApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TVTopRatedApi } from "../../../Services/tvShowsApi";
import {
  convertSecondsToHoursMinutes,
  dateToWords,
  getDate,
  handleAnchorClick,
  limitTextToWords,
} from "../../../Services/generalFunctions/generalFunctions";
import { useTranslation } from "react-i18next";
import MyComponent from "./check";
// import { podCastLatestSliderApi } from "../../../Services/podCastApi";
import {
  handleConfirmDeleteWatchList,
  handleConfirmDeleteFAV,
} from "./FavWatch";
import SlickSlider from "../../../components/SlickSlider";
import ReactPlayer from "react-player";
import { MoviesByGenre } from "../../../Services/moviesApi";
import { getAllGenres } from "../../../Services/GenreApi";
import GenreSlider from "../../../components/GenreSlider";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  genreFacebookShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
} from "./ShareFunction";
import DonateForm from "../../../components/DonateForm";
import TrendingSlider from "../../../components/TrendingSlider";
import LoginSignupModal from "../../../components/LoginSignupModal";
import VideoDescriptionModal from "../../../components/partials/UI/VideoDescriptionModal";
import ShowDescriptionModal from "../../../components/partials/UI/ShowDescriptionModal";
import Loader from "../../../components/Loader";
import AdsModal from "../../../components/partials/UI/AdsModal";
import { adClickApi, fetchAdsDataHome } from "../../../Services/adsApi";
import { Helmet } from "react-helmet";
SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
]);
function useLocalStorageLocal(key, initialValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue || initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === key) {
        setValue(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return value;
}

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");
      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
        //------------
        infinite: option.infinite,
        autoplaySpeed: option.autoplaySpeed,
        pauseOnHover: option.pauseOnHover,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

export const currentUserToken = localStorage.getItem("token");

const Homepage = () => {
  const userDetails = JSON.parse(localStorage.getItem("session"));
  const profileImage = userDetails?.profile_image?.url;
  const profile_id = userDetails?._id;
  const profile_name = userDetails?.name || "";
  const profile_email = userDetails?.email || "";
  const profile_tfa = userDetails?.tfa !== undefined ? userDetails.tfa : false;
  const profile_phone = userDetails?.phone || "";
  const profile_dob = userDetails?.dob;
  const profile_language = userDetails?.language || "unknown";
  const profile_gender = userDetails?.gender || "unknown";
  const profile_country = userDetails?.country || "N/A";
  const profile_password = userDetails?.password || "N/A";
  const profile_sub = userDetails?.subscription;
  const profile_brand = userDetails?.brand;
  const profile_last4 = userDetails?.last4;


  const sessionExists = localStorage.getItem("session");
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  const [showPopup, setShowPopup] = useState(() => {
    const userIdExists = currentUserId;
    const fieldsEmpty =
      profile_id === null ||
      profile_name === "" ||
      profile_email === "" ||
      profile_phone === "" ||
      profile_dob === null ||
      profile_language === "" ||
      profile_gender === "" ||
      profile_country === "" ||
      profile_password === "" ||
      profileImage === null ||
      profile_sub === null ||
      profile_brand === null ||
      profile_last4 === null;

    let hasShownModal = localStorage.getItem("hasShownModal") === "true";

    if (!(sessionExists || userIdExists)) {
      hasShownModal = false;
      localStorage.removeItem("hasShownModal");
    } else if (hasShownModal) {
      localStorage.setItem("hasShownModal", "true");
    }

    return (sessionExists || userIdExists) && fieldsEmpty && !hasShownModal;
  });

  const handleClosePopup = () => {
    localStorage.setItem("hasShownModal", "true");
    setShowPopup(false);
  };

  const handleCompleteProfile = () => {
    localStorage.setItem("hasShownModal", "true");
    history.push("/profile/setting");
  };

  const currentSelectedLanguage = localStorage.getItem("lang") || "fr";
  const { t } = useTranslation();
  const [mainSliderData, setMainSliderData] = useState([]);

  const source = "app";
  const [upcomingData, setUpcomingData] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [videosGenre, setVideosGenre] = useState([]);
  const [recommandedData, setRecommandedData] = useState([]);
  const [trailerMediaId, setTrailerMediaId] = useState("");
  const [swiper1, setSwiper1] = useState([]);
  const [swiper2, setSwiper2] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [topRatedData, setTopRatedData] = useState([]);
  const [topRatedSlider, setTopRatedSlider] = useState([]);
  const [tvShowDetails, setTvShowDetails] = useState([]);
  const [randomContent, setRandomContent] = useState([]);
  const [mySeasons, setMySeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [loadingTrailerModal, setLoadingTrailerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const [isClicked, setIsClicked] = useState(false);
  const [isWatchListClicked, setIsWatchListClicked] = useState(false);
  const [genresList, setGenresList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setLoadingTrailerModal(true);
    setShow(true);
  };

  // to handle the donation modal
  const [showDonationModal, setShowDonationModal] = useState(false);
  const handleDonationClose = () => setShowDonationModal(false);
  const handleDonationShow = () => setShowDonationModal(true);

  // to handle the login modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);



  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  // fetching the random data
  useEffect(() => {
    fetchRandomData();
  }, []);

  const fetchRandomData = async () => {
    const data = await fetchRandomContent();
    setRandomContent(data);
  };

  useEffect(() => {
    MoviesByGenre(currentSelectedLanguage)
      .then((data) => {
        if (
          data &&
          data?.upcomingContent &&
          data?.upcomingContent?.length > 0
        ) {
          setVideosGenre(data?.upcomingContent);
        } else {
          setVideosGenre([]);
        }
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Check if trailerMediaId is found
      if (trailerMediaId) {
        setLoadingTrailerModal(false);
        clearInterval(interval); // Stop the interval if trailerMediaId is found
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [trailerMediaId]);

  // to handle the trailer in trending section
  const [trendingTrailerMediaId, setTrendingTrailerMediaId] = useState(null);
  const handleTrailerClick = (mediaId) => {
    setTrendingTrailerMediaId(mediaId);
  };

  
  const [itemIdToDelete, setItemIdToDelete] = useState(null);


  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );

  const getMediaInfo = async (mediaId, sourceMediaId) => {


    try {

      console.log("getting media info ")
      const response = await axios.get(`${process.env.REACT_APP_API}/api/admin/get-info-jwplayer-api/${mediaId}`);
      console.log(response);
  
      if (response.status === 200) {
        // set loading false
        // setIsUploading(false);

        const data = {
          duration : response.data.duration,
          mediaId : sourceMediaId
        }
        await updateMediaField(data)
        return response.data;

      } else {
        return false;
      }
    } catch (error) {
      console.error('Error while getting media info:', error);
      return false;
    }
    
  };


  const updateMediaField = async (data) => {
    
    var bodyData = {
      duration: data.duration,
    };

    console.log(bodyData);
      var apiResponse = await axios
        .put(
          `${process.env.REACT_APP_API}/api/media/upload-media-id/${data.mediaId}`,
          bodyData,
          {
            headers: {
              Authorization: `Bearer ${currentUserToken}`,
              source: "administer",
            },
          }
        )
        // onMediaUpload needed in redux state
        .then(async (onMediaUpload) => {
          console.log(onMediaUpload);
        })
        .catch(async (onMediaUploadError) => {
          console.log(onMediaUploadError);
        });
  }


  function handleFavClick(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    if (favoriteItems.includes(id)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(id);
      toast.info(t("Content Removed From Favorite list!"), {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== id));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, id]);
          } else {
            toast.error(t("Error occurred at Fav List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  function handleWatchList(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Content Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at Watch List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }

    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item.general_content_id);
        setData(data);
      } catch (error) { }
    };

    const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
    // fetchData(watchListUrl, setWatchListItems);

    const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
    // fetchData(favoriteItemsUrl, setFavoriteItems);
  }, [currentUserId]);
 

  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item.general_content_id);
        setData(data);
      } catch (error) { }
    };

    if (currentUserId) {
      const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
      // fetchData(watchListUrl, setWatchListItems);

      const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
      // fetchData(favoriteItemsUrl, setFavoriteItems);
    }
  }, [currentUserId, setWatchListItems, setFavoriteItems]);

  const [randomNumber, setRandomNumber] = useState(null);

  const handleClick = (index) => {
    if (swiper2 && swiper2.slides && swiper2.slides[index]) {
      swiper2.slideTo(index);
    }
  };
  useEffect(() => {
    setLoader(true);
    MainSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data?.allSliders && data?.allSliders?.length > 0) {
          setMainSliderData(data?.allSliders);
          const randomIndex = Math.floor(
            Math.random() * data?.allSliders.length
          );
          setRandomNumber(randomIndex);
        } else {
          setMainSliderData([
            // {
            //   id: 'no-data',
            //   noData: noData,
            // }
          ]);
        }
        setLoader(false);
      })
      .catch((error) => {
        // setLoader(false);
      });
  }, []);

  const banner = mainSliderData[randomNumber];

  useEffect(() => {
    UpComingSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (
          data &&
          data?.upcomingContent &&
          data?.upcomingContent?.length > 0
        ) {
          setUpcomingData(data.upcomingContent);
        } else {
          setUpcomingData([]);
        }
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    LatestSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (
          data &&
          data?.upcomingContent &&
          data?.upcomingContent?.length > 0
        ) {
          setLatestData(data.upcomingContent);
        } else {
          setLatestData([]);
        }
      })
      .catch((error) => { });
  }, []);
  
  useEffect(() => {
    fetchLiveData(currentSelectedLanguage)
      .then((data) => {
        if (data?.allChannels && data?.allChannels?.length > 0) {
          setLiveData(data.allChannels);
        } else {
          setLiveData([]);
        }
      })
      .catch((error) => { });
  }, []);


  // top rated
  useEffect(() => {
    TopRatedHomeSlider(currentSelectedLanguage)
      .then((data) => {
        if (
          data &&
          data?.upcomingContent &&
          data?.upcomingContent?.length > 0
        ) {
          setTopRatedSlider(data.upcomingContent);
        } else {
          setTopRatedSlider([]);
        }
      })
      .catch((error) => { });
  }, []);

  // Recommanded data
  useEffect(() => {
    RecommendedApi(currentSelectedLanguage)
      .then((data) => {
        setRecommandedData(data.upcomingContent);
      })
      .catch((error) => { });
  }, []);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [toggler1, setToggler1] = useState(false);

  const animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };


  // for trending section

  const fetchData = (id) => {
    setError(null);

    fetch(`${process.env.REACT_APP_API}/api/tv-shows/get-tvShow-details/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch TV show details.");
        }
        return res.json();
      })
      .then((result) => {
        if (result && result.tvShowDetails) {
          setTvShowDetails(result.tvShowDetails);
          setMySeasons(
            result?.tvShowDetails?.seasons?.map((season, index) => ({
              ...season,
              index,
            }))
          );
          setSelectedSeason(result.tvShowDetails.seasons[0]?._id);
        } else {
          setTvShowDetails(null);
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => { });
  };

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

 
  useEffect(() => {
    if (selectedOption) {
      fetchData(selectedOption._id);
    }
  }, [selectedOption]);

  useEffect(() => {
    // Set the initial selected option when the component mounts
    if (topRatedData?.length > 0) {
      setSelectedOption(topRatedData[0]);
    }
  }, [topRatedData]);

  const crewMembers = tvShowDetails?.crew_members;
  const genres = tvShowDetails?.genre;
  const jwTags = tvShowDetails?.jw_tags;
  const crewNames = crewMembers?.map((member) => member.member_name);
  const crewRoles = crewMembers?.map((member) => member.member_role);
  const genre = genres?.map((item) => {
    const capitalizedText =
      item.name.charAt(0).toUpperCase() + item.name.slice(1);
    return capitalizedText;
  });
  const tags = jwTags?.map((tag) => {
    const capitalizedTags = tag.charAt(0).toUpperCase() + tag.slice(1);
    return capitalizedTags;
  });

  const episodes =
    mySeasons.find((season) => season._id === selectedSeason)?.episodes || [];

  const imageUrl =
    banner?.category === "movie" || banner?.category === "video"
      ? banner?.general_content?.thumbnail?.banner_thumbnail_url
      : banner?.show?.thumbnail?.banner_thumbnail_url;

  const bannerTitle =
    banner?.category === "movie" || banner?.category === "video"
      ? (currentSelectedLanguage !== "fr" ? banner?.general_content?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :banner?.general_content?.media?.title  )
      : banner?.show?.title;



  const bannerDescription =
    banner?.category === "movie" || banner?.category === "video"
      ? (currentSelectedLanguage !== "fr" ? banner?.general_content?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :banner?.general_content?.media?.description  )
      : banner?.show?.description;

  const bannerMediaLink =
    banner?.category === "movie" || banner?.category === "video"
      ? banner?.general_content?.media?.description
      : banner?.show?.description;

  const badgeText =
    banner?.category === "movie" || banner?.category === "video"
      ? banner?.general_content?.media?.categ
      : banner?.show?.title;
  const genresData =
    banner?.category === "movie" || banner?.category === "video"
      ? banner?.general_content?.genre?.map((genre) => genre.name).join(". ")
      : banner?.show?.genre?.map((genre) => genre.name).join(". ");

  const displayGenres = genresData ? genresData : null;

  const [homeStripeModal, setHomeStripeModal] = useState(false);

  // description modal work for video/movies

  const [hoveredItemVideo, setHoveredItemVideo] = useState({});
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleCloseVideoModal = () => setShowVideoModal(false);
  const handleShowVideoModal = () => setShowVideoModal(true);
  const modalTimerRefVideo = useRef(null);

  const handleMouseEnterVideo = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItemVideo(item);

    modalTimerRefVideo.current = setTimeout(() => {
      setShowVideoModal(true);
    }, 3000); // 3 seconds
  };

  const handleMouseLeaveVideo = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRefVideo.current);
    // setHoveredItem(null);
  };

  const handleModalClose = () => {
    // Close the modal
    setShowVideoModal(false);
  };

  // description modal work for TVShows/Podcasts

  const [hoveredItemTVShow, setHoveredItemTVShow] = useState({});
  const [showTVShowModal, setShowTVShowModal] = useState(false);
  const handleCloseTVShowModal = () => setShowTVShowModal(false);
  const handleShowTVShowModal = () => setShowTVShowModal(true);
  const modalTimerRefTVShow = useRef(null);

  const handleMouseEnterTVShow = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItemTVShow(item);

    modalTimerRefTVShow.current = setTimeout(() => {
      setShowTVShowModal(true);
    }, 3000); // 3 seconds
  };

  const handleMouseLeaveTVShow = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRefTVShow.current);
    // setHoveredItem(null);
  };

  const handleModalCloseTVshow = () => {
    // Close the modal
    setShowTVShowModal(false);
  };

  // For ADs
  const [hoveredItemAD, setHoveredItemAD] = useState({});
  const [showADModal, setShowADModal] = useState(false);
  const handleCloseADModal = () => setShowADModal(false);
  const handleShowADModal = () => setShowADModal(true);
  const modalTimerRefAD = useRef(null);
  const handleMouseEnterAD = (item) => {
    // Set the hovered item
    setHoveredItemAD(item);

    // Clear the previous timer, if any
    clearTimeout(modalTimerRefAD.current);

    if (item?.autoplay === "instant") {
      // Autoplay with a delay of 1 second
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 500); // half a second
    } else if (item?.autoplay === "3 seconds") {
      // Autoplay with a delay of 3 seconds
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 3000);
    } else if (item?.autoplay === "off") {
      // No autoplay
      setShowADModal(false);
    }
  };

  const handleMouseLeaveAD = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRefAD.current);
    // setHoveredItem(null);
  };

  const handleModalCloseAD = () => {
    // Close the modal
    setShowADModal(false);
  };

  const [adsData, setAdsData] = useState({});
  useEffect(() => {
    // Call the fetchAdsDataTvShow function
    const fetchData = async () => {
      try {
        const data = await fetchAdsDataHome(currentSelectedLanguage);
        setAdsData(data?.adsList);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function assignAdToTarget(sourceArray, targetArray) {
    if (!Array.isArray(sourceArray) || !Array.isArray(targetArray)) {
      // console.error("Both arguments should be arrays.");
      return;
    }

    if (sourceArray.length === 0) {
      // console.error("Source array is empty.");
      return;
    }

    const randomIndex = Math.floor(Math.random() * sourceArray.length);
    const objectToAssign = sourceArray.splice(randomIndex, 1)[0]; // Get and remove the random object from the source array
    targetArray.push(objectToAssign); // Assign the object to the target array
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }
    return array;
  }

  const [adsCopyCopy, setAdsCopyCopy] = useState([]);
  const [topRatedDataCopy, setTopRatedDataCopy] = useState([]);
  const [upComingDataCopy, setUpComingDataCopy] = useState([]);
  const [latestDataCopy, setLatestDataCopy] = useState([]);
  const [recommenedDataCopy, setRecommenedDataCopy] = useState([]);

  useEffect(() => {

    const adsCopyCopyTemp = adsData.length > 0 ? [...adsData] : [];
    const topRatedDataCopyTemp = [...topRatedSlider];
    const upComingDataCopyTemp = [...upcomingData];
    const latestDataCopyTemp = [...latestData];
    const recommendedDataCopyTemp = [...recommandedData];

    // Assign ads from the adsCopyCopy to topRatedDataCopy and upComingDataCopy
    assignAdToTarget(adsCopyCopy, topRatedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, upComingDataCopyTemp);
    assignAdToTarget(adsCopyCopy, latestDataCopyTemp);
    assignAdToTarget(adsCopyCopy, recommendedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, topRatedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, upComingDataCopyTemp);
    assignAdToTarget(adsCopyCopy, latestDataCopyTemp);
    assignAdToTarget(adsCopyCopy, recommendedDataCopyTemp);

    // Update the state variables to reflect the changes
    setAdsCopyCopy(shuffleArray(adsCopyCopyTemp));
    setTopRatedDataCopy(shuffleArray(topRatedDataCopyTemp));
    setUpComingDataCopy(shuffleArray(upComingDataCopyTemp));
    setLatestDataCopy((latestDataCopyTemp));

    
    const latestDataWithDurationNull = latestDataCopyTemp.filter(d => d?.media?.duration === 0);
    const topRatedDataWithDurationNull = topRatedDataCopyTemp.filter(d => d?.media?.duration === 0);

   
    if(topRatedDataWithDurationNull.length > 0 ){
      topRatedDataWithDurationNull.forEach(da => {
        getMediaInfo(da?.media?.media_id, da?.media?._id)
    });
    }

    if(latestDataWithDurationNull.length > 0 ){
    latestDataWithDurationNull.forEach(da => {
      getMediaInfo(da?.media?.media_id, da?.media?._id)
    });

    }
    setRecommenedDataCopy(shuffleArray(recommendedDataCopyTemp));

  }, [adsData, topRatedSlider, upcomingData, latestData, recommandedData]);

  const handleAdClicked = (event, data) => {
    if (data?.category === "ads") {
      event.preventDefault(); // Prevent the default link behavior for ads category
      adClickApi(data?._id)
        .then(() => {
          window.open(data?.redirect_url, "_blank");
        })
        .catch((error) => {
          // console.error("Error handling after API call:", error);
        });
    }
  };




  return (
    <>
      {/* Video Description Modal */}
        <Helmet>
          <title>{t("meta.home.title")} </title>
          <meta property="og:title" content="AFO Média"/>
          <meta name="description" property="og:description" content="Le narratif africain à travers des reportages, des chroniques, des magazines, des documentaires et des rendez-vous culturels. Sans complaisance mais sans condescendance." />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <VideoDescriptionModal
            item={hoveredItemVideo}
            handleClose={handleCloseVideoModal}
          />
        </Modal.Body>
      </Modal>
      {/* Show Description Modal */}
      <Modal
        show={showTVShowModal}
        onHide={handleCloseTVShowModal}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ShowDescriptionModal
            item={hoveredItemTVShow}
            handleClose={handleCloseTVShowModal}
          />
        </Modal.Body>
      </Modal>
      {/* AD Modal */}
      <Modal
        show={showADModal}
        onHide={handleCloseADModal}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <AdsModal item={hoveredItemAD} handleClose={handleCloseADModal} />
        </Modal.Body>
      </Modal>
      {/* Complete your profile Modal  */}
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName="modal-dialog-right"
        className="complete_profile"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <div className="logo-container">
            <img src={userIcon} alt="Logo" className="logo-image" />
          </div>
          <div>
            <p style={{ color: "white", marginLeft: "45px" }}>
              {t("Complete Your Profile")}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body style={{ color: "white" }}>
          <span role="img" aria-label="Star">
            🌟
          </span>{" "}
          {t(
            "Enhance your streaming experience! Kindly complete your profile for personalized recommendations and exclusive sneak peeks."
          )}
          <span role="img" aria-label="Star">
            🌟
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="donate-button"
            onClick={handleCompleteProfile}
          >
            {t("Complete Profile")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* trailer modal */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered={true}
        className="trailer-modal "
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ReactJWPlayer
            playerId="rGoke68A"
            playerScript="https://content.jwplatform.com/libraries/rGoke68A.js"
            playlist={`https://cdn.jwplayer.com/v2/media/${trailerMediaId}`}
            controls={false}
            isAutoPlay={false}
            licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
          // file="https://live-hls-web-aje.getaj.net/AJE/05.m3u8"
          // onReady={onReady}
          />
        </Modal.Body>
      </Modal>

      {/* donation modal */}
      <Modal
        show={showDonationModal}
        onHide={handleDonationClose}
        size="md"
        centered={true}
        className={`${homeStripeModal ? "stripe-Modal" : "special_modal"} `}
        contentClassName=""
      >
        <Modal.Body>
          <div style={{ height: "100%" }}>
            <DonateForm
              handleDonationClose={handleDonationClose}
              setHomeStripeModal={setHomeStripeModal}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* login and signup modal */}
      <Modal
        show={showLoginModal}
        onHide={handleCloseLoginModal}
        className="special_modal"
        size="lg"
        centered={true}
      >
        <Modal.Body>
          <LoginSignupModal />
        </Modal.Body>
      </Modal>
      {loader ? (
        <div style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
            <div id="prev5" className="swiper-button swiper-button-prev">
              <i className="fa fa-chevron-left"></i>
            </div>
            <div id="next5" className="swiper-button swiper-button-next">
              <i className="fa fa-chevron-right"></i>
            </div>
             {/* slider here */}
            <Swiper
              navigation={{
                prevEl: "#prev5",
                nextEl: "#next5",
              }}
              pagination={{
                clickable: true,
              }}
              onInit={() => {
                animationInit();
              }}
              onSlideChangeTransitionStart={() => animationInit()}
              loop={true}
              autoplay={{ delay: 6000 }}
              scrollbar={{ draggable: true }}
              id="home-slider"
              className="slider m-0 p-0"
            >
              {mainSliderData?.length > 0 &&
                mainSliderData?.map((mainSlider) => {
                  if (
                    mainSlider?.general_content?.thumbnail
                      ?.banner_thumbnail_url === "" ||
                    mainSlider?.general_content?.thumbnail
                      ?.banner_thumbnail_url === null ||
                    mainSlider?.show?.thumbnail?.banner_thumbnail_url === "" ||
                    mainSlider?.show?.thumbnail?.banner_thumbnail_url === null
                  ) {
                    return null;
                  }
                  return (
                    <SwiperSlide
                      className="slide slick-bg"
                      style={{
                        backgroundImage:
                          mainSlider?.category === "movie" ||
                            mainSlider?.category === "video"
                            ? `url(${mainSlider?.general_content?.thumbnail?.banner_thumbnail_url})`
                            : `url(${mainSlider?.show?.thumbnail?.banner_thumbnail_url})`,
                      }}
                    >
                      <Container fluid className="position-relative h-100">
                        <div className="slider-inner h-100">
                          <Row className="align-items-center  iq-ltr-direction h-100 ">
                            <Col lg="12" md="12" sm="12">
                              <Link to="#" className="main-slider-logo">
                                <div
                                  className="channel-logo"
                                  data-iq-delay="0.5"
                                >
                                  <img
                                    src={logo}
                                    className="c-logo"
                                    alt="streamit"
                                  />
                                </div>
                              </Link>
                              <h1
                                className="slider-text big-title title text-uppercase heading-length font-raleway"
                                data-iq-gsap="onStart"
                                data-iq-position-y="80"
                                data-iq-delay="0.8" 
                              >
                                {mainSlider?.category === "movie" ||
                                  mainSlider?.category === "video"
                                  ? (currentSelectedLanguage === 'fr' ? mainSlider?.general_content?.media?.title:  mainSlider?.general_content?.media
                                  ?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated )
                                  : mainSlider?.show?.title }
                              </h1>
                              <div className="d-flex flex-wrap align-items-center">
                                <div
                                  className="d-flex align-items-center mt-2 mt-md-3"
                                  data-iq-gsap="onStart"
                                  data-iq-position-x="-200"
                                  data-iq-delay="-0.5"
                                ></div>
                                <p
                                  data-iq-gsap="onStart"
                                  data-iq-position-y="80"
                                  data-iq-delay="0.8"
                                  className="paraOverflow"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      mainSlider?.category === "movie" ||
                                        mainSlider?.category === "video"
                                        ? (currentSelectedLanguage === 'fr' ? mainSlider?.general_content?.media?.description:  mainSlider?.general_content?.media
                                        ?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated )
                                        : mainSlider?.show?.description,
                                  }}
                                >
                                  {/* {mainSlider?.category === "movie" ||
                              mainSlider?.category === "video"
                                ? mainSlider?.general_content?.media
                                    ?.description
                                : mainSlider?.show?.description} */}
                                </p>
                              </div>

                              {/* mot clés et categorie */}
                              {/* <div
                                className="trending-list"
                                data-wp_object-in="fadeInUp"
                                data-delay-in="1.2"
                              >
                                {mainSlider?.category === "movie" ||
                                  mainSlider?.category === "video"
                                  ? mainSlider?.general_content?.genre?.length >
                                  0 && (
                                    <div className="text-primary title genres">
                                      {t("Categories")}:
                                      {mainSlider?.general_content?.genre?.map(
                                        (genreObj, index) => (
                                          <span
                                            style={{
                                              color: "white",
                                              marginLeft: "5px",
                                            }}
                                            key={genreObj.name}
                                          >
                                            {genreObj.name}
                                            {index !==
                                              mainSlider?.general_content
                                                ?.genre?.length -
                                              1 && ","}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )
                                  : mainSlider?.show?.genre?.length > 0 && (
                                    <div className="text-primary title genres">
                                      {t("Categories")}:{" "}
                                      {mainSlider?.show?.genre?.map(
                                        (genreObj, index) => (
                                          <span
                                            style={{
                                              color: "white",
                                              marginLeft: "5px",
                                            }}
                                            key={genreObj.name}
                                          >
                                            {genreObj.name}
                                            {index !==
                                              mainSlider?.show?.genre
                                                ?.length -
                                              1 && ","}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )}

                                {mainSlider?.category === "movie" ||
                                  mainSlider?.category === "video"
                                  ? mainSlider?.general_content?.media?.seo_tags
                                    ?.length > 0 && (
                                    <div className="text-primary title tag">
                                      {t("tags").toUpperCase()}:{" "}
                                      {mainSlider?.general_content?.media?.seo_tags?.map(
                                        (tag, index) => (
                                          <span
                                            className="text-body"
                                            key={tag}
                                          >
                                            {tag}
                                            {index !==
                                              mainSlider?.general_content
                                                ?.media?.seo_tags?.length -
                                              1 && ","}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )
                                  : mainSlider?.show?.media?.seo_tags?.length >
                                  0 && (
                                    <div className="text-primary title tag">
                                      Tag:
                                      {mainSlider?.show?.media?.seo_tags?.map(
                                        (tag, index) => (
                                          <span
                                            className="text-body"
                                            key={tag}
                                          >
                                            {tag}
                                            {index !==
                                              mainSlider?.show?.media
                                                ?.seo_tags?.length -
                                              1 && ","}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )}
                              </div> */}

                              <div
                                className="buttons-container r-mb-23"
                                data-iq-gsap="onStart"
                                data-iq-position-y="80"
                                data-iq-delay="0.8"
                              >
                                <Link
                                  to={
                                    mainSlider?.category === "movie" ||
                                      mainSlider?.category === "video"
                                      ? `/video-details/${source}/${mainSlider?.general_content?._id}`
                                      : `/show-info/${mainSlider?.show?._id}`
                                  }
                                  className="btn btn-hover ml-2"
                                  style={{ marginTop: "5px" }}
                                >
                                  <i
                                    className="fa fa-solid fa-play fa-beat mr-2"
                                    aria-hidden="true"
                                  ></i>
                                  {t("Play now")}
                                </Link>
                                {/* <button
                                  className="donate-button ml-2 mt-1"
                                  onClick={
                                    sessionExists
                                      ? handleDonationShow
                                      : handleShowLoginModal
                                  }
                                >
                                  <img
                                    src={heartSvg}
                                    alt="heart-svg"
                                    className="donate-icon mr-2"
                                  />
                                  <span className="donate-button-text">
                                    {t("Donate")}
                                  </span>
                                </button> */}
                              </div>
                            </Col>
                            {/* {((mainSlider?.category === "movie" ||
                          mainSlider?.category === "video") &&
                          mainSlider?.general_content?.trailer?.media_id !==
                            "") ||
                          (mainSlider?.category === "tvshow" &&
                            mainSlider?.show?.trailer?.media_id !== "") ||
                          (mainSlider?.category !== "podcast" */}
                            {((mainSlider?.category === "movie" ||
                              mainSlider?.category === "video") &&
                              mainSlider?.general_content?.trailer?.media_id !==
                              "") ||
                              (mainSlider?.category === "tv_show" &&
                                mainSlider?.show?.trailer?.media_id !== "" &&
                                mainSlider?.category !== "podcast") ? (
                              <Col
                                xl="5"
                                lg="12"
                                md="12"
                                className="trailor-video text-center mt-1"
                              >
                                <div
                                  onClick={() => {
                                    if (
                                      mainSlider.category === "movie" ||
                                      mainSlider.category === "video"
                                    ) {
                                      setTrailerMediaId(
                                        mainSlider.general_content.trailer
                                          .media_id
                                      );
                                    } else {
                                      setTrailerMediaId(
                                        mainSlider.show.trailer.media_id
                                      );
                                    }
                                    handleShow();
                                  }}
                                  className="video-open playbtn"
                                  style={{ cursor: "pointer" }}
                                >
                                  <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    width="80px"
                                    height="80px"
                                    viewBox="0 0 213.7 213.7"
                                    enableBackground="new 0 0 213.7 213.7"
                                    xmlSpace="preserve"
                                  >
                                    <polygon
                                      className="triangle"
                                      fill="none"
                                      strokeWidth="7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeMiterlimit="10"
                                      points="73.5,62.5 148.5,105.8 73.5,149.1"
                                    />
                                    <circle
                                      className="circle"
                                      fill="none"
                                      strokeWidth="7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeMiterlimit="10"
                                      cx="106.8"
                                      cy="106.8"
                                      r="103.3"
                                    />
                                  </svg>
                                  <span className="w-trailor">
                                    {t("Watch Trailer")}
                                  </span>
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      </Container>
                    </SwiperSlide>
                  );
                })}
            </Swiper>





            {/* slider here */}
          </section>
          <ToastContainer
            toastStyle={{
              background: "black",
              color: "white",
            }}
          />
          <div className="main-content">
            {/* Upcoming Movies  */}
            {upComingDataCopy?.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Upcoming")}</h4>
                        {/* <Link className="iq-view-all" to="/movie-category">
                    View All
                  </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev",
                            nextEl: "#next",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {upComingDataCopy?.length > 0 &&
                            upComingDataCopy?.map((data) => (
                              <SwiperSlide
                                as="li"
                                key={data.id}
                                onMouseEnter={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseEnterVideo(data)
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseEnterTVShow(data)
                                      : handleMouseEnterAD(data)
                                }
                                onMouseLeave={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseLeaveVideo()
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseLeaveTVShow()
                                      : handleMouseLeaveAD()
                                }
                              >
                                <Link
                                  to={
                                    data?.category === "movie" ||
                                      data?.category === "video"
                                      ? `/video-details/${source}/${data?._id}`
                                      : `/show-info/${data?._id}`
                                  }
                                  onClick={(event) =>
                                    handleAdClicked(event, data)
                                  }
                                >
                                  <div className="block-images position-relative slider-img-main">
                                    <div className="img-box">
                                      {/* Only render the video if the media category is "video" or "movie" */}
                                      {data?.category === "movie" ||
                                        data?.category === "podcast" ||
                                        data?.category === "tvshow" ? (
                                        <img
                                          src={
                                            data?.thumbnail
                                              ?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      ) : data?.media_id !== "" ? (
                                        <ReactPlayer
                                          className="img-fluid slider-img"
                                          url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                          width="100%"
                                          height="100%"
                                          pip={false}
                                          controls={false}
                                          playing={true}
                                          muted={true}
                                          loop={true}
                                        />
                                      ) : (
                                        <img
                                          src={data?.banner_url}
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="card-slider-badge">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? (convertSecondsToHoursMinutes(
                                            data?.media?.duration.toFixed(2)
                                          ) )
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                    <div className="block-description ">
                                      <div className="card-slider-content">
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (
                                          <h6 className="iq-title title-desc">
                                          
                                            {currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.media?.title } 
                                          </h6>
                                        ) : (
                                          <h6 className="iq-title title-desc">
                                            {data?.title}
                                          </h6>
                                        )}
                                      </div>
                                      <div className="card-slider-desc">
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: limitTextToWords(
                                              currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description,
                                              12
                                            ),
                                          }}
                                        ></span>
                                      </div>
                                      {data?.category !== "ads" && (
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              {data?.category === "movie" ||
                                                data?.category === "video" ? (
                                                <Link
                                                  to={`/video-details/${source}/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/show-info/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              )}
                                            </div>
                                          </li>
                                        </div>
                                      )}
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {/* <section>
          <MyComponent />
        </section> */}
            {/* Latest Data */}
            {latestDataCopy?.length > 0 && (
              <section id="iq-favorites">
                {/* className="p-big" */}
                <Container fluid  >
                  <Row>
                    <Col sm="12" className="overflow-hidden mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Latest")}</h4>
                        {/* <Link className="iq-view-all" to="/movie-category">
                  View All
                </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev1"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next1"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev1",
                            nextEl: "#next1",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {latestDataCopy?.length > 0 &&
                            latestDataCopy?.map((data) => (
                              <SwiperSlide
                                as="li"
                                key={data.id}
                                onMouseEnter={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseEnterVideo(data)
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseEnterTVShow(data)
                                      : handleMouseEnterAD(data)
                                }
                                onMouseLeave={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseLeaveVideo()
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseLeaveTVShow()
                                      : handleMouseLeaveAD()
                                }
                              >
                                <Link
                                  to={
                                    data?.category === "movie" ||
                                      data?.category === "video"
                                      ? `/video-details/${source}/${data?._id}`
                                      : `/show-info/${data?._id}`
                                  }
                                  onClick={(event) =>
                                    handleAdClicked(event, data)
                                  }
                                >
                                  <div className="block-images position-relative slider-img-main">
                                    <div className="img-box">
                                      {/* Only render the video if the media category is "video" or "movie" */}
                                      {data?.category === "movie" ||
                                        data?.category === "podcast" ||
                                        data?.category === "tvshow" ? (
                                        <img
                                          src={
                                            data?.thumbnail
                                              ?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      ) : data?.media_id !== "" ? (
                                        <ReactPlayer
                                          className="img-fluid slider-img"
                                          url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                          width="100%"
                                          height="100%"
                                          pip={false}
                                          controls={false}
                                          playing={true}
                                          muted={true}
                                          loop={true}
                                        />
                                      ) : (
                                        <img
                                          src={data?.banner_url}
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="card-slider-badge">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {/* {data?.category === "movie" ||
                                          data?.category === "video"
                                          ?
                                            // convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                            ""
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`} */}
                                      </div>
                                    </div>
                                    <div className="block-description ">
                                      <div className="card-slider-content">
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (
                                          // <h6 className="iq-title title-desc">
                                          //    {currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated }
                                          // </h6>
                                          <></>
                                        ) : (
                                          // <h6 className="iq-title title-desc">
                                          //   {data?.title}
                                          // </h6>
                                          <></>
                                        )}
                                      </div>
                                      <div className="card-slider-desc">

                                      {/* limitTextToWords(
                                              data?.category === "movies"
                                                ? data?.media?.description
                                                : data?.description,
                                              12
                                               */}
                                        {/* <span
                                          dangerouslySetInnerHTML={{
                                            __html: limitTextToWords(
                                              currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description  ,
                                              12
                                            ) 
                                          }}
                                        ></span> */}
                                      </div>
                                      {data?.category !== "ads" && (
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              {data?.category === "movie" ||
                                                data?.category === "video" ? (
                                                <Link
                                                  to={`/video-details/${source}/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/show-info/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              )}
                                            </div>
                                          </li>
                                        </div>
                                      )}
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>

                                  <div className="d-flex mt-4 justify-content-space-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px', padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}>{data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`} </div>
                                    </div>
                                  </div>

                                </Link>


                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {topRatedDataCopy?.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Top Rated")}</h4>
                        {/* <Link className="iq-view-all" to="/movie-category">
                  View All
                </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev",
                            nextEl: "#next",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {topRatedDataCopy?.length > 0 &&
                            topRatedDataCopy?.map((data) => (
                              <SwiperSlide
                                as="li"
                                key={data.id}
                                onMouseEnter={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseEnterVideo(data)
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseEnterTVShow(data)
                                      : handleMouseEnterAD(data)
                                }
                                onMouseLeave={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseLeaveVideo()
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseLeaveTVShow()
                                      : handleMouseLeaveAD()
                                }
                              >
                                <Link
                                  to={
                                    data?.category === "movie" ||
                                      data?.category === "video"
                                      ? `/video-details/${source}/${data?._id}`
                                      : `/show-info/${data?._id}`
                                  }
                                  onClick={(event) =>
                                    handleAdClicked(event, data)
                                  }
                                >
                                  <div className="block-images position-relative slider-img-main">
                                    <div className="img-box">
                                      {/* Only render the video if the media category is "video" or "movie" */}
                                      {data?.category === "movie" ||
                                        data?.category === "podcast" ||
                                        data?.category === "tvshow" ? (
                                        <img
                                          src={
                                            data?.thumbnail
                                              ?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      ) : data?.media_id !== "" ? (
                                        <ReactPlayer
                                          className="img-fluid slider-img"
                                          url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                          width="100%"
                                          height="100%"
                                          pip={false}
                                          controls={false}
                                          playing={true}
                                          muted={true}
                                          loop={true}
                                        />
                                      ) : (
                                        <img
                                          src={data?.banner_url}
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="card-slider-badge">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {/* {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? null
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`} */}
                                      </div>
                                    </div>
                                    <div className="block-description ">
                                      <div className="card-slider-content">
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (
                                          // <h6 className="iq-title title-desc">
                                          //   {/* {data?.media?.title} */}
                                          //   {currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated }
                                          //   {/* ici */}
                                          // </h6>
                                          <></>
                                        ) : (
                                          // <h6 className="iq-title title-desc">
                                          //   {data?.title}
                                          // </h6>
                                          <></>
                                        )}
                                      </div>
                                      <div className="card-slider-desc">
                                        {/* <span
                                          dangerouslySetInnerHTML={{
                                            __html: limitTextToWords(
                                              currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description  ,
                                              12
                                            ),
                                          }}
                                        ></span> */}
                                      </div>
                                      {data?.category !== "ads" && (
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              {data?.category === "movie" ||
                                                data?.category === "video" ? (
                                                <Link
                                                  to={`/video-details/${source}/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/show-info/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              )}
                                            </div>
                                          </li>
                                        </div>
                                      )}
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>

                                  <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>

                                </Link>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

            {/* VERTICAL SLIDER */}
            {liveData?.length > 0 && (
              <div className="verticle-slider">
                <Container fluid>
                  <section className="slider">
                    <div className="slider-flex position-relative">
                      <div
                        id="prev31"
                        className="swiper-button swiper-button-prev verticle-btn"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <h4 className="main-title">{t("Live Channels")}</h4>
                      <div className="slider--col position-relative">
                        <div id="prev31" className="slider-prev btn-verticle">
                          <i className="ri-arrow-up-s-line vertical-aerrow"></i>
                        </div>
                        <div className="slider-thumbs">
                          <Swiper
                            onSwiper={setSwiper1}
                            navigation={{
                              prevEl: "#prev31",
                              nextEl: "#next31",
                            }}
                            direction="vertical"
                            loop={false}
                            slidesPerView={4}
                            spaceBetween={20}
                            as="ul"
                            className="swiper-vertical top-ten-slider-nav"
                          >
                            {liveData?.map((data, index) => (
                              <SwiperSlide key={index.id} as="li">
                                <Link
                                  to={
                                    data?.channel_type === "m3u"
                                      ? `/live-channel/${data?._id}`
                                      : `/live/${data?._id}`
                                  }
                                >
                                  <div
                                    className="block-images position-relative "
                                    onClick={() => handleClick(index)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="img-box slider--image">
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid"
                                        alt=""
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="block-description">
                                      <h6 className="iq-title live-title-des">
                                        <a href="show-detail.html">
                                          {data?.title}
                                        </a>
                                      </h6>
                                      <div className="movie-time d-flex align-items-center my-2">
                                        {/* <span className="text-white">1hr : 50mins</span> */}
                                      </div>
                                      <div className="play-now-button mb-1">
                                        {data?.channel_type === "m3u" ? (
                                          <Link
                                            to={`/live-channel/${data?._id}`}
                                            role="button"
                                            className="btnV btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/live/${data?._id}`}
                                            role="button"
                                            className="btnV btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                    <div className="block-social-info-live">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              {data?.channel_type === "m3u" ? (
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    "live-channel",
                                                    data?.channel_type
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                              ) : (
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    "live",
                                                    data?.channel_type
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                              )}
                                              {data?.channel_type === "m3u" ? (
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    "live-channel",
                                                    data?.channel_type
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                              ) : (
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    "live",
                                                    data?.channel_type
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                              )}
                                              {data?.channel_type === "m3u" ? (
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    "live-channel",
                                                    data?.channel_type
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              ) : (
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    "live",
                                                    data?.channel_type
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                        {/* <li>
                                    <span>
                                      <i
                                        className={`ri-heart-fill ${
                                          favoriteItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleFavClick(
                                            data?._id,
                                            data?.category
                                          )
                                        }
                                        title={
                                          favoriteItems.includes(data?._id)
                                            ? "Remove From Favourite"
                                            : "Add To Favourite"
                                        }
                                      ></i>
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <span>
                                        <i
                                          className={`ri-add-line ${
                                            watchListItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleWatchList(
                                              data?._id,
                                              data?.category
                                            )
                                          }
                                          title={
                                            watchListItems.includes(data?._id)
                                              ? "Remove From WatchList"
                                              : "Add To WatchList"
                                          }
                                        ></i>
                                      </span>
                                    </span>
                                  </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </Link>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                        <div className="slider-next btn-verticle" id="next31">
                          <i className="ri-arrow-down-s-line vertical-aerrow"></i>
                        </div>
                      </div>
                      <div
                        className="slider-images"
                        data-swiper="slider-images"
                      >
                        <Swiper
                          navigation={{
                            prevEl: "#prev31",
                            nextEl: "#next31",
                          }}
                          direction="vertical"
                          loop={false}
                          slidesPerView={1}
                          spaceBetween={20}
                          as="ul"
                          className="swiper-vertical top-ten-slider-nav"
                          onSwiper={setSwiper2}
                          controller={{ control: swiper1 }}
                        >
                          {liveData?.map((data, index) => (
                            <SwiperSlide key={index.id} as="li">
                              <div className="slider--image vertical-block-images">
                                <img
                                  src={data?.thumbnail?.banner_thumbnail_url}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="live-channel-banner-content">
                                  <div
                                    style={{
                                      width: "80%",
                                    }}
                                  >
                                    <h1
                                      className="slider-text big-title title text-uppercase"
                                      data-iq-gsap="onStart"
                                      data-iq-position-x="-200"
                                      style={{ fontSize: "3rem" }}
                                    >
                                      {data?.title}
                                    </h1>
                                  </div>
                                  <div
                                    className="live-banner-description d-flex flex-wrap align-items-center"
                                    style={{ width: "90%" }}
                                  >
                                    <p
                                      data-iq-gsap="onStart"
                                      data-iq-position-y="80"
                                      data-iq-delay="0.8"
                                      className="paraOverflow"
                                      style={{ fontSize: "1rem" }}
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    >
                                      {/* {data?.description} */}
                                    </p>
                                  </div>
                                  {data?.genre?.length > 0 && (
                                    <div
                                      className="text-primary title genres"
                                      style={{ fontSize: "1rem" }}
                                    >
                                      {t("Categories")}:
                                      {data?.genre?.map((genreObj, index) => (
                                        <span
                                          style={{
                                            color: "white",
                                            marginLeft: "5px",
                                          }}
                                          key={genreObj.name}
                                        >
                                          {genreObj.name}
                                          {index !== data?.genre?.length - 1 &&
                                            ","}
                                        </span>
                                      ))}
                                    </div>
                                  )}

                                  {/* <div style={{ cursor: "pointer" }}>
                              {channel?.channel_type === "m3u" ? (
                                <Link
                                  to={`/live-channel/${data?._id}`}
                                  className="btn btn-hover iq-button-live livebtn w-100"
                                  style={{ width: "fit-content" }}
                                >
                                  <i
                                    className="fa fa-play mr-2"
                                    aria-hidden="true"
                                  ></i>
                                  {t("Play now")}
                                </Link>
                              ) : (
                                <Link
                                  to={`/live/${data?._id}`}
                                  className="btn btn-hover iq-button-live livebtn w-100"
                                  style={{ width: "fit-content" }}
                                >
                                  <i
                                    className="fa fa-play mr-2"
                                    aria-hidden="true"
                                  ></i>
                                  {t("Play now")}
                                </Link>
                              )}
                            </div> */}
                                </div>

                                <div className="description">
                                  <div className="block-description">
                                    <div className="d-flex justify-content-center mb-2">
                                      <div className="play-now-button">
                                        {data?.channel_type === "m3u" ? (
                                          <Link
                                            to={`/live-channel/${data?._id}`}
                                            role="button"
                                            className="btnV btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/live/${data?._id}`}
                                            role="button"
                                            className="btnV btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                    <h6 className="iq-title title-desc d-flex justify-content-center">
                                      <a href="show-detail.html">
                                        {data?.title}
                                      </a>
                                    </h6>
                                    {/* <div className="movie-time d-flex align-items-center my-2">
                                  <span className="text-white">
                                    {data?.content_type}
                                  </span>
                                </div> */}
                                    <div className="block-social-info-button">
                                      <li className="share">
                                        <div className="hover-buttons">
                                          <Link
                                            to={`/video-details/${source}/${data?._id}`}
                                            className="btn btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-2"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        </div>
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>

                      <div
                        className="swiper-button-next swiper-button verticle-btn"
                        id="next31"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                    </div>
                  </section>
                </Container>
              </div>
            )}
            {/* Categories section */}

            {videosGenre?.map((data, index) => {
              // if (data?.filteredContent?.length > 0) {
              //   return (
              //     <section id="iq-upcoming-movie">
              //       <div className="container-fluid">
              //         <div className="row">
              //           <div className="col-sm-12 overflow-hidden">
              //             <div className="d-flex align-items-center justify-content-between">
              //               <h4 className="main-title">{data?.genre}</h4>
              //               {/* <Link className="iq-view-all" to="/">
              //         View All
              //       </Link> */}
              //             </div>

              //             <div id="upcoming-contens">
              //               <div
              //                 id={`prev2${index}`}
              //                 className="swiper-button swiper-button-prev"
              //               >
              //                 <i className="fa fa-chevron-left"></i>
              //               </div>
              //               <div
              //                 id={`next2${index}`}
              //                 className="swiper-button swiper-button-next"
              //               >
              //                 <i className="fa fa-chevron-right"></i>
              //               </div>
              //               <Swiper
              //                 slidesPerView={4}
              //                 spaceBetween={20}
              //                 navigation={{
              //                   nextEl: `#prev2${index}`,
              //                   prevEl: `#next2${index}`,
              //                 }}
              //                 loop={false}
              //                 breakpoints={{
              //                   320: { slidesPerView: 1 },
              //                   550: { slidesPerView: 2 },
              //                   991: { slidesPerView: 3 },
              //                   1400: { slidesPerView: 4 },
              //                   1600: { slidesPerView: 5 },
              //                 }}
              //                 className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
              //               >
              //                 {data?.filteredContent?.map((Obj, index) => (
              //                   <SwiperSlide as="li">
              //                     <Link
              //                       to={
              //                         Obj?.category === "movie" ||
              //                           Obj?.category === "video"
              //                           ? `/video-details/${source}/${Obj?._id}`
              //                           : `/show-info/${Obj?._id}`
              //                       }
              //                     >
              //                       <div
              //                         className="block-images position-relative slider-img-main"
              //                         onMouseEnter={() =>
              //                           Obj?.category === "video" ||
              //                             Obj?.category === "movie"
              //                             ? handleMouseEnterVideo(Obj)
              //                             : handleMouseEnterTVShow(Obj)
              //                         }
              //                         onMouseLeave={
              //                           Obj?.category === "video" ||
              //                             Obj?.category === "movie"
              //                             ? handleMouseLeaveVideo
              //                             : handleMouseLeaveTVShow
              //                         }
              //                       // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
              //                       >
              //                         <div className="img-box">
              //                           <img
              //                             src={
              //                               Obj?.thumbnail?.banner_thumbnail_url
              //                             }
              //                             className="img-fluid slider-img"
              //                             alt=""
              //                           />
              //                         </div>
              //                         {/* showing on the top */}
              //                         <div className="card-slider-badge">
              //                           <div className="badge badge-secondary p-1 mr-2">
              //                             {Obj?.category === "movie" ||
              //                               Obj?.category === "video"
              //                               ? convertSecondsToHoursMinutes(
              //                                 Obj?.media?.duration.toFixed(2)
              //                               )
              //                               : `${Obj?.episodes?.length} ${Obj?.episodes?.length === 1
              //                                 ? "Episode"
              //                                 : "Episodes"
              //                               }`}
              //                           </div>
              //                         </div>
              //                         <div className="block-description ">
              //                           <div className="card-slider-content">
              //                             {Obj?.category === "movie" ||
              //                               Obj?.category === "video" ? (
              //                               <h6 className="iq-title title-desc">
              //                                 {Obj?.media?.title}
              //                               </h6>
              //                             ) : (
              //                               <h6 className="iq-title title-desc">
              //                                 {Obj?.title}
              //                               </h6>
              //                             )}
              //                           </div>
              //                           <div className="card-slider-desc">
              //                             <span
              //                               dangerouslySetInnerHTML={{
              //                                 __html: limitTextToWords(
              //                                   Obj?.media?.description,
              //                                   12
              //                                 ),
              //                               }}
              //                             ></span>
              //                           </div>
              //                           <div className="block-social-info-button">
              //                             <li className="share">
              //                               <div className="hover-buttons">
              //                                 {Obj?.category === "movie" ||
              //                                   Obj?.category === "video" ? (
              //                                   <Link
              //                                     to={`/video-details/${source}/${Obj?._id}`}
              //                                     className="btn btn-hover iq-button"
              //                                   >
              //                                     <i
              //                                       className="fa fa-play mr-2"
              //                                       aria-hidden="true"
              //                                     ></i>
              //                                     {t("Play now")}
              //                                   </Link>
              //                                 ) : (
              //                                   <Link
              //                                     to={`/show-info/${Obj?._id}`}
              //                                     className="btn btn-hover iq-button"
              //                                   >
              //                                     <i
              //                                       className="fa fa-play mr-2"
              //                                       aria-hidden="true"
              //                                     ></i>
              //                                     {t("Play now")}
              //                                   </Link>
              //                                 )}
              //                               </div>
              //                             </li>
              //                           </div>
              //                         </div>
              //                         <div className="block-social-info">
              //                           <ul className="list-inline p-0 m-0 music-play-lists">
              //                             <li className="share">
              //                               <span>
              //                                 <i className="ri-share-fill"></i>
              //                               </span>
              //                               <div className="share-box">
              //                                 <div className="d-flex align-items-center">
              //                                   <a
              //                                     href={genreFacebookShareUrl(
              //                                       Obj,
              //                                       source,
              //                                       Obj?.category,
              //                                       Obj?._id
              //                                     )}
              //                                     target="_blank"
              //                                     rel="noopener noreferrer"
              //                                     className="share-ico"
              //                                     tabIndex="0"
              //                                   >
              //                                     <i className="ri-facebook-fill"></i>
              //                                   </a>
              //                                   <a
              //                                     href={genreTwitterShareUrl(
              //                                       Obj,
              //                                       source,
              //                                       Obj?.category,
              //                                       Obj?._id
              //                                     )}
              //                                     target="_blank"
              //                                     rel="noopener noreferrer"
              //                                     className="share-ico"
              //                                     tabIndex="0"
              //                                   >
              //                                     <i className="ri-twitter-fill"></i>
              //                                   </a>
              //                                   <a
              //                                     href={genreWhatsappShareUrl(
              //                                       Obj,
              //                                       source,
              //                                       Obj?.category,
              //                                       Obj?._id
              //                                     )}
              //                                     target="_blank"
              //                                     rel="noopener noreferrer"
              //                                     className="share-ico iq-copy-link"
              //                                     tabIndex="0"
              //                                   >
              //                                     <i className="ri-whatsapp-fill"></i>
              //                                   </a>
              //                                 </div>
              //                               </div>
              //                             </li>
              //                             <li>
              //                               <span>
              //                                 <i
              //                                   className={`ri-heart-fill ${favoriteItems.includes(
              //                                     Obj?._id
              //                                   )
              //                                     ? "red-heart"
              //                                     : ""
              //                                     }`}
              //                                   onClick={(event) =>
              //                                     handleFavClick(
              //                                       event,
              //                                       Obj?._id,
              //                                       Obj?.category
              //                                     )
              //                                   }
              //                                   title={
              //                                     favoriteItems.includes(
              //                                       Obj?._id
              //                                     )
              //                                       ? t("Remove From Favourite")
              //                                       : t("Add To Favourite")
              //                                   }
              //                                 ></i>
              //                               </span>
              //                               {/* <span className="count-box">19+</span> */}
              //                             </li>
              //                             <li>
              //                               <span>
              //                                 <i
              //                                   className={`ri-add-line ${watchListItems.includes(
              //                                     Obj?._id
              //                                   )
              //                                     ? "red-heart"
              //                                     : ""
              //                                     }`}
              //                                   onClick={(event) =>
              //                                     handleWatchList(
              //                                       event,
              //                                       Obj?._id,
              //                                       Obj?.category
              //                                     )
              //                                   }
              //                                   title={
              //                                     watchListItems.includes(
              //                                       Obj?._id
              //                                     )
              //                                       ? t("Remove From WatchList")
              //                                       : t("Add To WatchList")
              //                                   }
              //                                 ></i>
              //                               </span>
              //                             </li>
              //                           </ul>
              //                         </div>
              //                       </div>
              //                     </Link>
              //                   </SwiperSlide>
              //                 ))}
              //               </Swiper>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     </section>
              //   );
              // } else {
              //   return null;
              // }
            })}
            {/* Single Section */}
            <section
              id="parallex"
              className="parallax-window"
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            >
              <Container fluid className="h-100">
                <Row className="align-items-center justify-content-center h-100 parallaxt-details">
                  <Col lg="4" className="r-mb-23">
                    <div className="text-left">
                      <Link to="#">
                        <h2
                          className="text-white"
                          style={{
                            fontSize: "30px",
                            fontWeight: "600px",
                            // whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            textTransform: "uppercase",
                            display: "-webkit-box",
                            WebkitLineClamp: "6",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {bannerTitle}
                        </h2>
                      </Link>
                      <div className="movie-time d-flex align-items-center mb-3 mt-3 iq-ltr-direction">
                        {/* <div className="badge badge-secondary mr-3">{"24.20"}</div> */}
                        {displayGenres && (
                          <h6 className="text-white">{displayGenres}</h6>
                        )}
                      </div>
                      <p
                        className="paraOverflow"
                        dangerouslySetInnerHTML={{
                          __html: bannerDescription,
                        }}
                      >
                        {/* {mainSlider?.category === "movie" ||
                              mainSlider?.category === "video"
                                ? mainSlider?.general_content?.media
                                    ?.description
                                : mainSlider?.show?.description} */}
                      </p>
                      <div className="parallax-buttons">
                        <Link
                          to={
                            banner?.category === "movie" ||
                              banner?.category === "video"
                              ? `/video-details/${source}/${banner?.general_content?._id}`
                              : `/show-info/${banner?.show?._id}`
                          }
                          className="btn btn-hover"
                        >
                          {t("Play now")}
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col lg="8">
                    <div className="parallax-img">
                      <Link
                        to={
                          banner?.category === "movie" ||
                            banner?.category === "video"
                            ? `/video-details/${source}/${banner?.general_content?._id}`
                            : `/show-info/${banner?.show?._id}`
                        }
                      >
                        <img
                          src={imageUrl}
                          className="img-fluid w-100"
                          alt="afo"
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            {/* TRENDING SECTION */}
            <TrendingSlider />
            {/* genre section */}
            {/* <GenreSlider /> */}
            {/* Recommended for you */}
            {recommenedDataCopy?.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">
                          {t("Recommended for you")}
                        </h4>
                        {/* <Link className="iq-view-all" to="/movie-category">
                  View All
                </Link> */}
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prev",
                            nextEl: "#next",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          slidesPerView={4}
                          spaceBetween={20}
                          as="ul"
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {recommenedDataCopy?.length > 0 &&
                            recommenedDataCopy?.map((data) => (
                              <SwiperSlide
                                as="li"
                                key={data.id}
                                onMouseEnter={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseEnterVideo(data)
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseEnterTVShow(data)
                                      : handleMouseEnterAD(data)
                                }
                                onMouseLeave={() =>
                                  data?.category === "video" ||
                                    data?.category === "movie"
                                    ? handleMouseLeaveVideo()
                                    : data?.category === "tvshow" ||
                                      data?.category === "podcast"
                                      ? handleMouseLeaveTVShow()
                                      : handleMouseLeaveAD()
                                }
                              >
                                <Link
                                  to={
                                    data?.category === "movie" ||
                                      data?.category === "video"
                                      ? `/video-details/${source}/${data?._id}`
                                      : `/show-info/${data?._id}`
                                  }
                                  onClick={(event) =>
                                    handleAdClicked(event, data)
                                  }
                                >
                                  <div className="block-images position-relative slider-img-main">
                                    <div className="img-box">
                                      {/* Only render the video if the media category is "video" or "movie" */}
                                      {data?.category === "movie" ||
                                        data?.category === "podcast" ||
                                        data?.category === "tvshow" ? (
                                        <img
                                          src={
                                            data?.thumbnail
                                              ?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      ) : data?.media_id !== "" ? (
                                        <ReactPlayer
                                          className="img-fluid slider-img"
                                          url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                          width="100%"
                                          height="100%"
                                          pip={false}
                                          controls={false}
                                          playing={true}
                                          muted={true}
                                          loop={true}
                                        />
                                      ) : (
                                        <img
                                          src={data?.banner_url}
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="card-slider-badge">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(
                                            data?.media?.duration.toFixed(2)
                                          )
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                    <div className="block-description ">
                                      <div className="card-slider-content">
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (
                                          <h6 className="iq-title title-desc">
                                           
                                            {currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.media?.title } 
                                            {/* ici */}
                                          </h6>
                                        ) : (
                                          <h6 className="iq-title title-desc">
                                            {data?.title}
                                          </h6>
                                        )}
                                      </div>
                                      <div className="card-slider-desc">
                                        {/* recommend */}
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: limitTextToWords(
                                              currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.media?.description  ,
                                              12
                                            ),
                                          }}
                                        ></span>
                                      </div>
                                      {data?.category !== "ads" && (
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              {data?.category === "movie" ||
                                                data?.category === "video" ? (
                                                <Link
                                                  to={`/video-details/${source}/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/show-info/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              )}
                                            </div>
                                          </li>
                                        </div>
                                      )}
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    source,
                                                    data?.category
                                                  )}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                  onClick={handleAnchorClick}
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Homepage;
