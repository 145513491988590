import React, { useState } from "react";
import "./donate-form.css";
import { useTranslation } from "react-i18next";

const ContactConfirmation = ({ handleContactClose }) => {
  const { t } = useTranslation();

  return (
    <div className="donate-container vh-100 d-flex justify-content-center align-items-center">
      <div className="donate-box">
        <div className="mb-4 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-success"
            width="75"
            height="75"
            fill="#27aae1"
            viewBox="0 0 16 16"
          >
            <path d="M13.293 2H2.707A1.707 1.707 0 0 0 1 3.707v8.586C1 13.299 1.701 14 2.707 14h10.586A1.707 1.707 0 0 0 15 12.293V3.707A1.707 1.707 0 0 0 13.293 2zM2 3.707c0-.375.307-.682.707-.682h10.586c.4 0 .707.307.707.682v1.096L8 8 2 4.803V3.707zm0 8.586V5.732L8 9.22l6-3.488v6.561c0 .375-.307.682-.707.682H2.707A.708.708 0 0 1 2 12.293z" />
          </svg>
        </div>
        <div className="text-center">
          <h3>{t("Thank you")} !</h3>
          <p style={{ color: "white" }}>
            {t("We've received your message and will get back to you soon")}{" "}
          </p>
          <button className="btn btn-primary mt-2" onClick={handleContactClose}>
            {t("Close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactConfirmation;
