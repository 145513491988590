const donateMoneyApi = (payload, userId) => {
  return fetch(
    `${process.env.REACT_APP_API}/api/user/stripe/donation/${userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  )
    .then((response) => {
      if (response.ok) {
      } else {
        throw new Error("API request failed");
      }
    })
    .catch((error) => {
      throw new Error("Network error");
    });
};

export default donateMoneyApi;
