import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion, Button } from "react-bootstrap";
import Card from "../../../../components/Card";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const TermsOfUse = () => {
  const [content, setContent] = useState("");
  const [headings, setHeadings] = useState([]);
  const [lists, setLists] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/settings/get-terms`
        );
        const json = await response.json();
        // setData(json.savedData);
        // const parser = new DOMParser();
        // const htmlDoc = parser.parseFromString(json.savedData.terms, 'text/html');
        // console.log(json);
        setContent(json.savedData.terms);
        // const h2Elements = htmlDoc.querySelectorAll('h2');
        // const ulElements = htmlDoc.querySelectorAll('ul');

        // setHeadings(Array.from(h2Elements).map(element => element.innerHTML));
        // setLists(Array.from(ulElements).map(element => element.innerHTML));
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  // if (headings.length === 0 || lists.length === 0) {
  //   return <div>Loading...</div>;
  // }
  return (
    <>
     <Helmet>
          <title>{`${t('meta.terms.title')} | AFO Media`}</title>
          <meta property="og:title" content={`${t('meta.terms.title')} | AFO Media`} />
          <meta property="og:description" content={t('meta.terms.description')} />
          <meta property="og:url" content={`https://afomedia.com/terms-of-service`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <div>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <div className="iq-privacy-policy" style={{ marginTop: "10rem" }}>
                <div className="mb-3">
                  <h4 className="mb-3">{t("Terms Of Use")}</h4>
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </div> */}

      {/* <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy">
                <div className="mb-3">
                  <h4 className="mb-3">Terms Of Use</h4>
                  <div dangerouslySetInnerHTML={{ __html: content }} ></div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main> */}
    </>
  );
};

export default TermsOfUse;
