import { currentUserToken } from "../views/backend/home/home";
const currentSelectedLanguage = localStorage.getItem("lang");
export const UpComingSliderApi = async (currentSelectedLanguage) => {
  try {
    const upcomingOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
      body: JSON.stringify({ language: "en" }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/home/get-upcoming-content/${currentSelectedLanguage}`,
      upcomingOptions
    );

    if (!responseUpcoming.ok) {
      throw new Error("Failed to fetch Upcoming Content Response");
    }

    const data = await responseUpcoming.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from sliderAPI function");
    throw error;
  }
};

// export const LatestSliderApi = async (currentSelectedLanguage) => {
//   try {
//     // const language = currentSelectedLanguage.find(lang => lang.code === currentSelectedLanguage?.code);

//     // if (!language) {
//     //   throw new Error("Invalid language code");
//     // }
//     const latestOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${currentUserToken}`,
//         source: "front",
//       },
//       // body: JSON.stringify({ language: language.code }),
//       body: JSON.stringify({ language: "en" }),
//     };

//     const responseUpcoming = await fetch(
//       `${process.env.REACT_APP_API}/api/home/get-latest-content/${currentSelectedLanguage}`,
//       latestOptions
//     );

//     if (!responseUpcoming.ok) {
//       throw new Error("Failed to fetch Latest Content Response");
//     }

//     const data = await responseUpcoming.json();
//     return data;
//   } catch (error) {
//     // console.error(error, "Error Coming from Latest APi function");
//     throw error;
//   }
// };

export const LatestSliderApi = async (currentSelectedLanguage) => {
  try {
    const latestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
      body: JSON.stringify({ language: "en" }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/home/get-latest-content/${currentSelectedLanguage}`,
      latestOptions
    );

    if (!responseUpcoming.ok) {
      throw new Error("Failed to fetch Latest Content Response");
    }

    const data = await responseUpcoming.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Latest APi function");
    throw error;
  }
};
export const TopRatedHomeSlider = async (currentSelectedLanguage) => {
  try {
    const verticalOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
      body: JSON.stringify({ language: "en" }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/home/get-top-rated-content/${currentSelectedLanguage}`,
      verticalOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Vertical Top Rated Content Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Vertical function");
    throw error;
  }
};

export const fetchLiveData = async (currentSelectedLanguage) => {
  try {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_API}/api/channel/get-all-active-channels/${currentSelectedLanguage}`,
      requestOptions
    );



    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    // console.error(error, "Error occurred while fetching data");
    throw error;
  }
};

export const MainSliderApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/sliders/get-slider-by-slider-type/home/${currentSelectedLanguage}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Main Slider Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Main Slider function");
    throw error;
  }
};

export const SuggestedApi = async (currentSelectedLanguage) => {
  try {
    const suggestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
      body: JSON.stringify({ language: "en" }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-upcoming-movies/${currentSelectedLanguage}`,
      suggestedOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Suggested API Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Suggested API function");
    throw error;
  }
};
export const TrendingApi = async (currentSelectedLanguage) => {
  try {
    const trendingOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
      body: JSON.stringify({ language: "en" }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-upcoming-movies/${currentSelectedLanguage}`,
      trendingOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Trending API Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from trending API function");
    throw error;
  }
};
export const RecommendedApi = async (currentSelectedLanguage) => {
  try {
    const recommendedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
      body: JSON.stringify({ language: "en" }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-upcoming-movies/${currentSelectedLanguage}`,
      recommendedOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch recommended API Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from recommended API function");
    throw error;
  }
};
