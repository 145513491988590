import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Modal } from "react-bootstrap";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import {
  convertSecondsToHoursMinutes,
  limitTextToWords,
} from "../../../Services/generalFunctions/generalFunctions";
import { useTranslation } from "react-i18next";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
} from "../home/ShareFunction";
import { FaTrash } from "react-icons/fa";

const FavouriteList = () => {
  const { t } = useTranslation();
  const source = "app";
  const [favList, setFavList] = useState([]);
  const [query, setQuery] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const location = useLocation();

  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData(currentPage, query);
  }, [currentPage, query]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteFav = (itemId) => {
    setShowDeleteModal(true);
    setItemIdToDelete(itemId);
  };

  const handleConfirmDeleteFAV = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/user/delete-favorite/${itemIdToDelete}`
      );
      // setFavList(watchList.filter((item) => item._id !== itemIdToDelete));
      setFavList((prevList) =>
        prevList.filter((item) => item._id !== itemIdToDelete)
      );
    } catch (error) {}
    setShowDeleteModal(false);
  };

  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;

  const fetchData = (page, query) => {
    const url = `${process.env.REACT_APP_API}/api/user/get-user-favorite/${currentUserId}?page=${page}&query=${query}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        setFavList(result?.userWatchList);

        const totalItems = result?.userWatchList?.length;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(totalPages);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData(currentPage, query);
    const searchParams = new URLSearchParams(location.search);
    setQuery(searchParams.get("query") || "");
  }, [currentPage, location, query]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = favList.slice(startIndex, endIndex);

  // const useLocalStorage = (key, defaultValue) => {
  //   const [value, setValue] = useState(() => {
  //     const storedValue = localStorage.getItem(key);
  //     return storedValue ? JSON.parse(storedValue) : defaultValue;
  //   });

  //   useEffect(() => {
  //     localStorage.setItem(key, JSON.stringify(value));
  //   }, [key, value]);

  //   return [value, setValue];
  // };
  // const [favoriteItems, setFavoriteItems] = useLocalStorage(
  //   "favoriteItems",
  //   []
  // );
  // const [watchListItems, setWatchListItems] = useLocalStorage(
  //   "watchListItems",
  //   []
  // );

  return (
    <>
      <Modal
        className="deleteModal"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete FAV LIST?")}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary delBtn"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary delBtn"
            onClick={handleConfirmDeleteFAV}
          >
            {t("Delete")}
          </button>
        </Modal.Footer>
      </Modal>
      <br />
      <div style={{ marginTop: "80px" }}></div>
      <div style={{ minHeight: "70vh" }}>
        <section id="iq-favorites">
          <Container fluid>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div
                  className="gallery-container"
                  style={{ marginTop: "20px", marginBottom: "10px" }}
                >
                  <div className="">
                    <div id="upcoming-contents">
                      <ul className="list-inline mb-0 iq-rtl-direction row">
                        {displayedData?.map((data) => (
                          <li
                            className={`all-card-container slide-item  mb-4 ${
                              displayedData?.length === 1
                                ? "col-lg-6"
                                : "col-lg-3 col-md-4 col-sm-6"
                            }`}
                            key={data._id}
                          >
                            <div className="block-images position-relative">
                              <div className="card-slider-badge">
                                <div className="badge badge-secondary p-1 mr-2">
                                  {data?.category === "movie" ||
                                  data?.category === "video"
                                    ? convertSecondsToHoursMinutes(
                                        data?.general_content?.media?.duration?.toFixed(
                                          2
                                        )
                                      )
                                    : data?.category?.toUpperCase()}
                                </div>
                              </div>
                              <div className="img-box">
                                {data?.category === "movie" ||
                                data?.category === "video" ? (
                                  <img
                                    src={
                                      data?.general_content?.thumbnail
                                        ?.static_thumbnail_url
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={
                                      data?.show?.thumbnail
                                        ?.static_thumbnail_url
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                                <FaTrash
                                  className="cross-icon"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDeleteFav(data?._id);
                                  }}
                                />
                              </div>
                              <div className="block-description">
                                <h6 className="iq-title all-title-desc">
                                  {data?.category === "movie" ||
                                  data?.category === "video"
                                    ? data?.general_content?.media?.title
                                    : data?.show?.title}
                                </h6>

                                <div className="card-slider-desc">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: limitTextToWords(
                                        data?.media?.description,
                                        12
                                      ),
                                    }}
                                  ></span>
                                </div>
                                <div className="hover-buttons">
                                  {data?.category === "movie" ||
                                  data?.category === "video" ? (
                                    <Link
                                      to={`/video-details/${source}/${data?.general_content?._id}`}
                                      role="button"
                                      className="btn btn-hover iq-button btn-typ"
                                    >
                                      <i
                                        className="fa fa-play mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  ) : (
                                    <Link
                                      to={`/show-info/${data?.show?._id}`}
                                      role="button"
                                      className="btn btn-hover iq-button btn-typ"
                                    >
                                      <i
                                        className="fa fa-play mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  )}
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        {/* <a
                                          href={generateLinkedinShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-linkedin-fill"></i>
                                        </a>
                                        <a
                                          href={generateInstagramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-instagram-fill"></i>
                                        </a>
                                        <a
                                          href={generateSnapchatShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-snapchat-fill"></i>
                                        </a>
                                        <a
                                          href={generateTikTokShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-tiktok-fill"></i>
                                        </a> */}
                                        <a
                                          href={generateFacebookShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                        {/* <a
                                          href={generatePinterestShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-pinterest-fill"></i>
                                        </a>
                                        <a
                                          href={generateTelegramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-telegram-fill"></i>
                                        </a>
                                        <a
                                          href={generateRedditShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-reddit-fill"></i>
                                        </a>
                                        <a
                                          href={generateQuoraShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-quora-fill"></i>
                                        </a> */}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-heart-fill red-heart
                                      `}
                                        //   onClick={() =>
                                        //     handleFavClick(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        // title={
                                        //   favoriteItems.includes(data?._id)
                                        //     ? "Remove From Favourite"
                                        //     : "Add To Favourite"
                                        // }
                                      ></i>
                                    </span>
                                    {/* <span className="count-box">19+</span> */}
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-add-line red-heart
                                      `}
                                        //   onClick={() =>
                                        //     handleWatchList(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        // title={
                                        //   watchListItems.includes(data?._id)
                                        //     ? "Remove From WatchList"
                                        //     : "Add To WatchList"
                                        // }
                                      ></i>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="pagination-container">
          <Pagination className="custom-pagination">
            {" "}
            {/* Add the custom class */}
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
    </>
  );
};
export default FavouriteList;
