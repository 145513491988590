import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { Container, Col, Row } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import { Modal } from "react-bootstrap";
import ReactJWPlayer from "react-jw-player";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import getGeneralContent from "../../../Services/generalContent";
import MoviePoster from "../../../components/moviePoster";
import {
  assignAdToTarget,
  convertSecondsToHoursMinutes,
  dateToWords,
  getDate,
  handleAdClicked,
  handleAnchorClick,
  limitTextToWords,
  shuffleArray,
} from "../../../Services/generalFunctions/generalFunctions";
import { useTranslation } from "react-i18next";
import { TVShowsByGenre } from "../../../Services/tvShowsApi";
import {
  podCastAndShowsByGenre,
  podCastShowsByGenre,
} from "../../../Services/podCastApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  genreFacebookShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
} from "../home/ShareFunction";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../home/FavWatch";
import { currentUserToken } from "../home/home";
import getAnalytics from "../../../Services/getAnalytics";
import { useContext } from "react";
import { MyContext } from "../../../store/context";
import ShowDescriptionModal from "../../../components/partials/UI/ShowDescriptionModal";
import {
  adClickApi,
  fetchAdsDataPodcast,
  fetchAdsDataTVShow,
  fetchAdsDataTVShowAndPodcast,
} from "../../../Services/adsApi";
import ReactPlayer from "react-player";
import AdsModal from "../../../components/partials/UI/AdsModal";
import { Helmet } from "react-helmet";
SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
]);

const ShowInfo = () => {
  const currentSelectedLanguage = localStorage.getItem("lang");
  const [tvGenreData, setTVGenreData] = useState([]);
  const [podCastGenreData, setPodCastGenreData] = useState([]);
  const [genre, setGenre] = useState([]);
  const [playerId, setPlayerId] = useState("");
  const { t } = useTranslation();
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [trailerMediaId, setTrailerMediaId] = useState("");
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [noOfEpisodes, setNoOfEpisodes] = useState(8);
  const [toggler1, setToggler1] = useState(false);
  const renderedIDs = []; // to keep track of duplicate items inside more like this
  const [loader, setLoader] = useState(true);

  const history = useHistory();
  const source = "app";

  const { category, setCategory } = useContext(MyContext);

  // to change the routes when language is changed
  const location = useLocation();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (
      !isInitialRender.current &&
      currentSelectedLanguage &&
      location.pathname.includes("/show-info/")
    ) {
      history.push("/");
    }
    isInitialRender.current = false;
  }, [currentSelectedLanguage, history, location]);

  const crewMembers = data?.crew_members;
  const jwTags = data?.jw_tags;
  const seoTags = data?.seo_tags;
  const tvID = data?._id;
  const mediaCategory = data?.category;
  const episodes_id = data?.seasons?.flatMap((season) =>
    season?.episodes?.map((episode) => episode?._id)
  );
  const crewNames = crewMembers?.map((member) => member.member_name);
  const crewRoles = crewMembers?.map((member) => member.member_role);
  const monetization = data?.monetization;
  const handleEpisodesNumber = () => {
    setNoOfEpisodes(noOfEpisodes + 4);
  };

  useEffect(() => {
    if (monetization === true) {
      setPlayerId("rGoke68A");
    } else {
      setPlayerId("rGoke68A");
    }
  }, [monetization]);
  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API}/api/tv-shows/get-tvShow-details/${id}`)
      .then((res) => res.json())
      .then((result) => {
        if (result && result.tvShowDetails) {
          const mediaIds = [];
          result?.tvShowDetails?.seasons.forEach((season) => {
            season.episodes.forEach((episode) => {
              if (episode.media_id) {
                mediaIds.push(episode.media_id);
              }
            });
          });
          setLoader(false);
          setMediaIdArray(mediaIds);
          setData(result.tvShowDetails);
          setSeasons(
            result?.tvShowDetails?.seasons?.map((season, index) => ({
              ...season,
              index,
            }))
          );
          setSelectedSeason(result?.tvShowDetails?.seasons[0]?._id);
        } else {
          setData([]);
          setMediaIdArray([]);
          setLoader(false);
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (seasons.length > 0) {
      setSelectedSeason(seasons[0]?._id);
    }
  }, [seasons]);

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  const episodes =
    seasons.find((season) => season._id === selectedSeason)?.episodes || [];

  const latestEpisodeId = seasons[0]?.episodes[0]?._id;

  const [genresData, setGenresData] = useState([]);

  useEffect(() => {
    podCastAndShowsByGenre(currentSelectedLanguage, mediaCategory)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setGenresData(data.upcomingContent);
        } else {
          setGenresData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, [currentSelectedLanguage, mediaCategory]);

  useEffect(() => {
    getGeneralContent({ id })
      .then((data) => {
        setData((prevGenre) => data?.genre || prevGenre);
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, []);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let concatenatedMedia = [];
        for (let i = 0; i < data?.genre.length; i++) {
          const genreId = data?.genre?.[i]._id;
          let response;
          if (mediaCategory === "tvshow") {
            response = await axios.get(
              `${process.env.REACT_APP_API}/api/tv-shows/get-genre-tvShows/${genreId}/${currentSelectedLanguage}`
            );
          } else if (mediaCategory === "podcast") {
            response = await axios.get(
              `${process.env.REACT_APP_API}/api/podcast/get-genre-podcasts/${genreId}/${currentSelectedLanguage}`
            );
          }
          const moviesData = response.data;
          concatenatedMedia = concatenatedMedia.concat(
            moviesData.upcomingContent
          );
        }
        setMovies(concatenatedMedia);
      } catch (error) {
        // console.error("Error fetching media:", error.response.data);
      }
    };

    fetchData();
  }, [data]);

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );
  function handleFavClick(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }
    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    // Check if the item is already in the favorite list
    if (favoriteItems.includes(id)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(id);
      toast.info(t("Content Removed From Favorite list!"), {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== id));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, id]);
          } else {
            toast.error(t("Error occurred at Fav List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  function handleWatchList(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }
    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    // Check if the item is already in the watch list
    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Content Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at adding in WatchList"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }
    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item?.general_content_id);
        setData(data);
      } catch (error) {
        // console.log(`Error fetching data: ${error}`);
      }
    };

    // if (currentUserId) {
    const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
    fetchData(watchListUrl, setWatchListItems);

    const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
    fetchData(favoriteItemsUrl, setFavoriteItems);
    // }
  }, [currentUserId]);
  // analytics=============================================================================
  const [media_idsArray, setMediaIdArray] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  // const media_idsArray = ["FXD122yw", "c3LQXevQ", "u6xPerX6"];
  useEffect(() => {
    if (media_idsArray.length > 0) {
      const mediaId = media_idsArray;
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];

      const analyticsData = {
        media_ids: mediaId,
        start_date: getDate(data?.createdAt),
        end_date: formattedDate,
      };
      getAnalytics(analyticsData)
        .then((response) => {
          setDataRows(response.data?.rows);
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }, [media_idsArray, data]);

  // hovering video modal description work
  const [descModal, setDescModal] = useState(false);
  const handleClose1 = () => setDescModal(false);
  const handleShow1 = () => setDescModal(true);

  // for description modal
  const [hoveredItem, setHoveredItem] = useState({});
  const modalTimerRef = useRef(null);

  const handleMouseEnter = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItem(item);

    modalTimerRef.current = setTimeout(() => {
      setDescModal(true);
    }, 3000); // 5 seconds
  };

  const handleMouseLeave = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRef.current);
    // setHoveredItem(null);
  };

  const handleModalClose = () => {
    // Close the modal
    setDescModal(false);
  };

  // AD Data
  const [adsData, setAdsData] = useState({});
  useEffect(() => {
    // Call the fetchAdsDataTvShow function
    const fetchData = async () => {
      try {
        const data = await fetchAdsDataTVShowAndPodcast(
          currentSelectedLanguage,
          mediaCategory
        );
        setAdsData(data?.adsList);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [mediaCategory]);

  const [adsCopyCopy, setAdsCopyCopy] = useState([]);
  const [similarCopy, setSimilarCopy] = useState([]);

  useEffect(() => {

    const adsCopyCopyTemp = adsData.length > 0 ? [...adsData] : [];
    const similarCopyTemp = [...movies];

    // Assign ads from the adsCopyCopy to topRatedDataCopy and upComingDataCopy
    assignAdToTarget(adsCopyCopy, similarCopyTemp);
    assignAdToTarget(adsCopyCopy, similarCopyTemp);

    // Update the state variables to reflect the changes
    setAdsCopyCopy(shuffleArray(adsCopyCopyTemp));
    setSimilarCopy(shuffleArray(similarCopyTemp));

  }, [adsData, movies]);

  // AD Modal
  const [hoveredItemAD, setHoveredItemAD] = useState({});
  const [showADModal, setShowADModal] = useState(false);
  const handleCloseADModal = () => setShowADModal(false);
  const handleShowADModal = () => setShowADModal(true);
  const modalTimerRefAD = useRef(null);
  const handleMouseEnterAD = (item) => {
    // Set the hovered item
    setHoveredItemAD(item);

    // Clear the previous timer, if any
    clearTimeout(modalTimerRefAD.current);

    if (item?.autoplay === "instant") {
      // Autoplay with a delay of 1 second
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 500); // half a second
    } else if (item?.autoplay === "3 seconds") {
      // Autoplay with a delay of 3 seconds
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 3000);
    } else if (item?.autoplay === "off") {
      // No autoplay
      setShowADModal(false);
    }
  };

  const handleMouseLeaveAD = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRefAD.current);
    // setHoveredItem(null);
  };

  const handleModalCloseAD = () => {
    // Close the modal
    setShowADModal(false);
  };
  return (
    <>
     <Helmet>
          <title>AFO Media | Liste des episodes</title>
          <meta property="og:title" content={`Liste des episodes | AFO Media`} />
          <meta property="og:description" content="Liste des episodes"/>
          <meta property="og:url" content={`https://afomedia.com/show-info/${id}`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <Modal
        show={descModal}
        onHide={handleClose1}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ShowDescriptionModal item={hoveredItem} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
      {/* AD Modal */}
      <Modal
        show={showADModal}
        onHide={handleCloseADModal}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <AdsModal item={hoveredItemAD} handleClose={handleCloseADModal} />
        </Modal.Body>
      </Modal>
      <FsLightbox
        toggler={toggler1}
        sources={[
          <iframe
            src={`https://cdn.jwplayer.com/players/${trailerMediaId}-4t00MwmP.html`}
            title=" "
            // width="500px"
            // height="200px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{ widt: "80%", height: "50%" }}
          />,
        ]}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          {trailerMediaId ? (
            <ReactJWPlayer
              playerId={playerId}
              playerScript={`https://content.jwplatform.com/libraries/${playerId}.js`}
              playlist={`https://cdn.jwplayer.com/v2/media/${trailerMediaId}`}
              controls={false}
              isAutoPlay={false}
              licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
            // file="https://live-hls-web-aje.getaj.net/AJE/05.m3u8"
            // onReady={onReady}
            />
          ) : (
            <div className=" d-flex justify-content-center align-items-center">
              <h4>{t("No trailer available!")}</h4>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {loader ? (
        <div className="loader" style={{ minHeight: "100vh" }}>
          <div className="outer"></div>
          <div className="middle"></div>
          <div className="inner"></div>
        </div>
      ) : (
        <>
          <section className="container-fluid" style={{ paddingTop: "6rem" }}>
            
            <MoviePoster
              thumbnail={data.thumbnail?.banner_thumbnail_url}
              title={currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  : data?.title }
              seoTags={seoTags}
              description={currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  : data?.description }
              trailer={data?.trailer?.media_id}
              crewNames={crewNames}
              crewRoles={crewRoles}
              jwTags={jwTags}
              latestEpisodeId={latestEpisodeId}
              tvID={tvID}
              mediaCategory={mediaCategory}
              episodes_id={episodes_id}
              currentUserId={currentUserId}
              createdAt={data?.createdAt}
              seasons={data?.seasons}
              dataRows={dataRows}
              monetization={monetization}
            />
          </section>

          <section>
            <div
              className="container-fluid"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5rem",
              }}
            >
              <div>
                {seasons.length > 0 && (
                  <select
                    value={selectedSeason}
                    onChange={handleSeasonChange}
                    style={{
                      padding: "0.5rem",
                      fontSize: "1rem",
                      borderRadius: "4px",
                      border: "1px solid #5e6163",
                      width: "200px",
                      backgroundColor: "#272a2b",
                      color: "white",
                    }}
                  >
                    {seasons?.map((season) => (
                      <option key={season._id} value={season._id}>
                        {t("Season")} {season.index + 1}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {/* <div
            className="trailer-container"
            onClick={() => setToggler1(!toggler1)}
            
          >
            <img
              src={data.thumbnail?.banner_thumbnail_url}
              alt="Background"
              className="trailer-image"
            />
            <div className="trailer-overlay"></div>
            <div className="trailer-text">Watch trailer</div>
          </div> */}
            </div>
          </section>
          {/* <select value={selectedSeason?._id || null} onChange={handleSeasonChange}>
        <option value={null}>Select Season </option>
        {seasons.map((season, index) => <option value={season._id}>{`Season ${index + 1}`}</option>
        )}
      </select> */}
          <section id="iq-favorites">
            <Container fluid>
              <div className="block-space">
                <div className="episode-section-season">
                  <h5>{t("EPISODES")}</h5>
                </div>

                <Row>
                  {episodes?.map((episode, index) => (
                    <Col
                      xl="3"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                      key={index}
                      className="mb-3"
                    >
                      <div className="epi-box">
                        <div className="epi-img position-relative">
                          <img
                            src={episode?.thumbnail?.static_thumbnail_url}
                            className="img-fluid img-zoom"
                            alt=""
                          />
                          <span className="episode-tag">
                            {index < 10
                              ? "EO" + (index + 1)
                              : "E" + (index + 1)}
                          </span>
                          <div className="episode-play-info">
                            <div
                              className="episode-play"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <Link to={`/episode/${episode?._id}`}>
                                <i className="ri-play-fill"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="epi-desc p-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="episode-release-date">
                              {dateToWords(episode?.release_year)}
                            </span>
                            <span className="text-primary episode-tag-duration">
                              {convertSecondsToHoursMinutes(episode?.duration)}
                            </span>
                          </div>
                          <h6 className="epi-name text-white mb-0 mt-3">
                            {/* {episode?.title} */}

                              {/* {console.log(episode)} */}
                            {currentSelectedLanguage !== "fr" ? episode?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :episode?.title }

                          </h6>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>

                {selectedSeason &&
                  selectedSeason?.episodes &&
                  noOfEpisodes < selectedSeason?.episodes.length && (
                    <div className="text-center flex justify-content-center mt-5">
                      <button
                        className="btn btn-primary"
                        onClick={handleEpisodesNumber}
                      >
                        Show More Episodes
                      </button>
                    </div>
                  )}
              </div>
            </Container>
          </section>
          {similarCopy?.length > 0 && (
            <section id="iq-favorites">
              <Container fluid>
                <Row>
                  <Col sm="12" className="overflow-hidden">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="main-title">{t("More Like This")}</h4>
                      <Link className="iq-view-all" to="/">
                        {/* View All */}
                      </Link>
                    </div>
                    <div id="favorites-contens">
                      <div
                        id="prev1"
                        className="swiper-button swiper-button-prev"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <div
                        id="next1"
                        className="swiper-button swiper-button-next"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        navigation={{
                          prevEl: "#prev1",
                          nextEl: "#next1",
                        }}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          550: { slidesPerView: 2 },
                          991: { slidesPerView: 3 },
                          1400: { slidesPerView: 4 },
                          1600: { slidesPerView: 5 },
                        }}
                        loop={false}
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                      >
                        {similarCopy?.map((data) => (
                          <SwiperSlide as="li">
                            <a
                              href={
                                data?.category !== "ads"
                                  ? `/show-info/${data?._id}`
                                  : null
                              }
                              onClick={(event) => handleAdClicked(event, data)}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="block-images position-relative slider-img-main"
                                onMouseEnter={() => {
                                  if (data?.category !== "ads") {
                                    handleMouseEnter(data);
                                  } else {
                                    handleMouseEnterAD(data);
                                  }
                                }}
                                onMouseLeave={() => {
                                  if (data?.category !== "ads") {
                                    handleMouseLeave();
                                  } else {
                                    handleMouseLeaveAD();
                                  }
                                }}
                              >
                                <div className="img-box">
                                  {/* Only render the video if the media category is "movie" */}
                                  {data?.category === "tvshow" ||
                                    data?.category === "podcast" ? (
                                    <img
                                      src={
                                        data?.thumbnail?.banner_thumbnail_url
                                      }
                                      className="img-fluid slider-img"
                                      alt=""
                                    />
                                  ) : data?.media_id !== "" ? (
                                    <ReactPlayer
                                      className="img-fluid slider-img"
                                      url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                      width="100%"
                                      height="100%"
                                      pip={false}
                                      controls={false}
                                      playing={true}
                                      muted={true}
                                      loop={true}
                                    />
                                  ) : (
                                    <img
                                      src={data?.banner_url}
                                      className="img-fluid slider-img"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="card-slider-badge">
                                  <div className="badge badge-secondary p-1 mr-2">
                                    {data?.category === "movie" ||
                                      data?.category === "video"
                                      ? convertSecondsToHoursMinutes(
                                        data?.media?.duration.toFixed(2)
                                      )
                                      : data?.category === "ads"
                                        ? null
                                        : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                          ? "Episode"
                                          : "Episodes"
                                        }`}
                                  </div>
                                </div>
                                <div className="block-description ">
                                  <div className="card-slider-content">
                                    <h6 className="iq-title title-desc">
                                     
                                

                                      {currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  : data?.title }
                                    </h6>
                                  </div>
                                  <div className="card-slider-desc">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: limitTextToWords(
                                          currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  : data?.description ,
                                          12
                                        ),
                                      }}
                                    ></span>
                                  </div>
                                  {data?.category !== "ads" && (
                                    <div className="block-social-info-button">
                                      <li className="share">
                                        <div className="hover-buttons">
                                          <Link
                                            to={`/show-info/${data?._id}`}
                                            className="btn btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-2"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        </div>
                                      </li>
                                    </div>
                                  )}
                                </div>
                                {data?.category !== "ads" && (
                                  <div className="block-social-info">
                                    <ul className="list-inline p-0 m-0 music-play-lists">
                                      <li className="share">
                                        <span>
                                          <i className="ri-share-fill"></i>
                                        </span>
                                        <div className="share-box">
                                          <div className="d-flex align-items-center">
                                            <a
                                              href={generateFacebookShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-facebook-fill"></i>
                                            </a>
                                            <a
                                              href={generateTwitterShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-twitter-fill"></i>
                                            </a>
                                            <a
                                              href={generateWhatsappShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico iq-copy-link"
                                              tabIndex="0"
                                            >
                                              <i className="ri-whatsapp-fill"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-heart-fill ${favoriteItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                              }`}
                                            onClick={(event) =>
                                              handleFavClick(
                                                event,
                                                data?._id,
                                                data?.category
                                              )
                                            }
                                            title={
                                              favoriteItems.includes(data?._id)
                                                ? t("Remove From Favourite")
                                                : t("Add To Favourite")
                                            }
                                          ></i>
                                        </span>
                                        {/* <span className="count-box">19+</span> */}
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-add-line ${watchListItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                              }`}
                                            onClick={(event) =>
                                              handleWatchList(
                                                event,
                                                data?._id,
                                                data?.category
                                              )
                                            }
                                            title={
                                              watchListItems.includes(data?._id)
                                                ? t("Remove From WatchList")
                                                : t("Add To WatchList")
                                            }
                                          ></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </a>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          {genresData.length > 0 &&
            genresData?.map((data, index) => {
              if (data?.generalContents?.length > 0) {
                return (
                  <section id="iq-upcoming-movie">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12 overflow-hidden">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="main-title">{t(data.genre)}</h4>
                            <Link className="iq-view-all" to="/">
                              {/* View All */}
                            </Link>
                          </div>
                          <div id="upcoming-contens">
                            <div
                              id={`prev2${index}`}
                              className="swiper-button swiper-button-prev"
                            >
                              <i className="fa fa-chevron-left"></i>
                            </div>
                            <div
                              id={`next2${index}`}
                              className="swiper-button swiper-button-next"
                            >
                              <i className="fa fa-chevron-right"></i>
                            </div>
                            <Swiper
                              slidesPerView={4}
                              spaceBetween={20}
                              navigation={{
                                nextEl: `#prev2${index}`,
                                prevEl: `#next2${index}`,
                              }}
                              breakpoints={{
                                320: { slidesPerView: 1 },
                                550: { slidesPerView: 2 },
                                991: { slidesPerView: 3 },
                                1400: { slidesPerView: 4 },
                                1600: { slidesPerView: 5 },
                              }}
                              loop={false}
                              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                            >
                              {data?.generalContents?.map((Obj, index) => (
                                <SwiperSlide as="li">
                                  {/* <div className=" block-images position-relative slider-img-main">
                            <div className="img-box">
                              <img
                                src={Obj?.thumbnail?.banner_thumbnail_url}
                                className="img-fluid slider-img"
                                alt=""
                                key={index}
                              />
                            </div>
                            <div className="block-description">
                              <h6 className="iq-title title-desc">
                                <Link to="/episode" key={index}>
                                  {Obj?.title}
                                </Link>
                              </h6>
                              <div className="card-slider-desc">
                                <span>
                                  {limitTextToWords(
                                    Obj?.description.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    ),
                                    12
                                  )}
                                </span>
                              </div>
                              <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                <div className="badge badge-secondary p-1 mr-2 ">
                                 
                                </div>
                              </div>
                              <div className="block-social-info-button">
                                <li className="share">
                                  <div className="hover-buttons">
                                    <Link
                                      to={`/show-info/${Obj?._id}`}
                                      className="btn btn-hover iq-button"
                                    >
                                      <i
                                        className="fa fa-play mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  </div>
                                </li>
                              </div>
                            </div>
                          </div> */}
                                  <a href={`/show-info/${Obj?._id}`}>
                                    <div
                                      className="block-images position-relative slider-img-main"
                                      onMouseEnter={() => handleMouseEnter(Obj)}
                                      onMouseLeave={handleMouseLeave}
                                    // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                    >
                                      <div className="img-box">
                                        <img
                                          src={
                                            Obj?.thumbnail?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      </div>
                                      {/* showing on the top */}
                                      <div className="card-slider-badge">
                                        <div className="badge badge-secondary p-1 mr-2">
                                          {Obj?.episodes.length}{" "}
                                          {Obj?.episodes.length === 1
                                            ? "Episode"
                                            : "Episodes"}
                                        </div>
                                      </div>
                                      <div className="block-description ">
                                        <div className="card-slider-content">
                                          {Obj?.category === "movie" ||
                                            Obj?.category === "video" ? (
                                            <h6 className="iq-title title-desc">
                                           

                                              {currentSelectedLanguage !== "fr" ? Obj?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  : Obj?.media?.title }
                                            </h6>
                                          ) : (
                                            <h6 className="iq-title title-desc">
                                                {currentSelectedLanguage !== "fr" ? Obj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  : Obj?.title }
                                              {console.log(Obj)}
                                         
                                         
                                            </h6>
                                          )}
                                        </div>
                                        <div className="card-slider-desc">
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: limitTextToWords(
                                                currentSelectedLanguage !== "fr" ? Obj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  : Obj?.media?.description ,
                                                12
                                              ),
                                            }}
                                          ></span>
                                        </div>
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              <Link
                                                to={`/show-info/${Obj?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            </div>
                                          </li>
                                        </div>
                                      </div>
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={genreFacebookShareUrl(
                                                    Obj,
                                                    source,
                                                    Obj?.category,
                                                    Obj?._id
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={genreTwitterShareUrl(
                                                    Obj,
                                                    source,
                                                    Obj?.category,
                                                    Obj?._id
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={genreWhatsappShareUrl(
                                                    Obj,
                                                    source,
                                                    Obj?.category,
                                                    Obj?._id
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  Obj?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    Obj?._id,
                                                    Obj?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    Obj?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                            {/* <span className="count-box">19+</span> */}
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  Obj?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    Obj?._id,
                                                    Obj?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    Obj?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </a>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                );
              } else {
                return null;
              }
            })}
        </>
      )}
    </>
  );
};

export default ShowInfo;
