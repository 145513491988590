const currentSelectedLanguage = localStorage.getItem("lang");
export const MoviesMainSliderApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/sliders/get-slider-by-slider-type/movie/${currentSelectedLanguage}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Movies Main Slider Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Movies Main Slider function");
  }
};

export const ALLSliderApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-active-movies/${currentSelectedLanguage}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("ALL MOVIES DATA");
    }

    const data = await response.json();
    return data;

    console.log(data);
  } catch (error) {
    // console.error(error, "Error Coming ALL MOVIES DATA");
  }
};
export const MoviesUpComingSliderApi = async (currentSelectedLanguage) => {
  try {
    const upcomingOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: "en" }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-upcoming-movies/${currentSelectedLanguage}`,
      upcomingOptions
    );

    if (!responseUpcoming.ok) {
      throw new Error("Failed to fetch Upcoming Content Response");
    }

    const data = await responseUpcoming.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from sliderAPI function");
  }
};

const fetchVideosData = async (url) => {
  try {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: "en" }),
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, `Error fetching data from ${url}`);
    throw error;
  }
};

export const MoviesGetLatestSliderApi = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/general-content/get-latest-movies/${currentSelectedLanguage}`;
  try {
    return await fetchVideosData(url);
  } catch (error) {
    // console.error(error, `Error fetching latest videos from ${url}`);
    throw new Error("Failed to fetch Latest Video Response");
  }
};

export const MoviesTopRatedApi = async (currentSelectedLanguage) => {
  try {
    const upcomingOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: "en" }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-top-rated-movies/${currentSelectedLanguage}`,
      upcomingOptions
    );

    if (!responseUpcoming.ok) {
      throw new Error("Failed to fetch Top Rated Movies Response");
    }

    const data = await responseUpcoming.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Top Rated Movies function");
    throw error;
  }
};

export const MoviesByGenre = async (currentSelectedLanguage) => {
  try {
    const upcomingOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: currentSelectedLanguage }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/general-content/get-movies-by-genre/${currentSelectedLanguage}`,
      upcomingOptions
    );

    if (!responseUpcoming.ok) {
      throw new Error("Failed to fetch Genre Movies Response");
    }

    const data = await responseUpcoming.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Genre Movies function");
    throw error;
  }
};
