import React, { useState, useEffect } from "react";
import heartSvg from "../assets/images/heart-icon.svg";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DonateForm1 from "./DonateForm1";
import { useParams } from "react-router-dom";

function DonateLink() {

    // const { token } = useParams();
    const { t } = useTranslation();

    const sessionExists = localStorage.getItem("session");
    const token = localStorage.getItem("token");
    const [tokenValid, setTokenValid] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        // Check if the token is valid based on your localStorage logic
        if (!token) {
            setTokenValid(false);
        }
    }, [token]);

    const [homeStripeModal, setHomeStripeModal] = useState(false);
    // const [showDonationModal, setShowDonationModal] = useState(true);

    // const handleDonationClose = () => setShowDonationModal(false);
    const handleDonationShow = () => setShowDonationModal(true);

    // to handle the login modal
    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleCloseLoginModal = () => setShowLoginModal(false);
    const handleShowLoginModal = () => setShowLoginModal(true);

    const [showDonationModal, setShowDonationModal] = useState(true);

    const handleDonationClose = () => setShowDonationModal(false);
    const handleDonationSuccess = (message) => {
        console.log("handleDonationSuccess called with message:", message);
        setSuccessMessage(message);
        console.log("Success message updated:", message);
    };


    if (!tokenValid) {
        return <div style={{
            textAlign: "center",
            color: "white",
            fontWeight: 600
        }}>Invalid Token</div>;
    }
    console.log("successMessage:", successMessage);
    return (
        <>
            <Modal
                show={showDonationModal}
                onHide={handleDonationClose}
                size="md"
                centered={true}
                className={`${homeStripeModal ? "stripe-Modal" : "special_modal"} `}
            // contentClassName=""
            >
                <Modal.Body>
                    <div style={{ height: "100%" }}>
                        <DonateForm1
                            handleDonationClose={handleDonationClose}
                            setHomeStripeModal={setHomeStripeModal}
                            handleDonationSuccess={handleDonationSuccess}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <div style={{
                paddingTop: "6rem"
            }}>

            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // This centers vertically
                minHeight: "80vh",   // This ensures the container takes the full viewport height
            }}>
                {successMessage && (
                    <div style={{ textAlign: "center", color: "white", fontWeight: 600 }}>
                        {successMessage}
                    </div>
                )}
            </div>

        </>
    )
}

export default DonateLink

