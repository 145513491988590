import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./videoDescriptionModal.css";
import { adClickApi } from "../../../Services/adsApi";
const AdsModal = ({ item, handleClose }) => {
  const [userVisited, setUserVisited] = useState(false);

  const adViewApi = () => {
    if (userVisited) {
      const apiUrl = `${process.env.REACT_APP_API}/api/ads/ads-views/${item?._id}`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {});
    }
  };
  useEffect(() => {
    let timeoutId;

    if (userVisited) {
      // Set a timeout of 3 seconds
      timeoutId = setTimeout(() => {
        // Call your function here when the component has been open for 3 seconds
        adViewApi();
      }, 3000);
    }

    return () => {
      // Clear the timeout when the component unmounts or isOpen becomes false
      clearTimeout(timeoutId);
    };
  }, [userVisited]);

  useEffect(() => {
    // When the component is mounted, set userVisited to true
    setUserVisited(true);
  }, []);

  const handleVisitButtonClick = () => {
    adClickApi(item?._id)
      .then(() => {
        // You can perform additional actions here if needed after the API call
        window.open(item?.redirect_url, "_blank");
      })
      .catch((error) => {
        // Handle any errors from the API call if needed
        //("Error handling after API call:", error);
      });
  };
  return (
    <div className="modal-container">
      <span onClick={() => handleClose()} className="close-modal-icon">
        <i
          className="ri-close-circle-fill"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // color: "white",
          }}
        ></i>
      </span>
      <div className="modal-video-player-wrapper">
        <div className="blurred-overlay"></div>
        {item?.media_id !== "" ? (
          <ReactPlayer
            className="modal-video-player"
            url={`https://cdn.jwplayer.com/manifests/${item?.media_id}.m3u8`}
            width="100%"
            height="100%"
            pip={false}
            controls={true}
            playing={true}
            muted={false}
            autoplay={true}
            loop={true}
          />
        ) : (
          <img
            src={item?.banner_url}
            style={{ height: "100%", width: "100%" }}
            className="modal-video-player"
          />
        )}
      </div>
      <div className="p-3" style={{ position: "relative" }}>
        <a
          className="btnMain btn-hover iq-button"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={handleVisitButtonClick}
        >
          Visit
        </a>
        {/* line 1 */}
        <div>
          <h4
            className="trending-text big-title text-uppercase mt-0 mr-2"
            style={{ width: "82%" }}
          >
            {item?.title}
          </h4>
          {/* icons */}
        </div>

        <div className="desc-modal-details-container">
          <div className="desc-modal-desc-container" style={{ width: "100%" }}>
            <span
              className=""
              dangerouslySetInnerHTML={{
                __html: item?.description,
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsModal;
