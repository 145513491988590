import { currentUserToken } from "../views/backend/home/home";

export const TVMainSliderApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/sliders/get-slider-by-slider-type/tvshow/${currentSelectedLanguage}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch TV Main Slider Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from TV Main Slider function");
    // throw error;
  }
};
export const TVShowListApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/tv-shows/get-active-tvShows/${currentSelectedLanguage}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch TVSHOW LIST Slider Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from TVSHOW LIST Slider function");
    // throw error;
  }
};
const fetchTVData = async (url, currentSelectedLanguage) => {
  try {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: currentSelectedLanguage }),
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, `Error fetching data from ${url}`);
    // throw error;
  }
};

export const TVUpComingSliderApi = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/tv-shows/get-upcoming-tvShows/${currentSelectedLanguage}`;
  try {
    return fetchTVData(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching upcoming TV Shows from ${url}`);
    // throw new Error("Failed to fetch Upcoming TV Shows Response");
  }
};

export const TVLatestSliderApi = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/tv-shows/get-latest-tvShows/${currentSelectedLanguage}`;
  try {
    return fetchTVData(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching Latest TV Shows from ${url}`);
    // throw new Error("Failed to fetch Latest TV Shows Response");
  }
};

export const TVShowsByGenre = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/tv-shows/get-tvShows-by-genre/${currentSelectedLanguage}`;
  try {
    return fetchTVData(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching TV Shows Genre from ${url}`);
    // throw new Error("Failed to fetch TV Shows Genre Response");
  }
};

export const TVTopRatedApi = async (currentSelectedLanguage) => {
  try {
    const upcomingOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: currentSelectedLanguage }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/tv-shows/get-top-rated-tvShows/${currentSelectedLanguage}`,
      upcomingOptions
    );

    if (!responseUpcoming.ok) {
      throw new Error("Failed to fetch Top Rated TV Response");
    }

    const data = await responseUpcoming.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Top Rated TV function");
    // throw error;
  }
};

// export const TVShowsByGenre = async (languages) => {
//     try {
//         const upcomingOptions = {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify({ language: "en" }),
//         };
//         const responseUpcoming = await fetch(
//             `${process.env.REACT_APP_API}/api/tv-shows/get-tvShows-by-genre/en`,
//             upcomingOptions
//         );

//         if (!responseUpcoming.ok) {
//             throw new Error("Failed to fetch Genre Video Response");
//         }

//         const data = await responseUpcoming.json();
//         return data;
//     } catch (error) {
//         console.error(error, "Error Coming from Genre Video function");
//         throw error;
//     }
// };
