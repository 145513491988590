import axios from "axios";

function verifyEmailCode(obj) {
  const url = `${process.env.REACT_APP_API}/api/user/verifyMailCode`;
  return axios
    .post(url, obj)
    .then((response) => {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export default verifyEmailCode;
