import React, { useState, useEffect } from "react";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import SlickSlider from "./SlickSlider";
import logo from "../../src/assets/images/login/logo.png";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { useTranslation } from "react-i18next";
import { dateToWords } from "../Services/generalFunctions/generalFunctions";
import fetchRandomContent from "../Services/randomContent";
import { random } from "gsap";
SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
]);

const TrendingSlider = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [randomContent, setRandomContent] = useState([]);
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const { t } = useTranslation();
  const currentSelectedLanguage = localStorage.getItem("lang");
  // fetching the random data
  useEffect(() => {
    fetchRandomData();
  }, []);

  const fetchRandomData = async () => {
    const data = await fetchRandomContent(currentSelectedLanguage);
    setRandomContent(data);
  };

  const [trendingTrailerMediaId, setTrendingTrailerMediaId] = useState(null);
  const handleTrailerClick = (mediaId) => {
    setTrendingTrailerMediaId(mediaId);
  };

  return (
    <section id="iq-trending" className="s-margin pb-5">
      {randomContent?.tvShows?.length > 0 && (
        <Container fluid>
          <Row>
            <Col sm="12" lg="12" lx="12" className="overflow-hidden">
              {randomContent?.tvShows?.length > 0 && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="main-title">{t("Trending")}</h4>
                  </div>
                  {/* {randomContent?.tvShows?.length > 1 && ( */}
                  <div id="trending-contens">
                    <div
                      id="prev4"
                      className="swiper-button swiper-button-prev"
                    >
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div
                      id="next4"
                      className="swiper-button swiper-button-next"
                    >
                      <i className="fa fa-chevron-right"></i>
                    </div>
                    <Swiper
                      as="ul"
                      navigation={{
                        prevEl: "#prev4",
                        nextEl: "#next4",
                      }}
                      thumbs={{ swiper: thumbsSwiper }}
                      centeredSlides={true}
                      centeredSlidesBounds={false}
                      slidesPerView={5}
                      spaceBetween={20}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        550: { slidesPerView: 2 },
                        991: { slidesPerView: 3 },
                        1400: { slidesPerView: 4 },
                        1500: { slidesPerView: 5 },
                      }}
                      loop={false}
                      className="list-inline p-0 m-0 row align-items-center iq-rtl-direction"
                    >
                      {randomContent?.tvShows?.length > 0 ? (
                        randomContent.tvShows.map((data) => (
                          <SwiperSlide as="li" key={data?._id} value={data?._id}>
                            <div className="movie-slick position-relative">
                              <img
                                src={data?.thumbnail?.banner_thumbnail_url}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <SwiperSlide as="li" key="placeholder">
                          <div className="movie-slick position-relative">
                            {/* You can put a placeholder image or any other content here */}
                            <img
                              src="placeholder-image-url.jpg"
                              className="img-fluid"
                              alt="Placeholder"
                            />
                          </div>
                        </SwiperSlide>
                      )}
                    </Swiper>

                  </div>
                  {/* )} */}
                  <div>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      slidesPerView={1}
                      freeMode={true}
                      watchSlidesProgress={true}
                      id="trending-slider"
                      className="mt-3  list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction"
                      allowTouchMove={false}
                    >
                      {randomContent?.tvShows?.length > 0 &&
                        randomContent?.tvShows?.map((data) => (
                          <SwiperSlide as="li">
                            <div
                              className="tranding-block position-relative"
                              style={{
                                backgroundImage: `url(${data?.thumbnail?.banner_thumbnail_url})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                              }}
                            >
                              <Tab.Container
                                defaultActiveKey="trending-data1"
                                className="trending-custom-tab"
                              >
                                <div className="tab-title-info position-relative iq-ltr-direction">
                                  <Nav
                                    as="ul"
                                    variant="pills"
                                    className="trending-pills nav-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction"
                                  >
                                    <Nav.Item as="li" className="nav-item">
                                      <Nav.Link
                                        href=""
                                        eventKey="trending-data1"
                                      >
                                        Overview
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" className="nav-item">
                                      <Nav.Link
                                        href=""
                                        eventKey="trending-data2"
                                      >
                                        Episodes
                                      </Nav.Link>
                                    </Nav.Item>
                                    {data?.trailer?.media_id !== "" && (
                                      <Nav.Item as="li" className="nav-item">
                                        <Nav.Link
                                          href=""
                                          eventKey="trending-data3"
                                          onClick={() =>
                                            handleTrailerClick(
                                              data?.trailer?.media_id
                                            )
                                          }
                                        >
                                          Trailer
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </Nav>
                                </div>
                                {/* NEECHY WALA CONTENT */}
                                <Tab.Content className="trending-content">
                                  <Tab.Pane
                                    eventKey="trending-data1"
                                    className="overlay-tab show fade"
                                  >
                                    <div
                                      className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction"
                                      style={{ paddingTop: "10rem" }}
                                    >
                                      <div className="res-logo">
                                        <div className="channel-logo">
                                          <img
                                            src={logo}
                                            className="c-logo"
                                            alt="afo"
                                          />
                                        </div>
                                      </div>
                                      <h1 className="trending-text big-title text-uppercase">
                                        {/* {data?.title} */}

                                     

                                        {currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.title }
                                      </h1>
                                      <div className="d-flex align-items-center text-white text-detail">
                                        <span className="">
                                          {data?.seasons?.length} Season
                                          {data?.seasons?.length === 1
                                            ? ""
                                            : "s"}
                                        </span>
                                        <span className="trending-year">
                                          {dateToWords(data?.createdAt)}
                                        </span>
                                      </div>

                                      <p
                                        className="trending-dec"
                                        maxLength={50}
                                        dangerouslySetInnerHTML={{
                                          __html: currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.description  ,
                                        }}
                                      ></p>

                                      <div className="p-btns mb-4">
                                        <div className="d-flex align-items-center p-0">
                                          <Link
                                            to={`/show-info/${data?._id}`}
                                            role="button"
                                            className="btn btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="trending-list">
                                        {data?.crew_members?.length > 0 && (
                                          <div className="text-primary title mr-1">
                                            Starring:{" "}
                                            {data?.crew_members?.map(
                                              (crew, index) => (
                                                <span
                                                  className="text-body mr-2"
                                                  key={index}
                                                >
                                                  {crew.member_role}
                                                </span>
                                              )
                                            )}
                                          </div>
                                        )}
                                        {data?.genre?.length > 0 && (
                                          <div className="text-primary title">
                                            {t("Genres")}:{" "}
                                            {data?.genre?.map((item) => (
                                              <span className="text-body mr-2">
                                                {item?.name}
                                              </span>
                                            ))}
                                          </div>
                                        )}
                                        {data?.jw_tags?.length > 0 && (
                                          <div className="text-primary title">
                                            {t("tags").toUpperCase()}:{" "}
                                            {data?.jw_tags?.map((item) => (
                                              <span className="text-body mr-2">
                                                {item}
                                              </span>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane
                                    eventKey="trending-data2"
                                    className="overlay-tab  fade show "
                                    id="trending-episode1"
                                  >
                                    <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                      <Link to="#" tabIndex="0">
                                        <div className="channel-logo">
                                          <img
                                            src={logo}
                                            className="c-logo"
                                            alt="stramit"
                                          />
                                        </div>
                                      </Link>
                                      <h1 className="trending-text big-title text-uppercase">
                                        {/* {data?.title} */}

                                        {currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.title }
                                      </h1>

                                      <div className="iq-custom-select d-inline-block sea-epi">
                                        <select
                                          value={selectedSeasonIndex}
                                          onChange={(e) =>
                                            setSelectedSeasonIndex(
                                              Number(e.target.value)
                                            )
                                          }
                                          style={{
                                            padding: "0.5rem",
                                            fontSize: "1rem",
                                            borderRadius: "4px",
                                            border: "1px solid #5e6163",
                                            width: "200px",
                                            backgroundColor: "#272a2b",
                                            color: "white",
                                          }}
                                        >
                                          {data?.seasons.map(
                                            (season, index) => (
                                              <option value={index} key={index}>
                                                {t("Season")} {index + 1}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div
                                        className="episodes-contens mt-4"
                                        id="episode1"
                                      >
                                        <SlickSlider
                                          objectData={
                                            data?.seasons[selectedSeasonIndex]
                                              ?.episodes
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane
                                    eventKey="trending-data3"
                                    className="overlay-tab fade show"
                                  >
                                    <div
                                      className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction"
                                      style={{
                                        paddingTop: "10vh",
                                        paddingBottom: "10vh",
                                      }}
                                    >
                                      <Link to="/" tabIndex="0">
                                        <div className="channel-logo">
                                          <img
                                            src={logo}
                                            className="c-logo"
                                            alt="stramit"
                                          />
                                        </div>
                                      </Link>

                                      <div
                                        className="episodes-contens mt-4"
                                        id="episode2"
                                      >
                                        <div className="block-image position-relative">
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "block",
                                              height: 0,
                                              padding: 0,
                                              overflow: "hidden",
                                              marginBottom: "1em",
                                              paddingBottom: "56.25%",
                                            }}
                                            className="onb-embed-responsive"
                                          >
                                            <ReactPlayer
                                              controls
                                              width="100%"
                                              height="100%"
                                              url={`https://cdn.jwplayer.com/manifests/${trendingTrailerMediaId}.m3u8`}
                                              style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                border: 0,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default TrendingSlider;
