import axios from "axios";
import CryptoJS from "crypto-js";
import generateRandomIv from "./generateRandomIv";
import AuthSession from "./getSessionAuth";
import { Buffer } from "buffer";
import FACodeSend from "./FACodeSend";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";

const btoa = (text) => {
  return Buffer.from(text, "binary").toString("base64");
};

const atob = (base64) => {
  return Buffer.from(base64, "base64").toString("binary");
};

function login_tfa(
  obj,
  setShowLoginError,
  history,
  setLoginError,
  setShowLogin,
  setUserDetails,
  nextPath
) {
  // obj['isEmail']=true;
  let msg = JSON.stringify(obj);
  const i = generateRandomIv(16);
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(i);
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  var transitmessage = JSON.stringify({
    iv: btoa(i),
    value: encrypted.toString(),
  });
  transitmessage = btoa(transitmessage);
  let request = {
    data: transitmessage,
  };
  const url = `${process.env.REACT_APP_API}/api/user/login`;

  axios
    .post(url, request)
    .then(async (response) => {
      console.log("on est ici")
      let res = atob(response.data.data);
      let jsn = JSON.parse(res);
      const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
        mode: CryptoJS.mode.CBC,
        iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
      });
      const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      const Authtoken = decrypt.token;
      localStorage.setItem("myToken", Authtoken);

      // history.push("/");
      // session request

      try {
        await createUserWithEmailAndPassword(auth, obj.email, obj.password);
        console.log("log into firebase");
      } catch (err) {
        console.log(err.code);
        if(err.code === "auth/email-already-in-use"){
          try {
            await signInWithEmailAndPassword(auth, obj.email, obj.password);
            console.log("log into firebase");
          } catch (err) {
            console.log(err.message);
          }
        }
      
      }

      
      const result = await AuthSession();
      if (result) {
        let userDetails_Session = JSON.parse(localStorage.getItem("mySession"));

        if (!userDetails_Session) {
          userDetails_Session = JSON.parse(localStorage.getItem("session"));
        }
        if (userDetails_Session.tfa && userDetails_Session.phone !== "") {
          setShowLogin(false);
          setUserDetails(userDetails_Session);
          FACodeSend({
            email: obj.email,
            phone: userDetails_Session.phone,
          });
        } else {
          localStorage.setItem("token", localStorage.getItem("myToken"));
          if (nextPath !== "") {
            history.push(nextPath);
          } else {
            window.location.reload();
          }
          setTimeout(() => {
            localStorage.removeItem("myToken");
          }, 2000);
        }
      } else {
        history.push("/login");
      }
    })
    .catch((error) => {
      setLoginError("Invalid Email Address or Password");
      setShowLoginError(true);
      setTimeout(() => {
        setShowLoginError(false);
      }, 3000);
    });
}

export default login_tfa;
