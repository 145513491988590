import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import {
  addComment,
  postCommentWithGeneralObjectId,
  postCommentWithTvShowId,
} from "../Services/commentsApi";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginSignupModal from "./LoginSignupModal";
import { useTranslation } from "react-i18next";
function AddComment({ generalID, mediaCategory, setCommentFlag }) {
  const [comment, setComment] = useState("");
  const { t } = useTranslation();
  // to handle the login modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);

  const currentUser = JSON.parse(localStorage.getItem("session"));
  const token = localStorage.getItem("token");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (comment.trim() === "") {
      toast.error(t("Please add a comment"), {
        position: "top-center",
      });
      return;
    }
    postCommentWithGeneralObjectId(
      comment,
      currentUser?._id,
      mediaCategory,
      generalID,
      token
    )
      .then((response) => {
        toast.success(t("Comment added"), {
          position: "top-center",
        });
        setComment("");
        setCommentFlag(true);
      })
      .catch((error) => {});
  };

  return (
    <section className=" mt-5">
      <Modal
        show={showLoginModal}
        onHide={handleCloseLoginModal}
        className="special_modal"
        size="lg"
        centered={true}
      >
        <Modal.Body>
          <LoginSignupModal />
        </Modal.Body>
      </Modal>
      <Row className="add-comment-container">
        <ToastContainer
          toastStyle={{
            background: "black",
            color: "white",
          }}
        />
        <Col xs={12}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="comment">
                  <Form.Label>{t("Your Review")}*</Form.Label>
                  <Form.Control
                    className="bg-transparent text-white border-none"
                    as="textarea"
                    rows={3}
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {currentUser ? (
              <Row>
                <Col xs={6}>
                  <Button
                    type="submit"
                    className="btn btn-hover iq-button py-2"
                  >
                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                    {t("Submit")}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={6}>
                  <Button
                    className="btn btn-hover iq-button py-2"
                    onClick={handleShowLoginModal}
                  >
                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                    {t("Submit")}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </section>
  );
}

export default AddComment;
