import React, { useState } from "react";

const Dropdown = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="custom-dropdown">
      <select
        className="custom-dropdown-select "
        value={selectedOption}
        onChange={handleOptionSelect}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
