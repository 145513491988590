// import axios from "axios";
// import CryptoJS from "crypto-js";
// import generateRandomIv from "./generateRandomIv";

// import { Buffer } from "buffer";

// const btoa = (text) => {
//   return Buffer.from(text, "binary").toString("base64");
// };

// const atob = (base64) => {
//   return Buffer.from(base64, "base64").toString("binary");
// };

// function addStripeCard(user_id, obj) {
//   const currentUserToken = localStorage.getItem("token");
//   let msg = JSON.stringify(obj);
//   const i = generateRandomIv(16);
//   const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
//   const iv = CryptoJS.enc.Utf8.parse(i);
//   const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//   });
//   var transitmessage = JSON.stringify({
//     iv: btoa(i),
//     value: encrypted.toString(),
//   });
//   transitmessage = btoa(transitmessage);
//   let request = {
//     data: transitmessage,
//   };
//   const url = `${process.env.REACT_APP_API}/api/user/stripe/set-card/${user_id}`;

//   axios
//     .post(url, request, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${currentUserToken}`,
//         source: "front",
//       },
//     })
//     .then(async (response) => {
//       let res = atob(response.data.data);
//       let jsn = JSON.parse(res);
//       const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
//         mode: CryptoJS.mode.CBC,
//         iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
//       });
//       const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
//     })
//     .catch((error) => {
//     });
// }

// export default addStripeCard;
import axios from "axios";
import CryptoJS from "crypto-js";
import generateRandomIv from "./generateRandomIv";

import { Buffer } from "buffer";

const btoa = (text) => {
  return Buffer.from(text, "binary").toString("base64");
};

const atob = (base64) => {
  return Buffer.from(base64, "base64").toString("binary");
};

function addStripeCard(user_id, obj) {
  return new Promise((resolve, reject) => {
    const currentUserToken = localStorage.getItem("token");
    let msg = JSON.stringify(obj);
    const i = generateRandomIv(16);
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(i);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    var transitmessage = JSON.stringify({
      iv: btoa(i),
      value: encrypted.toString(),
    });
    transitmessage = btoa(transitmessage);
    let request = {
      data: transitmessage,
    };
    const url = `${process.env.REACT_APP_API}/api/user/stripe/set-card/${user_id}`;

    axios
      .post(url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
      })
      .then(async (response) => {
        // let res = atob(response.data.data);
        // let jsn = JSON.parse(res);
        // const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
        //   mode: CryptoJS.mode.CBC,
        //   iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
        // });
        // const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        resolve(response); // Resolve the promise with the decrypted data
      })
      .catch((error) => {
        reject(error); // Reject the promise with the error
      });
  });
}

export default addStripeCard;
