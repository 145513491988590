export const fetchAdsDataVideo = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/ads/get-ads/${lang}/video`
  );
  const data = await response.json();
  return data;
};

export const fetchAdsDataHome = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/ads/get-ads/${lang}/home`
  );
  const data = await response.json();
  return data;
};

export const fetchAdsDataTVShow = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/ads/get-ads/${lang}/tvshow`
  );
  const data = await response.json();
  return data;
};

export const fetchAdsDataTVShowAndPodcast = async (lang, category) => {
  const categoryInUrl = category === "podcast" ? "podcast" : "tvshow";
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/ads/get-ads/${lang}/${categoryInUrl}`
  );
  const data = await response.json();
  return data;
};

export const fetchAdsDataPodcast = async (lang) => {
  const response = fetch(
    `${process.env.REACT_APP_API}/api/ads/get-ads/${lang}/podcast`
  );
  const data = await response.json();
  return data;
};

export const adClickApi = async (itemId) => {
  const apiUrl = `${process.env.REACT_APP_API}/api/ads/ads-clicks/${itemId}`;
  return fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // throw error;
    });
};
