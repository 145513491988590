export const getSocialChannels = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/social/get-all-social-media-platforms`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data from an API");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    // console.error("Error fetching data: ", error);
    return null;
  }
};
