import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { TVLatestSliderApi } from "../Services/tvShowsApi";
import { Link } from "react-router-dom";
import episodes5 from "../assets/images/episodes/05.jpg";
import { convertSecondsToHoursMinutes } from "../Services/generalFunctions/generalFunctions";

const styles = {
  container: {
    width: "90%",
    height: "400px",
    // border: "1px solid #ccc",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // padding: "10px",
  },
  imageContainer: {
    position: "relative",
    // marginBottom: "10px",
  },
  image: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  play: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    background: "#fff",
    border: "none",

    // borderRadius: "50%",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
  },
  infoContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    background: "#3f4142",
    width: "100%",
    alignItems: "center",
    padding: "8px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    // marginBottom: "5px",
  },
  duration: {
    fontSize: "14px",
    color: "#888",
  },
};

const SlickSlider = ({ objectData }) => {
  const [tvLatest, setTvLatest] = useState([]);
  const [data, setData] = useState([]);

  function truncateTitle(text) {
    if (!text) return "";

    const words = text.split(" ");
    if (words.length > 3) {
      return words.slice(0, 3).join(" ") + "...";
    }

    return text;
  }

  const currentSelectedLanguage = localStorage.getItem("lang");

  const renderSliders = () => {
    return objectData?.map((data, index) => (
      //   <div className="slider-item" key={index} style={{ marginRight: "10vh" }}>
      //     <div>
      //       <div
      //         className="slider-image-container"
      //         style={{ position: "relative", width: "100%", margin: "0 10px" }}
      //       >
      //         <div className="position-relative" style={{ paddingTop: "56.25%" }}>
      //           <Link to="#">
      //             <img
      //               src={data?.genre_image?.url}
      //               className="slider-image"
      //               alt=""
      //               style={{
      //                 position: "absolute",
      //                 top: 0,
      //                 left: 0,
      //                 width: "100%",
      //                 height: "100%",
      //                 objectFit: "cover",
      //               }}
      //             />
      //           </Link>
      //         </div>
      //         <div
      //           className="episodes-description text-body"
      //           style={{ backgroundColor: "black" }}
      //         >
      //           <div
      //             className="d-flex align-items-center justify-content-between"
      //             style={{
      //               backgroundColor: "black",
      //               paddingLeft: "10px",
      //               paddingRight: "10px",
      //             }}
      //           >
      //             Episode 5<span className="text-primary">2.54 m</span>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      <div>
        {/* <div style={styles.container}>
          <div style={styles.imageContainer}>
            <img
              src={data?.thumbnail?.banner_thumbnail_url}
              alt="Image"
              style={styles.image}
            />
            <Link to={`/show-details/${data?._id}`}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  borderRadius: "50%",
                  background: "#00aeff",
                  width: "40px",
                  height: "40px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <i className="fa fa-play"></i>
              </div>
            </Link>
          </div>
          <div style={styles.infoContainer}>
            <h2 style={styles.title}>{truncateTitle(data?.title)}</h2>
          </div>
        </div> */}
        <div
          className="block-image position-relative"
          style={{ width: "95%" }}
          key={index}
        >
          <Link to="#">
            <img
              src={data?.thumbnail?.banner_thumbnail_url}
              className="img-fluid"
              alt=""
            />
          </Link>
          <div
            className=""
            style={{
              position: "absolute",
              left: "44%",
              top: "44%",
              backgroundColor: "rgb(105, 176, 211, 0.9)",
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="">
              <Link to={`/episode/${data?._id}`} tabIndex="0">
                <i className="ri-play-fill"></i>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="episodes-description text-body"
          style={{
            width: "95%",
            backgroundColor: "#110e0e",
            padding: "0.6rem",
          }}
        >
        
          <div className="d-flex align-items-center justify-content-between">
            <Link to="#">{truncateTitle(currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.title )}</Link>
            <span className="text-primary">
              {convertSecondsToHoursMinutes(data?.duration)}
            </span>
          </div>
        </div>
      </div>
    ));
  };

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API}/api/genres/get-all-genres`)
      .then((res) => res.json())
      .then((result) => {
        setData(result.allGenres);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    TVLatestSliderApi()
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setTvLatest(data.upcomingContent);
        } else {
          setTvLatest([]);
        }
      })
      .catch((error) => {});
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} className="slider-container">
        {" "}
        {/* Added 'slider-container' class */}
        {renderSliders()}
      </Slider>
    </div>
  );
};

export default SlickSlider;
