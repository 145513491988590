import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
// import CustomToggle from '../../../components/dropdowns'
import { useTranslation } from "react-i18next";
//img
import aboutus1 from "../../../assets/images/about-us/faq.png";
import { Helmet } from "react-helmet";

const FAQ = () => {
  const [faqList, setFaqList] = useState([]);
  const [activeFaq, setActiveFaq] = useState("");
  const { t } = useTranslation();
  const currentSelectedLanguage = localStorage.getItem("lang") || "fr";

  useEffect(() => {
    fetchFAQList();
  }, [currentSelectedLanguage]);

  useEffect(() => {
    // Open the first FAQ item on page load
    if (faqList.length > 0) {
      setActiveFaq(faqList[0]._id);
    }
  }, [faqList]);

  const fetchFAQList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/faq/get-all-faq`
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data);

        setFaqList(data.faqList.filter(faq => faq.lang === currentSelectedLanguage  ));
      } else {
        // console.error("Failed to fetch FAQ list");
      }
    } catch (error) {
      // console.error("Error while fetching FAQ list:", error);
    }
  };

  const toggleFaq = (id) => {
    setActiveFaq((prevActiveFaq) => (prevActiveFaq === id ? "" : id));
  };

  const renderPlusMinusIcon = (id) => {
    return (
      <i
        className={`fa ${
          activeFaq === id ? "fa-minus active" : "fa-plus inactive"
        }`}
        aria-hidden="true"
      />
    );
  };

  return (
    <>
    
      <Helmet>
          <title>{`${t('meta.faq.title')} | AFO Media`}</title>
          <meta property="og:title" content={`${t('meta.faq.title')} | AFO Media`} />
          <meta property="og:description" content={t('meta.faq.description')} />
          <meta property="og:url" content={`https://afomedia.com/faq`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <div
        className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        style={{ backgroundImage: `url(${aboutus1})` }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav
                aria-label="breadcrumb"
                className="text-center iq-breadcrumb-two"
              >
                <h2 className="title">FAQ</h2>
                <Breadcrumb className="main-bg">
                  <Breadcrumb.Item>{t("Home")}</Breadcrumb.Item>
                  <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                </Breadcrumb>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-accordion iq-accordion-square">
                {faqList.map((faq) => (
                  <div
                    key={faq._id}
                    className={`iq-accordion-block ${faq._id} ${
                      activeFaq === faq._id ? "iq-active" : ""
                    }`}
                    onClick={() => toggleFaq(faq._id)}
                  >
                    <div className="iq-accordion-title">
                      <div className="iq-icon-right">
                        {renderPlusMinusIcon(faq._id)}
                      </div>
                      <h4 className="mb-0 accordion-title iq-heading-title">
                        {faq.question}
                      </h4>
                    </div>
                    <div
                      className={`iq-accordion-details ${
                        activeFaq === faq._id ? "d-block" : "d-none"
                      }`}
                    >
                      <p className="iq-content-text">{faq.answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default FAQ;
