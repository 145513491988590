import axios from "axios";

export const handleConfirmDeleteWatchList = async (itemIdToDelete) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_API}/api/user/delete-watch-list/${itemIdToDelete}`
    );
  } catch (error) {}
};

export const handleConfirmDeleteFAV = async (itemIdToDelete) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_API}/api/user/delete-favorite/${itemIdToDelete}`
    );
  } catch (error) {}
};
