import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Logo from "../../../../assets/images/logo.png";
import { FcPrevious } from "react-icons/fc";
import OtpInput from "react-otp-input";
import postLoginData from "../../../../Services/postLoginData";
import postSignUpData from "../../../../Services/postSignUpData";
import {sendEmailVerificationWithoutToken} from "../../../../Services/sendEmailVerification";
import verifyEmailCode from "../../../../Services/verifiyEmailCode";
import Lottie from "lottie-react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import premium from "../../../../assets/premium.json";
import free from "../../../../assets/free.json";
import final from "../../../../assets/Final.json";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import login_tfa from "../../../../Services/login_tfa";
import verifyFACode from "../../../../Services/verifyFACode";
import { faC } from "@fortawesome/free-solid-svg-icons";
import FACodeSend from "../../../../Services/FACodeSend";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import sendPhoneVerification from "./../../../../Services/SendPhoneVerification";
import verifyFAPhoneCode from "../../../../Services/verifyFAPhoneCode";

const LoginMob = ({ nextPath }) => {
  let history = useHistory("");
  const [signIn, setSignIn] = useState(true);
  const [subType, setSubType] = useState(false);
  const [selectedOption, setSelectedOption] = useState("paid");

  // login form data
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emptyObj, setEmptyObj] = useState(false);
  const [loginInput, setLoginInput] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginInput({ ...loginInput, [name]: value });
  };
  const handleEmailLoginChange = (e) => {
    const value = e.target.value;
    setLoginInput({ ...loginInput, email: value });
    if (value.length >= 3 && value.match(/[a-zA-Z]/) === null) {
      setIsEmail(false);
    }
  };

  const handlePhoneLoginChange = (value) => {
    setLoginInput({ ...loginInput, email: value });
    if (value.length < 3) {
      setIsEmail(true);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, email: value });
    if (value.length >= 3 && value.match(/[a-zA-Z]/) === null) {
      setIsEmail(false);
    }
  };
  const handlePhoneChange = (value) => {
    setFormData({ ...formData, email: value });
    if (value.length < 3) {
      setIsEmail(true);
    }
  };

  const isValidEmail = (email) => {
    // regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPassword = (password) => {
    const hasSpecialCharacter = /[!@#$%^&(),.?":{}|<>]/.test(password);
    const hasMinimumLength = password.length >= 8;
    const hasNumber = /\d/.test(password);

    return hasSpecialCharacter && hasMinimumLength && hasNumber;
  };

  // const loginSubmit = async (event) => {
  //   event.preventDefault();
  //   if (!loginInput.email || !loginInput.password) {
  //     setEmptyObj(true);
  //     setTimeout(() => {
  //       setEmptyObj(false);
  //     }, 3000);
  //     return;
  //   }

  //   if (!isValidEmail(loginInput.email)) {
  //     setError("Invalid email address");
  //     setShowLoginError(true);
  //     return;
  //   }
  //   // if (Object.keys(loginInput).length === 0) {
  //   //   setEmptyObj(true);
  //   //   setTimeout(() => {
  //   //     setEmptyObj(false);
  //   //   }, 3000);
  //   //   return;
  //   // }
  //   postLoginData(
  //     loginInput,
  //     setShowLoginError,
  //     history,
  //     setLoginError,
  //     nextPath
  //   );
  // };

  // signup form data
  const [otpForm, setOtpForm] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };
  const [isEmail, setIsEmail] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    subscription: "free",
    //  subscriptionType: "",
    //  otp: "",
  });
  const [otpValidated, setOtpValidated] = useState(false);
  const [userExistError, setuserExistError] = useState("");
  // const [showError, setShowError] = useState(false);
  const handleSignUp = async (event) => {
   
    formData["isEmail"] = isEmail;
    if (isEmail == false) {
      formData["phone"] = formData.email
        .replace(" ", "")
        .replace("+", "")
        .replace("-", "");
      formData["email"] = "";
    }
    postSignUpData(
      formData,
      history,
      setuserExistError,
      setShowError,
      setShowLoginError,
      setLoginError,
      nextPath,
      setOtpValidated
    );
  };

  // handle user exist on next button of signup form
  // const handleSignUpNext = () ={

  // }

  // SIGN UP : EMAIL VERIFICATION ==================================================================================
  const obj = {
    subject: "email verification",
    email: formData.email,
    name: formData.name,
  };
  const phoneobj = {
    // phone: formData.email.replace("-", "").replace(" ", "").replace("+", ""),
    phone: formData.email.replace("-", "").replace(" ", ""),
  };
  // when user clicks on next button send the email on emailverficiation api
  const handleEmailVerification = () => {
    
    const email = formData.email;

    if (isEmail === false) {
      sendPhoneVerification(phoneobj);
      setSubType(true);
      setTimer(180);
      return;
    } else if (isEmail === true) {
      if (!isValidEmail(email)) {
        setError("Invalid phone number format");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      } else {
        sendEmailVerificationWithoutToken(obj);
        setSubType(true);
        setTimer(60);
        return;
      }
    }
    setError("Invalid email or phone number");
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 3000);

    if (!isValidPassword(formData.password)) {
      let errorMessage = "Password must contain ";
      if (formData.password.length < 8) {
        errorMessage += "at least 8 characters";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
      if (!/[!@#$%^&(),.?":{}|<>]/.test(formData.password)) {
        errorMessage += "at least one special character";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
      if (!/\d/.test(formData.password)) {
        errorMessage += "at least one number";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      return;
    }
    sendEmailVerificationWithoutToken(obj);
    setSubType(true);
    setTimer(60);
  };
  const [redirectngTimer, setRedirectingTimer] = useState(3);

  useEffect(() => {
    let interval;
    if (redirectngTimer > 0) {
      interval = setInterval(() => {
        setRedirectingTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Stop the interval when timer reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [redirectngTimer]);
  // when user enters the received code validate the code using email code and token from email verification API
  const verifyCode = {
    email: formData.email,
    code: otpValue,
    token: localStorage.getItem("email verification token"),
  };

  const verifyPhoneOtpCode = {
    phone: formData.email.replace("-", "").replace(" ", ""),
    code: otpValue,
    token: localStorage.getItem("Phone verification token"),
    // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6Iis5MjMyMTI0NTM0NjYiLCJjb2RlIjoyMjI3NzAsImlhdCI6MTY4ODk3ODQ1MywiZXhwIjoxNjg5MDY0ODUzfQ.Kaxyy34xwTGG6WylBSqUVDhBk3LccP6N_iOxZCLBUzw"
  };
  //  used to show error on otp page
  const [wrongEmailCode, setWrongEmailCode] = useState(false);
  const handleEmailCodeVerification = () => {
    verifyEmailCode(verifyCode)
      .then((result) => {
        if (result) {
          // Email code is correct, redirect to another page
          
          localStorage.removeItem("email verification token");
          handleSignUp();
          setRedirectingTimer(3);
        } else {
          setWrongEmailCode(true);
          setTimeout(() => {
            setWrongEmailCode(false);
          }, 3000);
        }
      })
      .catch((error) => {});
  };
  // handle subscription type
  const handleSubscription = () => {
    // setFormData({
    //   ...formData,
    //   subscriptionType: selectedOption,
    // });
    setOtpForm(true);
  };
  const handlePhoneCodeVerification = () => {
    verifyFAPhoneCode(verifyPhoneOtpCode)
      .then((result) => {
        if (result) {
          localStorage.removeItem("Phone verification token");
          setRedirectingTimer(3);
          handleSignUp();
          history.push("/");
        } else {
          setWrongEmailCode(true);
          setTimeout(() => {
            setWrongEmailCode(false);
          }, 3000);
        }
      })
      .catch((error) => {});
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // login with otp==============================================
  const [showLogin, setShowLogin] = useState(true);
  const [phoneOtpValue, setPhoneOtpValue] = useState("");

  const handlePhoneOtpChange = (otp) => {
    setPhoneOtpValue(otp);
  };
  const [userDetails, setUserDetails] = useState({});
  const loginSubmit = async (event) => {
    event.preventDefault();
    if (!loginInput.email || !loginInput.password) {
      setEmptyObj(true);
      setTimeout(() => {
        setEmptyObj(false);
      }, 3000);
      return;
    }

    if (!isValidEmail(loginInput.email) && isEmail == true) {
      setError("Invalid email address");
      setShowLoginError(true);
      return;
    }
    loginInput["isEmail"] = isEmail;
    if (isEmail == false) {
      loginInput["phone"] = loginInput.email
        .replace(" ", "")
        .replace("+", "")
        .replace("-", "");
      loginInput["email"] = "";
    }
    login_tfa(
      loginInput,
      setShowLoginError,
      history,
      setLoginError,
      setShowLogin,
      setUserDetails,
      nextPath
    );
    setTimer(60);
  };

  const [wrongPhoneCode, setWrongPhoneCode] = useState(false);

  const verifyPhoneCode = async () => {
    const verifyObj = {
      email: loginInput.email,
      code: phoneOtpValue,
      token: localStorage.getItem("2fatoken"),
    };
    try {
      const result = await verifyFACode(verifyObj);
      if (result) {
        history.push(nextPath);

        localStorage.setItem("token", localStorage.getItem("myToken"));
        setWrongPhoneCode(false);
        setPhoneOtpValue("");
        localStorage.removeItem("2fatoken");
        setTimeout(() => {
          localStorage.removeItem("myToken");
        }, 2000);
      }
    } catch (error) {
      setWrongPhoneCode(true);
      setPhoneOtpValue("");
      setTimeout(() => {
        setWrongPhoneCode(false);
      }, 3000);
    }
  };
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Stop the interval when timer reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [timer]);

  const handleResendClick = (apiCall, obj) => {
    if (timer <= 0) {
      // Implement the logic to resend the one-time password here
      apiCall(obj);
      setTimer(60); // Reset the timer to 60 seconds
    }
  };
  return (
    <div className="sign-in-mob">
      <div
        className="maincontainer"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <div className="mobForm">
          {signIn ? (
            showLogin ? (
              <>
                <div className="signIn-Form">
                  <div className="logo">
                    <img src={Logo} alt="logo"></img>
                  </div>
                  <div className="signinForm">
                    <h3>Sign in</h3>
                    <div className="inputFields" style={{ width: "100%" }}>
                      <div
                        className={`alert alert-danger ${
                          showLoginError ? "" : "d-none"
                        }`}
                        role="alert"
                      >
                        {loginError}
                        {error}
                      </div>
                      <div
                        className={`alert alert-danger ${
                          emptyObj ? "" : "d-none"
                        }`}
                        role="alert"
                      >
                        Enter email and password
                      </div>
                      <label htmlFor="email">E-Mail</label>
                      {isEmail ? (
                        <input
                          type="email"
                          placeholder="yourname@example.com"
                          required
                          name="email"
                          value={loginInput.email || ""}
                          onChange={handleEmailLoginChange}
                        />
                      ) : (
                        <PhoneInput
                          inputClass="phoneInput"
                          placeholder="Phone Number"
                          value={loginInput.email}
                          defaultCountry="ua"
                          onChange={handlePhoneLoginChange}
                          autoFocus
                        />
                      )}
                      <label htmlFor="password">Password</label>
                      <div className="password-input">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Your Password"
                          required
                          name="password"
                          value={loginInput.password || ""}
                          onChange={handleInputChange}
                        />{" "}
                        <span
                          className="password-toggle"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>
                    <button className="button" onClick={loginSubmit}>
                      Sign In
                    </button>
                    <Link to="/recover-password" className="f-link">
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <div className="no-account">
                  <span>Dont' have an account yet?</span>
                  <h6 onClick={() => setSignIn(false)}>Signup &#8594;</h6>
                </div>
              </>
            ) : (
              <div className="signIn-Form">
                <div className="signinForm">
                  <>
                    <div className="heading-icon">
                      <FcPrevious
                        className="prev-icon"
                        size={30}
                        onClick={() => {
                          setShowLogin(true);
                        }}
                      />
                      <div className="heading">
                        Please enter OTP to verify your account
                      </div>
                    </div>
                    <div className="otpbody">
                      <div className="otp-body">
                        <span
                          style={{
                            marginBottom: "2rem",
                            textAlign: "center",
                          }}
                        >
                          We’ve sent an SMS message containing a code to your
                          mobile device.
                          {/* A one-Time Password has been sent to{" "}
                          {userDetails?.phone} */}
                        </span>
                        <OtpInput
                          value={phoneOtpValue}
                          onChange={handlePhoneOtpChange}
                          numInputs={6}
                          isInputNum
                          renderInput={(inputProps) => (
                            <input
                              {...inputProps}
                              style={{ margin: "0rem 0.4rem" }}
                            />
                          )}
                          renderSeparator={<span>-</span>}
                          inputStyle={{
                            width: "4rem",
                            height: "3rem",
                            margin: "0 1rem",
                            padding: 0,
                            borderRadius: 4,
                            background: "white",
                            outline: "none",
                            textAlign: "center",
                          }}
                        />
                      </div>
                      {wrongPhoneCode && (
                        <span style={{ color: "red" }}>
                          Entered wrong code, please enter the code sent on{" "}
                          {userDetails?.phone}{" "}
                        </span>
                      )}

                      <button
                        className="button"
                        // onClick={handleSignUp}
                        onClick={verifyPhoneCode}
                        style={{ marginTop: "2rem" }}
                      >
                        Validate
                      </button>

                      <div className="otp-body" style={{ marginTop: "3rem" }}>
                        <h4>
                          {timer === 0 ? (
                            <Link
                              onClick={() => {
                                handleResendClick(FACodeSend, {
                                  email: loginInput.email,
                                  phone: userDetails.phone,
                                });
                              }}
                            >
                              Resend One Time Password
                            </Link>
                          ) : (
                            <Link>
                              Resend One Time Password in {timer} seconds
                            </Link>
                          )}
                        </h4>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            )
          ) : (
            <>
              {!subType ? (
                <>
                  <div className="signIn-Form">
                    <div className="logo">
                      <img src={Logo} alt="logo"></img>
                    </div>
                    <div className="signinForm">
                      <h3>Sign Up</h3>

                      <div className="inputFields">
                        {/* <div
                          className={`alert alert-danger ${
                            showError ? "" : "d-none"
                          }`}
                          role="alert"
                        >
                          {userExistError}
                        </div> */}
                        <div
                          className={`alert alert-danger ${
                            showError ? "" : "d-none"
                          }`}
                          role="alert"
                        >
                          {error}
                        </div>
                        <label htmlFor="text">Name</label>
                        <input
                          type="text"
                          placeholder="Your name"
                          value={formData.name} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({ ...formData, name: e.target.value }) //setting the formData to the value input of the textfield
                          }
                        />
                        <label htmlFor="email">Email</label>
                        {isEmail ? (
                          <input
                            type="email"
                            placeholder="Email or Phone Number"
                            value={formData.email} //setting the value of the form to the props value
                            onChange={handleEmailChange}
                            autoFocus
                            // onChange={
                            //   (e) =>
                            //     setFormData({
                            //       ...formData,
                            //       email: e.target.value,
                            //     }) //setting the formData to the value input of the textfield
                            // }
                          />
                        ) : (
                          <PhoneInput
                            inputClass="phoneInput"
                            placeholder="Phone Number"
                            value={formData.email}
                            defaultCountry="ua"
                            onChange={handlePhoneChange}
                            autoFocus
                          />
                        )}
                        <label htmlFor="password">Password</label>
                        <div className="password-input">
                          {" "}
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={formData.password} //setting the value of the form to the props value
                            onChange={
                              (e) =>
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                }) //setting the formData to the value input of the textfield
                            }
                          />{" "}
                          <span
                            className="password-toggle"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <label htmlFor="password">Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              confirmPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                      <button
                        className="button"
                        onClick={handleEmailVerification}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  <div className="no-account" style={{ marginTop: "1rem" }}>
                    <span>Already have an account?</span>
                    <h6 onClick={() => setSignIn(true)}>Sign In &#8594;</h6>
                  </div>
                </>
              ) : (
                <div className="signIn-Form">
                  <div className="signinForm">
                    {!otpValidated ? (
                      <>
                        <div className="heading-icon">
                          <FcPrevious
                            className="prev-icon"
                            size={30}
                            onClick={() => {
                              setOtpForm(false);
                              setSubType(false);
                            }}
                          />
                          <div className="heading">
                            Please enter OTP to verify your account
                          </div>
                        </div>
                        <div className="otpbody">
                          <div className="otp-body">
                            <span
                              style={{
                                marginBottom: "2rem",
                                textAlign: "center",
                              }}
                            >
                              A one-Time Password has been sent to{" "}
                              {formData.email}
                            </span>
                            <OtpInput
                              value={otpValue}
                              onChange={handleOtpChange}
                              numInputs={6}
                              isInputNum
                              renderInput={(inputProps) => (
                                <input
                                  {...inputProps}
                                  style={{ margin: "0rem 0.4rem" }}
                                />
                              )}
                              renderSeparator={<span>-</span>}
                              inputStyle={{
                                width: "4rem",
                                height: "3rem",
                                margin: "0 1rem",
                                padding: 0,
                                borderRadius: 4,
                                background: "white",
                                outline: "none",
                                textAlign: "center",
                              }}
                            />
                          </div>
                          {wrongEmailCode && (
                            <span style={{ color: "red" }}>
                              Entered wrong code, please enter the code sent on{" "}
                              {formData.email}{" "}
                            </span>
                          )}
                          {userExistError !== "" && (
                            <span style={{ color: "red" }}>
                              {userExistError}
                            </span>
                          )}
                          {isEmail ? (
                            <button
                              className="button"
                              // onClick={handleSignUp}
                              onClick={handleEmailCodeVerification}
                              style={{ marginTop: "2rem" }}
                            >
                              Validate
                            </button>
                          ) : (
                            <button
                              className="button"
                              onClick={handlePhoneCodeVerification}
                            >
                              Validate
                            </button>
                          )}
                          <div
                            className="otp-body"
                            style={{ marginTop: "3rem" }}
                          >
                            {/* <h4>
                            <Link
                              onClick={() => {
                                sendEmailVerificationWithoutToken(obj);
                                setOtpValue("");
                              }}
                            >
                              Resend One Time Password
                            </Link>
                          </h4> */}
                            <h4>
                              {timer === 0 ? (
                                <Link
                                  onClick={() => {
                                    handleResendClick(
                                      sendEmailVerificationWithoutToken,
                                      obj
                                    );
                                  }}
                                >
                                  Resend One Time Password
                                </Link>
                              ) : (
                                <Link>
                                  Resend One Time Password in {timer} seconds
                                </Link>
                              )}
                            </h4>
                            <span style={{ marginBottom: "2rem" }}>
                              <Link
                                onClick={() => {
                                  setSubType(false);
                                  setOtpForm(false);
                                }}
                              >
                                Entered a Wrong Email?
                              </Link>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="otpbody" style={{ margin: "auto" }}>
                          <div className="success2FA">
                            <AiOutlineCheckCircle
                              style={{ color: "var(--iq-primary)" }}
                              size={30}
                            />
                            <h5
                              style={{
                                color: "var(--iq-primary)",
                                textAlign: "center",
                              }}
                            >
                              Your account has been created successfully!
                            </h5>
                          </div>
                          <div className="otp-body">
                            <h6>
                              Redirecting to home page in {redirectngTimer}
                            </h6>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/* <div className="mobForm">
        <div className="login-signup-form-container sign-in-container">
          <form action="#" className="login-signup-form">
            <h3>Sign in</h3>

            <input type="email" placeholder="Email" />
            <input type="password" placeholder="Password" />
            <Link to="/extra-pages/recover-pswd" className="f-link">
              Forgot your password?
            </Link>
            <button
              className="button"
              onClick={() => {
                history.push("/");
              }}
            >
              Sign In
            </button>
          </form>
        </div>{" "}
      </div> */}
      </div>
    </div>
  );
};

export default LoginMob;
