import axios from "axios";

// function to get all comments
export async function CommentsGetApi(generalId, mediaCategory) {
  try {
    const mainOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/comments/get-general-content-comments/${generalId}/${mediaCategory}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Main Slider Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Main Slider function");
  }
}
export async function GetEpisodeComments(episodeObjectId, mediaCategory) {
  // tvShowId = tvshow ke undersroll id hy
  try {
    const mainOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/comments/get-comment-episode/${episodeObjectId}/${mediaCategory}`,
      mainOptions
    );

    if (!response.ok) {
      throw new Error("Failed to Get Comments of Episode Response");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Get Comments of Episode function");
  }
}

// function to add comment
export async function addComment(comment, user_id, generalContentId) {
  const url = `${process.env.REACT_APP_API}/api/comments/add-comment/${generalContentId}`;
  const commentData = {
    comment: comment,
    user_id: user_id,
  };

  try {
    const response = await axios.post(url, commentData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
}

export async function addEpisodeComment(
  comment,
  user_id,
  type,
  episodeObjectId,
  tvShowId
) {
  const url = `${process.env.REACT_APP_API}/api/comments/add-comment-series/${tvShowId}`;
  const commentData = {
    comment: comment,
    user_id: user_id,
    type: type,
    episode_object_id: episodeObjectId,
  };

  try {
    const response = await axios.post(url, commentData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
}

// function to update comment
export async function updateComment(generalContentId, commentId, comment) {
  const url = `${process.env.REACT_APP_API}/api/comments/update-comment/${commentId}`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ updatedComment: comment }),
    });

    const data = await response.json();
    return data;
  } catch (error) {}
}

// function to delete comment
export async function deleteComment(generalContentId, commentId) {
  axios
    .delete(
      `${process.env.REACT_APP_API}/api/comments/delete-comment/${generalContentId}/${commentId}`
    )
    .then((response) => {})
    .catch((error) => {});
}

export async function postCommentWithGeneralObjectId(
  comment,
  user_id,
  mediaCategory,
  generalContentId,
  token
) {
  // const url = `${process.env.REACT_APP_API}/api/comments/add-comment/${generalContentId}`;
  const url = `${process.env.REACT_APP_API}/api/comments/add-comment-general-content/${generalContentId}`;
  const commentData = {
    comment: comment,
    user_id: user_id,
    type: mediaCategory,
  };

  try {
    const response = await axios.post(url, commentData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        source: "front",
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
}

export async function postCommentWithTvShowId(
  comment,
  user_id,
  mediaCategory,
  episodeObjId,
  tvShowId
) {
  // const url = `${process.env.REACT_APP_API}/api/comments/add-comment/${generalContentId}`;
  const url = `${process.env.REACT_APP_API}/api/comments/add-comment-series/${tvShowId}`;
  const commentData = {
    comment: comment,
    user_id: user_id,
    type: mediaCategory,
    episode_object_id: episodeObjId,
  };

  try {
    const response = await axios.post(url, commentData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
}
