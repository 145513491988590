const getEpisode = async ({ id }) => {
  const options = {
    method: "GET",
    headers: { accept: "application/json; charset=utf-8" },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/episodes/get-episode/${id}`,
    options
  );

  if (!response.ok) {
    throw new Error("Failed to data");
  }

  const data = await response.json();
  return data;
};

export default getEpisode;
