import axios from "axios";

function removeStripeCard(user_id) {
  const url = `${process.env.REACT_APP_API}/api/user/stripe/remove-card/${user_id}`;
  const currentUserToken = localStorage.getItem("token");
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
export default removeStripeCard;
