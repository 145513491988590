import axios from "axios";

function userSubscription(user_id) {
  const currentUserToken = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_API}/api/user/stripe/subscription-charge/${user_id}`;
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return []; // Return an empty array or handle the error as needed
    });
}
export default userSubscription;
