import { currentUserToken } from "../views/backend/home/home";
export const podCastMainSliderApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/sliders/get-slider-by-slider-type/podcast/${currentSelectedLanguage}`,
      mainOptions
    );

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from PodCast Slider function");
    // throw error;
  }
};

export const podCastListApi = async (currentSelectedLanguage) => {
  try {
    const mainOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/podcast/get-active-podcasts/${currentSelectedLanguage}`,
      mainOptions
    );

    const data = await response.json();
    console.log(currentSelectedLanguage, "curen");
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from TVSHOW LIST Slider function");
    // throw error;
  }
};
const fetchPodcasts = async (url, currentSelectedLanguage) => {
  try {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: currentSelectedLanguage }),
    };

    const response = await fetch(url, options);

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, `Error fetching data from ${url}`);
    // throw error;
  }
};

export const podCastUpComingSliderApi = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/podcast/get-upcoming-podcasts/${currentSelectedLanguage}`;
  try {
    return await fetchPodcasts(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching upcoming podcasts from ${url}`);
    // throw new Error("Failed to fetch Upcoming Podcasts Response");
  }
};

export const podCastLatestSliderApi = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/podcast/get-latest-podcasts/${currentSelectedLanguage}`;
  try {
    return await fetchPodcasts(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching latest podcasts from ${url}`);
    // throw new Error("Error fetching latest podcasts");
  }
};

export const podCastTopRatedApi = async (currentSelectedLanguage) => {
  try {
    const upcomingOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: currentSelectedLanguage }),
    };
    const responseUpcoming = await fetch(
      `${process.env.REACT_APP_API}/api/podcast/get-top-rated-podcasts/${currentSelectedLanguage}`,
      upcomingOptions
    );

    const data = await responseUpcoming.json();
    console.log("data", data);
    return data;
  } catch (error) {
    // console.error(error, "Error Coming from Top Rated PodCast function");
    // throw error;
  }
};

const fetchTVData = async (url, currentSelectedLanguage) => {
  try {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language: currentSelectedLanguage }),
    };

    const response = await fetch(url, options);

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error, `Error fetching data from ${url}`);
    // throw error;
  }
};

export const podCastShowsByGenre = async (currentSelectedLanguage) => {
  const url = `${process.env.REACT_APP_API}/api/podcast/get-podcasts-by-genre/${currentSelectedLanguage}`;
  try {
    return fetchTVData(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching Podcast Genre from ${url}`);
    // throw new Error("Failed to fetch Podcast Genre Response");
  }
};

export const podCastAndShowsByGenre = async (
  currentSelectedLanguage,
  category
) => {
  const categoryInUrl = category === "podcast" ? "podcasts" : "tvShows";
  const categoryTemp = category === "podcast" ? "podcast" : "tv-shows";
  const url = `${process.env.REACT_APP_API}/api/${categoryTemp}/get-${categoryInUrl}-by-genre/${currentSelectedLanguage}`;
  try {
    return fetchTVData(url, currentSelectedLanguage);
  } catch (error) {
    // console.error(error, `Error fetching ${categoryInUrl} Genre from ${url}`);
    // throw new Error(`Failed to fetch ${categoryInUrl} Genre Response`);
  }
};
