import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
//img
import aboutus1 from "../../../../assets/images/about-us/privacy1.jpg";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const [content, setContent] = useState("");
  const [headings, setHeadings] = useState([]);
  const [paragraphs, setParagraphs] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/settings/get-policy`
        );
        const json = await response.json();
        setContent(json.savedData.policies);
        // setData(json.savedData);
        // const parser = new DOMParser();
        // const htmlDoc = parser.parseFromString(
        //   json.savedData.policies,
        //   "text/html"
        // );

        // const h2Elements = htmlDoc.querySelectorAll("h2");
        // const pElements = htmlDoc.querySelectorAll("p");

        // setHeadings(Array.from(h2Elements).map((element) => element.innerHTML));
        // setParagraphs(
        //   Array.from(pElements).map((element) => element.innerHTML)
        // );
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
     <Helmet>
          <title>{`${t('meta.privacy-policy.title')} | AFO Media`}</title>
          <meta property="og:title" content={`${t('meta.privacy-policy.title')} | AFO Media`} />
          <meta property="og:url" content={`https://afomedia.com/privacy-policy`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <div>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <div className="iq-privacy-policy" style={{ marginTop: "10rem" }}>
                <div className="mb-3">
                  <h4 className="mb-3">{t("Privacy Policy")}</h4>
                  {/* <h2 className="title">Privacy Policy</h2> */}
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
