// utils/api.js

export const absoutUs = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/admin/about/afo`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    //("Error fetching data:", error);
    throw error;
  }
};
