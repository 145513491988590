import axios from "axios";

function sendPhoneVerification(phoneobj) {
  const url = `${process.env.REACT_APP_API}/api/user/sendPhoneCode`;

  axios
    .post(url, phoneobj)
    .then((response) => {
      localStorage.setItem("Phone verification token", response.data.token);
    })
    .catch((error) => {});
}

export default sendPhoneVerification;
