import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { languages } from "../../../components/partials/backend/headerstyle/headerstyle1";
// import { fetchVideosData, fetchTVshowsData } from "../../../Services/GenreApi";
import { useTranslation } from "react-i18next";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
} from "../home/ShareFunction";

const GenreView = () => {
  const source = "app";
  const { name, genre_image, genre_type, id } = useParams();
  const [data, setData] = useState([]);
  const [tvData, setTVData] = useState([]);
  const [moviesData, setMoviesData] = useState([]);
  const [podCastData, setPodCastData] = useState([]);

  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { t } = useTranslation();

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );

  const fetchData = (endpoint, id, languages, setData) => {
    fetch(`${process.env.REACT_APP_API}${endpoint}/${id}/${languages}`)
      .then((res) => res.json())
      .then((result) => {
        if (
          result &&
          result.upcomingContent &&
          result.upcomingContent.length > 0
        ) {
          setData(result.upcomingContent);
          const totalItems = result.upcomingContent.length;
          const totalPages = Math.ceil(totalItems / itemsPerPage);
          setTotalPages(totalPages);
        } else {
          setData([]);
        }
      })
      .catch((error) => {});
  };

  const fetchTVshowsData = () => {
    fetchData("/api/tv-shows/get-genre-tvShows", id, languages, setTVData);
  };

  const fetchMoviesData = () => {
    fetchData(
      "/api/general-content/get-genre-movies",
      id,
      languages,
      setMoviesData
    );
  };
  const fetchPodCastData = () => {
    fetchData("/api/podcast/get-genre-podcasts", id, languages, setPodCastData);
  };
  useEffect(() => {
    fetchTVshowsData();
  }, [id, currentPage]);

  useEffect(() => {
    fetchMoviesData();
  }, [id, currentPage]);

  useEffect(() => {
    fetchPodCastData();
  }, [id, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedData = data.slice(startIndex, endIndex) || []; // Set empty array if data is undefined
  const TVdisplayedData = tvData.slice(startIndex, endIndex) || []; // Set empty array if data is undefined
  const MoviesdisplayData = moviesData.slice(startIndex, endIndex) || [];
  const PodCastdisplayData = podCastData.slice(startIndex, endIndex) || [];
  return (
    <>
      <br />
      <div style={{ marginTop: "80px" }}></div>

      <section id="iq-favorites">
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div
                className="gallery-container"
                style={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <div className="">
                  <div id="upcoming-contents">
                    <ul className="list-inline mb-0 iq-rtl-direction row">
                      {PodCastdisplayData?.map((data) => (
                        <li
                          className="all-card-container slide-item  mb-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                          key={data._id}
                        >
                          <Link
                            to={
                              data?.category === "movie" ||
                              data?.category === "video"
                                ? `/video-details/${source}/${data?._id}`
                                : `/show-info/${data?._id}`
                            }
                          >
                            <div className="block-images position-relative">
                              <div className="card-slider-badge">
                                <div className="badge badge-secondary p-1 mr-2">
                                  {data?.category === "movie"
                                    ? "VIDEO"
                                    : data?.category?.toUpperCase()}
                                </div>
                              </div>
                              <div className="img-box">
                                <img
                                  src={data?.thumbnail?.static_thumbnail_url}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="block-description">
                                <h6 className="iq-title all-title-desc">
                                  {data?.category === "podcast" ||
                                  data?.category === "tvshow"
                                    ? data?.title
                                    : data?.media?.title}
                                </h6>
                                {/* <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            data?.media?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div> */}
                                <div className="hover-buttons">
                                  <Link
                                    to={
                                      data?.category === "movie" ||
                                      data?.category === "video"
                                        ? `/video-details/${source}/${data?._id}`
                                        : `/show-info/${data?._id}`
                                    }
                                    className="btn btn-hover iq-button btn-typ"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <i
                                      className="fa fa-play mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    {t("Play now")}
                                  </Link>
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href={generateFacebookShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-heart-fill ${
                                          favoriteItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        //   onClick={() =>
                                        //     handleFavClick(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        title={
                                          favoriteItems.includes(data?._id)
                                            ? t("Remove From Favourite")
                                            : t("Add To Favourite")
                                        }
                                      ></i>
                                    </span>
                                    {/* <span className="count-box">19+</span> */}
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-add-line ${
                                          watchListItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        //   onClick={() =>
                                        //     handleWatchList(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        title={
                                          watchListItems.includes(data?._id)
                                            ? t("Remove From WatchList")
                                            : t("Add To WatchList")
                                        }
                                      ></i>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="iq-favorites">
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div
                className="gallery-container"
                style={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <div className="">
                  <div id="upcoming-contents">
                    <ul className="list-inline mb-0 iq-rtl-direction row">
                      {TVdisplayedData?.map((data) => (
                        <li
                          className="all-card-container slide-item  mb-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                          key={data._id}
                        >
                          <Link
                            to={
                              data?.category === "movie" ||
                              data?.category === "video"
                                ? `/video-details/${source}/${data?._id}`
                                : `/show-info/${data?._id}`
                            }
                          >
                            <div className="block-images position-relative">
                              <div className="card-slider-badge">
                                <div className="badge badge-secondary p-1 mr-2">
                                  {data?.category === "movie"
                                    ? "VIDEO"
                                    : data?.category?.toUpperCase()}
                                </div>
                              </div>
                              <div className="img-box">
                                <img
                                  src={data?.thumbnail?.static_thumbnail_url}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="block-description">
                                <h6 className="iq-title all-title-desc">
                                  {data?.category === "podcast" ||
                                  data?.category === "tvshow"
                                    ? data?.title
                                    : data?.media?.title}
                                </h6>
                                {/* <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            data?.media?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div> */}
                                <div className="hover-buttons">
                                  <Link
                                    to={
                                      data?.category === "movie" ||
                                      data?.category === "video"
                                        ? `/video-details/${source}/${data?._id}`
                                        : `/show-info/${data?._id}`
                                    }
                                    className="btn btn-hover iq-button btn-typ"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <i
                                      className="fa fa-play mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    {t("Play now")}
                                  </Link>
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        {/* <a
                                              href={generateLinkedinShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-linkedin-fill"></i>
                                            </a>
                                            <a
                                              href={generateInstagramShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-instagram-fill"></i>
                                            </a>
                                            <a
                                              href={generateSnapchatShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-snapchat-fill"></i>
                                            </a>
                                            <a
                                              href={generateTikTokShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-tiktok-fill"></i>
                                            </a> */}
                                        <a
                                          href={generateFacebookShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                        {/* <a
                                              href={generatePinterestShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-pinterest-fill"></i>
                                            </a>
                                            <a
                                              href={generateTelegramShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-telegram-fill"></i>
                                            </a>
                                            <a
                                              href={generateRedditShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-reddit-fill"></i>
                                            </a>
                                            <a
                                              href={generateQuoraShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-quora-fill"></i>
                                            </a> */}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-heart-fill ${
                                          favoriteItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        //   onClick={() =>
                                        //     handleFavClick(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        title={
                                          favoriteItems.includes(data?._id)
                                            ? t("Remove From Favourite")
                                            : t("Add To Favourite")
                                        }
                                      ></i>
                                    </span>
                                    {/* <span className="count-box">19+</span> */}
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-add-line ${
                                          watchListItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        //   onClick={() =>
                                        //     handleWatchList(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        title={
                                          watchListItems.includes(data?._id)
                                            ? t("Remove From WatchList")
                                            : t("Add To WatchList")
                                        }
                                      ></i>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="iq-favorites">
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div
                className="gallery-container"
                style={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <div className="">
                  <div id="upcoming-contents">
                    <ul className="list-inline mb-0 iq-rtl-direction row">
                      {MoviesdisplayData?.map((data) => (
                        <li
                          className="all-card-container slide-item  mb-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                          key={data._id}
                        >
                          <Link
                            to={
                              data?.category === "movie" ||
                              data?.category === "video"
                                ? `/video-details/${source}/${data?._id}`
                                : `/show-info/${data?._id}`
                            }
                          >
                            <div className="block-images position-relative">
                              <div className="card-slider-badge">
                                <div className="badge badge-secondary p-1 mr-2">
                                  {data?.category === "movie"
                                    ? "VIDEO"
                                    : data?.category?.toUpperCase()}
                                </div>
                              </div>
                              <div className="img-box">
                                <img
                                  src={data?.thumbnail?.static_thumbnail_url}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="block-description">
                                <h6 className="iq-title all-title-desc">
                                  {data?.category === "podcast" ||
                                  data?.category === "tvshow"
                                    ? data?.title
                                    : data?.media?.title}
                                </h6>
                                {/* <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            data?.media?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div> */}
                                <div className="hover-buttons">
                                  <Link
                                    to={
                                      data?.category === "movie" ||
                                      data?.category === "video"
                                        ? `/video-details/${source}/${data?._id}`
                                        : `/show-info/${data?._id}`
                                    }
                                    className="btn btn-hover iq-button btn-typ"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <i
                                      className="fa fa-play mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    {t("Play now")}
                                  </Link>
                                </div>
                              </div>
                              <div className="block-social-info">
                                <ul className="list-inline p-0 m-0 music-play-lists">
                                  <li className="share">
                                    <span>
                                      <i className="ri-share-fill"></i>
                                    </span>
                                    <div className="share-box">
                                      <div className="d-flex align-items-center">
                                        {/* <a
                                              href={generateLinkedinShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-linkedin-fill"></i>
                                            </a>
                                            <a
                                              href={generateInstagramShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-instagram-fill"></i>
                                            </a>
                                            <a
                                              href={generateSnapchatShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-snapchat-fill"></i>
                                            </a>
                                            <a
                                              href={generateTikTokShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-tiktok-fill"></i>
                                            </a> */}
                                        <a
                                          href={generateFacebookShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a
                                          href={generateTwitterShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a
                                          href={generateWhatsappShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico iq-copy-link"
                                          tabIndex="0"
                                        >
                                          <i className="ri-whatsapp-fill"></i>
                                        </a>
                                        {/* <a
                                              href={generatePinterestShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-pinterest-fill"></i>
                                            </a>
                                            <a
                                              href={generateTelegramShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-telegram-fill"></i>
                                            </a>
                                            <a
                                              href={generateRedditShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-reddit-fill"></i>
                                            </a>
                                            <a
                                              href={generateQuoraShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-quora-fill"></i>
                                            </a> */}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-heart-fill ${
                                          favoriteItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        //   onClick={() =>
                                        //     handleFavClick(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        title={
                                          favoriteItems.includes(data?._id)
                                            ? t("Remove From Favourite")
                                            : t("Add To Favourite")
                                        }
                                      ></i>
                                    </span>
                                    {/* <span className="count-box">19+</span> */}
                                  </li>
                                  <li>
                                    <span>
                                      <i
                                        className={`ri-add-line ${
                                          watchListItems.includes(data?._id)
                                            ? "red-heart"
                                            : ""
                                        }`}
                                        //   onClick={() =>
                                        //     handleWatchList(
                                        //       data?._id,
                                        //       data?.category
                                        //     )
                                        //   }
                                        title={
                                          watchListItems.includes(data?._id)
                                            ? t("Remove From WatchList")
                                            : t("Add To WatchList")
                                        }
                                      ></i>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="pagination-container">
        <Pagination className="custom-pagination">
          {" "}
          {/* Add the custom class */}
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </>
  );
};

export default GenreView;
