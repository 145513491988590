import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import countryList from "react-select-country-list";
import Select from "react-select";
import { PhoneInput } from "react-contact-number-input";
import axios from "axios";
import { MdError } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
// import AuthSession from "../../../../getSessionAuth";
import { Dropdown } from "react-bootstrap";
import user from "../../../../assets/images/user/user.jpg";
import { useTranslation } from "react-i18next";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCcVisa } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import fav1 from "../../../../assets/images/profile-default.png";
import "./tooltip.css";
import Modal from "react-bootstrap/Modal";
import { FaTrash } from "react-icons/fa";
import OtpInput from "react-otp-input";
// swipper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import Enable2FA from "./2FA/Enable2FA";
import Disable2FA from "./2FA/Disable2FA";
import { HmacSHA256 } from "crypto-js";
import updateUserDetails from "../../../../Services/updateUserDetails";
import getPackageDetails from "../../../../Services/package";
import {
  capitalizeFirstLetter,
  convertSecondsToHoursMinutes,
  generalDateFormat,
  getNextBillingDate,
  getNextMonthDate,
  limitTextToWords,
} from "../../../../Services/generalFunctions/generalFunctions";
import UserFreeAccountSetting from "./userfreeaccountsetting";
import uploadProfilePicture from "../../../../Services/uploadProfileImage";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import generateRandomIv from "../../../../Services/generateRandomIv";
import AuthSession from "./../../../../Services/getSessionAuth";
import { Buffer } from "buffer";
import visa from "../../../../assets/images/cards/visa.png";
import mastercard from "../../../../assets/images/cards/mastercard.png";
import Stripe from "../../pages/auth/stripe";
import CryptoJS from "crypto-js";
import removeStripeCard from "../../../../Services/removeStripeCard";
import checkUserCard from "../../../../Services/checkUserCard";
import verifyFACode from "../../../../Services/verifyFACode";
import FACodeSend from "../../../../Services/FACodeSend";
import { getBillingHistory } from "../../../../Services/billingHistory";
import { Helmet } from "react-helmet";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const btoa = (text) => {
  return Buffer.from(text, "binary").toString("base64");
};

const atob = (base64) => {
  return Buffer.from(base64, "base64").toString("binary");
};

const UserAccountSetting = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [expirationDate, setExpirationDate] = useState('');
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    const currentUser = JSON.parse(localStorage.getItem("session"));
    const expirationDateY = new Date(currentUser?.subscription_date);
    expirationDateY.setMonth(expirationDateY.getMonth() + 1);
    setExpirationDate(expirationDateY)

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
   


  }, []);

  // get billing history
  const [billing, setBillingHistory] = useState([]);

  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 15);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    setMaxDate(year + "-" + month + "-" + day);
    const fetchBillingHistory = async () => {
      const userId = currentUserId;
      const history = await getBillingHistory(userId);
      if (history) {
        setBillingHistory(history.billingHistory);
      }
    };

    fetchBillingHistory();
  }, []);

  <ToastContainer
    toastStyle={{
      background: "black",
      color: "white",
    }}
  />;
  // const customStyles = {
  //   menu: (provided) => ({
  //     ...provided,
  //     maxHeight: "100px",
  //     overflowY: "scroll",
  //   }),
  // };

  // user details from session api

  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));

    // Get the current date
    const currentDate = new Date();

    // Subtract 15 years from the current date
    currentDate.setFullYear(currentDate.getFullYear() - 15);

    // Format the date as "YYYY-MM-DD"
    const fifteenYearsAgo = currentDate.toISOString().substring(0, 10);

    if(userDetails_Session && typeof userDetails_Session !="undefined"){
      return {
        _id: userDetails_Session?._id,
        name: userDetails_Session?.name,
        email: userDetails_Session?.email,
        tfa:
          userDetails_Session.tfa !== undefined ? userDetails_Session.tfa : false,
        phone: userDetails_Session.phone ? userDetails_Session.phone : "",
        // dob: userDetails_Session.dob
        //   ? userDetails_Session.dob.substring(0, 10)
        //   : "2021-09-02",
        dob: userDetails_Session.dob
          ? userDetails_Session.dob.substring(0, 10)
          : fifteenYearsAgo,
        language: userDetails_Session.language
          ? userDetails_Session.language
          : "unknown",
        gender: userDetails_Session.gender
          ? userDetails_Session.gender
          : "unknown",
        country: userDetails_Session.country
          ? userDetails_Session.country
          : "N/A",
        password: userDetails_Session.password
          ? userDetails_Session.password
          : "N/A",
        profileIMG: userDetails_Session.profile_image?.url
          ? userDetails_Session.profile_image?.url
          : "",
        subscription: userDetails_Session.subscription,
        brand: userDetails_Session.brand,
        last4: userDetails_Session.last4,
      };
    }
   
  };

  const [userDetails, setUserDetails] = useState(getSessionData());
  // 2fa active/inactive toggle
  const [switchState, setSwitchState] = useState(userDetails.tfa);
  // enable 2fa modal
  const [show, setShow] = useState(false);
  // disable 2fa modal
  const [inactiveshow, setInactiveShow] = useState(false);
  const [packageDetails, setPackageDetails] = useState();

  const handleSwitchChange = (checked) => {
    setSwitchState(checked);
    if (checked) {
      setShow(true);
    } else {
      setInactiveShow(true);
    }
  };
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const handleModalChange = () => {
    getPackageDetails().then((data) => {
      setPackageDetails(data?.package);
      setShowSubscriptionModal(true);
    });
  };
  const [editInfo, setEditInfo] = useState(false);
  const handleEdit = () => {
    setEditInfo(true);
  };
  // USER INFO
  const [userInfo, setUserInfo] = useState({
    dob: userDetails.dob,
    _id: userDetails._id,
    phone: userDetails.phone,
    gender: userDetails.gender,
    language: userDetails.language,
    country: userDetails.country,
  });
  // gender dropdown
  const [selectedGender, setSelectedGender] = useState();
  const handleGender = (e) => {
    setSelectedGender(e);
    setUserInfo({ ...userInfo, gender: e });
  };

  // language dropdown

  const [selectedlang, setSelectedlang] = useState();
  const handleLang = (e) => {
    setSelectedlang(e);
    setUserInfo({ ...userInfo, language: e });
  };
  // country dropdown

  const mycountry = useMemo(() => {
    const countries = countryList().getData();
    const labels = countries.map((country) => country.label);
    return labels;
  }, []);
  // phoneNumber
  const [value, setValue] = useState(userInfo.phone);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneNum = (newValue) => {
    setValue(newValue);
    if (newValue && newValue.countryCode && newValue.phoneNumber) {
      setPhoneNumber(newValue.countryCode.concat(newValue.phoneNumber));
      setUserInfo({
        ...userInfo,
        phone: newValue.countryCode.concat(newValue.phoneNumber),
      });
    } else {
      setPhoneNumber("");
    }
  };
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(mycountry);
  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    // Filter the options based on the entered text
    const filteredOptions = mycountry.filter((option) =>
      option.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredOptions(filteredOptions);
  };
  const [selectedCountry, setSelectedCountry] = useState();
  const handleSelect = (option) => {
    setSelectedCountry(option);
    setUserInfo({ ...userInfo, country: option });
  };

  // const handleSave = async () => {
  //   await updateUserDetails(userInfo);
  //   setEditInfo(false);
  //   await AuthSession();
  //   const updatedUserDetails = getSessionData();
  //   setUserDetails(updatedUserDetails);
  // };

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModalWatchList, setShowDeleteModalWatchList] =
    useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [itemIdToDeleteWL, setItemIdToDeleteWL] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;

  const handleDelete = (itemId) => {
    setShowDeleteModalWatchList(true);
    setItemIdToDeleteWL(itemId); // Store the ID of the item to be deleted
  };
  const handleDeleteFav = (itemId) => {
    setShowDeleteModal(true);
    setItemIdToDelete(itemId); // Store the ID of the item to be deleted
  };
  const handleConfirmDeleteWatchList = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/user/delete-watch-list/${itemIdToDeleteWL}`
      );
      // setWatchList(watchList.filter((item) => item._id !== itemIdToDeleteWL));
      setWatchList((prevList) =>
        prevList.filter((item) => item._id !== itemIdToDeleteWL)
      );
    } catch (error) { }
    setShowDeleteModalWatchList(false);
  };

  const handleConfirmDeleteFAV = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/user/delete-favorite/${itemIdToDelete}`
      );
      // setFavList(watchList.filter((item) => item._id !== itemIdToDelete));
      setFavList((prevList) =>
        prevList.filter((item) => item._id !== itemIdToDelete)
      );
    } catch (error) { }
    setShowDeleteModal(false);
  };

  //----------HISTORY WORK
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const url = `${process.env.REACT_APP_API}/api/user/get-user-history/${currentUserId}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        if (result?.userHistory?.length > 0) {
          setData(result.userHistory);
        } else {
          setData([]);
        }
      });
  };
  //History ENds

  //-----Watchlist
  const [watchList, setWatchList] = useState([]);

  useEffect(() => {
    const fetchWatchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/user/get-user-watch-list/${currentUserId}`
        );
        const data = await response.json();
        if (data?.userWatchList?.length > 0) {
          setWatchList(data?.userWatchList);
        } else {
          setWatchList([]);
        }
      } catch (error) { }
    };

    fetchWatchData();
  }, []);
  //-----Watchlist ENd
  //----- Fav
  const [favList, setFavList] = useState([]);

  useEffect(() => {
    const fetchFavData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/user/get-user-favorite/${currentUserId}`
        );

        const data = await response.json();
        if (data.userWatchList?.length > 0) {
          setFavList(data.userWatchList);

          // const watchListIds = data.userWatchList.map((item) => item._id);
        } else {
          setFavList([]);
        }
      } catch (error) { }
    };

    fetchFavData();
  }, []);
  //------Fav END
  // load more button
  const [load, setLoad] = useState(false);
  const source = "app";

  // const [showPassword, setShowPassword] = useState(false);

  // const handleTogglePassword = () => {
  //   setShowPassword(!showPassword);
  // };
  const [imageBase64, setImageBase64] = useState("");
  const handleClick = () => {
    document.getElementById("file-input").click();
  };
  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      const base64String = reader.result;

      setImageBase64(base64String);

      uploadProfilePicture(userDetails._id, {
        profileImageBase64: base64String,
      });
      setTimeout(async () => {
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
      }, 6000);
    };
  };
  const handleUpdateData = (data) => {
    setUserDetails(data);
  };

  //change pass ka kam hy
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handlePasswordChange = () => {
    if (password !== confirmPassword) {
      toast.error(t("newPasswordMismatch"), {
        position: "top-center",
      });
      return;
    }
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasMinimumLength = password?.length >= 8;
    const hasNumber = /\d/.test(password);

    if (!hasSpecialCharacter || !hasMinimumLength || !hasNumber) {
      toast.error(t("invalidPassword"), {
        position: "top-center",
      });
      return;
    }

    // Prepare the request body
    const requestBody = {
      newPassword: password,
      user_id: currentUserId,
      currentPassword: oldPassword,
    };

    // Make the API request
    const msg = JSON.stringify(requestBody);
    const i = generateRandomIv(16);
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(i);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    const transitMessage = JSON.stringify({
      iv: btoa(i),
      value: encrypted.toString(),
    });
    const encryptedMessage = btoa(transitMessage);

    const request = {
      data: encryptedMessage,
    };

    const url = `${process.env.REACT_APP_API}/api/user/changePassword`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "200") {
          // Store the new password in localStorage
          localStorage.setItem("password", password);
          toast.success(t("Password changed successfully"), {
            position: "top-center",
          });
          setPassword("");
          setConfirmPassword("");
          setOldPassword("");
          closeModal(); // Close the modal on success
        } else {
          toast.info(t("Failed to change password"), {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        toast.info(t("Error at Change Password!"), {
          position: "top-center",
        });
      });
  };

  // remove card=======================================================
  const [checkCardConnection, setCheckCardConnection] = useState(false);

  useEffect(() => {
    checkUserCard(currentUserId).then((response) => {
      if (response.data.connected) {
        setCheckCardConnection(true);
      } else {
        setCheckCardConnection(false);
      }
    });
  }, [userDetails]);

  const [removeCard, setRemoveCard] = useState(false);
  const [cardRemoved, setCardRemoved] = useState(false);
  const handleRemoveCard = async () => {
    removeStripeCard(currentUserId).then(async () => {
      setCardRemoved(true);
      checkUserCard(currentUserId).then((response) => {
        if (response.data.connected) {
          setCheckCardConnection(true);
        } else {
          setCheckCardConnection(false);
        }
      });
    });
  };
  // cancel subscription
  const handleCancelSubscription = async () => {
    toast.success(t("Cancelling Membership !"), {
      position: "top-center",
      progressStyle: { background: "var(--iq-primary)" },
    });
    const subscriptionDate = new Date(currentUser?.subscription_date);
    subscriptionDate.setMonth(subscriptionDate.getMonth() - 1);
    await updateUserDetails({ _id: userDetails._id, subscription: "free" , paid : false, subscription_date : subscriptionDate });
    await AuthSession();
    const updatedUserDetails = getSessionData();
    setUserDetails(updatedUserDetails);
    toast.success(t("Membership Cancelled Successfully!"), {
      position: "top-center",
      progressStyle: { background: "var(--iq-primary)" },
    });
  };
  // ======= change card ==================================
  const [changeCard, setChangeCard] = useState(false);
  // phone number change modal
  const [phoneModal, setPhoneModal] = useState(false);
  const handleClosePhoneModal = async () => {
    setPhoneModal(false);
    setPhoneNumberUpdated(false);
    setEditInfo(false);
    setUserInfo({
      dob: userDetails.dob,
      _id: userDetails._id,
      phone: userDetails.phone,
      gender: userDetails.gender,
      language: userDetails.language,
      country: userDetails.country,
    });
    await AuthSession();
    const updatedUserDetails = getSessionData();
    setUserDetails(updatedUserDetails);
  }; // verfification code - OTP

  const handleSave = async () => {
    if (userDetails.phone !== "" && userInfo.phone !== userDetails.phone) {
      setPhoneModal(true);
      const codeObj = {
        email: userDetails.email,
        phone: userInfo.phone,
      };
      FACodeSend(codeObj);
      setTimer(60);
    } else {
      await updateUserDetails(userInfo);
      setEditInfo(false);
      await AuthSession();
      const updatedUserDetails = getSessionData();
      setUserDetails(updatedUserDetails);
    }
  };

  const [verificationCode, setVerificationCode] = useState("");
  const [wrongCode, setWrongCode] = useState(false);
  const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);
  const verifyCode = async () => {
    const verifyObj = {
      email: userDetails.email,
      code: verificationCode,
      token: localStorage.getItem("2fatoken"),
    };
    try {
      const result = await verifyFACode(verifyObj);
      if (result) {
        await updateUserDetails(userInfo);
        await AuthSession();
        setEditInfo(false);
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
        setPhoneNumberUpdated(true);
        setWrongCode(false);
        setVerificationCode(""); // Clear the password field
        localStorage.removeItem("2fatoken");
      }
    } catch (error) {
      setPhoneNumberUpdated(false);
      setWrongCode(true);
      setTimeout(() => {
        setWrongCode(false);
      }, 3000);
    }
  };
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Stop the interval when timer reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [timer]);

  const handleResendClick = () => {
    if (timer <= 0) {
      const codeObj = {
        email: userDetails.email,
        phone: userInfo.phone,
      };
      FACodeSend(codeObj);
      setTimer(60);
    }
  };
  return (
    <>

    <Helmet>
          <title>{`${t('meta.profil.title')} | AFO Media`}</title>
          <meta property="og:title" content={`${t('meta.profil.title')} | AFO Media`} />
          <meta property="og:description" content={t('meta.profil.description')} />
          <meta property="og:url" content={`https://afomedia.com/profile/setting`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <ToastContainer
        toastStyle={{
          background: "black",
          color: "white",
        }}
      />
      <Modal
        show={phoneModal}
        onHide={handleClosePhoneModal}
        className="enable-modal"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>{t("One Time Password")}</Modal.Title>
        </Modal.Header>
        {!phoneNumberUpdated ? (
          <>
            {" "}
            <Modal.Body style={{ marginLeft: "0.5rem" }}>
              <p>
                {t("enterAuthCode")}
                {userDetails.phone}
              </p>
              <Form>
                <div className="otp-code">
                  {width > 425 ? (
                    <OtpInput
                      value={verificationCode}
                      onChange={setVerificationCode}
                      numInputs={6}
                      renderInput={(inputProps) => (
                        <input
                          {...inputProps}
                          className="OtpInput__input" // add class here
                        />
                      )}
                      renderSeparator={<span>-</span>}
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        margin: "0 0.2rem",

                        borderRadius: 2,

                        outline: "none",
                        textAlign: "center",
                        fontWeight: "400",
                      }}
                    />
                  ) : (
                    <OtpInput
                      value={verificationCode}
                      onChange={setVerificationCode}
                      numInputs={6}
                      renderInput={(inputProps) => (
                        <input
                          {...inputProps}
                          className="OtpInput__input" // add class here
                        />
                      )}
                      renderSeparator={<span>-</span>}
                      inputStyle={{
                        width: "2.2rem",
                        height: "2.2rem",
                        margin: "0 0.2rem",

                        borderRadius: 2,
                        padding: 0,
                        outline: "none",
                        textAlign: "center",
                        fontWeight: "400",
                      }}
                    />
                  )}

                  {wrongCode && (
                    <div>
                      <MdError style={{ color: "red" }} />{" "}
                      <span style={{ color: "red" }}>
                        {t("Incorrect verification code")}
                      </span>
                    </div>
                  )}
                  {timer === 0 ? (
                    <p onClick={handleResendClick}>Resend Code</p>
                  ) : (
                    <p>
                      {" "}
                      {t("Resend One Time Password in")} {timer} {t("seconds")}
                    </p>
                  )}
                  {/* <p onClick={sendCode}>Resend Code</p> */}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClosePhoneModal}>
                {t("Cancel")}
              </Button>
              {/* CHECK THE phone number error from value state variable if no error use api and setForm3(true) else show error */}
              <Button variant="primary" onClick={verifyCode}>
                {t("Verify")}
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <>
              <Modal.Body style={{ marginLeft: "0.5rem" }}>
                <div className="success2FA">
                  <AiOutlineCheckCircle
                    style={{ color: "var(--iq-primary)" }}
                    size={30}
                  />
                  <span style={{ color: "var(--iq-primary)" }}>
                    {t("Phone Number Changed Successfully!")}
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClosePhoneModal}>
                  {t("Done")}
                </Button>
              </Modal.Footer>
            </>
          </>
        )}
      </Modal>
      <Modal
        show={showModal}
        onHide={closeModal}
        className="deleteModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Change Password")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="oldPassword">
              <Form.Label>{t("Old Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Enter old password")}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>{t("New Password")}</Form.Label>
              <div className="password-input-group">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Enter new password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="password-toggle-icon"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="confirmPassword">
              <Form.Label>{t("confirm password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Confirm new password")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <div>
              <h2 style={{ fontSize: "1.3rem" }}>
                {t("Password Requirements")}
              </h2>
              <p>{t("passwordGuide")}</p>
              <ul
                style={{
                  listStyleType: "disc",
                  fontSize: ".8rem",
                  paddingLeft: "1rem",
                }}
              >
                <li>{t("Atleast One special character")}</li>
                <li>{t("Minimum 8 characters")}</li>
                <li>{t("numberRecommendation")}</li>
              </ul>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handlePasswordChange}>
            {t("Save Changes")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* -- */}
      <Modal
        className="deleteModal"
        show={showDeleteModalWatchList}
        onHide={() => setShowDeleteModalWatchList(false)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete Watch LIST?")}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary delBtn"
            onClick={() => setShowDeleteModalWatchList(false)}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary delBtn"
            onClick={handleConfirmDeleteWatchList}
          >
            {t("Delete")}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="deleteModal"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete FAV LIST?")}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary delBtn"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary delBtn"
            onClick={handleConfirmDeleteFAV}
          >
            {t("Delete")}
          </button>
        </Modal.Footer>
      </Modal>
      <section className="m-profile setting-wrapper">
        <Container>
          <h4 className="main-title mb-4">{t("account setting")}</h4>
          <Row>
            <Col lg="4" className="mb-3">
              <div className="sign-user_card ">
                <div className="text-center">
                  <div class="image-upload ">
                    <label>
                      <div
                        className="position-relative custom-tooltip custom-left"
                        data-text={t(
                          "Please select an image of 1:1 dimensions!"
                        )}
                      >
                        <img
                          style={{
                            width: "200px",
                            height: "200px",
                            cursor: "help",
                          }}
                          src={
                            userDetails.profileIMG !== ""
                              ? userDetails.profileIMG
                              : fav1
                          }
                          className="rounded-circle img-fluid d-block mx-auto mb-3 user-profile-img "
                          alt="user"
                        />
                        <div
                          style={{
                            position: "absolute",
                            right: "1.1rem",
                            bottom: "0",
                          }}
                        >
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li>
                              <span onClick={handleClick}>
                                <i className="ri-pencil-fill red-heart"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </label>
                    <div class="container"></div>
                    <input
                      id="file-input"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={(event) => {
                        if (
                          event.target.files &&
                          event.target.files?.length > 0
                        ) {
                          handleFileUpload(event);
                        }
                      }}
                    />
                  </div>
                  <h4 className="mb-3 a-border pb-3">
                    {capitalizeFirstLetter(userDetails.name)}
                  </h4>

                  {!editInfo ? (
                    <Link
                      to="#"
                      className="edit-icon text-primary"
                      onClick={handleEdit}
                    >
                      {t("edit")}
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="edit-icon text-primary"
                      onClick={handleSave}
                    >
                      {t("Save")}
                    </Link>
                  )}
                </div>

                <Row className="row align-items-center justify-content-between mb-3">
                  <Col md="8">
                    <span className="text-light font-size-13">
                      {t("email")}
                    </span>
                    <p className="mb-0">{userDetails.email}</p>
                  </Col>
                  {/* <Col md="4" className="text-md-right text-left">
                    <Link to="#" className="text-primary">
                      {t("change")}
                    </Link>
                  </Col> */}
                </Row>

                {!editInfo ? (
                  <>
                    <Row className="align-items-center justify-content-between mb-3">
                      <Col md="8">
                        {/* <span className="text-light font-size-13">
                      {t("password")}
                    </span>
                    <p className="mb-0">asder!</p> */}
                        <div>
                          <span className="text-light font-size-13">
                            {" "}
                            {t("password")}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            {/* <p className="mb-0" style={{ margin: 0 }}>
                              {showPassword
                                ? userDetails?.password
                                : "*".repeat(userDetails?.password?.length)}
                            </p>
                            {showPassword ? (
                              <i
                                className="fa fa-eye-slash"
                                aria-hidden="true"
                                onClick={handleTogglePassword}
                              />
                            ) : (
                              <i
                                className="fa fa-eye"
                                aria-hidden="true"
                                onClick={handleTogglePassword}
                              />
                            )} */}
                            {/* <Row className="align-items-center justify-content-between mb-3">
                              <Col md="8">
                                <Button >Change Password</Button>
                              </Col>
                            </Row> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3 ">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("date of birth")}
                        </span>
                        <p className="mb-0">{userDetails.dob}</p>
                      </Col>

                      {/* <Col md="4" className="text-md-right text-left">
                        <Link to="#" className="text-primary">
                          {t("change")}
                        </Link>
                      </Col> */}
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("Phone Number")}
                        </span>
                        <p className="mb-0">
                          {userDetails.phone !== "" ? userDetails.phone : "N/A"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("language")}
                        </span>
                        <p className="mb-0">{t(userDetails.language)}</p>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("Gender")}
                        </span>
                        <p className="mb-0">{t(userDetails.gender)}</p>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("Country")}
                        </span>
                        <p className="mb-0">{t(userDetails.country)}</p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {/* <Row className="align-items-center justify-content-between mb-3">
                      <Col md="8">
                        <Button>Change Password</Button>
                      </Col>
                    </Row> */}
                    <Row className="align-items-center justify-content-between mb-3 user-update-info">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("date of birth")}
                        </span>
                        <br />
                        <input
                          type="date"
                          max={maxDate}
                          value={userInfo.dob}
                          onChange={
                            (e) =>
                              setUserInfo({ ...userInfo, dob: e.target.value }) //setting the formData to the value input of the textfield
                          }
                        />
                      </Col>
                    </Row>
                    {/* phone number */}
                    <Row className="align-items-center justify-content-between mb-3 user-update-info">
                      <Col md="8" className="userSettings-Phone">
                        <span className="text-light font-size-13">
                          {t("Phone Number")}
                        </span>
                        <br />
                        <PhoneInput
                          style={{ paddingTop: "0.5rem" }}
                          placeholder="Enter Phone Number"
                          value={value}
                          onChange={handlePhoneNum}
                        />
                        {/* <input
                          type="tel"
                          placeholder="Enter Phone Number"
                          value={userInfo.phone}
                          onChange={
                            (e) =>
                              setUserInfo({
                                ...userInfo,
                                phone: e.target.value,
                              }) //setting the formData to the value input of the textfield
                          }
                        /> */}
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3 user-update-info">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("language")}
                        </span>
                        <Form.Group>
                          <Dropdown
                            className="gender-dropdown"
                            name="gender"
                            onSelect={handleLang}
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {selectedlang ? selectedlang : "Choose Language"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="user-info-dropdown-menu">
                              <Dropdown.Item eventKey="English">
                                English
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="Deutsch">
                                Deutsch
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="French">
                                French
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="Italiano">
                                Italiano
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="Espanol">
                                Espanol
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="Polski">
                                Polski
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3 user-update-info">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("Gender")}
                        </span>
                        {/* <p className="mb-0">{userDetails.gender}</p> */}
                        <Form.Group>
                          <Dropdown
                            className="gender-dropdown"
                            name="gender"
                            onSelect={handleGender}
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {selectedGender
                                ? selectedGender
                                : "Choose Gender"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="user-info-dropdown-menu">
                              <Dropdown.Item eventKey="Male">
                                Male
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="Female">
                                Female
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="Other">
                                Other
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between mb-3 user-update-info">
                      <Col md="8">
                        <span className="text-light font-size-13">
                          {t("Country")}
                        </span>
                        <Form.Group>
                          <Dropdown
                            className="gender-dropdown"
                            onSelect={handleSelect}
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {selectedCountry
                                ? selectedCountry
                                : "Choose Country"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="user-info-dropdown-menu">
                              <div className="search-container">
                                <input
                                  style={{
                                    color: "black",
                                    padding: "0px 7px",
                                  }}
                                  type="text"
                                  placeholder="Search..."
                                  value={searchText}
                                  onChange={handleSearchChange}
                                />
                              </div>

                              {filteredOptions.map((option) => (
                                <Dropdown.Item eventKey={option}>
                                  {option}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="align-items-center justify-content-between mb-3">
                  <Col md="8">
                    {/* <Button >Change Password</Button> */}
                    <Button onClick={openModal}>{t("Change Password")}</Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="8">
              <div className="sign-user_card">
                <h5 className="mb-3 mt-4 pb-3 a-border">
                  {t("Two-Factor Authentication")}
                </h5>
                <Row className="justify-content-between mb-3">
                  <Col md="8" className="r-mb-15">
                    <p>
                      {t(
                        "Setup your account to receive authentication codes via sms"
                      )}
                    </p>
                  </Col>
                  <div className="col-md-4 text-md-right text-left">
                    <div className="fa-btn">
                      <BootstrapSwitchButton
                        checked={switchState}
                        onlabel={t("Active")}
                        offlabel={t("Inactive")}
                        onChange={handleSwitchChange}
                        width={120}
                        offstyle="outline-secondary"
                      // onSwitchChange={handleSwitchChange}
                      />
                    </div>
                  </div>
                  <Enable2FA
                    show={show}
                    setShow={setShow}
                    setSwitchState={setSwitchState}
                    updateData={handleUpdateData}
                  />
                  <Disable2FA
                    show={inactiveshow}
                    setShow={setInactiveShow}
                    setSwitchState={setSwitchState}
                    updateData={handleUpdateData}
                    userDetailsData={userDetails}
                  />
                  {/* <UserFreeaccount updateData={handleUpdateData} /> */}
                </Row>

                <h5 className="mb-3 mt-4 pb-3 a-border">{t("plan details")}</h5>
                {userDetails?.subscription === "paid" && expirationDate > Date.now() ? (
                  <>
                    {" "}
                    <Row className="justify-content-between mb-3">
                      <Col md="6">
                        <p>{t("premium")}</p>
                      </Col>
                      <Col md="6" className="text-md-right text-left">
                        <Button
                          className="btn btn-typ btn-hover"
                          style={{ padding: "6px 7px", opacity: 1 }}
                          onClick={handleCancelSubscription}
                        >
                          {t("cancel membership")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row className="justify-content-between mb-3">
                    <Col md="6">
                      <p>{t("Free")}</p>
                    </Col>
                    <Col md="6" className="text-md-right text-left">
                      <Button
                        className="btn btn-typ btn-hover"
                        style={{ padding: "6px 7px", opacity: 1 }}
                        onClick={handleModalChange}
                      >
                        {t("Upgrade to Premium")}
                      </Button>
                    </Col>
                  </Row>
                )}

                <UserFreeAccountSetting
                  updateData={handleUpdateData}
                  showModal={showSubscriptionModal}
                  setShowModal={setShowSubscriptionModal}
                  packageDetails={packageDetails}
                />
                <>
                  {checkCardConnection ? (
                    <>
                      <h5 className="mb-3 mt-4 pb-3 a-border">
                        {t("Card Details")}
                      </h5>
                      <Row className="justify-content-between mb-3">
                        <Col
                          md="6"
                          sm="6"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "0.5rem",
                          }}
                        >
                          {/* <FontAwesomeIcon icon="fa-brands fa-cc-visa" /> */}
                          <img
                            src={
                              userDetails.brand === "Visa" ? visa : mastercard
                            }
                            alt="card"
                          />
                          {/* <FontAwesomeIcon
                icon={faCcVisa}
                style={{ color: "blue" }}
              /> */}
                          <div>
                            <p className="mb-0">
                              {t("This account is billed to")}
                              <b>
                                &nbsp;
                                {userDetails.brand}{" "}
                                <span
                                  style={{
                                    fontWeight: "200",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {t("card ending in")}
                                </span>
                                &nbsp;
                                {userDetails.last4}
                              </b>
                            </p>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm="6"
                          className="text-md-right text-left"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            gap: "0.5rem",
                          }}
                        >
                          <Button
                            className="btn"
                            style={{
                              padding: "6px 7px",
                              backgroundColor: "transparent",
                              borderColor: "var(--iq-body-text)",
                            }}
                            onClick={handleRemoveCard}
                          >
                            {t("Remove Card")}
                          </Button>
                          <Button
                            className="btn"
                            style={{
                              padding: "6px 7px",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => setChangeCard(true)}
                          >
                            {t("Change Card")}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <h5 className="mb-3 mt-4 pb-3 a-border">
                        {t("Card Details")}
                      </h5>
                      <Row className="justify-content-between mb-3">
                        <Col
                          md="6"
                          sm="6"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "0.5rem",
                          }}
                        >
                          {/* <img
                            src={userDetails.brand === "Visa" ? visa : mastercard}
                            alt="card"
                          /> */}
                          <div>
                            <p className="mb-0">
                              <b>{t("No Card Attached")}</b>
                            </p>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm="6"
                          className="text-md-right text-left"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            gap: "0.5rem",
                          }}
                        >
                          <Button
                            className="btn"
                            style={{
                              padding: "6px 7px",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => setChangeCard(true)}
                          >
                            {t("Add Card")}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
                <Modal
                  className="deleteModal"
                  show={removeCard}
                  onHide={() => setRemoveCard(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("Confirmation")}</Modal.Title>
                  </Modal.Header>
                  {!cardRemoved ? (
                    <>
                      {" "}
                      <Modal.Body>
                        {t("Are you sure you want to remove your credit card?")}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-primary delBtn"
                          onClick={() => setRemoveCard(false)}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          className="btn btn-primary delBtn"
                          onClick={handleRemoveCard}
                        >
                          {t("Remove")}
                        </button>
                      </Modal.Footer>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Modal.Body>{t("Card Removed Successfully!")}</Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-primary delBtn"
                          onClick={() => {
                            setRemoveCard(false);
                            setCardRemoved(false);
                          }}
                        >
                          {t("Close")}
                        </button>
                      </Modal.Footer>
                    </>
                  )}
                </Modal>
                <Modal
                  show={changeCard}
                  className={"stripe-Modal"}
                  size="md"
                  centered={true}
                  onHide={() => {
                    setChangeCard(false);
                  }}
                >
                  <Stripe
                    updateData={handleUpdateData}
                    message={t("Card Changed Successfully!")}
                    subscription={""}
                  />
                </Modal>
                {/* billing */}
                {billing?.length > 0 && (
                  <h5 className="mb-3 mt-4 pb-3 a-border">
                    {t("billing details")}
                  </h5>
                )}
                {billing?.length > 0 && (
                  <Row className="justify-content-between mb-3">
                    <Col md="8" className="r-mb-15">
                      <p>
                        {t("You are subscribed up to ")} {getNextBillingDate()}.
                      </p>
                    </Col>
                    {/* <div className="col-md-4 text-md-right text-left">
                    <Link to="#" className="text-primary">
                      {t("update payment info")}
                    </Link>
                  </div> */}
                  </Row>
                )}
                {billing?.length > 0 && (
                  <div>
                    <h6 className="mb-3 mt-4 ">{t("Billing History")}</h6>
                    <Row className="justify-content-between mb-3 bill-history">
                      <Col md="12" className="r-mb-15">
                        <div className="table-view">
                          <table
                            className="data-tables table movie_table "
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>{t("Date")}</th>

                                <th>{t("Type")}</th>
                                <th>{t("Payment Method")}</th>
                                <th>{t("Total")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {billing?.map((data) => (
                                <tr>
                                  <td>{generalDateFormat(data?.createdAt)}</td>
                                  <td>{data.type}</td>
                                  <td>{data?.payment_method}</td>
                                  <td>${data?.amount}.00</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          {/* fav slider */}
          {favList && favList?.length > 0 && (
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="main-title">{t("my favourites")}</h4>
                  <Link className="iq-view-all" to="/FavouriteList">
                    {t("View More")}
                  </Link>
                </div>
                <div id="favorites-contens">
                  <div id="prev" className="swiper-button swiper-button-prev">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div id="next" className="swiper-button swiper-button-next">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                  <Swiper
                    navigation={{
                      prevEl: "#prev",
                      nextEl: "#next",
                    }}
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 4 },
                      1400: { slidesPerView: 4 },
                    }}
                    loop={false}
                    slidesPerView={4}
                    spaceBetween={20}
                    as="ul"
                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                  >
                    {favList?.map((item) => (
                      <SwiperSlide as="li">
                        <Link
                          to={
                            item?.category === "movie" ||
                              item?.category === "video"
                              ? `/video-details/${source}/${item?.general_content?._id}`
                              : `/show-info/${item?.show?._id}`
                          }
                        >
                          <div className="block-images position-relative slider-img-main">
                            <div className="img-box">
                              <img
                                src={
                                  item?.show?.thumbnail?.banner_thumbnail_url ||
                                  item?.general_content?.thumbnail
                                    ?.banner_thumbnail_url
                                }
                                className="img-fluid slider-img"
                                alt=""
                              />
                              <FaTrash
                                className="cross-icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDeleteFav(item._id);
                                }}
                              />
                            </div>
                            {/* showing on the top */}
                            <div className="card-slider-badge">
                              <div className="badge badge-secondary p-1 mr-2">
                                {(item?.category === "movie" ||
                                  item?.category === "video") &&
                                  convertSecondsToHoursMinutes(
                                    item?.general_content?.media?.duration.toFixed(
                                      2
                                    )
                                  )}
                              </div>
                            </div>
                            <div className="block-description ">
                              <div className="card-slider-content">
                                {item?.category === "movie" ||
                                  item?.category === "video" ? (
                                  <h6 className=" title-desc">
                                    {item?.general_content?.media?.title}
                                  </h6>
                                ) : (
                                  <h6 className=" title-desc">
                                    {item?.show?.title}
                                  </h6>
                                )}
                              </div>
                              {/* <div className="card-slider-desc">
                              <span>
                                {limitTextToWords(data?.media?.description, 12)}
                              </span>
                            </div> */}
                              <div className="block-social-info-button myFavList">
                                <li className="share">
                                  <div className="hover-buttons">
                                    {item?.category === "movie" ||
                                      item?.category === "video" ? (
                                      <Link
                                        to={`/video-details/${source}/${item?.general_content?._id}`}
                                        className="btn btn-hover iq-button"
                                      >
                                        <i
                                          className="fa fa-play mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Play now")}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/show-info/${item?.show?._id}`}
                                        className="btn btn-hover iq-button"
                                      >
                                        <i
                                          className="fa fa-play mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Play now")}
                                      </Link>
                                    )}
                                  </div>
                                </li>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* <div className="block-images position-relative slider-img-main">
                          <div className="img-box">
                            <img
                              src={
                                item?.show?.thumbnail?.banner_thumbnail_url ||
                                item?.general_content?.thumbnail
                                  ?.banner_thumbnail_url
                              }
                              className="img-fluid  slider-img"
                              alt={item?.show?.title || ""}
                            />
                            <FaTrash
                              className="cross-icon"
                              onClick={() => handleDelete(item._id)}
                            />
                          </div>
                          <div className="card-slider-badge">
                            <div className="badge badge-secondary p-1 mr-2">
                              {(item?.category === "movie" ||
                                item?.category === "video") &&
                                convertSecondsToHoursMinutes(
                                  item?.general_content?.media?.duration.toFixed(
                                    2
                                  )
                                )}
                            </div>
                          </div>
                          <div className="block-description ">
                            <div className="card-slider-content">
                              {item?.category === "movie" ||
                              item?.category === "video" ? (
                                <h6 className="iq-title title-desc">
                                  {item?.general_content?.media?.title}
                                </h6>
                              ) : (
                                <h6 className="iq-title title-desc">
                                  {item?.show?.title}
                                </h6>
                              )}
                            </div>

                            <div className="block-social-info-button">
                              <li className="share">
                                <div className="hover-buttons">
                                  {item?.category === "movie" ||
                                  item?.category === "video" ? (
                                    <Link
                                      to={`/video-details/${source}/${item?.general_content?._id}`}
                                      className="btn btn-hover iq-button"
                                    >
                                      <i
                                        className="fa fa-play mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  ) : (
                                    <Link
                                      to={`/show-info/${item?.show?._id}`}
                                      className="btn btn-hover iq-button"
                                    >
                                      <i
                                        className="fa fa-play mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  )}
                                </div>
                              </li>
                            </div>
                          </div>
                        </div> */}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Col>
            </Row>
          )}

          {/* watchlist slider */}
          {watchList && watchList?.length > 0 && (
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="main-title">{t("my watchlist")}</h4>
                  <Link className="iq-view-all" to="/WatchList">
                    {t("View More")}
                  </Link>
                </div>
                <div id="suggestede-contens">
                  <div id="prev1" className="swiper-button swiper-button-prev">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div id="next1" className="swiper-button swiper-button-next">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                  <Swiper
                    navigation={{
                      prevEl: "#prev1",
                      nextEl: "#next1",
                    }}
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 4 },
                      1400: { slidesPerView: 4 },
                    }}
                    loop={false}
                    slidesPerView={4}
                    spaceBetween={20}
                    as="ul"
                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                  >
                    {watchList?.map((item) => (
                      <SwiperSlide key={item._id} as="li">
                        <Link
                          to={
                            item?.category === "movie" ||
                              item?.category === "video"
                              ? `/video-details/${source}/${item?.general_content?._id}`
                              : `/show-info/${item?.show?._id}`
                          }
                        >
                          <div className="block-images position-relative slider-img-main">
                            <div className="img-box">
                              <img
                                src={
                                  item?.show?.thumbnail?.banner_thumbnail_url ||
                                  item?.general_content?.thumbnail
                                    ?.banner_thumbnail_url
                                }
                                className="img-fluid  slider-img"
                                alt={item?.show?.title || ""}
                              />
                              <FaTrash
                                className="cross-icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDelete(item._id);
                                }}
                              />
                            </div>
                            <div className="card-slider-badge">
                              <div className="badge badge-secondary p-1 mr-2">
                                {(item?.category === "movie" ||
                                  item?.category === "video") &&
                                  convertSecondsToHoursMinutes(
                                    item?.general_content?.media?.duration.toFixed(
                                      2
                                    )
                                  )}
                              </div>
                            </div>
                            <div className="block-description ">
                              <div className="card-slider-content">
                                {item?.category === "movie" ||
                                  item?.category === "video" ? (
                                  <h6 className="iq-title title-desc">
                                    {item?.general_content?.media?.title}
                                  </h6>
                                ) : (
                                  <h6 className="iq-title title-desc">
                                    {item?.show?.title}
                                  </h6>
                                )}
                              </div>

                              <div className="block-social-info-button">
                                <li className="share">
                                  <div className="hover-buttons">
                                    {item?.category === "movie" ||
                                      item?.category === "video" ? (
                                      <Link
                                        to={`/video-details/${source}/${item?.general_content?._id}`}
                                        className="btn btn-hover iq-button"
                                      >
                                        <i
                                          className="fa fa-play mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Play now")}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/show-info/${item?.show?._id}`}
                                        className="btn btn-hover iq-button"
                                      >
                                        <i
                                          className="fa fa-play mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Play now")}
                                      </Link>
                                    )}
                                  </div>
                                </li>
                              </div>
                            </div>

                            {/* <div className="block-description">
                            <h6 className="iq-title">
                              <Link to="/episode">
                                {item?.show?.title ||
                                  item?.general_content?.media?.title ||
                                  ""}
                              </Link>
                            </h6>
                            <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                              <div className="badge badge-secondary p-1 mr-2">
                                {convertSecondsToHoursMinutes(
                                  item?.show?.title ||
                                    item?.general_content?.media?.duration ||
                                    ""
                                )}
                              </div>
                             
                            </div>
                            <div className="hover-buttons">
                              <Link
                                to="/episode"
                                role="button"
                                className="btn btn-typ btn-hover iq-button"
                              >
                                <i
                                  className="fa fa-play mr-1"
                                  aria-hidden="true"
                                ></i>
                                Play Now
                              </Link>
                            </div>
                          </div> */}
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Col>
            </Row>
          )}

          {/* history slider */}

          <Row>
            <Col xs="12" sm="12" md="12" lg="12" className="overflow-hidden">
              {data?.length > 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="main-title pb-3">{t("my history")}</h4>
                    <Link className="iq-view-all" to="/history">
                      View More
                    </Link>
                  </div>
                  <div className="">
                    <div id="upcoming-contents">
                      <ul className="list-inline mb-0 iq-rtl-direction row">
                        {data?.map((data) => (
                          <li
                            className="all-card-container slide-item  mb-4 col-lg-3 col-md-4 col-sm-6"
                            key={data._id}
                          >
                            <Link
                              to={
                                data?.category === "movie" ||
                                  data?.category === "video"
                                  ? `/video-details/${source}/${data?.media?.general_content?._id}`
                                  : `/show-info/${data?.show?._id}`
                              }
                            >
                              <div className="block-images position-relative">
                                <div className="card-slider-badge">
                                  <div className="badge badge-secondary p-1 mr-2">
                                    {data?.category === "movie" ||
                                      data?.category === "video"
                                      ? convertSecondsToHoursMinutes(
                                        data?.media?.duration.toFixed(2)
                                      )
                                      : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                        ? "Episode"
                                        : "Episodes"
                                      }`}
                                  </div>
                                </div>
                                <div className="img-box">
                                  <img
                                    src={
                                      data?.media?.general_content?.thumbnail
                                        ?.banner_thumbnail_url
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="block-description">
                                  <h6 className="iq-title all-title-desc">
                                    {data?.media?.title}
                                  </h6>
                                  <div className="card-slider-desc">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: limitTextToWords(
                                          data?.media?.description,
                                          12
                                        ),
                                      }}
                                    ></span>
                                  </div>
                                  <div className="hover-buttons">
                                    <Link
                                      to={`/video-details/app/${data?.media?.general_content?._id}`}
                                      role="button"
                                      className="btn btn-hover iq-button btn-typ"
                                    >
                                      <i
                                        className="fa fa-play mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  </div>
                                </div>
                                {/* <div className="block-social-info">
                              <ul className="list-inline p-0 m-0 music-play-lists">
                                <li className="share">
                                  <span>
                                    <i className="ri-share-fill"></i>
                                  </span>
                                  <div className="share-box">
                                    <div className="d-flex align-items-center">
                                      <a
                                        href={generateLinkedinShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-linkedin-fill"></i>
                                      </a>
                                      <a
                                        href={generateInstagramShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-instagram-fill"></i>
                                      </a>
                                      <a
                                        href={generateSnapchatShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-snapchat-fill"></i>
                                      </a>
                                      <a
                                        href={generateTikTokShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-tiktok-fill"></i>
                                      </a>
                                      <a
                                        href={generateFacebookShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-facebook-fill"></i>
                                      </a>
                                      <a
                                        href={generateTwitterShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-twitter-fill"></i>
                                      </a>
                                      <a
                                        href={generateWhatsappShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico iq-copy-link"
                                        tabIndex="0"
                                      >
                                        <i className="ri-whatsapp-fill"></i>
                                      </a>
                                      <a
                                        href={generatePinterestShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-pinterest-fill"></i>
                                      </a>
                                      <a
                                        href={generateTelegramShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-telegram-fill"></i>
                                      </a>
                                      <a
                                        href={generateRedditShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-reddit-fill"></i>
                                      </a>
                                      <a
                                        href={generateQuoraShareUrl(
                                          data,
                                          source,
                                          data?.category
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="share-ico"
                                        tabIndex="0"
                                      >
                                        <i className="ri-quora-fill"></i>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    <i
                                      className={`ri-heart-fill ${
                                        favoriteItems.includes(data?._id)
                                          ? "red-heart"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleFavClick(
                                          data?._id,
                                          data?.category
                                        )
                                      }
                                      title={
                                        favoriteItems.includes(data?._id)
                                          ? "Remove From Favourite"
                                          : "Add To Favourite"
                                      }
                                    ></i>
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i
                                      className={`ri-add-line ${
                                        watchListItems.includes(data?._id)
                                          ? "red-heart"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleWatchList(
                                          data?._id,
                                          data?.category
                                        )
                                      }
                                      title={
                                        watchListItems.includes(data?._id)
                                          ? "Remove From WatchList"
                                          : "Add To WatchList"
                                      }
                                    ></i>
                                  </span>
                                </li>
                              </ul>
                            </div> */}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserAccountSetting;
