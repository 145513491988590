import React, { useState, useEffect } from "react";
import axios from "axios";

function Search() {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        fetchSearchResults();
      }
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSearchResults = async () => {
    try {
      const languageCode = "en";
      const url = `${process.env.REACT_APP_API}/api/home/get-search-content/${languageCode}/${searchTerm}`;
      const response = await axios.get(url);
    } catch (error) {
      // console.error("Error fetching search results:", error);
    }
  };

  return (
    <input
      autoFocus
      type="text"
      autoComplete="off"
      className="live-search-field"
      placeholder="Search here..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  );
}

export default Search;
