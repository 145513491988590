import { Switch, Route } from "react-router-dom";
import Layout1 from "../layouts/backend/layout1";

import Loginsignup from "../views/backend/pages/auth/login_signup";
import recoverPswd from "../views/backend/pages/auth/recover-pswd";

const LayoutsRoute = (props) => {
  return (
    <Switch>
      <Route path="/login" component={Loginsignup} />
      <Route path="/recover-password" component={recoverPswd} />
      <Route path="/" component={Layout1} />
    </Switch>
  );
};

export default LayoutsRoute;
