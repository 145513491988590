// const currentUser = JSON.parse(localStorage.getItem("session"));
// const currentUserId = currentUser?._id;

// send in parameter currentUserId

export const postBankDonateApi = async (donationAmount, currentUserId) => {
  try {
    const url = `${process.env.REACT_APP_API}/api/user/bank/donation/${currentUserId}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: donationAmount }),
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (data.status === "200" || data.status === 200) {
      // Payment succeeded, handle the paymentSaved object
      const paymentSaved = data.paymentSaved;
      return paymentSaved;
    } else {
      //('Error:', data.error); // Assuming the response has an "error" property
      return null;
    }
  } catch (error) {
    //('Error fetching data:', error);
    return null;
  }
};
