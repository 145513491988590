import axios from "axios";

const fetchRandomContent = async (currentSelectedLanguage) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/home/get-random-content/${currentSelectedLanguage}`
    );
    return response.data;
  } catch (error) {
    // console.error("Error fetching data:", error);
    return null;
  }
};

export default fetchRandomContent;
