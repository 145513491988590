import React from 'react'
import ReactPlayer from "react-player";
import Trailer from './../../../assets/video/TEASER_SABLIER_V3_CARRE.mp4'

function ComingSoonVideo() {
  return (
       <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ReactPlayer
          url={Trailer}  // Remplacez cela par le chemin de votre vidéo
          width="100%"
          height="100%"
          controls={true}
          playing={false}
        />
      </div>
  )
}

export default ComingSoonVideo