import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { Modal } from "react-bootstrap";

import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  MainSliderApi,
  TrendingApi,
  RecommendedApi,
  SuggestedApi,
} from "../../../Services/SliderApi";
import { currentUserToken } from "../home/home";
import {
  podCastMainSliderApi,
  podCastListApi,
  podCastLatestSliderApi,
  podCastTopRatedApi,
  podCastShowsByGenre,
  podCastUpComingSliderApi,
} from "./../../../Services/podCastApi";
import {
  limitTextToWords,
  convertSecondsToHoursMinutes,
  assignAdToTarget,
  shuffleArray,
  handleAdClicked,
  handleAnchorClick,
} from "../../../Services/generalFunctions/generalFunctions";
import noData from "../../../assets/images/noData.jpg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../home/FavWatch";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  genreFacebookShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
} from "../home/ShareFunction";
import VideoDescriptionModal from "../../../components/partials/UI/VideoDescriptionModal";
import ShowDescriptionModal from "../../../components/partials/UI/ShowDescriptionModal";
import Loader from "../../../components/Loader";
import { adClickApi, fetchAdsDataPodcast } from "../../../Services/adsApi";
import AdsModal from "../../../components/partials/UI/AdsModal";
import ReactPlayer from "react-player";

// install Swiper modules
SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
]);

export const activeSlideStyles = {
  transform: "scale(1)",
  // paddingTop: "14px",
  background: "rgba(50, 50, 50, 0.7) !important",
  transition: "all 0.6s ease 0s",
};
const PodCast = () => {
  const [podCastMainSlider, setPodCastMainSlider] = useState([]);
  const [podCastLatest, setPodCastLatest] = useState([]);
  const [podCastUpComing, setPodCastUpComing] = useState([]);
  const [topRatedData, setTopRatedData] = useState([]);
  const [podCastGenreData, setPodCastGenreData] = useState([]);
  const [podShowListData, setPodShowListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [toggler1, setToggler1] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isWatchListClicked, setIsWatchListClicked] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const source = "app";
  const currentSelectedLanguage = localStorage.getItem("lang");

  // to redirect to the home page when language changes
  const location = useLocation();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (
      !isInitialRender.current &&
      currentSelectedLanguage &&
      location.pathname.includes("/podCast")
    ) {
      history.push("/");
    }
    isInitialRender.current = false;
  }, [currentSelectedLanguage, history, location]);

  const [descModal, setDescModal] = useState(false);
  const handleClose1 = () => setDescModal(false);
  const handleShow1 = () => setDescModal(true);

  // for description modal
  const [hoveredItem, setHoveredItem] = useState({});
  const modalTimerRef = useRef(null);

  const handleMouseEnter = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItem(item);

    modalTimerRef.current = setTimeout(() => {
      setDescModal(true);
    }, 3000); // 5 seconds
  };

  const handleMouseLeave = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRef.current);
    // setHoveredItem(null);
  };

  const handleModalClose = () => {
    // Close the modal
    setDescModal(false);
  };

  // load more button
  const [load, setLoad] = useState(4);

  // useEffect(() => {
  //   const detectDevTools = () => {
  //     const r = /./;
  //     r.toString = function () {
  //       alert("sd");
  //     };
  //     console.log("%c", r);
  //   };

  //   detectDevTools();
  // }, []);

  useEffect(() => {
    setLoader(true);
    podCastMainSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.allSliders && data.allSliders.length > 0) {
          setPodCastMainSlider(data.allSliders);
        } else {
          setPodCastMainSlider([]);
        }
        setLoader(false);
      })
      .catch((error) => {
        // console.log(error, "PodCast Main Slider error");
      });
  }, [currentSelectedLanguage]);
  //tvshow list
  useEffect(() => {
    podCastListApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.allTvShows && data.allTvShows.length > 0) {
          setPodShowListData(data.allTvShows);
        } else {
          setPodShowListData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "AllTvShows List error");
      });
  }, [currentSelectedLanguage]);

  // Upcoming data
  useEffect(() => {
    podCastUpComingSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setPodCastUpComing(data.upcomingContent);
        } else {
          setPodCastUpComing([]);
        }
      })
      .catch((error) => {
        // console.log(error, "TrendingApiData Slider error");
      });
  }, [currentSelectedLanguage]);

  // Latest data
  useEffect(() => {
    podCastLatestSliderApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setPodCastLatest(data.upcomingContent);
        } else {
          setPodCastLatest([]);
        }
      })
      .catch((error) => {
        // console.log(error, "TrendingApiData Slider error");
      });
  }, [currentSelectedLanguage]);

  useEffect(() => {
    podCastTopRatedApi(currentSelectedLanguage)
      .then((data) => {
        if (data && data?.general_contents && data?.general_contents?.length > 0) {
          setTopRatedData(data?.general_contents);
          console.log("checking", data?.generalContents)
        } else {
          setTopRatedData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "TrendingApiData Slider error");
      });
  }, [currentSelectedLanguage]);

  console.log("lol", topRatedData)
  useEffect(() => {
    podCastShowsByGenre(currentSelectedLanguage)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setPodCastGenreData(data.upcomingContent);
        } else {
          setPodCastGenreData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, [currentSelectedLanguage]);
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex);
  };

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );

  function handleFavClick(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    if (favoriteItems.includes(id)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(id);
      toast.info(t("Content Removed From Favorite list!"), {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== id));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, id]);
          } else {
            toast.error(t("Error occurred at Fav List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  function handleWatchList(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Content Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at Watch List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }

    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item.general_content_id);
        setData(data);
      } catch (error) {
        // console.log(`Error fetching data: ${error}`);
      }
    };

    const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
    fetchData(watchListUrl, setWatchListItems);

    const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
    fetchData(favoriteItemsUrl, setFavoriteItems);
  }, [currentUserId]);

  // AD Modal
  const [hoveredItemAD, setHoveredItemAD] = useState({});
  const [showADModal, setShowADModal] = useState(false);
  const handleCloseADModal = () => setShowADModal(false);
  const handleShowADModal = () => setShowADModal(true);
  const modalTimerRefAD = useRef(null);

  const handleMouseEnterAD = (item) => {
    // Set the hovered item
    setHoveredItemAD(item);
    // Clear the previous timer, if any
    clearTimeout(modalTimerRefAD.current);

    if (item?.autoplay === "instant") {
      // Autoplay with a delay of 1 second
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 500); // half a second
    } else if (item?.autoplay === "3 seconds") {
      // Autoplay with a delay of 3 seconds
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 3000);
    } else if (item?.autoplay === "off") {
      // No autoplay
      setShowADModal(false);
    }
  };

  const handleMouseLeaveAD = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRefAD.current);
    // setHoveredItem(null);
  };

  const handleModalCloseAD = () => {
    // Close the modal
    setShowADModal(false);
  };

  // AD Data
  const [adsData, setAdsData] = useState({});
  useEffect(() => {
    // Call the fetchAdsDataTvShow function
    const fetchData = async () => {
      try {
        const data = await fetchAdsDataPodcast(currentSelectedLanguage);
        setAdsData(data?.adsList);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [adsCopyCopy, setAdsCopyCopy] = useState([]);
  const [topRatedDataCopy, setTopRatedDataCopy] = useState([]);
  const [upComingDataCopy, setUpComingDataCopy] = useState([]);
  const [latestDataCopy, setLatestDataCopy] = useState([]);

  console.log(adsData, "ads");
  console.log(podCastUpComing, "pod")

  useEffect(() => {

    const adsCopyCopyTemp = adsData.length > 0 ? [...adsData] : [];
    const topRatedDataCopyTemp = [...topRatedData];
    const upComingDataCopyTemp = [...podCastUpComing];
    const latestDataCopyTemp = [...podCastLatest];
    console.log("working");

    // Assign ads from the adsCopyCopy to topRatedDataCopy and upComingDataCopy
    assignAdToTarget(adsCopyCopy, topRatedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, upComingDataCopyTemp);
    assignAdToTarget(adsCopyCopy, latestDataCopyTemp);
    assignAdToTarget(adsCopyCopy, topRatedDataCopyTemp);
    assignAdToTarget(adsCopyCopy, upComingDataCopyTemp);
    assignAdToTarget(adsCopyCopy, latestDataCopyTemp);

    // Update the state variables to reflect the changes
    setAdsCopyCopy(shuffleArray(adsCopyCopyTemp));
    setTopRatedDataCopy(shuffleArray(topRatedDataCopyTemp));
    setUpComingDataCopy(shuffleArray(upComingDataCopyTemp));
    setLatestDataCopy(shuffleArray(latestDataCopyTemp));

  }, [adsData, topRatedData, podCastUpComing, podCastLatest]);


  return (
    <>
      <Modal
        show={descModal}
        onHide={handleClose1}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ShowDescriptionModal item={hoveredItem} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showADModal}
        onHide={handleCloseADModal}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <AdsModal item={hoveredItemAD} handleClose={handleCloseADModal} />
        </Modal.Body>
      </Modal>
      {loader ? (
        <div style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          <section
            id="tvshow"
            className="iq-main-slider "
            style={{ overflow: "hidden", paddingTop: "5rem" }}
          >
            <div id="prev" className="swiper-button swiper-button-prev">
              <i className="ri-arrow-left-s-line"></i>
            </div>
            <div id="next" className="swiper-button swiper-button-next">
              <i className="ri-arrow-right-s-line"></i>
            </div>
            <Swiper
              slidesPerView={2}
              navigation={{
                prevEl: "#prev",
                nextEl: "#next",
              }}
              loop={true}
              centeredSlides={true}
              autoplay={{ delay: 4000 }}
              id="tvshows-slider"
              className="iq-rtl-direction"
              onSlideChange={handleSlideChange}
              initialSlide={1}
              breakpoints={{
                320: { slidesPerView: 1 },
                720: { slidesPerView: 2 },
              }}
            >
              {podCastMainSlider?.map((mainSlider, index) => (
                <SwiperSlide
                  key={index}
                  className={`mt-5 ${activeSlideIndex === index ? "active" : ""
                    }`}
                  style={{
                    marginTop: "6rem",
                    ...(activeSlideIndex === index ? activeSlideStyles : {}),
                  }}
                >
                  <div
                    style={{
                      minWidth: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="shows-img">
                      <img
                        src={mainSlider?.show?.thumbnail?.banner_thumbnail_url}
                        className="w-100 "
                        alt=""
                      />
                      <Col
                        xl="9"
                        lg="12"
                        md="12"
                        sm="12"
                        style={{
                          position: "absolute",
                          zIndex: "10000",
                          bottom: "10%",
                        }}
                        className="new-video-slider"
                      >
                        <h1
                          className="slider-text big-title title text-uppercase heading-length"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          {mainSlider?.category === "movie" ||
                            mainSlider?.category === "video"
                            ? mainSlider?.general_content?.media?.title
                            : mainSlider?.show?.title}
                        </h1>
                        <div className="d-flex flex-wrap align-items-center">
                          <p
                            data-iq-gsap="onStart"
                            data-iq-position-y="80"
                            data-iq-delay="0.8"
                            className="paraOverflow"
                            dangerouslySetInnerHTML={{
                              __html:
                                mainSlider?.category === "movie" ||
                                  mainSlider?.category === "video"
                                  ? mainSlider?.general_content?.media
                                    ?.description
                                  : mainSlider?.show?.description,
                            }}
                          >
                            {/* {mainSlider?.category === "movie" ||
                        mainSlider?.category === "video"
                          ? mainSlider?.general_content?.media?.description
                          : mainSlider?.show?.description} */}
                          </p>
                        </div>
                        <div
                          className="trending-list"
                          data-wp_object-in="fadeInUp"
                          data-delay-in="1.2"
                        >
                          {mainSlider?.show?.genre?.length > 0 && (
                            <div className="text-primary title genres">
                              {t("Genres")}
                              {mainSlider?.show?.genre?.map(
                                (genreObj, index) => (
                                  <span
                                    style={{
                                      color: "white",
                                      marginLeft: "5px",
                                    }}
                                    key={genreObj.name}
                                  >
                                    {genreObj.name}
                                    {index !==
                                      mainSlider?.show?.genre?.length - 1 &&
                                      ","}
                                  </span>
                                )
                              )}
                            </div>
                          )}
                          {mainSlider?.show?.media?.seo_tags?.length > 0 && (
                            <div className="text-primary title tag">
                              {t("tags").toUpperCase()}
                              {mainSlider?.show?.media?.seo_tags?.map(
                                (tag, index) => (
                                  <span className="text-body" key={tag}>
                                    {tag}
                                    {index !==
                                      mainSlider?.show?.media?.seo_tags
                                        ?.length -
                                      1 && ","}
                                  </span>
                                )
                              )}
                            </div>
                          )}
                        </div>

                        <div
                          className="buttons-container r-mb-23 video-page-btn"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          <Link
                            to={`/show-info/${mainSlider?.show?._id}`}
                            className="btn btn-hover "
                            style={{ marginTop: "5px" }}
                          >
                            <i
                              className="fa fa-solid fa-play fa-beat mr-2"
                              aria-hidden="true"
                            ></i>
                            {t("Play now")}
                          </Link>
                        </div>
                      </Col>
                      {/* <div className="shows-content">
                    <h1
                      className="slider-text-video big-title title text-uppercase"
                      data-iq-gsap="onStart"
                      data-iq-position-x="-200"
                      style={{
                        whiteSpace: "wrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "500px",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                      }}
                    >
                      {mainSlider?.show?.title}
                    </h1>
                    <div className="movie-time para-play d-flex align-items-start justify-content-between">
                      <p
                        data-iq-gsap="onStart"
                        data-iq-position-y="80"
                        data-iq-delay="0.8"
                        className="mainSlider-Movie paraOverflow"
                      >
                        {mainSlider?.show?.description}
                      </p>
                      
                    </div>

                    <div
                      className="d-flex r-mb-23"
                      data-iq-gsap="onStart"
                      data-iq-position-y="80"
                      data-iq-delay="0.8"
                    >
                      <Link
                        to={`/show-info/${mainSlider?.show?._id}`}
                        className="btnMain btn-hover iq-button"
                      >
                        <i className="fa fa-play mr-2" aria-hidden="true"></i>
                        {t("Play now")}
                      </Link>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
          <ToastContainer
            toastStyle={{
              background: "black",
              color: "white",
            }}
          />
          <div className="main-content">
            {upComingDataCopy.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Upcoming Podcast")}</h4>
                        <Link className="iq-view-all" to="/">
                          {/* View All */}
                        </Link>
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev1"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next1"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          slidesPerView={4}
                          spaceBetween={20}
                          navigation={{
                            prevEl: "#prev1",
                            nextEl: "#next1",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {upComingDataCopy?.map((data) => (
                            <SwiperSlide as="li">
                              <Link
                                to={
                                  data?.category !== "ads"
                                    ? `/show-info/${data?._id}`
                                    : null
                                }
                                onClick={(event) =>
                                  handleAdClicked(event, data)
                                }
                              >
                                <div
                                  className="block-images position-relative slider-img-main"
                                  onMouseEnter={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseEnter(data);
                                    } else {
                                      handleMouseEnterAD(data);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseLeave();
                                    } else {
                                      handleMouseLeaveAD();
                                    }
                                  }}
                                >
                                  <div className="img-box">
                                    {/* Only render the video if the media category is "movie" */}
                                    {data?.category === "podcast" ? (
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    ) : data?.media_id !== "" ? (
                                      <ReactPlayer
                                        className="img-fluid slider-img"
                                        url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                        width="100%"
                                        height="100%"
                                        pip={false}
                                        controls={false}
                                        playing={true}
                                        muted={true}
                                        loop={true}
                                      />
                                    ) : (
                                      <img
                                        src={data?.banner_url}
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : data?.category === "ads"
                                          ? null
                                          : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="block-description ">
                                    <div className="card-slider-content">
                                      <h6 className="iq-title title-desc">
                                        {data?.title}
                                      </h6>
                                    </div>
                                    <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            data?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info-button">
                                        <li className="share">
                                          <div className="hover-buttons">
                                            <Link
                                              to={`/show-info/${data?._id}`}
                                              className="btn btn-hover iq-button"
                                            >
                                              <i
                                                className="fa fa-play mr-2"
                                                aria-hidden="true"
                                              ></i>
                                              {t("Play now")}
                                            </Link>
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div>
                                  {data?.category !== "ads" && (
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {latestDataCopy.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Latest Podcast")}</h4>
                        <Link className="iq-view-all" to="/">
                          {/* View All */}
                        </Link>
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev1"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next1"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          slidesPerView={4}
                          spaceBetween={20}
                          navigation={{
                            prevEl: "#prev1",
                            nextEl: "#next1",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {latestDataCopy?.map((data) => (
                            <SwiperSlide as="li">
                              <Link
                                to={
                                  data?.category !== "ads"
                                    ? `/show-info/${data?._id}`
                                    : null
                                }
                                onClick={(event) =>
                                  handleAdClicked(event, data)
                                }
                              >
                                <div
                                  className="block-images position-relative slider-img-main"
                                  onMouseEnter={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseEnter(data);
                                    } else {
                                      handleMouseEnterAD(data);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseLeave();
                                    } else {
                                      handleMouseLeaveAD();
                                    }
                                  }}
                                >
                                  <div className="img-box">
                                    {/* Only render the video if the media category is "movie" */}
                                    {data?.category === "podcast" ? (
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    ) : data?.media_id !== "" ? (
                                      <ReactPlayer
                                        className="img-fluid slider-img"
                                        url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                        width="100%"
                                        height="100%"
                                        pip={false}
                                        controls={false}
                                        playing={true}
                                        muted={true}
                                        loop={true}
                                      />
                                    ) : (
                                      <img
                                        src={data?.banner_url}
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : data?.category === "ads"
                                          ? null
                                          : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="block-description ">
                                    <div className="card-slider-content">
                                      <h6 className="iq-title title-desc">
                                        {data?.title}
                                      </h6>
                                    </div>
                                    <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            data?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info-button">
                                        <li className="share">
                                          <div className="hover-buttons">
                                            <Link
                                              to={`/show-info/${data?._id}`}
                                              className="btn btn-hover iq-button"
                                            >
                                              <i
                                                className="fa fa-play mr-2"
                                                aria-hidden="true"
                                              ></i>
                                              {t("Play now")}
                                            </Link>
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div>
                                  {data?.category !== "ads" && (
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {topRatedDataCopy.length > 0 && (
              <section id="iq-favorites">
                <Container fluid>
                  <Row>
                    <Col sm="12" className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title">{t("Top Rated")}</h4>
                        <Link className="iq-view-all" to="/">
                          {/* View All */}
                        </Link>
                      </div>
                      <div id="favorites-contens">
                        <div
                          id="prev1"
                          className="swiper-button swiper-button-prev"
                        >
                          <i className="fa fa-chevron-left"></i>
                        </div>
                        <div
                          id="next1"
                          className="swiper-button swiper-button-next"
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                        <Swiper
                          slidesPerView={4}
                          spaceBetween={20}
                          navigation={{
                            prevEl: "#prev1",
                            nextEl: "#next1",
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1600: { slidesPerView: 5 },
                          }}
                          loop={false}
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {topRatedDataCopy?.map((data) => (
                            <SwiperSlide as="li">
                              <Link
                                to={
                                  data?.category !== "ads"
                                    ? `/show-info/${data?._id}`
                                    : null
                                }
                                onClick={(event) =>
                                  handleAdClicked(event, data)
                                }
                              >
                                <div
                                  className="block-images position-relative slider-img-main"
                                  onMouseEnter={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseEnter(data);
                                    } else {
                                      handleMouseEnterAD(data);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (data?.category !== "ads") {
                                      handleMouseLeave();
                                    } else {
                                      handleMouseLeaveAD();
                                    }
                                  }}
                                >
                                  <div className="img-box">
                                    {/* Only render the video if the media category is "movie" */}
                                    {data?.category === "podcast" ? (
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    ) : data?.media_id !== "" ? (
                                      <ReactPlayer
                                        className="img-fluid slider-img"
                                        url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                        width="100%"
                                        height="100%"
                                        pip={false}
                                        controls={false}
                                        playing={true}
                                        muted={true}
                                        loop={true}
                                      />
                                    ) : (
                                      <img
                                        src={data?.banner_url}
                                        className="img-fluid slider-img"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="card-slider-badge">
                                    <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie" ||
                                        data?.category === "video"
                                        ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                        : data?.category === "ads"
                                          ? null
                                          : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="block-description ">
                                    <div className="card-slider-content">
                                      <h6 className="iq-title title-desc">
                                        {data?.title}
                                      </h6>
                                    </div>
                                    <div className="card-slider-desc">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: limitTextToWords(
                                            data?.description,
                                            12
                                          ),
                                        }}
                                      ></span>
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info-button">
                                        <li className="share">
                                          <div className="hover-buttons">
                                            <Link
                                              to={`/show-info/${data?._id}`}
                                              className="btn btn-hover iq-button"
                                            >
                                              <i
                                                className="fa fa-play mr-2"
                                                aria-hidden="true"
                                              ></i>
                                              {t("Play now")}
                                            </Link>
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div>
                                  {data?.category !== "ads" && (
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
            {podCastGenreData.length > 0 &&
              podCastGenreData?.map((data, index) => {
                if (data?.generalContents?.length > 0) {
                  return (
                    <section id="iq-upcoming-movie">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-12 overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4 className="main-title">{t(data.genre)}</h4>
                              <Link className="iq-view-all" to="/">
                                {/* View All */}
                              </Link>
                            </div>
                            <div id="upcoming-contens">
                              <div
                                id={`prev2${index}`}
                                className="swiper-button swiper-button-prev"
                              >
                                <i className="fa fa-chevron-left"></i>
                              </div>
                              <div
                                id={`next2${index}`}
                                className="swiper-button swiper-button-next"
                              >
                                <i className="fa fa-chevron-right"></i>
                              </div>
                              <Swiper
                                navigation={{
                                  nextEl: `#prev2${index}`,
                                  prevEl: `#next2${index}`,
                                }}
                                breakpoints={{
                                  320: { slidesPerView: 1 },
                                  550: { slidesPerView: 2 },
                                  991: { slidesPerView: 3 },
                                  1400: { slidesPerView: 4 },
                                  1600: { slidesPerView: 5 },
                                }}
                                loop={false}
                                slidesPerView={4}
                                spaceBetween={20}
                                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                              >
                                {data?.generalContents?.map((Obj, index) => (
                                  <SwiperSlide as="li">
                                    {/* <div className=" block-images position-relative slider-img-main">
                            <div className="img-box">
                              <img
                                src={Obj?.thumbnail?.banner_thumbnail_url}
                                className="img-fluid slider-img"
                                alt=""
                                key={index}
                              />
                            </div>
                            <div className="block-description">
                              <h6 className="iq-title title-desc">
                                <Link to="/episode" key={index}>
                                  {Obj?.title}
                                </Link>
                              </h6>
                              <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                <div className="badge badge-secondary p-1 mr-2 ">
                                  <div key={Obj._id}>{Obj?.category}</div>
                                </div>
                              </div>
                              <div className="card-slider-desc">
                                <span>
                                  {limitTextToWords(
                                    Obj?.description.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    ),
                                    12
                                  )}
                                </span>
                              </div>
                              <div className="block-social-info-button">
                                <li className="share">
                                  <div className="hover-buttons">
                                    <Link
                                      to={`/show-info/${Obj?._id}`}
                                      className="btn btn-hover iq-button"
                                    >
                                      <i
                                        className="fa fa-play mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  </div>
                                </li>
                              </div>
                            </div>
                          </div> */}
                                    <Link to={`/show-info/${Obj?._id}`}>
                                      <div
                                        className="block-images position-relative slider-img-main"
                                        onMouseEnter={() =>
                                          handleMouseEnter(Obj)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                      // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                      >
                                        <div className="img-box">
                                          <img
                                            src={
                                              Obj?.thumbnail
                                                ?.banner_thumbnail_url
                                            }
                                            className="img-fluid slider-img"
                                            alt=""
                                          />
                                        </div>
                                        {/* showing on the top */}
                                        <div className="card-slider-badge">
                                          <div className="badge badge-secondary p-1 mr-2">
                                            {Obj?.category === "movie" ||
                                              Obj?.category === "video"
                                              ? convertSecondsToHoursMinutes(
                                                Obj?.media?.duration.toFixed(
                                                  2
                                                )
                                              )
                                              : `${Obj?.episodes.length} ${Obj?.episodes.length === 1
                                                ? "Episode"
                                                : "Episodes"
                                              }`}
                                          </div>
                                        </div>
                                        <div className="block-description ">
                                          <div className="card-slider-content">
                                            {Obj?.category === "movie" ||
                                              Obj?.category === "video" ? (
                                              <h6 className="iq-title title-desc">
                                                {Obj?.media?.title}
                                              </h6>
                                            ) : (
                                              <h6 className="iq-title title-desc">
                                                {Obj?.title}
                                              </h6>
                                            )}
                                          </div>
                                          <div className="card-slider-desc">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: limitTextToWords(
                                                  Obj?.description,
                                                  12
                                                ),
                                              }}
                                            ></span>
                                          </div>
                                          <div className="block-social-info-button">
                                            <li className="share">
                                              <div className="hover-buttons">
                                                <Link
                                                  to={`/show-info/${Obj?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              </div>
                                            </li>
                                          </div>
                                        </div>
                                        <div className="block-social-info">
                                          <ul className="list-inline p-0 m-0 music-play-lists">
                                            <li className="share">
                                              <span>
                                                <i className="ri-share-fill"></i>
                                              </span>
                                              <div className="share-box">
                                                <div className="d-flex align-items-center">
                                                  <a
                                                    href={genreFacebookShareUrl(
                                                      Obj,
                                                      source,
                                                      Obj?.category,
                                                      Obj?._id
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="share-ico"
                                                    tabIndex="0"
                                                  >
                                                    <i className="ri-facebook-fill"></i>
                                                  </a>
                                                  <a
                                                    href={genreTwitterShareUrl(
                                                      Obj,
                                                      source,
                                                      Obj?.category,
                                                      Obj?._id
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="share-ico"
                                                    tabIndex="0"
                                                  >
                                                    <i className="ri-twitter-fill"></i>
                                                  </a>
                                                  <a
                                                    href={genreWhatsappShareUrl(
                                                      Obj,
                                                      source,
                                                      Obj?.category,
                                                      Obj?._id
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="share-ico iq-copy-link"
                                                    tabIndex="0"
                                                  >
                                                    <i className="ri-whatsapp-fill"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <span>
                                                <i
                                                  className={`ri-heart-fill ${favoriteItems.includes(
                                                    Obj?._id
                                                  )
                                                    ? "red-heart"
                                                    : ""
                                                    }`}
                                                  onClick={(event) =>
                                                    handleFavClick(
                                                      event,
                                                      Obj?._id,
                                                      Obj?.category
                                                    )
                                                  }
                                                  title={
                                                    favoriteItems.includes(
                                                      Obj?._id
                                                    )
                                                      ? t(
                                                        "Remove From Favourite"
                                                      )
                                                      : t("Add To Favourite")
                                                  }
                                                ></i>
                                              </span>
                                              {/* <span className="count-box">19+</span> */}
                                            </li>
                                            <li>
                                              <span>
                                                <i
                                                  className={`ri-add-line ${watchListItems.includes(
                                                    Obj?._id
                                                  )
                                                    ? "red-heart"
                                                    : ""
                                                    }`}
                                                  onClick={(event) =>
                                                    handleWatchList(
                                                      event,
                                                      Obj?._id,
                                                      Obj?.category
                                                    )
                                                  }
                                                  title={
                                                    watchListItems.includes(
                                                      Obj?._id
                                                    )
                                                      ? t(
                                                        "Remove From WatchList"
                                                      )
                                                      : t("Add To WatchList")
                                                  }
                                                ></i>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Link>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  );
                } else {
                  return null;
                }
              })}

            <Container fluid>
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  className="overflow-hidden"
                >
                  {podShowListData.length > 0 ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="main-title pb-3">{t("ALL")}</h4>
                        <Link className="iq-view-all" to="/all-podcast">
                          View More
                        </Link>
                      </div>
                      <div className="">
                        <div id="upcoming-contents">
                          <ul className="list-inline mb-0 iq-rtl-direction row">
                            {podShowListData?.slice(0, load).map((data) => (
                              <li
                                className="all-card-container slide-item  mb-4 col-lg-3 col-md-4 col-sm-6"
                                key={data._id}
                                onMouseEnter={() => handleMouseEnter(data)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <Link to={`/show-info/${data?._id}`}>
                                  <div className="block-images position-relative">
                                    <div className="card-slider-badge">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(
                                            data?.media?.duration.toFixed(2)
                                          )
                                          : `${data?.episodes.length} ${data?.episodes.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                          }`}
                                      </div>
                                    </div>
                                    <div className="img-box">
                                      <img
                                        src={
                                          data?.thumbnail?.banner_thumbnail_url
                                        }
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="block-description">
                                      <h6 className="iq-title all-title-desc">
                                        {data?.title}
                                      </h6>
                                      <div className="card-slider-desc">
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: limitTextToWords(
                                              data?.description,
                                              12
                                            ),
                                          }}
                                        ></span>
                                      </div>
                                      <div className="hover-buttons">
                                        <Link
                                          to={`/show-info/${data?._id}`}
                                          role="button"
                                          className="btn btn-hover iq-button btn-typ"
                                        >
                                          <i
                                            className="fa fa-play mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          {t("Play now")}
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="block-social-info">
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li className="share">
                                          <span>
                                            <i className="ri-share-fill"></i>
                                          </span>
                                          <div className="share-box">
                                            <div className="d-flex align-items-center">
                                              {/* <a
                                          href={generateLinkedinShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-linkedin-fill"></i>
                                        </a>
                                        <a
                                          href={generateInstagramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-instagram-fill"></i>
                                        </a>
                                        <a
                                          href={generateSnapchatShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-snapchat-fill"></i>
                                        </a>
                                        <a
                                          href={generateTikTokShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-tiktok-fill"></i>
                                        </a> */}
                                              <a
                                                href={generateFacebookShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-facebook-fill"></i>
                                              </a>
                                              <a
                                                href={generateTwitterShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico"
                                                tabIndex="0"
                                              >
                                                <i className="ri-twitter-fill"></i>
                                              </a>
                                              <a
                                                href={generateWhatsappShareUrl(
                                                  data,
                                                  source,
                                                  data?.category
                                                )}
                                                onClick={handleAnchorClick}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="share-ico iq-copy-link"
                                                tabIndex="0"
                                              >
                                                <i className="ri-whatsapp-fill"></i>
                                              </a>
                                              {/* <a
                                          href={generatePinterestShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-pinterest-fill"></i>
                                        </a>
                                        <a
                                          href={generateTelegramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-telegram-fill"></i>
                                        </a>
                                        <a
                                          href={generateRedditShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-reddit-fill"></i>
                                        </a>
                                        <a
                                          href={generateQuoraShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          onClick={handleAnchorClick}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-quora-fill"></i>
                                        </a> */}
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-heart-fill ${favoriteItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleFavClick(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From Favourite")
                                                  : t("Add To Favourite")
                                              }
                                            ></i>
                                          </span>
                                          {/* <span className="count-box">19+</span> */}
                                        </li>
                                        <li>
                                          <span>
                                            <i
                                              className={`ri-add-line ${watchListItems.includes(
                                                data?._id
                                              )
                                                ? "red-heart"
                                                : ""
                                                }`}
                                              onClick={(event) =>
                                                handleWatchList(
                                                  event,
                                                  data?._id,
                                                  data?.category
                                                )
                                              }
                                              title={
                                                watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? t("Remove From WatchList")
                                                  : t("Add To WatchList")
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      {podShowListData.length > load &&
                        podShowListData.length > load && (
                          <Link to="/all-podcast">
                            <button
                              className="btn btn-hover btn-typ"
                              type="button"
                            >
                              <span>{t("load more")}</span>
                            </button>
                          </Link>
                        )}
                    </>
                  ) : null}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};
export default PodCast;
