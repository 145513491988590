import axios from "axios";

function sendEmailVerification(obj) {
  const url = `${process.env.REACT_APP_API}/api/user/sendMailCode`;

  axios
    .post(url, obj)
    .then((response) => {
      localStorage.setItem("email verification token", response.data.token);
    })
    .catch((error) => {});
}

export function sendEmailVerificationWithoutToken(obj) {
  const url = `${process.env.REACT_APP_API}/api/user/sendMailCodeWithoutToken`;
  axios
    .post(url, obj)
    .then((response) => {
      localStorage.setItem("email verification token", response.data.token);
    })
    .catch((error) => {});
}

export default sendEmailVerification;
