// import React, { useState } from "react";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// export default function PaymentForm() {
//   const [loading, setLoading] = useState(false);
//   const stripe = useStripe();
//   const elements = useElements();
//   const CARD_OPTIONS = {
//     iconStyle: "solid",
//     style: {
//       base: {
//         iconColor: "#c4f0ff",
//         color: "black",
//         fontWeight: 500,
//         fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
//         fontSize: "16px",
//         fontSmoothing: "antialiased",
//         ":-webkit-autofill": { color: "white" },
//         "::placeholder": { color: "white" },
//       },
//       invalid: {
//         iconColor: "#ffc7ee",
//         color: "black",
//       },
//     },
//   };
//   const [value, setValue] = useState(2000 * 100);

//   // const handleSubmit = async (event) => {
//   //   event.preventDefault();

//   //   setLoading(true);

//   //   const { error, paymentMethod } = await stripe.createPaymentMethod({
//   //     type: "card",
//   //     card: elements.getElement(CardElement),
//   //   });

//   //   if (!error) {
//   //     // Send the payment method ID to your server to complete the payment
//   //     console.log("Payment method:", paymentMethod);

//   //   } else {
//   //     console.log(error);
//   //   }
//   //   setLoading(false);
//   // };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (stripe) {
//       const response = await fetch(
//         `${process.env.REACT_APP_API}/api/user/stripe/create-checkout-session/${currentUserId}`,
//         {
//           method: "POST",
//           // headers: {
//           //   "Content-Type": "application/json",
//           // },
//           // body: JSON.stringify({ amount: value }),
//           body: JSON.stringify({}),
//         }
//       );

//       // Process the response or redirect to Stripe checkout
//       if (response.ok) {
//         const session = await response.json();
//         console.log(session.session.url, "data");
//         window.location.href = session.session.url;
//       } else {
//         const errorResponse = await response.json();
//         console.error(
//           "Failed to create checkout session:",
//           errorResponse.error
//         );
//       }
//     }
//   };
//   const currentUser = JSON.parse(localStorage.getItem("session"));
//   const currentUserId = currentUser?._id;
//   console.log(currentUserId, "User ID");
//   return (
//     <>
//       <form
//         onSubmit={handleSubmit}
//         style={{ display: "flex", justifyContent: "center" }}
//       >
//         <input name="amount" value={value} hidden />
//         {/* <CardElement options={CARD_OPTIONS} /> */}
//         <button
//           className="pricingTable-signup"
//           type="submit"
//           style={{ cursor: "pointer" }}
//         >
//           Subscribe
//         </button>
//       </form>
//       {/* <form
//         action={`https://afo.herokuapp.com/api/user/stripe/create-checkout-session/${currentUserId}`}
//         method="POST"
//         style={{ display: "flex", justifyContent: "center" }}
//       // onSubmit={handleSubmitForm}
//       >
//         <input name="amount" value={value} hidden />
//         <button className="subBtn" type="submit">Checkout</button>
//       </form > */}
//     </>
//   );
// }
import React, { useState, useRef, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import addStripeCard from "../../../../Services/addStripeCard";
import updateUserDetails from "../../../../Services/updateUserDetails";
import AuthSession from "../../../../Services/getSessionAuth";
import userSubscription from "../../../../Services/userSubscription";
import videoCharge from "../../../../Services/exclusiveVideoCharge";
import cardImage from "../../../../assets/images/cards/card.png";
import { useTranslation } from "react-i18next";

export default function PaymentForm({
  updateData,
  message,
  subscription,
  contentType,
  myObj,
  setHasUserPaid,
  donateFn,
  handleDonationClose,
}) {
  const [loading, setLoading] = useState(false);
  const [paymentProcessed, setPaymentProcessed] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const cardholderNameRef = useRef();
  const cardElementRef = useRef();
  const { t } = useTranslation();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "white",
        fontWeight: 500,
        fontFamily: " Segoe UI",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "white" },
        "::placeholder": { color: "white" },
      },
      invalid: {
        iconColor: "#ff0000",
        color: "white",
      },
    },
    hidePostalCode: true,
  };
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  const [showError, setShowError] = useState(false);
  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));

    // Get the current date
    const currentDate = new Date();

    // Subtract 15 years from the current date
    currentDate.setFullYear(currentDate.getFullYear() - 15);

    // Format the date as "YYYY-MM-DD"
    const fifteenYearsAgo = currentDate.toISOString().substring(0, 10);
    if (userDetails_Session) {
      return {
        _id: userDetails_Session._id,
        name: userDetails_Session.name,
        email: userDetails_Session.email,
        tfa: userDetails_Session.tfa,
        phone: userDetails_Session.phone ? userDetails_Session.phone : "N/A",
        // dob: userDetails_Session.dob
        //   ? userDetails_Session.dob.substring(0, 10)
        //   : "2021-09-02",
        dob: userDetails_Session.dob
          ? userDetails_Session.dob.substring(0, 10)
          : fifteenYearsAgo,
        language: userDetails_Session.language
          ? userDetails_Session.language
          : "unknown",
        gender: userDetails_Session.gender
          ? userDetails_Session.gender
          : "unknown",
        country: userDetails_Session.country
          ? userDetails_Session.country
          : "N/A",
        password: userDetails_Session.password
          ? userDetails_Session.password
          : "N/A",
        profileIMG: userDetails_Session.profile_image?.url || "",
        subscription: userDetails_Session.subscription,
        brand: userDetails_Session.brand,
        last4: userDetails_Session.last4,
      };
    } else {
      return {
        _id: "",
        name: "",
        email: "",
        tfa: false,
        phone: "N/A",
        dob: "2021-09-02",
        language: "unknown",
        gender: "unknown",
        country: "N/A",
        password: "N/A",
        profileIMG: "",
        subscription: "",
        brand: "",
        last4: "",
      };
    }
  };

  // console.log(getSessionData());
  const [userDetails, setUserDetails] = useState(getSessionData());
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    if (subscription !== "") {
      setUserInfo({ _id: userDetails?._id, subscription: subscription });
    } else {
      setUserInfo({
        _id: userDetails?._id,
        subscription: userDetails?.subscription,
      });
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    const card = elements.getElement(CardElement);

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: card,
    //   billing_details: {
    //     name: cardholderNameRef.current.value,
    //   },
    // });\
    const { error, token } = await stripe.createToken(card);
    if (!error) {
      // const obj = {
      //   number: "4242424242424242",
      //   exp_month: paymentMethod.card.exp_month,
      //   exp_year: paymentMethod.card.exp_year,
      //   // cvc: card._privateCardData.cvc,
      // };
      // console.log("obj", obj);
      // console.log("Token:", token);
      const obj = {
        token: token.id,
        brand: token.card.brand,
        last4: token.card.last4,
      };

      await addStripeCard(currentUserId, obj);
      if (contentType === "donation") {
        donateFn();
      } else if (contentType === "exclusive") {
        videoCharge(currentUserId, myObj);
        setHasUserPaid(true);
      } else {
        await userSubscription(currentUserId);
        await updateUserDetails(userInfo);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
        updateData(updatedUserDetails);
      }
      setLoading(false);
      setPaymentProcessed(true);
      setTimeout(() => {
        handleDonationClose();
      }, 1000);

      // WAIT FOR THE RESPONSE FROM THIS API (ddStripeCard) THEN SET LOADING TO FALSE AND SET A STATE FOR SUCCESS MSG
      // update user details with subscription type get session data and update data on page

      // console.log("userinfo", userInfo);

      // console.log(userDetails);
    } else {
      setErrorMessage(error.message);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      console.log(error);
      setLoading(false);
    }
  };

  return !paymentProcessed ? (
    <>
      {" "}
      <>
        <Modal.Header>
          <Modal.Title>{t("Add new card")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="paymanetcard-image">
              <img src={cardImage} alt="paymanetcard-image" className="" />
            </div>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm="12">
                  <div
                    className={`alert alert-danger ${
                      showError ? "" : "d-none"
                    }`}
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                  <fieldset className="FormGroup">
                    <div className="FormRow ">
                      <label htmlFor="cardholderName" className="FormLabel">
                        {t("Cardholder Name")}
                      </label>
                      <div className=" StripeElement cardHolder ">
                        <input
                          type="text"
                          id="cardholderName"
                          className="FormInput "
                          placeholder={t("Enter Cardholder name")}
                          required
                          ref={cardholderNameRef}
                        />
                      </div>
                    </div>
                  </fieldset>
                </Col>
                <Col sm="12">
                  <fieldset className="FormGroup">
                    <div className="FormRow">
                      <label htmlFor="s" className="FormLabel">
                        {t("Card Number")}
                      </label>
                      <CardElement options={CARD_OPTIONS} />
                    </div>
                  </fieldset>
                </Col>
                <Col sm="12">
                  {" "}
                  <Button
                    disabled={!stripe || loading}
                    type="submit"
                    style={{ width: "93%", margin: "0 15px 20px" }}
                  >
                    {loading ? t("Processing") : t("Add Card")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        </Modal.Body>
      </>
    </>
  ) : (
    <>
      <Modal.Header>
        <Modal.Title>Card</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "0.5rem" }}>
        <div className="success2FA">
          <AiOutlineCheckCircle
            style={{ color: "var(--iq-primary)" }}
            size={30}
          />
          <span>{t(message)}</span>
        </div>
      </Modal.Body>{" "}
    </>
  );
}
