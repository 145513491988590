import React, { useState, useEffect, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { Link, useParams, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ReactJWPlayer from "react-jw-player";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { FcPrevious } from "react-icons/fc";
import { Button, Modal } from "react-bootstrap";
import getEpisode from "../../../Services/episodes";
import getSeasonData from "../../../Services/seasons";
import {
  assignAdToTarget,
  convertSecondsToHoursMinutes,
  dateToWords,
  getDate,
  handleAdClicked,
  handleAnchorClick,
  limitTextToWords,
  shuffleArray,
} from "../../../Services/generalFunctions/generalFunctions";
import AddCommentEpisodes from "./../../../components/AddCommentEpisodes";
import EpisodesComments from "./../../../components/EpisodesComments";
import postLoginData from "../../../Services/postLoginData";
import postSignUpData from "../../../Services/postSignUpData";
import sendEmailVerification from "../../../Services/sendEmailVerification";
import verifyEmailCode from "../../../Services/verifiyEmailCode";
import checkUserCard from "../../../Services/checkUserCard";
import updateUserDetails from "../../../Services/updateUserDetails";
import AuthSession from "../../../Services/getSessionAuth";
import LoginMob from "../pages/auth/Login_mb";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Stripe from "../pages/auth/stripe";
import {
  podCastAndShowsByGenre,
  podCastShowsByGenre,
} from "../../../Services/podCastApi";
import { TVShowsByGenre } from "../../../Services/tvShowsApi";
import getGeneralContent from "./../../../Services/generalContent";
import {
  EpisodeFbShareUrl,
  EpisodeTwitterShareUrl,
  EpisodeWhatsappShareUrl,
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  genreFacebookShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
} from "./../home/ShareFunction";
import { currentUserToken } from "./../home/home";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../home/FavWatch";
import userSubscription from "../../../Services/userSubscription";
import getAnalytics from "../../../Services/getAnalytics";
import userPaid from "../../../Services/userPaid";
import showCharge from "../../../Services/exclusiveShowCharge";
import { useContext } from "react";
import { MyContext } from "../../../store/context";
import ShowDescriptionModal from "../../../components/partials/UI/ShowDescriptionModal";
import { fetchUserData } from "../../../Services/bankDetails";
import { addToHistory } from "../../../Services/addToHistory";
import {
  adClickApi,
  fetchAdsDataTVShow,
  fetchAdsDataTVShowAndPodcast,
} from "../../../Services/adsApi";
import ReactPlayer from "react-player";
import AdsModal from "../../../components/partials/UI/AdsModal";
import { Helmet } from "react-helmet";

const ShowList = () => {
  const { t } = useTranslation();
  const [tvGenreData, setTVGenreData] = useState([]);
  const [podCastGenreData, setPodCastGenreData] = useState([]);
  const [content, setContent] = useState({});
  const [season, setSeason] = useState({});
  const [tvShow, setTvShow] = useState({});
  const [activeBtn, setActiveBtn] = useState("description");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [episodeCommentFlag, setEpisodeCommentFlag] = useState(false);
  const [loader, setLoader] = useState(true);

  const { id, source } = useParams();
  const renderedIDs = []; // to keep track of duplicate items inside more like this

  // to change the routes when language is changed
  const currentSelectedLanguage = localStorage.getItem("lang") || 'fr';
  const location = useLocation();
  const isInitialRender = useRef(true);

  const { category, setCategory } = useContext(MyContext);

  // useEffect(() => {
  //   if (
  //     !isInitialRender.current &&
  //     currentSelectedLanguage &&
  //     location.pathname.includes("/episode/")
  //   ) {
  //     history.push("/");
  //   }
  //   isInitialRender.current = false;
  // }, [currentSelectedLanguage, history, location]);

  // disable the right click
  // useEffect(() => {
  //   // define a custom handler function
  //   // for the contextmenu event
  //   const handleContextMenu = (e) => {
  //     // prevent the right-click menu from appearing
  //     e.preventDefault();
  //   };

  //   // attach the event listener to
  //   // the document object
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // clean up the event listener when
  //   // the component unmounts
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Ctrl+Shift+I
      if (
        (event.ctrlKey &&
          event.shiftKey &&
          (event.key === "I" || event.key === "i")) ||
        event.key === "F12"
      ) {
        event.preventDefault();
      }
    };

    // Attach event listener to disable the shortcut key
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      // Cleanup: remove event listener
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  let h = window.innerHeight;
  let w = window.innerWidth;
  window.onresize = () => {
    if (h !== window.innerHeight || w !== window.innerWidth) {
      console.log("original", w);
      console.log("after", window.innerWidth);
      if (window.innerWidth <= w / 2) {
        alert("The new width is half or less than half of the old width!");
      }
    }
  };
  // useEffect(() => {
  //   const checkConsole = () => {
  //     console.log(
  //       Object.defineProperties(new Error(), {
  //         toString: {
  //           value() {
  //             new Error().stack.includes("toString@") &&
  //               alert("Safari devtools");
  //           },
  //         },
  //         message: {
  //           get() {
  //             window.location.href = "/";
  //           },
  //         },
  //       })
  //     );
  //   };

  //   checkConsole();
  // }, []);

  const mediaId = content?.episodeObj?.media_id;
  const trailerId = content?.generalContentObj?.trailer?.media_id;
  const bannerImg = content?.episodeObj?.thumbnail?.banner_thumbnail_url;
  const movieTitle = content?.episodeObj?.title;
  const description = content?.episodeObj?.description;
  const releaseDate = content?.episodeObj?.release_year;
  const mediaDesc = content?.generalContentObj?.media?.description;
  const duration = content?.episodeObj?.duration;
  const genreName = content?.generalContentObj?.genre?.name;
  const jwTags = content?.episodeObj?.jw_tags;
  // const mediaCategory = content?.episodeObj?.category;
  const tvShowId = content?.episodeObj?.tv_show?._id;
  const price = tvShow?.tvShowDetails?.price;

  const mediaCategory = tvShow?.tvShowDetails?.category;
  const EPtitle = tvShow?.tvShowDetails?.title;
  const EPDesc = tvShow?.tvShowDetails?.description;
  const contenttype = tvShow?.tvShowDetails?.content_type;
  const episodes_id = tvShow?.tvShowDetails?.seasons?.flatMap((season) =>
    season?.episodes?.map((episode) => episode._id)
  );
  const seasonTitle = tvShow?.tvShowDetails?.title;

  // const contentType = content?.generalContentObj?.content_type;
  const handleClick = (btnText) => {
    setActiveBtn(btnText);
  };

  const [genresData, setGenresData] = useState([]);

  useEffect(() => {
    podCastAndShowsByGenre(currentSelectedLanguage, mediaCategory)
      .then((data) => {
        if (data && data.upcomingContent && data.upcomingContent.length > 0) {
          setGenresData(data.upcomingContent);
        } else {
          setGenresData([]);
        }
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, [currentSelectedLanguage, mediaCategory]);
  //genral id for 2d work
  const [genre, setGenre] = useState([]);
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    getGeneralContent({ id })
      .then((data) => {
        setTvShow((prevGenre) => data?.genre || prevGenre);
      })
      .catch((error) => {
        // console.log(error, "error");
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let concatenatedMedia = [];
        for (let i = 0; i < tvShow?.tvShowDetails?.genre.length; i++) {
          const genreId = tvShow?.tvShowDetails?.genre?.[i]._id;
          let response;
          if (mediaCategory === "tvshow") {
            response = await axios.get(
              `${process.env.REACT_APP_API}/api/tv-shows/get-genre-tvShows/${genreId}/${currentSelectedLanguage}`
            );
          } else if (mediaCategory === "podcast") {
            response = await axios.get(
              `${process.env.REACT_APP_API}/api/podcast/get-genre-podcasts/${genreId}/${currentSelectedLanguage}`
            );
          }
          const moviesData = response.data;
          concatenatedMedia = concatenatedMedia.concat(
            moviesData?.upcomingContent
          );
        }
        setMovies(concatenatedMedia);
      } catch (error) {
        // throw new Error("error fetching data");
      }
    };

    fetchData();
  }, [tvShow]);
  //---------------------
  // getting episode details

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const concatenatedMedia = [];
  //       for (let i = 0; i < tvShow?.genre.length; i++) {
  //         const genreId = tvShow?.genre[i]._id;
  //         let response;
  //         if (mediaCategory === 'tvshow') {
  //           response = await axios.get(
  //             `${process.env.REACT_APP_API}/api/tv-shows/get-genre-tvShows/${genreId}/en`
  //           );
  //         } else if (mediaCategory === 'podcast') {
  //           response = await axios.get(
  //             `${process.env.REACT_APP_API}/api/podcast/get-genre-podcasts/${genreId}/en`
  //           );
  //         }
  //         const moviesData = response.data;
  //         concatenatedMedia.push(...moviesData.episodes);
  //         console.log(moviesData, "data inner");
  //         console.log(genreId, "id");
  //       }
  //       setMovies(concatenatedMedia);
  //     } catch (error) {
  //       console.error('Error fetching media:', error.response.data);
  //     }
  //   };

  //   if (tvShow?.tvShowDetails && tvShow?.tvShowDetails.genre) {
  //     fetchData();
  //   }
  // }, [tvShow, mediaCategory]);

  useEffect(() => {
    getEpisode({ id })
      .then((data) => {
        setContent(data);
        setLoader(false);
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, []);

  const episodeObjectId = content?.episodeObj?._id;

  // getting corresponding season details
  useEffect(() => {
    getSeasonData({ tvShowId })
      .then((data) => {
        setTvShow(data);
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, [tvShowId]);

  // const { episodes } = tvShow.seasons[0];

  const tvShowDetailsId = tvShow?.tvShowDetails?._id;

  const seasonId = content?.episodeObj?.season;
  const seasonsList = tvShow?.tvShowDetails?.seasons;
  const currentEpisodeSeason = seasonsList?.find(
    (season) => season?._id === seasonId
  );
  const sameSeasonEpisodes = currentEpisodeSeason?.episodes;

  //LIKE AND UNLIKE
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;

  const [likeCount, setLikeCount] = useState(() => {
    const storedLikeCount = localStorage.getItem("likeCount");
    return storedLikeCount ? Number(storedLikeCount) : 0;
  });
  const [isLiked, setIsLiked] = useState(false);
  const [likeId, setLikeId] = useState("");

  const fetchLikeCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/likes/like-general-content/${id}`
      );
      if (response.status === 200) {
        if (response.data?.noOfLikes) {
          const { noOfLikes } = response.data;
          setLikeCount(Number(noOfLikes));
        }
      }
    } catch (error) {
      // console.error("Error", error);
    }
  }, [id, setLikeCount]);
  useEffect(() => {
    const storedLikeCount = localStorage.getItem("likeCount");
    const storedLikeStatus = localStorage.getItem("isLiked");
    const storedLikeId = localStorage.getItem("likeId");

    if (storedLikeCount) {
      setLikeCount(Number(storedLikeCount));
    }

    if (storedLikeStatus) {
      setIsLiked(storedLikeStatus === "true");
    }

    if (storedLikeId) {
      setLikeId(storedLikeId);
    }

    checkLikeStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem("likeCount", likeCount.toString());
    localStorage.setItem("isLiked", isLiked.toString());
    localStorage.setItem("likeId", likeId);
  }, [likeCount, isLiked, likeId]);

  const checkLikeStatus = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/likes/check-like-episode`,
        {
          episode_obj_id: id,
          user_id: currentUserId,
          category: mediaCategory,
        }
      );
      //&& response.data.like
      if (response.status === 200) {
        if (response?.data?.like?._id) {
          setIsLiked(true);
          setLikeId(response?.data?.like?._id);
        } else {
          setIsLiked(false);
          setLikeId("");
        }
      }
    } catch (error) {
      // console.error(error, "Error checking like status");
    }
  }, [id, currentUserId, mediaCategory]);

  const handleLike = async () => {
    if (currentUserId) {
      try {
        if (isLiked) {
          await dislikeContent();
          toast.info(t("Item Disliked!"), {
            position: "top-center",
          });
          setLikeCount((prevCount) => prevCount - 1);
        } else {
          await likeContent();
          toast.success(t("Item Liked!"), {
            position: "top-center",
          });
          setLikeCount((prevCount) => prevCount + 1);
        }

        setIsLiked(!isLiked);
        fetchLikeCount();
      } catch (error) {
        toast.error(t("Error occurred while liking/disliking"), {
          position: "top-center",
        });
      }
    } else {
      // Navigate to the login page
      history.push("/login");
    }
  };

  const likeContent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/likes/like-episode`,
        {
          show_id: tvShowId,
          episode_obj_id: id,
          user_id: currentUserId,
          category: mediaCategory,
        }
      );
      //&& response.data.savedLike
      if (response.status === 200) {
        setLikeId(response.data.savedLike._id);
      }
    } catch (error) {
      throw error;
    }
  };

  const dislikeContent = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/likes/dislike-content/${likeId}`
      );
      setLikeId("");
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchLikeCount();
  }, [fetchLikeCount]);
  // modals for subscription and login ===============

  // to handle the checkout modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowStripeCard(false);
    setCardInfo(false);
  };
  const handleShow = () => setShow(true);

  // to handle the login modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // switch language

  const [isSignUpActive, setIsSignUpActive] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpActive(true);
  };

  const handleSignInClick = () => {
    setIsSignUpActive(false);
  };

  // get the page number
  const [page, setPage] = useState(0);
  const [subscriptionForm, setSubscriptionForm] = useState(false);
  const [otp, setOtp] = useState(false);
  let history = useHistory("");
  // cards
  const [activeCard, setActiveCard] = useState(true);
  const handleCardClick = (cardIndex) => {
    setActiveCard(cardIndex);
  };

  // login form data
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emptyObj, setEmptyObj] = useState(false);
  const [loginInput, setLoginInput] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginInput({ ...loginInput, [name]: value });
  };

  const isValidEmail = (email) => {
    // regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPassword = (password) => {
    const hasSpecialCharacter = /[!@#$%^&(),.?":{}|<>]/.test(password);
    const hasMinimumLength = password.length >= 8;
    const hasNumber = /\d/.test(password);

    return hasSpecialCharacter && hasMinimumLength && hasNumber;
  };

  const loginSubmit = async (event) => {
    event.preventDefault();
    if (!loginInput.email || !loginInput.password) {
      setEmptyObj(true);
      setTimeout(() => {
        setEmptyObj(false);
      }, 3000);
      return;
    }

    if (!isValidEmail(loginInput.email)) {
      setError("Invalid email address");
      setShowLoginError(true);
      return;
    }
    postLoginData(loginInput, setShowLoginError, history, setLoginError, "");

    // setShowLoginModal(false);
  };

  // signup form data
  const [otpValue, setOtpValue] = useState("");
  // form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    subscription: "free",
    //  otp: "",
  });
  const handleOtpChange = (otp) => {
    setOtpValue(otp);
    //  setFormData({ ...formData, otp: otp }); // Update the formData object with the new OTP value
  };
  //
  //  this is for handling the signup data (works on validate button) - giving it break for a while for email verification code api
  const [userExistError, setuserExistError] = useState("");
  // const [showError, setShowError] = useState(false);
  const handleSignUp = async (event) => {
    postSignUpData(
      formData,
      history,
      setuserExistError,
      setShowError,
      setShowLoginError,
      setLoginError,
      ""
    );
  };

  // handle user exist on next button of signup form
  // const handleSignUpNext = () ={

  // }

  // SIGN UP : EMAIL VERIFICATION ==================================================================================
  const obj = {
    subject: "email verification",
    email: formData.email,
  };
  // when user clicks on next button send the email on emailverficiation api
  const handleEmailVerification = () => {
    if (!isValidEmail(formData.email)) {
      setError("Invalid email address");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      return;
    }
    if (!isValidPassword(formData.password)) {
      let errorMessage = "Password must contain ";
      if (formData.password.length < 8) {
        errorMessage += "at least 8 characters";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
      if (!/[!@#$%^&(),.?":{}|<>]/.test(formData.password)) {
        errorMessage += "at least one special character";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
      if (!/\d/.test(formData.password)) {
        errorMessage += "at least one number";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
    }

    sendEmailVerification(obj);
    setPage(page + 1);
    setSubscriptionForm(true);
  };
  // when user enters the received code validate the code using email code and token from email verification API
  const verifyCode = {
    email: formData.email,
    code: otpValue,
    token: localStorage.getItem("email verification token"),
  };
  //  used to show error on otp page
  const [wrongEmailCode, setWrongEmailCode] = useState(false);
  const handleEmailCodeVerification = () => {
    verifyEmailCode(verifyCode)
      .then((result) => {
        if (result) {
          localStorage.removeItem("email verification token");
          handleSignUp();
          // history.push("/");
        } else {
          setWrongEmailCode(true);
          setTimeout(() => {
            setWrongEmailCode(false);
          }, 3000);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  // stripe state
  const [showStripe, setShowStripe] = useState(false);
  // subscription modal------------------
  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));
    if (userDetails_Session) {
      return {
        _id: userDetails_Session._id,
        subscription: userDetails_Session.subscription,
      };
    } else {
      return null;
    }
  };

  const [userDetails, setUserDetails] = useState(getSessionData());
  const handleUpdateData = (data) => {
    setUserDetails(data);
  };
  // stripe
  const [showStripeCard, setShowStripeCard] = useState(false);
  const [cardInfo, setCardInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  // console.log("setinfo", cardInfo);
  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      const response = await checkUserCard(currentUserId);
      setShowStripeCard(true);
      if (response.data.connected) {
        await userSubscription(currentUserId);
        await updateUserDetails({
          _id: currentUserId,
          subscription: "paid",
        });
        setCardInfo(true);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
      } else {
        setCardInfo(false);
      }
    } catch (error) {
      // console.log(error);
    }

    setLoading(false);
  };

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );
  function handleFavClick(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }
    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    // Check if the item is already in the favorite list
    if (favoriteItems.includes(id)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(id);
      toast.info("Content Removed From Favorite list!", {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== id));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, id]);
          } else {
            toast.error(t("Error occurred at Fav List"));
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  function handleWatchList(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }
    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: category,
    };

    // Check if the item is already in the watch list
    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Content Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at Watch List"));
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }
    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item.general_content_id);
        setData(data);
      } catch (error) {
        // throw new Error("Something went wrong...");
      }
    };

    if (currentUserId) {
      const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
      fetchData(watchListUrl, setWatchListItems);

      const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
      fetchData(favoriteItemsUrl, setFavoriteItems);
    }
  }, [currentUserId]);

  // to show the current episode and season number

  let seasonNumber;
  let episodeNumber;
  const currentEpisodeID = content?.episodeObj?._id;

  for (let i = 0; i < tvShow?.tvShowDetails?.seasons?.length; i++) {
    const season = tvShow?.tvShowDetails?.seasons[i];
    const episodeIndex = season?.episodes?.findIndex(
      (episode) => episode._id === currentEpisodeID
    );
    if (episodeIndex !== -1) {
      seasonNumber = i + 1;
      episodeNumber = episodeIndex + 1;
      break;
    }
  }
  const formattedEpisodeNumber = episodeNumber?.toString().padStart(2, "0");
  //  analytics ========================================
  const [dataRows, setDataRows] = useState([]);
  function formatViewCount(viewCount) {
    if (viewCount >= 1000000) {
      // Convert to millions (e.g., 1.1m)
      return (viewCount / 1000000).toFixed(1) + "m";
    } else if (viewCount >= 1000) {
      // Convert to thousands (e.g., 1k)
      return (viewCount / 1000).toFixed(0) + "k";
    } else {
      return viewCount.toString();
    }
  }
  useEffect(() => {
    if (content?.episodeObj?.media_id !== "") {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      const analyticsData = {
        media_ids: [content?.episodeObj?.media_id],
        start_date: getDate(content?.episodeObj?.createdAt),
        end_date: formattedDate,
      };
      getAnalytics(analyticsData)
        .then((response) => {
          // Handle the response data
          setDataRows(response.data?.rows);
          // setDataMetrics(response.metadata?.column_headers?.metrics);
        })
        .catch((error) => {
          // Handle the error
        });
    }
  }, [content]);
  // exclusive content =====================================
  const [hasUserPaid, setHasUserPaid] = useState(false);
  useEffect(() => {
    userPaid(currentUserId, {
      content_id: tvShow?.tvShowDetails?._id,
      category: mediaCategory,
    }).then((data) => {
      if (data) {
        setHasUserPaid(true);
      } else {
        setHasUserPaid(false);
      }
    });
  }, [mediaCategory !== undefined, currentUserId]);

  const videoObj = {
    content: {
      _id: tvShow?.tvShowDetails?._id,
      category: mediaCategory,
      title: tvShow?.tvShowDetails?.title,
    },
  };
  const handleExclusiveSubmit = async (event) => {
    setLoading(true);
    try {
      const response = await checkUserCard(currentUserId);
      setShowStripeCard(true);

      if (response.data.connected) {
        await showCharge(currentUserId, videoObj);
        setHasUserPaid(true);
        setCardInfo(true);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
      } else {
        setCardInfo(false);
      }
    } catch (error) {
      // console.log(error);
    }

    setLoading(false);
  };

  //----------------------------------------------- hovering video modal description work
  const [descModal, setDescModal] = useState(false);
  const handleClose1 = () => setDescModal(false);
  const handleShow1 = () => setDescModal(true);

  // for description modal
  const [hoveredItem, setHoveredItem] = useState({});
  const modalTimerRef = useRef(null);

  const handleMouseEnter = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItem(item);

    modalTimerRef.current = setTimeout(() => {
      setDescModal(true);
    }, 3000); // 5 seconds
  };

  const handleMouseLeave = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRef.current);
    // setHoveredItem(null);
  };

  const handleModalClose = () => {
    // Close the modal
    setDescModal(false);
  };

  // for exclusive content
  const [showExclusiveModal, setShowExclusiveModal] = useState(false);
  const handleShowExclusiveModal = () => {
    setShowExclusiveModal(true);
  };
  const handleCloseExclusiveModal = () => {
    setShowExclusiveModal(false);
  };
  const [bankDetails, setBankDetails] = useState({});

  useEffect(() => {
    fetchUserData().then((data) => setBankDetails(data));
  }, []);

  // Modal for bank details
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [showBankDetails, setShowBankDetails] = useState(false);
  const handleCloseBankDetails = () => setShowBankDetails(false);
  const handleShowBankDetails = () => setShowBankDetails(true);

  const handlePaymentTransfer = () => {
    const requestBody = {
      content: {
        _id: tvShow?.tvShowDetails?._id,
        category: mediaCategory,
        title: seasonTitle,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API}/api/user/bank/exclusive-show-charge/${currentUserId}`,
        requestBody
      )
      .then((response) => {
        // If the API call is successful, set isPaymentSuccessful to true
        if (response?.data?.status !== "200") {
          setPaymentStatus("Payment Failed!");
        } else {
          setIsPaymentSuccessful(true);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  // add to history api call
  useEffect(() => {
    const sendHistory = async () => {
      const obj = {
        general_content_id: mediaId,
        category: mediaCategory,
      };

      addToHistory(obj, currentUserId);
    };

    // conditions on wich history api will be called
    const isFreeContent = tvShow?.tvShowDetails?.contentType === "free";
    const isSubscriptionPaid =
      tvShow?.tvShowDetails?.contentType === "subscription" &&
      currentUser?.paid === true;
    const isExclusiveContent =
      tvShow?.tvShowDetails?.contentType === "exclusive" &&
      hasUserPaid === true;
    const isConditionsMatched =
      isFreeContent || isSubscriptionPaid || isExclusiveContent;

    // Check if the conditions are met and make the API call
    if (isConditionsMatched && currentUserId !== "") {
      sendHistory();
    }
  }, [
    tvShow?.tvShowDetails?.contentType,
    userDetails,
    hasUserPaid,
    currentUserId,
  ]);

  // AD Work
  // AD Data
  const [adsData, setAdsData] = useState({});
  useEffect(() => {
    // Call the fetchAdsDataTvShow function
    const fetchData = async () => {
      try {
        const data = await fetchAdsDataTVShowAndPodcast(
          currentSelectedLanguage,
          mediaCategory
        );
        setAdsData(data?.adsList);
      } catch (error) {
        // console.error("Error", error);
      }
    };

    fetchData();
  }, [mediaCategory]);

  const [adsCopyCopy, setAdsCopyCopy] = useState([]);
  const [similarCopy, setSimilarCopy] = useState([]);

  useEffect(() => {

    const adsCopyCopyTemp = adsData.length > 0 ? [...adsData] : [];
    const similarCopyTemp = [...movies];

    // Assign ads from the adsCopyCopy to topRatedDataCopy and upComingDataCopy
    assignAdToTarget(adsCopyCopy, similarCopyTemp);
    assignAdToTarget(adsCopyCopy, similarCopyTemp);

    // Update the state variables to reflect the changes
    setAdsCopyCopy(shuffleArray(adsCopyCopyTemp));
    setSimilarCopy(shuffleArray(similarCopyTemp));

  }, [adsData, movies]);

  // AD Modal
  const [hoveredItemAD, setHoveredItemAD] = useState({});
  const [showADModal, setShowADModal] = useState(false);
  const handleCloseADModal = () => setShowADModal(false);
  const handleShowADModal = () => setShowADModal(true);
  const modalTimerRefAD = useRef(null);
  const handleMouseEnterAD = (item) => {
    // Set the hovered item
    setHoveredItemAD(item);

    // Clear the previous timer, if any
    clearTimeout(modalTimerRefAD.current);

    if (item?.autoplay === "instant") {
      // Autoplay with a delay of 1 second
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 500); // half a second
    } else if (item?.autoplay === "3 seconds") {
      // Autoplay with a delay of 3 seconds
      modalTimerRefAD.current = setTimeout(() => {
        setShowADModal(true);
      }, 3000);
    } else if (item?.autoplay === "off") {
      // No autoplay
      setShowADModal(false);
    }
  };

  const handleMouseLeaveAD = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRefAD.current);
    // setHoveredItem(null);
  };

  const handleModalCloseAD = () => {
    // Close the modal
    setShowADModal(false);
  };

  const extractText = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.innerText;
  };

  return (
    <>
      <Helmet>
          <title>{currentSelectedLanguage !== "fr" ? content?.episodeObj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :movieTitle }</title>
          <meta property="og:title" content={`${movieTitle} | AFO Media`} />
          <meta property="og:description" content={extractText(description).substr(0, 100)} />
          <meta  property="og:image" content={bannerImg} />
          <meta property="og:url" content={`https://afomedia.com/episode/${id}`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <ToastContainer
        toastStyle={{
          background: "black",
          color: "white",
        }}
      />
      {/* AD Modal */}
      <Modal
        show={showADModal}
        onHide={handleCloseADModal}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <AdsModal item={hoveredItemAD} handleClose={handleCloseADModal} />
        </Modal.Body>
      </Modal>
      <Modal
        className="deleteModal"
        show={showBankDetails}
        onHide={() => {
          setShowExclusiveModal(false);
          setBankDetails(false);
          handleCloseBankDetails();
        }}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bank Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            {!isPaymentSuccessful && !paymentStatus && (
              <h6>Bank Transfer - {bankDetails?.bankDetails?.bank_detail}</h6>
            )}
            {isPaymentSuccessful && (
              <h6>
                Confirmation - {bankDetails?.bankDetails?.confirmation_msg}
              </h6>
            )}
            {paymentStatus && <h6>{paymentStatus}</h6>}
          </div>
          <br />
          {!isPaymentSuccessful && !paymentStatus && (
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary delBtn Bank-Type"
                onClick={() => {
                  handlePaymentTransfer();
                }}
              >
                Payment transfer done
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* modal for exclusive content =============================*/}
      <Modal
        className="exclusiveModal"
        show={showExclusiveModal}
        onHide={handleCloseExclusiveModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-primary delBtn Payment-Type"
            onClick={handleShow}
          >
            Card Payment
          </button>
          <button
            className="btn btn-primary delBtn Payment-Type"
          // onClick={() => { stripModal() }}
          >
            PayPal
          </button>
          <button
            className="btn btn-primary delBtn Payment-Type"
            onClick={() => {
              handleShowBankDetails();
              handleCloseExclusiveModal();
            }}
          >
            Bank Transfer
          </button>
        </Modal.Body>
      </Modal>
      {/* Hovering Modal */}
      <Modal
        show={descModal}
        onHide={handleClose1}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ShowDescriptionModal item={hoveredItem} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
      {/* Modal for subscription */}
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered={true}
        className={`${showStripeCard ? "stripe-Modal" : "special_modal"} `}
      // contentClassName="transparent-modal"
      >
        {tvShow?.tvShowDetails?.contentType === "subscription" ? (
          !showStripeCard ? (
            <Modal.Body>
              <div class="demo">
                <div class="container">
                  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <div class="pricingTable blue">
                      <div class="pricingTable-header">
                        <i class="fa fa-diamond"></i>
                        <div class="price-value">
                          {" "}
                          $20.00 <span class="month">
                            {t("per month")}
                          </span>{" "}
                        </div>
                      </div>
                      <h3 class="heading">{t("premium").toUpperCase()}</h3>
                      <div class="pricing-content">
                        <ul>
                          <li>
                            <b>{t("Unlimited Content")}</b>
                          </li>
                          <li>
                            {t("Contains")} <b>{t("No Ads")}</b>
                          </li>
                          <li>
                            <b>$20</b>/{t("Month")}
                          </li>
                          <li>
                            <b>70GB</b> {t("Monthly Bandwidth")}
                          </li>
                        </ul>
                      </div>
                      <div class="pricingTable-signup">
                        <button
                          className="pricingTable-signup"
                          type="submit"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSubmit()}
                        >
                          {t("Subscribe")}
                        </button>
                        {/* <Stripe /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          ) : cardInfo ? (
            <>
              <Modal.Header>
                <Modal.Title>{t("Subscription")}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ marginLeft: "0.5rem" }}>
                <div className="success2FA">
                  <AiOutlineCheckCircle
                    style={{ color: "var(--iq-primary)" }}
                    size={30}
                  />
                  <span>{t("Successfully Subscribed!")}</span>
                </div>
              </Modal.Body>{" "}
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  {t("Close")}
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <Stripe
              message={"Successfully Subscribed!"}
              subscription={"paid"}
              updateData={handleUpdateData}
            />
          )
        ) : !showStripeCard ? (
          <Modal.Body>
            <div class="demo">
              <div class="container">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                  <div class="pricingTable blue">
                    <div class="pricingTable-header">
                      <i class="fa fa-diamond"></i>
                      <div class="price-value"> ${price}</div>
                    </div>
                    <h3 class="heading">{t("Exclusive")}</h3>
                    <div class="pricing-content">
                      <ul>
                        <li>
                          <b>{t("Unlimited Content")}</b>
                        </li>
                        <li>
                          {t("Contains")} <b>{t("No Ads")}</b>
                        </li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <button
                        className="pricingTable-signup"
                        type="submit"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleExclusiveSubmit()}
                      >
                        {t("Buy")}
                      </button>
                      {/* <Stripe /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : cardInfo ? (
          <>
            <Modal.Header>
              <Modal.Title>{t("Exclusive")}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "0.5rem" }}>
              <div className="success2FA">
                <AiOutlineCheckCircle
                  style={{ color: "var(--iq-primary)" }}
                  size={30}
                />
                <span>{t("Successfully Purchased!")}</span>
              </div>
            </Modal.Body>{" "}
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                {t("Close")}
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Stripe
            message={"Successfully Purchased"}
            subscription={"paid"}
            updateData={handleUpdateData}
            contentType={contenttype}
            myObj={videoObj}
            setHasUserPaid={setHasUserPaid}
          />
        )}
      </Modal>
      {/* Modal for login  */}
      <Modal
        show={showLoginModal}
        onHide={handleCloseLoginModal}
        className="special_modal"
        size="lg"
        centered={true}
      >
        <Modal.Body>
          {width >= 800 ? (
            <section className="sign-in-page-modal">
              <div className="maincontainer">
                {!subscriptionForm ? (
                  <div
                    className={`login-signup-container ${isSignUpActive ? "right-panel-active" : ""
                      }`}
                    id="container"
                  >
                    <div className="login-signup-form-container sign-up-container">
                      <div className="login-signup-form">
                        <h3>{t("Create Account")}</h3>
                        <h4>{t("Personal Details")}</h4>
                        {/* <div
                      className={`alert alert-danger ${
                        showError ? "" : "d-none"
                      }`}
                      role="alert"
                    ></div> */}
                        <div
                          className={`alert alert-danger ${showError ? "" : "d-none"
                            }`}
                          role="alert"
                        >
                          {" "}
                          {error}
                        </div>
                        <input
                          type="text"
                          placeholder="Name"
                          value={formData.name} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({ ...formData, name: e.target.value }) //setting the formData to the value input of the textfield
                          }
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          value={formData.email} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              }) //setting the formData to the value input of the textfield
                          }
                        />
                        <input
                          type="password"
                          placeholder="Password"
                          value={formData.password} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              }) //setting the formData to the value input of the textfield
                          }
                        />
                        <button
                          className="button"
                          onClick={() => {
                            handleEmailVerification();
                          }}
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                    <div className="login-signup-form-container sign-in-container">
                      <form action="#" className="login-signup-form">
                        <h3>{t("sign in").toUpperCase()}</h3>
                        <div
                          className={`alert alert-danger ${showLoginError ? "" : "d-none"
                            }`}
                          role="alert"
                        >
                          {loginError}
                          {error}
                        </div>
                        <div
                          className={`alert alert-danger ${emptyObj ? "" : "d-none"
                            }`}
                          role="alert"
                        >
                          {t("Enter email and password")}
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          required
                          name="email"
                          value={loginInput.email || ""}
                          onChange={handleInputChange}
                        />
                        <input
                          type="password"
                          placeholder="Password"
                          required
                          name="password"
                          value={loginInput.password || ""}
                          onChange={handleInputChange}
                        />
                        <Link to="/recover-password" className="f-link">
                          {t("Forgot your password?")}
                        </Link>
                        <button
                          className="button"
                          onClick={loginSubmit}
                        // onClick={() => {
                        //   // history.push("/");

                        // }}
                        >
                          {t("sign in").toUpperCase()}
                        </button>
                      </form>
                    </div>
                    <div className="overlay-container">
                      <div className="overlay">
                        <div className="overlay-panel overlay-left">
                          <h3>{t("Welcome Back!")}</h3>
                          <p>
                            {t(
                              "To keep connected with us please login with your personal info"
                            )}
                          </p>
                          <button
                            className="ghost button"
                            id="signIn"
                            onClick={handleSignInClick}
                          >
                            {t("sign in").toUpperCase()}
                          </button>
                        </div>
                        <div className="overlay-panel overlay-right">
                          <h3>{t("Hello, Friend!")}</h3>
                          <p>
                            {t(
                              "Enter your personal details and start journey with us"
                            )}
                          </p>
                          <button
                            className="ghost button"
                            id="signUp"
                            onClick={handleSignUpClick}
                          >
                            {t("sign up").toUpperCase()}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form2">
                    <div className="Subscription">
                      <div className="heading-icon">
                        <FcPrevious
                          className="prev-icon"
                          size={30}
                          onClick={() => {
                            setPage(page - 1);
                            setOtp(false);
                            setSubscriptionForm(false);
                          }}
                        />
                        <div className="heading">
                          {t(
                            "Please enter the One-Time Password to verify your account"
                          )}
                        </div>
                      </div>
                      <div className="otpbody">
                        <div className="otp-body">
                          <span style={{ marginBottom: "2rem" }}>
                            {t("A one-Time Password has been sent to")}
                            {formData.email}
                          </span>
                          <OtpInput
                            value={otpValue}
                            onChange={handleOtpChange}
                            numInputs={6}
                            isInputNum
                            renderInput={(inputProps) => (
                              <input {...inputProps} />
                            )}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.5rem",

                              borderRadius: 4,

                              outline: "none",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        {wrongEmailCode && (
                          <span style={{ color: "red" }}>
                            {t(
                              "Entered wrong code, please enter the code sent on"
                            )}
                            {formData.email}{" "}
                          </span>
                        )}
                        {userExistError !== "" && (
                          <span style={{ color: "red" }}>{userExistError}</span>
                        )}
                        <button
                          className="button"
                          // onClick={handleSignUp}

                          onClick={handleEmailCodeVerification}
                        >
                          {t("Validate")}
                        </button>

                        <div className="otp-body">
                          <h4>
                            <Link
                              onClick={() => {
                                sendEmailVerification(obj);
                                setOtpValue("");
                              }}
                            >
                              {t("Resend One Time Password")}
                            </Link>
                          </h4>
                          <span style={{ marginBottom: "2rem" }}>
                            <Link
                              onClick={() => {
                                setSubscriptionForm(false);
                                setOtp(false);
                              }}
                            >
                              {t("Entered a Wrong Email?")}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          ) : (
            <LoginMob nextPath="" />
          )}
        </Modal.Body>
      </Modal>

      {loader ? (
        <div className="loader" style={{ minHeight: "100vh" }}>
          <div className="outer"></div>
          <div className="middle"></div>
          <div className="inner"></div>
        </div>
      ) : (
        <>
          {contenttype === "free" ? (
            <div
              className="container-fluid"
              style={{
                paddingBottom: width < 770 ? "0px" : "2rem",
                paddingTop: width < 770 ? "5rem" : "8rem",
                width: width < 770 ? "100%" : "73%",
              }}
            >
              <ReactJWPlayer
                playerId="4t00MwmP"
                playerScript="https://content.jwplatform.com/libraries/4t00MwmP.js"
                playlist={`https://cdn.jwplayer.com/v2/media/${mediaId}`}
                controls={false}
                isAutoPlay={true}
              // file="https://cdn.jwplayer.com/players/WInmHLzc-4t00MwmP.htm"
              // onReady={onReady}
              />
            </div>
          ) : contenttype === "subscription" ? (
            contenttype === "subscription" &&
              currentUser?.paid === true &&
              currentUserId !== "" ? (
              <div
                className="container-fluid"
                style={{
                  paddingBottom: width < 770 ? "0px" : "2rem",
                  paddingTop: width < 770 ? "5rem" : "8rem",
                  width: width < 770 ? "100%" : "73%",
                }}
              >
                <ReactJWPlayer
                  playerId="4t00MwmP"
                  playerScript="https://content.jwplatform.com/libraries/4t00MwmP.js"
                  playlist={`https://cdn.jwplayer.com/v2/media/${mediaId}`}
                  controls={false}
                  isAutoPlay={true}
                // file="https://cdn.jwplayer.com/players/WInmHLzc-4t00MwmP.htm"
                // onReady={onReady}
                />
              </div>
            ) : (
              <div
                style={
                  {
                    // paddingBottom: "1rem",
                    // paddingTop: "4rem",
                  }
                }
              >
                {/* lock new */}
                <section
                  className="container-fluid"
                  style={{
                    paddingTop: "6rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: width < 770 ? "0px" : "2rem",
                  }}
                >
                  <div className="lock-banner animated">
                    <div
                      className="poster"
                      style={{
                        backgroundImage: `url(${bannerImg})`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="overlay_poster"></div>
                      {/* if logged in but not free content */}
                      {currentUserId &&
                        contenttype === "subscription" &&
                        currentUser?.paid === false && (
                          // subscribe to unlock
                          <div
                            to="/"
                            className="video-open playbtn gap-5 ml-5"
                            onClick={handleShow}
                            style={{
                              // position: "absolute",
                              // top: "40%",
                              // left: "25%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                              zIndex: "99",
                            }}
                          >
                            <svg
                              fill="#7accf5"
                              width="60px"
                              height="60px"
                              viewBox="0 0 32 32"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#7accf5"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0" />
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />

                              <g id="SVGRepo_iconCarrier">
                                <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z" />{" "}
                              </g>
                            </svg>
                            <span
                              className="unlock-text"
                              style={{ cursor: "pointer" }}
                            >
                              {t("Subscribe to unlock")}
                            </span>
                          </div>
                        )}
                      {!currentUserId && contenttype === "subscription" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: "99",
                          }}
                        >
                          <span className="unlock-text">
                            {t("Login first to watch the video")}
                          </span>
                          <div
                            onClick={handleShowLoginModal}
                            className="btn btn-hover iq-button mt-2"
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                          >
                            <i
                              className="fa fa-lock mr-2"
                              aria-hidden="true"
                            ></i>
                            {t("Login to watch")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            )
          ) : contenttype === "exclusive" &&
            hasUserPaid === true &&
            currentUserId !== "" ? (
            <div
              className="container-fluid"
              style={{
                paddingBottom: width < 770 ? "0px" : "2rem",
                paddingTop: width < 770 ? "5rem" : "8rem",
                width: width < 770 ? "100%" : "73%",
              }}
            >
              <ReactJWPlayer
                playerId="4t00MwmP"
                playerScript="https://content.jwplatform.com/libraries/4t00MwmP.js"
                playlist={`https://cdn.jwplayer.com/v2/media/${mediaId}`}
                controls={false}
                isAutoPlay={true}
              // file="https://cdn.jwplayer.com/players/WInmHLzc-4t00MwmP.htm"
              // onReady={onReady}
              />
            </div>
          ) : (
            <div>
              {/* lock new */}
              <section
                className="container-fluid"
                style={{
                  paddingTop: "6rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: width < 770 ? "0px" : "2rem",
                }}
              >
                <div className="lock-banner animated">
                  <div
                    className="poster"
                    style={{
                      backgroundImage: `url(${bannerImg})`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="overlay_poster"></div>
                    {/* if logged in but not free content */}
                    {currentUserId &&
                      contenttype === "exclusive" &&
                      hasUserPaid === false && (
                        // subscribe to unlock
                        <div
                          className="video-open playbtn gap-5 ml-5"
                          style={{
                            // position: "absolute",
                            // top: "40%",
                            // left: "25%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            zIndex: "99",
                          }}
                        >
                          <svg
                            fill="#7accf5"
                            width="60px"
                            height="60px"
                            viewBox="0 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#7accf5"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />

                            <g id="SVGRepo_iconCarrier">
                              <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z" />{" "}
                            </g>
                          </svg>
                          <span
                            className="unlock-text"
                            style={{ cursor: "pointer" }}
                          >
                            {t("Pay to unlock Exclusive Content")}
                          </span>
                          <div
                            className="btn btn-hover iq-button"
                            onClick={handleShowExclusiveModal}
                          >
                            {t("Get exclusive content")}
                          </div>
                        </div>
                      )}
                    {!currentUserId && contenttype === "exclusive" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: "99",
                        }}
                      >
                        <span className="unlock-text">
                          {t("Login first to watch the video")}
                        </span>
                        <div
                          onClick={handleShowLoginModal}
                          className="btn btn-hover iq-button mt-2"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          <i className="fa fa-lock mr-2" aria-hidden="true"></i>
                          {t("Login to watch")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              {/* <section
                  className="lock-banner-container container-fluid "
                  style={
                    {
                      
                    }
                  }
                >
                  <div className="trending-info season-banner align-items-center w-100 animated fadeInUp pl-5">
                    <img
                      src={
                        bannerImg ||
                        "https://c4.wallpaperflare.com/wallpaper/957/544/1004/movie-poster-simple-background-fictional-batman-wallpaper-preview.jpg"
                      }
                      alt=""
                    />

                    <div className="d-flex align-items-center series mb-4">
                      <ul className="p-0 list-inline d-flex movie-content">
                        
                      </ul>
                    </div>
                    <div className="d-flex align-items-center text-white text-detail">
                      
                    </div>

                    {currentUserId && contentType !== "free" && (
                      <div className="unlock-button  mt-5 mr-5">
                        <Link
                          onClick={handleShow}
                          to="#"
                          className="unlock-button-link d-flex flex-column align-items-center justify-content-center gap-5"
                        >
                          <svg
                            fill="#7accf5"
                            width="80px"
                            height="80px"
                            viewBox="0 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#7accf5"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />

                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />

                            <g id="SVGRepo_iconCarrier">
                              <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z" />{" "}
                            </g>
                          </svg>
                          <span className="w-trailor unlock-text">
                            Subscribe to unlock
                          </span>
                        </Link>
                      </div>
                    )}
                    {!currentUserId && (
                      <div className="unlock-button  mt-5 mr-5">
                        <div className="unlock-button-link d-flex flex-column align-items-center justify-content-center gap-5">
                          <span className=" login-unlock-text">
                            Login first to watch the video
                          </span>
                          <div
                            onClick={handleShowLoginModal}
                            className="btn btn-hover iq-button mt-2"
                          >
                            <i
                              className="fa fa-lock mr-2"
                              aria-hidden="true"
                            ></i>
                            Login to watch
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section> */}
            </div>
          )}
          <div className="main-content.movi">
            <section className="movie-detail container-fluid">
              <Row>
                <Col lg="12">
                  <div className="trending-info season-info g-border">
                    <h4 className="trending-text big-title text-uppercase mt-0">
                      {seasonTitle}
                    </h4>
                    <div className="d-flex align-items-center text-white text-detail episode-name mb-0">
                      <span>
                        S{seasonNumber}E{formattedEpisodeNumber}
                      </span>
                      <span className="trending-year"> {currentSelectedLanguage !== "fr" ? content?.episodeObj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :movieTitle }</span>
                    </div>{" "}
                    <div className="movie-tags  ">
                      <div className=" text-white movie-desc-navigator__desc-text">
                        <i
                          className="fa fa-tags mr-1"
                          style={{
                            color: "#21a6e0",
                            fontSize: "17px",
                          }}
                        ></i>
                        <span
                          style={{
                            color: "#21a6e0",
                            marginRight: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("tags").toUpperCase()}:
                        </span>
                        {jwTags
                          ?.filter((tag) => !tag.endsWith("-episode"))
                          .map((tag, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && ", "}
                              <span className="text-white">{tag}</span>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    {/* <div className="d-flex movie-tags mb-4">
                  <div>
                    <i className="ri-price-tag-3-fill"></i>
                    Tags:
                  </div>
                  <div className="d-flex movie-tags-tag">
                    {jwTags?.map((jwTagsObj) => (
                      <span className="text-white" key={jwTagsObj._id}>
                        {jwTagsObj}
                      </span>
                    ))}
                  </div>
                </div> */}
                    <ul className="list-inline p-0 mt-3 mb-0 share-icons music-play-lists">
                      <li className="share">
                        <span>
                          <i className="ri-share-fill"></i>
                        </span>
                        <div className="share-box">
                          <div className="d-flex align-items-center">
                            <a
                              href={EpisodeFbShareUrl(
                                episodeObjectId,
                                mediaCategory,
                                EPtitle,
                                EPDesc
                              )}
                              onClick={handleAnchorClick}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="share-ico"
                              tabIndex="0"
                            >
                              <i className="ri-facebook-fill"></i>
                            </a>
                            <a
                              href={EpisodeTwitterShareUrl(
                                episodeObjectId,
                                mediaCategory,
                                EPtitle,
                                EPDesc
                              )}
                              onClick={handleAnchorClick}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="share-ico"
                              tabIndex="0"
                            >
                              <i className="ri-twitter-fill"></i>
                            </a>
                            <a
                              href={EpisodeWhatsappShareUrl(
                                episodeObjectId,
                                mediaCategory,
                                EPtitle,
                                EPDesc
                              )}
                              onClick={handleAnchorClick}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="share-ico iq-copy-link"
                              tabIndex="0"
                            >
                              <i className="ri-whatsapp-fill"></i>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="share">
                        <span>
                          <i
                            className={`ri-thumb-up-fill ${isLiked ? "blue-thumb-up" : ""
                              }`}
                            onClick={handleLike}
                            title={isLiked ? t("UnLike") : t("Like")}
                          ></i>
                          <span className="count-box">{likeCount}</span>
                        </span>
                      </li>
                    </ul>
                    <div
                      // style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                      className="d-flex align-items-center text-white text-detail flex-wrap mb-0"
                    >
                      <span
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0.5rem",
                        }}
                      >
                        <i
                          class="ri-eye-line"
                          style={{
                            color: "#21a6e0",
                            fontSize: "17px",
                            marginBottom: "1rem",
                          }}
                        ></i>{" "}
                        {dataRows && dataRows.length > 0 ? (
                          <p>
                            {formatViewCount(dataRows[0][3])} {t("Views")}
                          </p>
                        ) : (
                          <p>0 {t("Views")}</p>
                        )}
                      </span>

                      <p
                        style={{ color: "#21a6e0", fontSize: "1rem" }}
                        className="trending-year"
                      >
                        {convertSecondsToHoursMinutes(duration)}
                      </p>
                      <p className="trending-year" style={{ fontSize: "1rem" }}>
                        {dateToWords(releaseDate)}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
            <section>
              <div className="movie-desc-navigator">
                <button
                  className={` ${activeBtn === "description"
                    ? "movie-desc-navigator__active-btn movie-desc-navigator-btn "
                    : "movie-desc-navigator-btn"
                    }`}
                  onClick={() => handleClick("description")}
                >
                  {t("Description")}
                </button>
                <button
                  className={` ${activeBtn === "review"
                    ? "movie-desc-navigator__active-btn movie-desc-navigator-btn"
                    : "movie-desc-navigator-btn"
                    }`}
                  onClick={() => handleClick("review")}
                >
                  {t("Leave a Comment")}
                </button>
              </div>
            </section>
            <section>
              {activeBtn === "description" && (
                <section className="container-fluid">
                  
                  <Row className=" fs-5 mt-5">
                    <Col lg="12" md="12" className="overflow-hidden">
                      <span
                        className="movie-desc-navigator__desc-text "
                        dangerouslySetInnerHTML={{
                          __html:  currentSelectedLanguage !== "fr" ? content?.episodeObj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :description ,
                        }}
                      ></span>
                    </Col>
                  </Row>
                </section>
              )}
              {activeBtn === "review" && (
                <section className="mt-5 mb-5 container-fluid">
                  <h4 className="mb-2 comments-heading">{t("Comments")}:</h4>

                  <EpisodesComments
                    tvShowId={tvShowDetailsId}
                    mediaCategory={mediaCategory}
                    episodeObjectId={episodeObjectId}
                    episodeCommentFlag={episodeCommentFlag}
                    setEpisodeCommentFlag={setEpisodeCommentFlag}
                  />
                  <AddCommentEpisodes
                    tvShowId={tvShowDetailsId}
                    mediaCategory={mediaCategory}
                    episodeObjectId={episodeObjectId}
                    setEpisodeCommentFlag={setEpisodeCommentFlag}
                  />
                </section>
              )}
              <div className="gradient-line"></div>
            </section>
            {sameSeasonEpisodes?.length > 1 && (
              <section id="iq-favorites">
                <Container fluid>
                  <div className="block-space">
                    <Row>
                      <Col sm="12" className="overflow-hidden">
                        <div className="iq-main-header d-flex align-items-center justify-content-between">
                          <h4 className="main-title">{t("Other Episodes")}</h4>
                          {/* <Link to="#" className="text-primary">
                      View all
                    </Link> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Row>
                <Col sm="12" className="overflow-hidden">
                  <div className="iq-main-header d-flex align-items-center justify-content-between">
                    {!sameSeasonEpisodes ||
                      (sameSeasonEpisodes.lenght === 0 ? (
                        <h4 className="main-title">{t("Other Episodes")}</h4>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <h4>No more episodes found!</h4>
                        </div>
                      ))}

                    
                  </div>
                </Col>
              </Row> */}
                      {sameSeasonEpisodes?.map((episode, index) => {
                        if (episode?._id === id) {
                          return null;
                        }
                        return (
                          <Col
                            xl="3"
                            lg="4"
                            md="4"
                            sm="6"
                            xs="12"
                            key={index}
                            className="mb-3"
                          >
                            <div className="epi-box">
                              <div className="epi-img position-relative">
                                <img
                                  src={episode?.thumbnail?.static_thumbnail_url}
                                  className="img-fluid img-zoom"
                                  alt=""
                                />
                                <span className="episode-tag">
                                  {index < 10
                                    ? "EO" + (index + 1)
                                    : "E" + (index + 1)}
                                </span>
                                <div className="episode-play-info">
                                  <div
                                    className="episode-play"
                                    style={{ backgroundColor: "#fff" }}
                                  >
                                    <a href={`/episode/${episode?._id}`}>
                                      <i className="ri-play-fill"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="epi-desc p-3">
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="episode-release-date">
                                    {dateToWords(episode?.release_year)}
                                  </span>
                                  <span className="text-primary episode-tag-duration">
                                    {convertSecondsToHoursMinutes(
                                      episode?.duration
                                    )}
                                  </span>
                                </div>
                                <h6 className="epi-name text-white mb-0 mt-3">
                               

                                  {currentSelectedLanguage !== "fr" ? episode?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :episode?.title }

                                </h6>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <section id="iq-favorites">
                        <Container fluid>
                          <div className="block-space">
                            <Row>
                              <Col sm="12" className="overflow-hidden">
                                <div className="iq-main-header d-flex align-items-center justify-content-start"></div>
                              </Col>
                            </Row>

                            {/* {selectedSeason &&
                        selectedSeason?.episodes &&
                        noOfEpisodes < selectedSeason?.episodes.length && (
                          <div className="text-center flex justify-content-center mt-5">
                            <button
                              className="btn btn-primary"
                              onClick={handleEpisodesNumber}
                            >
                              Show More Episodes
                            </button>
                          </div>
                        )} */}
                          </div>
                        </Container>
                      </section>
                    </Row>
                  </div>
                </Container>
              </section>
            )}
          </div>
          {similarCopy?.length > 0 && (
            <section id="iq-favorites">
              <Container fluid>
                <Row>
                  <Col sm="12" className="overflow-hidden">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="main-title">{t("More Like This")}</h4>
                      <Link className="iq-view-all" to="/">
                        {/* View All */}
                      </Link>
                    </div>
                    <div id="favorites-contens">
                      <div
                        id="prev1"
                        className="swiper-button swiper-button-prev"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <div
                        id="next1"
                        className="swiper-button swiper-button-next"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        navigation={{
                          prevEl: "#prev1",
                          nextEl: "#next1",
                        }}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          550: { slidesPerView: 2 },
                          991: { slidesPerView: 3 },
                          1400: { slidesPerView: 4 },
                          1600: { slidesPerView: 5 },
                        }}
                        loop={false}
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                      >
                        {similarCopy?.map((data) => (
                          <SwiperSlide as="li">
                            <a
                              href={
                                data?.category !== "ads"
                                  ? `/show-info/${data?._id}`
                                  : null
                              }
                              onClick={(event) => handleAdClicked(event, data)}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="block-images position-relative slider-img-main"
                                onMouseEnter={() => {
                                  if (data?.category !== "ads") {
                                    handleMouseEnter(data);
                                  } else {
                                    handleMouseEnterAD(data);
                                  }
                                }}
                                onMouseLeave={() => {
                                  if (data?.category !== "ads") {
                                    handleMouseLeave();
                                  } else {
                                    handleMouseLeaveAD();
                                  }
                                }}
                              >
                                <div className="img-box">
                                  {data?.category === "tvshow" ||
                                    data?.category === "podcast" ? (
                                    <img
                                      src={
                                        data?.thumbnail?.banner_thumbnail_url
                                      }
                                      className="img-fluid slider-img"
                                      alt=""
                                    />
                                  ) : data?.media_id !== "" ? (
                                    <ReactPlayer
                                      className="img-fluid slider-img"
                                      url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                      width="100%"
                                      height="100%"
                                      pip={false}
                                      controls={false}
                                      playing={true}
                                      muted={true}
                                      loop={true}
                                    />
                                  ) : (
                                    <img
                                      src={data?.banner_url}
                                      className="img-fluid slider-img"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="card-slider-badge">
                                  <div className="badge badge-secondary p-1 mr-2">
                                    {data?.category === "movie" ||
                                      data?.category === "video"
                                      ? convertSecondsToHoursMinutes(
                                        data?.media?.duration.toFixed(2)
                                      )
                                      : data?.category === "ads"
                                        ? null
                                        : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                          ? "Episode"
                                          : "Episodes"
                                        }`}
                                  </div>
                                </div>
                                <div className="block-description ">
                                  <div className="card-slider-content">
                                    <h6 className="iq-title title-desc">
                                    
                                       
                                 
                                  {currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  :data?.title }
                                    </h6>
                                  </div>
                                  <div className="card-slider-desc">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: limitTextToWords(
                                          (currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  :data?.description),
                                          12
                                        ),
                                      }}
                                    ></span>
                                  </div>
                                  {data?.category !== "ads" && (
                                    <div className="block-social-info-button">
                                      <li className="share">
                                        <div className="hover-buttons">
                                          <Link
                                            to={`/show-info/${data?._id}`}
                                            className="btn btn-hover iq-button"
                                          >
                                            <i
                                              className="fa fa-play mr-2"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Play now")}
                                          </Link>
                                        </div>
                                      </li>
                                    </div>
                                  )}
                                </div>
                                {data?.category !== "ads" && (
                                  <div className="block-social-info">
                                    <ul className="list-inline p-0 m-0 music-play-lists">
                                      <li className="share">
                                        <span>
                                          <i className="ri-share-fill"></i>
                                        </span>
                                        <div className="share-box">
                                          <div className="d-flex align-items-center">
                                            <a
                                              href={generateFacebookShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-facebook-fill"></i>
                                            </a>
                                            <a
                                              href={generateTwitterShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-twitter-fill"></i>
                                            </a>
                                            <a
                                              href={generateWhatsappShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              onClick={handleAnchorClick}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico iq-copy-link"
                                              tabIndex="0"
                                            >
                                              <i className="ri-whatsapp-fill"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-heart-fill ${favoriteItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                              }`}
                                            onClick={(event) =>
                                              handleFavClick(
                                                event,
                                                data?._id,
                                                data?.category
                                              )
                                            }
                                            title={
                                              favoriteItems.includes(data?._id)
                                                ? t("Remove From Favourite")
                                                : t("Add To Favourite")
                                            }
                                          ></i>
                                        </span>
                                        {/* <span className="count-box">19+</span> */}
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-add-line ${watchListItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                              }`}
                                            onClick={(event) =>
                                              handleWatchList(
                                                event,
                                                data?._id,
                                                data?.category
                                              )
                                            }
                                            title={
                                              watchListItems.includes(data?._id)
                                                ? t("Remove From WatchList")
                                                : t("Add To WatchList")
                                            }
                                          ></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </a>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          {genresData.length > 0 &&
            genresData?.map((data, index) => {
              if (data?.generalContents?.length > 0) {
                return (
                  <section id="iq-upcoming-movie">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12 overflow-hidden">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="main-title">{t(data.genre)}</h4>
                            <Link className="iq-view-all" to="/">
                              {/* View All */}
                            </Link>
                          </div>
                          <div id="upcoming-contens">
                            <div
                              id={`prev2${index}`}
                              className="swiper-button swiper-button-prev"
                            >
                              <i className="fa fa-chevron-left"></i>
                            </div>
                            <div
                              id={`next2${index}`}
                              className="swiper-button swiper-button-next"
                            >
                              <i className="fa fa-chevron-right"></i>
                            </div>
                            <Swiper
                              slidesPerView={4}
                              spaceBetween={20}
                              navigation={{
                                nextEl: `#prev2${index}`,
                                prevEl: `#next2${index}`,
                              }}
                              breakpoints={{
                                320: { slidesPerView: 1 },
                                550: { slidesPerView: 2 },
                                991: { slidesPerView: 3 },
                                1400: { slidesPerView: 4 },
                                1600: { slidesPerView: 5 },
                              }}
                              loop={false}
                              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                            >
                              {data?.generalContents?.map((Obj, index) => (
                                <SwiperSlide as="li">
                                  <Link to={`/show-info/${Obj?._id}`}>
                                    <div
                                      className="block-images position-relative slider-img-main"
                                      onMouseEnter={() => handleMouseEnter(Obj)}
                                      onMouseLeave={handleMouseLeave}
                                    // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                    >
                                      <div className="img-box">
                                        <img
                                          src={
                                            Obj?.thumbnail?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      </div>
                                      {/* showing on the top */}
                                      <div className="card-slider-badge">
                                        <div className="badge badge-secondary p-1 mr-2">
                                          {Obj?.episodes.length}{" "}
                                          {Obj?.episodes.length === 1
                                            ? "Episode"
                                            : "Episodes"}
                                        </div>
                                      </div>
                                      <div className="block-description ">
                                        <div className="card-slider-content">
                                          {Obj?.category === "movie" ||
                                            Obj?.category === "video" ? (
                                            <h6 className="iq-title title-desc">
                                                {currentSelectedLanguage !== "fr" ? Obj?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  : Obj?.media?.title }
                                            
                                            </h6>
                                          ) : (
                                            <h6 className="iq-title title-desc">
                                          
                                              {currentSelectedLanguage !== "fr" ? Obj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated  : Obj?.title }
                                              
                                            </h6>
                                          )}
                                        </div>
                                        <div className="card-slider-desc">
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: limitTextToWords(
                                                currentSelectedLanguage !== "fr" ? Obj?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.description_translated  : Obj?.description,
                                                12
                                              ),
                                            }}
                                          ></span>
                                        </div>
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              <Link
                                                to={`/show-info/${Obj?._id}`}
                                                className="btn btn-hover iq-button"
                                              >
                                                <i
                                                  className="fa fa-play mr-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {t("Play now")}
                                              </Link>
                                            </div>
                                          </li>
                                        </div>
                                      </div>
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={genreFacebookShareUrl(
                                                    Obj,
                                                    source,
                                                    Obj?.category,
                                                    Obj?._id
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={genreTwitterShareUrl(
                                                    Obj,
                                                    source,
                                                    Obj?.category,
                                                    Obj?._id
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={genreWhatsappShareUrl(
                                                    Obj,
                                                    source,
                                                    Obj?.category,
                                                    Obj?._id
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  Obj?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    Obj?._id,
                                                    Obj?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    Obj?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                            {/* <span className="count-box">19+</span> */}
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  Obj?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    Obj?._id,
                                                    Obj?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    Obj?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </Link>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                );
              } else {
                return null;
              }
            })}
        </>
      )}
    </>
  );
};
export default ShowList;
