import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";

const Genre = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [tvShowView, setTvShowView] = useState(8);
  const [podCastView, setPodCastView] = useState(8);
  const [liveChannelsView, setLiveChannelsView] = useState(8);
  const [loader, setLoader] = useState(true);

  const [view, setView] = useState({ video: 8, movies: 8 });

  const handleShowMore = (type) => {
    setView((prevState) => ({
      ...prevState,
      [type]: prevState[type] + 8,
    }));
  };
  const handleLiveChannel = (type) => {
    setLiveChannelsView(liveChannelsView + 8);
  };
  const handleTvShowMore = () => {
    setTvShowView(tvShowView + 8);
  };
  const handlePodCastShowMore = () => {
    setPodCastView(podCastView + 8);
  };
  const fetchData = () => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_API}/api/genres/get-all-genres`)
      .then((res) => res.json())
      .then((result) => {
        setData(result.allGenres);
        setLoader(false);
      });
  };

  const location = useLocation();
  useEffect(() => {
    fetchData();
    const searchParams = new URLSearchParams(location.search);
    setQuery(searchParams.get("query") || "");
  }, [location]);
  return (
    <>
      <br />
      <div style={{ marginTop: "80px" }}></div>
      {/* <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-start" style={{ marginTop: "20px" }}>
                    <h4 className="main-title" style={{ marginLeft: "10px" }}>Videos</h4>
                </div>
                <div className="gallery-container" style={{ marginTop: "20px" }}>
                    {data
                        .filter((res) => res.genre_type === "videos")
                        .slice(0, view.video)
                        .map((res) => (
                            <Col xl="3" lg="4" md="6" sm="6" xs="6" xxs="12" >
                                <Link to={`/genreView/${res?._id}`} genre_image={res.genre_image} genre_type={res.genre_type} name={res.name}>
                                    <div className="gallery-item" key={res._id}>
                                        <div className=" block-images position-relative slider-img-main">
                                            <div className="img-box">
                                                <img
                                                    src={res?.genre_image?.url}
                                                    width="{100}" height="{100}"
                                                    className="img-fluid slider-genre-img"
                                                    alt=""
                                                    style={{ objectFit: "cover" }}
                                                />
                                            </div>
                                            <div className="block-description-genre">
                                                <h6 className="iq-title-genre">
                                                    <Link to={`/genreView/${res?._id}`} genre_image={res.genre_image} genre_type={res.genre_type} name={res.name}>
                                                        {res?.name}
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                </div>
                <div className="text-start" style={{ marginLeft: "10px" }}>
                    {view.video < data.length && data.filter((res) => res.genre_type === "videos").length > view.video && (
                        <button onClick={() => handleShowMore('video')} className="load-more-button">
                             {t("load more")}
                        </button>
                    )}
                </div>
            </div> */}

      {loader ? (
        <div style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ marginTop: "20px" }}
            >
              <h4 className="main-title" style={{ marginLeft: "10px" }}>
                {t("Videos")}
              </h4>
            </div>
            <div className="gallery-container" style={{ marginTop: "20px" }}>
              {data
                ?.filter((res) => res.genre_type === "movies")
                .slice(0, view.movies)

                .map((res) => (
                  <Col xl="3" lg="4" md="6" sm="6" xs="12">
                    <Link
                      to={`/genreView/${res?._id}`}
                      genre_image={res.genre_image}
                      genre_type={res.genre_type}
                      name={res.name}
                    >
                      <div className="gallery-item" key={res._id}>
                        <div className=" block-images position-relative slider-img-main">
                          <div className="img-box">
                            <img
                              src={res?.genre_image?.url}
                              width="{100}"
                              height="{100}"
                              className="img-fluid slider-genre-img"
                              alt=""
                              style={{ objectFit: "cover" }}
                            />
                          </div>
                          <div
                            className="block-description-genre"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                            }}
                          >
                            <h5 className="iq-title-genre">
                              <Link
                                to={`/genreView/${res?._id}`}
                                genre_image={res.genre_image}
                                name={res.name}
                              >
                                {res?.name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
            </div>
            <div className="text-start" style={{ marginLeft: "10px" }}>
              {/* {moviesView < data.length && data.filter((res) => res.genre_type === "movies").length > moviesView && ( */}
              {view.movies < data.length &&
                data?.filter((res) => res.genre_type === "movies").length >
                  view.movies && (
                  <button
                    onClick={() => handleShowMore("movies")}
                    className="load-more-button"
                  >
                    {t("load more")}
                  </button>
                )}
            </div>
          </div>
          <div className="container-fluid">
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ marginTop: "20px" }}
            >
              <h4 className="main-title" style={{ marginLeft: "10px" }}>
                {t("Tv Shows")}
              </h4>
            </div>
            <div className="gallery-container" style={{ marginTop: "20px" }}>
              {data
                ?.filter((res) => res.genre_type === "tv shows")
                .slice(0, tvShowView)

                .map((res) => (
                  <Col xl="3" lg="4" md="6" sm="6" xs="12">
                    <Link
                      to={`/genreView/${res?._id}`}
                      genre_image={res.genre_image}
                      genre_type={res.genre_type}
                      name={res.name}
                    >
                      <div className="gallery-item" key={res._id}>
                        <div className=" block-images position-relative slider-img-main">
                          <div className="img-box">
                            <img
                              src={res?.genre_image?.url}
                              width="{100}"
                              height="{100}"
                              className="img-fluid slider-genre-img"
                              alt=""
                              style={{ objectFit: "cover" }}
                            />
                          </div>
                          <div
                            className="block-description-genre"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                            }}
                          >
                            <h5 className="iq-title-genre">
                              <Link
                                to={`/genreView/${res?._id}`}
                                genre_image={res.genre_image}
                                name={res.name}
                              >
                                {res?.name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
            </div>
            <div className="text-start" style={{ marginLeft: "10px" }}>
              {tvShowView < data.length &&
                data.filter((res) => res.genre_type === "tv shows").length >
                  tvShowView && (
                  <button
                    onClick={handleTvShowMore}
                    className="load-more-button"
                  >
                    {t("load more")}
                  </button>
                )}
            </div>
          </div>

          <div className="container-fluid">
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ marginTop: "20px" }}
            >
              <h4 className="main-title" style={{ marginLeft: "10px" }}>
                {t("Podcast")}
              </h4>
            </div>
            <div className="gallery-container" style={{ marginTop: "20px" }}>
              {data
                ?.filter((res) => res.genre_type === "podcast")
                .slice(0, podCastView)

                .map((res) => (
                  <Col xl="3" lg="4" md="6" sm="6" xs="12">
                    <Link
                      to={`/genreView/${res?._id}`}
                      genre_image={res.genre_image}
                      genre_type={res.genre_type}
                      name={res.name}
                    >
                      <div className="gallery-item" key={res._id}>
                        <div className=" block-images position-relative slider-img-main">
                          <div className="img-box">
                            <img
                              src={res?.genre_image?.url}
                              width="{100}"
                              height="{100}"
                              className="img-fluid slider-genre-img"
                              alt=""
                              style={{ objectFit: "cover" }}
                            />
                          </div>
                          <div
                            className="block-description-genre"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                            }}
                          >
                            <h5 className="iq-title-genre">
                              <Link
                                to={`/genreView/${res?._id}`}
                                genre_image={res.genre_image}
                                name={res.name}
                              >
                                {res?.name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
            </div>
            <div className="text-start" style={{ marginLeft: "10px" }}>
              {podCastView < data.length &&
                data.filter((res) => res.genre_type === "podcast").length >
                  podCastView && (
                  <button
                    onClick={handlePodCastShowMore}
                    className="load-more-button"
                  >
                    {t("load more")}
                  </button>
                )}
            </div>
          </div>
          <div className="container-fluid">
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ marginTop: "20px" }}
            >
              <h4 className="main-title" style={{ marginLeft: "10px" }}>
                {t("Live Channels")}
              </h4>
            </div>
            <div className="gallery-container" style={{ marginTop: "20px" }}>
              {data
                ?.filter((res) => res.genre_type === "live channels")
                .slice(0, liveChannelsView)

                .map((res) => (
                  <Col
                    xl="3"
                    lg="4"
                    md="6"
                    sm="6"
                    xs="12"
                    className={window.innerWidth < 580 ? "col-12" : ""}
                  >
                    <Link
                      to={`/genreView/${res?._id}`}
                      genre_image={res.genre_image}
                      genre_type={res.genre_type}
                      name={res.name}
                    >
                      <div className="gallery-item" key={res._id}>
                        <div className=" block-images position-relative slider-img-main">
                          <div className="img-box">
                            <img
                              src={res?.genre_image?.url}
                              width="{100}"
                              height="{100}"
                              className="img-fluid slider-genre-img"
                              alt=""
                              style={{ objectFit: "cover" }}
                            />
                          </div>
                          <div
                            className="block-description-genre"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                            }}
                          >
                            <h5 className="iq-title-genre">
                              <Link
                                to={`/genreView/${res?.genre_type}/${res?._id}`}
                                genre_image={res.genre_image}
                                name={res.name}
                              >
                                {res?.name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
            </div>
            <div className="text-start" style={{ marginLeft: "10px" }}>
              {liveChannelsView < data.length &&
                data.filter((res) => res.genre_type === "live channels")
                  .length > liveChannelsView && (
                  <button
                    onClick={handleLiveChannel}
                    className="load-more-button"
                  >
                    {t("load more")}
                  </button>
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Genre;
