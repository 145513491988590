import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { languages } from "./../../../components/partials/backend/headerstyle/headerstyle1";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import { limitTextToWords } from "../../../Services/generalFunctions/generalFunctions";
import Loader from "../../../components/Loader";
SwiperCore.use([
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
]);
const LiveChannels = () => {
  const currentSelectedLanguage = localStorage.getItem("lang");
  const isInitialRender = useRef(true);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (
      !isInitialRender.current &&
      currentSelectedLanguage &&
      location.pathname.includes("/live-channel")
    ) {
      history.push("/");
    }
    isInitialRender.current = false;
  }, [currentSelectedLanguage, history, location]);
  const fetchData = () => {
    fetch(
      `${process.env.REACT_APP_API}/api/channel/get-all-active-channels/${currentSelectedLanguage}`
    )
      .then((res) => res.json())
      .then((result) => {
        setLoader(false);
        setData(result.allChannels);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentSelectedLanguage]);

  const [genre, setGenre] = useState([]);
  const fetchGenre = () => {
    fetch(`${process.env.REACT_APP_API}/api/genres/get-all-genres`)
      .then((res) => res.json())
      .then((result) => {
        const liveChannelsGenres = result?.allGenres
          ?.filter((genre) => genre?.genre_type === "live channels")
          ?.map((genre) => genre?.name);
        setGenre(liveChannelsGenres);
      });
  };
  useEffect(() => {
    fetchGenre();
  }, []);

  const [selectedGenres, setSelectedGenres] = useState(["all"]);
  const handleGenreClick = (type) => {
    if (type === "all") {
      setSelectedGenres(["all"]);
    } else if (selectedGenres.includes("all")) {
      if (selectedGenres.includes(type)) {
        setSelectedGenres(
          selectedGenres.filter((g) => g !== type && g !== "all")
        );
      } else {
        setSelectedGenres(
          selectedGenres.filter((g) => g !== "all").concat(type)
        );
      }
    } else if (!selectedGenres.includes(type)) {
      const updatedGenres = [...selectedGenres, type];
      if (updatedGenres.length === genre.length) {
        setSelectedGenres([...updatedGenres, "all"]);
      } else {
        setSelectedGenres(updatedGenres);
      }
    } else {
      setSelectedGenres(selectedGenres.filter((g) => g !== type));
      if (selectedGenres.length === genre.length) {
        setSelectedGenres([...selectedGenres.filter((g) => g !== type), "all"]);
      }
    }
  };

  const removeGenre = (genreToRemove) => {
    setSelectedGenres(
      selectedGenres.filter((genre) => genre !== genreToRemove)
    );
  };

  useEffect(() => {
    if (selectedGenres.length === 0) {
      setSelectedGenres(["all"]);
    }
  }, [selectedGenres]);
  return (
    <>
      <br />
      {loader ? (
        <div style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          <div style={{ marginTop: "80px" }}></div>
          <div className="container-fluid" style={{ minHeight: "80vh" }}>
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ marginTop: "20px" }}
            >
              <h4 className="main-title" style={{ marginBottom: "10px" }}>
                {t("Live Channels")}
              </h4>
            </div>
            <Row>
              {" "}
              <Col
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
                xxs="12"
                style={{ margin: "14px 0" }}
              >
                <div
                  style={{
                    width: "100%",
                    // overflow: "hidden",
                    paddingTop: "1rem",
                    whiteSpace: "nowrap",
                    paddingBottom: "1rem",
                  }}
                  className="channel-genre"
                >
                  {" "}
                  <div
                    className={`tag-item ${
                      selectedGenres.includes("all") ? "selected" : ""
                    }`}
                    style={{
                      marginLeft: selectedGenres.includes("all")
                        ? "8px"
                        : "4px",
                      marginRight: selectedGenres.includes("all")
                        ? "8px"
                        : "4px",
                      transform: selectedGenres.includes("all")
                        ? "scale(1.1)"
                        : "scale(1)",
                    }}
                    onClick={() => handleGenreClick("all")}
                  >
                    <span className="text">All</span>
                  </div>
                  {genre?.map((type) => (
                    <div
                      key={type}
                      className={`tag-item ${
                        selectedGenres.includes(type) ? "selected" : ""
                      }`}
                      style={{
                        marginLeft: selectedGenres.includes(type)
                          ? "8px"
                          : "4px",
                        marginRight: selectedGenres.includes(type)
                          ? "8px"
                          : "4px",
                        transform: selectedGenres.includes(type)
                          ? "scale(1.1)"
                          : "scale(1)",
                      }}
                      onClick={() => handleGenreClick(type)}
                    >
                      <span className="text">{type}</span>

                      {selectedGenres.includes(type) && (
                        <span
                          className="close-tag"
                          onClick={() => removeGenre(type)}
                          style={{ zIndex: "1000" }}
                        >
                          &times;
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            {/* <div className="gallery-container"> */}
            {data?.length > 0 ? (
              <Row>
                {data
                  ?.filter(
                    (res) =>
                      selectedGenres.length === 0 ||
                      selectedGenres.includes("all") ||
                      res.genre.some((genre) =>
                        selectedGenres.includes(genre.name)
                      )
                  )
                  .map((res) => (
                    <Col
                      xl="4"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                      xxs="12"
                      style={{ margin: "14px 0" }}
                    >
                      <Link
                        to={
                          res?.channel_type === "m3u"
                            ? `/live-channel/${res?._id}`
                            : `/live/${res?._id}`
                        }
                      >
                        <div
                          className="image-size"
                          key={res._id}
                          style={{
                            position: "relative",
                            width: "100%",
                            paddingTop: "56.25%",
                          }}
                        >
                          {/* <div className="img-box"> */}
                          <img
                            src={res?.thumbnail?.banner_thumbnail_url}
                            className="img-fluid liveChannel-image"
                            alt=""
                            style={{
                              position: "absolute",
                              top: 0,
                              height: "100% !important",
                              objectFit: "cover",
                            }}
                          />
                          {/* </div> */}
                          <div className="content-size-live-badge">
                            <div className="d-flex justify-content-end text-uppercase font-size-18">
                              <div
                                className="live-button-badge"
                                style={{ marginRight: "1rem" }}
                              >
                                <FaCircle className="circle-icon" />
                                <span className="label">LIVE</span>
                              </div>
                            </div>
                          </div>

                          <div className="content-size-live">
                            <div className="live-channel-container">
                              <h1
                                className="live-title big-title title text-uppercase"
                                data-iq-gsap="onStart"
                                data-iq-position-x="-200"
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {res?.title}
                              </h1>
                              <div
                                className="movie-time d-flex align-items-start justify-content-between"
                                style={{ color: "white" }}
                              >
                                <p
                                  data-iq-gsap="onStart"
                                  data-iq-position-y="80"
                                  data-iq-delay="0.8"
                                  className="paraOverflow"
                                  dangerouslySetInnerHTML={{
                                    __html: res?.description,
                                  }}
                                >
                                  {/* {mainSlider?.category === "movie" ||
                              mainSlider?.category === "video"
                                ? mainSlider?.general_content?.media
                                    ?.description
                                : mainSlider?.show?.description} */}
                                </p>
                              </div>
                              <div className="livebtn-hover">
                                {res?.channel_type === "m3u" ? (
                                  <Link
                                    to={`/live-channel/${res?._id}`}
                                    className="btn btn-hover iq-button-live livebtn"
                                  >
                                    <i
                                      className="fa fa-play mr-2"
                                      aria-hidden="true"
                                    ></i>
                                    {t("Play now")}
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/live/${res?._id}`}
                                    className="btn btn-hover iq-button-live livebtn"
                                  >
                                    <i
                                      className="fa fa-play mr-2"
                                      aria-hidden="true"
                                    ></i>
                                    {t("Play now")}
                                  </Link>
                                )}
                              </div>
                            </div>

                            {/* <div
                                        className="d-flex r-mb-23 justify-content-end view-btn"
                                        data-iq-gsap="onStart"
                                        data-iq-position-y="80"
                                        data-iq-delay="0.8"
                                    >
                                        <div className="livebtn-hover">
                                            <Link
                                                to={`/live-channell/${res?._id}`}
                                                className="btn btn-hover iq-button livebtn"
                                            >
                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>
                                                Play now
                                            </Link>
                                        </div>
                                    </div> */}
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))}
              </Row>
            ) : (
              <div>
                <h3
                  className=" d-flex justify-content-center align-items-center"
                  style={{ marginTop: "5rem", marginBottom: "5rem" }}
                >
                  {t("No live channels!")}
                </h3>
              </div>
            )}
            {/* </div> */}
          </div>
        </>
      )}
    </>
  );
};
export default LiveChannels;
