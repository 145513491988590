import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import { Modal } from "react-bootstrap";
import ReactJWPlayer from "react-jw-player";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./moviePoster.css";
import getGeneralContent from "./../Services/generalContent";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../views/backend/home/FavWatch";
import { currentUserToken } from "./../views/backend/home/home";
import {
  dateToWords,
  getDate,
} from "../Services/generalFunctions/generalFunctions";
const VideoPoster = ({
  thumbnail,
  title,
  description,
  seoTags,
  trailer,
  crewNames,
  crewRoles,
  jwTags,
  mediaCategory,
  tvID,
  monetizationCheck,
  genre,
  availability,
}) => {
  const [toggler1, setToggler1] = useState(false);
  const [show, setShow] = useState(false);
  const [playerId, setPlayerId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  const newDesc = description?.split(" ");
  let movieDesc = description;
  if (newDesc?.length > 40) {
    movieDesc = newDesc.slice(0, 40).join(" ") + "...";
  }

  const words = title?.split(" ");
  let movieTitle = title;
  if (words?.length > 4) {
    movieTitle = words.slice(0, 4).join(" ") + "...";
  }
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { id } = useParams();
  const history = useHistory();
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  useEffect(() => {
    if (monetizationCheck === true) {
      setPlayerId("rGoke68A");
    } else {
      setPlayerId("rGoke68A");
    }
  }, [monetizationCheck]);
  // useEffect(() => {
  //   const generalContentId = {
  //     general_content_id: getGeneralContent({ id }),
  //   };
  // }, [id]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );
  function handleFavClick(tvID, category) {
    if (currentUserId) {
      const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
      const data = {
        general_content_id: tvID,
        category: mediaCategory,
      };

      // Check if the item is already in the favorite list
      if (favoriteItems.includes(tvID)) {
        // Remove the item from the favorite list
        handleConfirmDeleteFAV(tvID);
        toast.info(t("Content Removed From Favorite list!"), {
          position: "top-center",
        });
        setFavoriteItems(favoriteItems.filter((item) => item !== tvID));
      } else {
        // Add the item to the favorite list
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUserToken}`,
            source: "front",
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              toast.success(t("Content added to Favorite list!"), {
                position: "top-center",
              });
              setFavoriteItems([...favoriteItems, tvID]);
            } else {
              toast.error(t("Error occurred at adding Favourite List"));
              throw new Error("Something went wrong...");
            }
          })
          .catch((error) => {
            //(error);
          });
      }
    } else {
      // Navigate to the login page
      history.push("/login");
    }
  }
  function handleWatchList(id, category) {
    if (currentUserId) {
      const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
      const data = {
        general_content_id: id,
        category: mediaCategory,
      };

      // Check if the item is already in the watch list
      if (watchListItems.includes(id)) {
        // Remove the item from the watch list
        handleConfirmDeleteWatchList(id);
        toast.info(t("Content Removed From Watch list!"), {
          position: "top-center",
        });
        setWatchListItems(watchListItems.filter((item) => item !== id));
      } else {
        // Add the item to the watch list
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUserToken}`,
            source: "front",
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              toast.success(t("Content added to Watch list!"), {
                position: "top-center",
              });
              setWatchListItems([...watchListItems, id]);
            } else {
              toast.error(t("Error occurred at adding in WatchList"));
              throw new Error("Something went wrong...");
            }
          })
          .catch((error) => {
            //(error);
          });
      }
    } else {
      // Navigate to the login page
      history.push("/login");
    }
  }
  // handleConfirmDeleteFAV(itemIdToDelete);
  // handleConfirmDeleteWatchList(itemIdToDelete);

  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response.data.map((item) => item.general_content_id);
        setData(data);
      } catch (error) {}
    };

    if (currentUserId) {
      const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
      fetchData(watchListUrl, setWatchListItems);

      const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
      fetchData(favoriteItemsUrl, setFavoriteItems);
    }
  }, [currentUserId]);

  return (
    <>
      <ToastContainer
        toastStyle={{
          background: "black",
          color: "white",
        }}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ReactJWPlayer
            playerId={playerId}
            playerScript={`https://content.jwplatform.com/libraries/${playerId}.js`}
            playlist={`https://cdn.jwplayer.com/v2/media/${trailer}`}
            controls={false}
            isAutoPlay={false}
            licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
            // file="https://live-hls-web-aje.getaj.net/AJE/05.m3u8"
            // onReady={onReady}
          />
        </Modal.Body>
      </Modal>
      {deviceWidth > 550 ? (
        <div className="video-banner">
          <div className="season-trailer-container-main">
            {trailer !== "" && (
              <div
                className="season-trailer-container"
                onClick={() => handleShow()}
              >
                <img
                  src={thumbnail}
                  alt="Background"
                  className="trailer-image"
                />
                <div className="trailer-overlay"></div>
                <div className="season-trailer-text">{t("Watch trailer")}</div>
              </div>
            )}
          </div>
          <div
            className="poster"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          >
            {availability !== "upcoming" && (
              <>
                <div className="overlay_poster"></div>
                <div className="content">
                  <h1 className="trending-text big-title text-uppercase m-0">
                    {movieTitle}
                  </h1>
                  <div>
                    <ul className="p-0 list-inline d-flex movie-content flex-wrap">
                      {genre?.map((genreObj) => (
                        <li className="text-white" key={genreObj._id}>
                          {genreObj.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {seoTags?.map((tag, index) => (
                    <span
                      key={index}
                      style={{
                        marginRight: "5px",
                        color: "#21a6e0",
                        fontWeight: 600,
                        fontSize: "1.1rem",
                      }}
                    >
                      {tag.toUpperCase()}
                    </span>
                  ))}
                  {/* <div className="season-banner-description d-flex align-items-center text-white text-detail mt-2 mb-2">
                <span className="">
                  {seasons?.length} Season{seasons?.length > 1 ? "s" : ""}
                </span>
                <span className="trending-year">{getDate(createdAt)}</span>
              </div> */}

                  <div
                    style={
                      {
                        //   display: "flex",
                        //   justifyContent: "space-between",
                        //   gap: "20vh",
                      }
                    }
                  >
                    <p
                      className="season-banner-dec"
                      style={{ color: "lightgray" }}
                    >
                      {movieDesc}
                    </p>
                  </div>

                  <div>
                    <h4 className="movie-desc-crew-heading">{t("Crew mem")}</h4>
                    <table style={{ borderCollapse: "collapse" }}>
                      {crewNames?.map((name, index) => (
                        <tr key={index}>
                          <td
                            style={{ padding: "10px 20px 0 0", color: "white" }}
                          >
                            {name}
                          </td>
                          <td
                            style={{
                              padding: "10px 0 0 20px",
                              marginLeft: "5px",
                              color: "lightgray",
                            }}
                          >
                            {crewRoles[index]}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  {/* <Link
                to={`/episode/${latestEpisodeId}`}
                className="video-open playbtn gap-5 mb-4"
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <a
                  href={`/episode/${latestEpisodeId}`}
                  class="play-new-btn"
                ></a>
                <span className="w-trailor">Watch Latest Episode</span>
              </Link> */}
                  <div className="icon-movies">
                    <ul className="list-inline p-0 mt-2 share-icons music-play-lists">
                      <li className="share">
                        <span>
                          <i
                            className={`ri-add-line ${
                              watchListItems.includes(tvID) ? "red-heart" : ""
                            }`}
                            onClick={() => handleWatchList(tvID, mediaCategory)}
                          ></i>
                        </span>
                        {/* <div className="favorite-box">
                      <div className="">Add to wishlist</div>
                    </div> */}
                      </li>

                      <li className="share">
                        <span>
                          <i
                            className={`ri-heart-fill ${
                              favoriteItems.includes(tvID) ? "red-heart" : ""
                            }`}
                            onClick={() => handleFavClick(tvID, mediaCategory)}
                          ></i>
                        </span>
                        {/* <div className="favorite-box">
                      <div className="">Add to favorites</div>
                    </div> */}
                      </li>
                      <li className="share">
                        <span>
                          <i className="ri-share-fill"></i>
                        </span>
                        <div className="share-box">
                          <div className="d-flex align-items-center">
                            <Link to="#" className="share-ico">
                              <i className="ri-facebook-fill"></i>
                            </Link>
                            <Link to="#" className="share-ico">
                              <i className="ri-twitter-fill"></i>
                            </Link>
                            <Link to="#" className="share-ico">
                              <i className="ri-links-fill"></i>
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {jwTags?.length > 0 && (
                    <div
                      className="d-flex movie-tags"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <i
                          className="fa fa-tags mr-1"
                          style={{ fontSize: "17px" }}
                        ></i>
                        <span
                          style={{
                            color: "#21a6e0",
                            marginRight: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          TAGS:
                        </span>
                      </div>
                      <div className="d-flex flex-wrap movie-tags-tag ">
                        {jwTags?.map((tag, index) => (
                          <span className="text-white" key={tag}>
                            {tag}
                            {index !== jwTags?.length - 1 && ","}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="banner-img-container-mobile ">
              <img src={thumbnail} alt="banner-thumbnail-mobile" />
              {trailer !== "" && (
                <div
                  className="video-open playbtn gap-2"
                  style={{
                    position: "absolute",
                    marginTop: "1rem",
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "40%",
                    left: "0%",
                    width: "100%",
                  }}
                  onClick={() => setToggler1(!toggler1)}
                >
                  <a href="" class="play-new-btn-mbl"></a>
                  <span className="w-trailor-mbl">Watch Trailer</span>
                </div>
              )}
            </div>
            {/* <div className="">
              <h1
                className="trending-text big-title text-uppercase m-0"
                style={{ fontSize: "3rem" }}
              >
                {movieTitle}
              </h1>
              {seoTags?.map((tag, index) => (
                <span
                  key={index}
                  style={{
                    marginRight: "5px",
                    color: "#21a6e0",
                    fontWeight: 600,
                    fontSize: "1.1rem",
                  }}
                >
                  {tag.toUpperCase()}
                </span>
              ))}
              <div className="season-banner-description d-flex align-items-center text-white text-detail mt-2 mb-2">
                <span className="">3 Seasons</span>
                <span className="trending-year">{dateToWords(createdAt)}</span>
              </div>

              <div
                style={
                  {
                    
                  }
                }
              >
                <p className="season-banner-dec" style={{ color: "lightgray" }}>
                  {movieDesc}
                </p>
              </div>

              
              <div>
                <h4 className="movie-desc-crew-heading">Crew Members</h4>
                <table style={{ borderCollapse: "collapse" }}>
                  {crewNames?.map((name, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px 20px 0 0", color: "white" }}>
                        {name}
                      </td>
                      <td
                        style={{
                          padding: "10px 0 0 20px",
                          marginLeft: "5px",
                          color: "lightgray",
                        }}
                      >
                        {crewRoles[index]}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <div className="icon-movies">
                <ul className="list-inline p-0 mt-2 share-icons music-play-lists">
                  <li className="share">
                    <span>
                      <i
                        className={`ri-add-line ${
                          watchListItems.includes(tvID) ? "red-heart" : ""
                        }`}
                        onClick={() => handleWatchList(tvID, mediaCategory)}
                      ></i>
                    </span>
                    
                  </li>

                  <li className="share">
                    <span>
                      <i
                        className={`ri-heart-fill ${
                          favoriteItems.includes(tvID) ? "red-heart" : ""
                        }`}
                        onClick={() => handleFavClick(tvID, mediaCategory)}
                      ></i>
                    </span>
                    
                  </li>
                  <li className="share">
                    <span>
                      <i className="ri-share-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        <Link to="#" className="share-ico">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                        <Link to="#" className="share-ico">
                          <i className="ri-twitter-fill"></i>
                        </Link>
                        <Link to="#" className="share-ico">
                          <i className="ri-links-fill"></i>
                        </Link>
                      </div>
                    </div>
                  </li>

                  <li className="share">
                    <span>
                      <i className="ri-thumb-up-fill"></i>
                    </span>
                    <div className="favorite-box">
                      <div className="">Like</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="d-flex movie-tags">
                <div>
                  <i
                    className="ri-price-tag-3-fill"
                    style={{ fontSize: "17px" }}
                  ></i>
                  Tags:
                </div>
                <div className="d-flex flex-wrap movie-tags-tag ">
                  {jwTags?.map((tag, index) => (
                    <span
                      className="text-white"
                      key={index}
                      style={{ marginRight: "1px" }}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default VideoPoster;
