const getGeneralContent = async ({ id }) => {
  const options = {
    method: "GET",
    headers: { accept: "application/json; charset=utf-8" },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/general-content/get-general-content/${id}`,
    options
  );
  if (!response.ok) {
  }

  const data = await response.json();
  return data;
};

export default getGeneralContent;
