//router
import LayoutsRoute from "./router/layouts-route";
import { MyContext } from "./store/context";
//scss files
import "./assets/css/bootstrap.min.css";
import "./assets/css/typography.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import CookieBanner from "./components/CookieBanner";
import { useEffect, useState } from "react";
import ComingSoonVideo from "./views/backend/coming-soon-video/ComingSoonVideo";

// import  './assets/css/custom.css';

import { useTranslation } from "react-i18next";

function App() {
  const [category, setCategory] = useState("");
  const [inMaintenance, setInMaintenance] = useState(false);
  useEffect(() => {
    if(window.location.href.startsWith("https://afomedia.netlify.app")){
      setInMaintenance(false);
    }
  }, [])


  return (
    <MyContext.Provider value={{ category, setCategory }}>
      <div className="App">
        {inMaintenance ?

        <ComingSoonVideo/>
        
        : <>
        
        <LayoutsRoute />
        {/* <CookieBanner /> */}
        </> }
        
      </div>
    </MyContext.Provider>
  );
}

export default App;
