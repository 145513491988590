import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
//img
import video from "../../../assets/video/sample-video.mp4";
import { UpComingSliderApi } from "../../../Services/allSliders";
import { LatestSliderApi } from "../../../Services/SliderApi";
import CustomDropDown from "../../../components/CustomDropDown";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/scrollbar/scrollbar.scss";
import Comments from "../../../components/Comments";
import AddComment from "../../../components/AddComment";
const episodes = [
  {
    title: "Introduction to Programming",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "11 Aug 2020",
    duration: "30m",
  },
  {
    title: "Data Structures and Algorithms",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "18 Aug 2020",
    duration: "45m",
  },
  {
    title: "Web Development with React",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "25 Aug 2020",
    duration: "60m",
  },
  {
    title: "Introduction to Programming",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "11 Aug 2020",
    duration: "30m",
  },
  {
    title: "Data Structures and Algorithms",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "18 Aug 2020",
    duration: "45m",
  },
  {
    title: "Web Development with React",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "25 Aug 2020",
    duration: "60m",
  },
  {
    title: "Introduction to Programming",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "11 Aug 2020",
    duration: "30m",
  },
  {
    title: "Data Structures and Algorithms",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "18 Aug 2020",
    duration: "45m",
  },
  {
    title: "Web Development with React",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "25 Aug 2020",
    duration: "60m",
  },
  {
    title: "Introduction to Programming",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "11 Aug 2020",
    duration: "30m",
  },
  {
    title: "Data Structures and Algorithms",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "18 Aug 2020",
    duration: "45m",
  },
  {
    title: "Web Development with React",
    image:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2016/04/IMG_1422.jpg",
    date: "25 Aug 2020",
    duration: "60m",
  },
];

const options = ["Season 1", "Season 2", "Season 3"];
const locations = [
  {
    label: "New York",
    value: "newYork",
  },
  {
    label: "Oslo",
    value: "oslo",
  },
  {
    label: "Istanbul",
    value: "istanbul",
  },
];
const ShowDetails = () => {
  const { t } = useTranslation();
  const [upcomingData, setUpcomingData] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isWatchListClicked, setIsWatchListClicked] = useState(false);
  const [latestData, setLatestData] = useState([]);
  const [noOfEpisodes, setNoOfEpisodes] = useState(8);
  const [activeBtn, setActiveBtn] = useState("description");
  const id = "6455628b82f9b413cfbb65c8";
  const handleEpisodesNumber = () => {
    setNoOfEpisodes(noOfEpisodes + 4);
  };

  const episodesToShow = episodes.slice(0, noOfEpisodes);

  const handleClick = (btnText) => {
    setActiveBtn(btnText);
  };

  useEffect(() => {
    LatestSliderApi()
      .then((data) => {
        setLatestData(data.upcomingContent);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="container-video iq-main-slider">
          <video className="video d-block" controls loop>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="main-content">
        <section id="iq-favorites">
          <Container fluid>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="trending-text big-title text-uppercase mt-0">
                    The Hero Camp
                  </h4>
                  <CustomDropDown options={options} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="movie-detail container-fluid mt-6">
          <Row>
            <Col lg="12">
              <div className="trending-info season-info g-border ">
                <div className="d-flex align-items-center text-white text-detail episode-name mb-0">
                  <span>S1E01</span>
                  <span className="trending-year">The undisputed camp</span>
                </div>
                {/* <p className="trending-dec w-100 mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </p> */}
                <ul className="list-inline p-0 mt-4 share-icons music-play-lists">
                  <li>
                    <span>
                      <i className="ri-add-line"></i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="ri-heart-fill"></i>
                    </span>
                  </li>
                  <li className="share">
                    <span>
                      <i className="ri-share-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        <Link to="#" className="share-ico">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                        <Link to="#" className="share-ico">
                          <i className="ri-twitter-fill"></i>
                        </Link>
                        <Link to="#" className="share-ico">
                          <i className="ri-links-fill"></i>
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </section>
        <section id="iq-favorites">
          <Container fluid>
            <div className="block-space">
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div className="iq-main-header d-flex align-items-center justify-content-start">
                    <h4 className="main-title">Latest Episodes</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                {episodesToShow?.map((episode, index) => (
                  <Col
                    xl="3"
                    lg="4"
                    md="4"
                    sm="6"
                    xs="12"
                    key={index}
                    className="mb-3"
                  >
                    <div className="epi-box">
                      <div className="epi-img position-relative">
                        <img
                          src={episode.image}
                          className="img-fluid img-zoom"
                          alt=""
                        />
                        <span className="episode-tag">S01E01</span>
                        <div className="episode-play-info">
                          <div
                            className="episode-play"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Link to="#">
                              <i className="ri-play-fill"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="epi-desc p-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className=" episode-release-date">
                            October 1, 2020
                          </span>
                          <span className="text-primary episode-tag-duration">
                            {episode.duration}
                          </span>
                        </div>
                        <Link to="" className="">
                          <h6 className="epi-name text-white mb-0 mt-3">
                            {episode.title}
                          </h6>
                        </Link>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              {noOfEpisodes < episodes.length && (
                <div className="text-center flex justify-content-center mt-5">
                  <button
                    className="btn btn-primary"
                    onClick={handleEpisodesNumber}
                  >
                    Show More Episodes
                  </button>
                </div>
              )}
            </div>
          </Container>
        </section>
        {/* <section id="iq-upcoming-movie">
          <Container fluid>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <h4 className="main-title">Upcoming Movies</h4>
                  <Link className="iq-view-all" to="/movie-category">
                    View All
                  </Link>
                </div>
                <div id="upcoming-contens">
                  <div id="prev1" className="swiper-button swiper-button-prev">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div id="next1" className="swiper-button swiper-button-next">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                  <Swiper
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 4 },
                      1400: { slidesPerView: 4 },
                    }}
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={20}
                    navigation={{
                      prevEl: "#prev1",
                      nextEl: "#next1",
                    }}
                    as="ul"
                    className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                  >
                    <SwiperSlide as="li">
                      <div className=" block-images position-relative">
                        <div className="img-box">
                          <img src={upcoming1} className="img-fluid" alt="" />
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title">
                            <Link to="/show-details">The Last Breath</Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                            <div className="badge badge-secondary p-1 mr-2">
                              5+
                            </div>
                            <span className="text-white">2h 30m</span>
                          </div>
                          <div className="hover-buttons">
                            <Link
                              to="/show-details"
                              role="button"
                              className="btn btn-hover iq-button"
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        <div className="block-social-info">
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li className="share">
                              <span>
                                <i className="ri-share-fill"></i>
                              </span>
                              <div className="share-box">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </Link>
                                  <Link
                                    to="https://twitter.com/intent/tweet?text=Currentlyreading"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-twitter-fill"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    className="share-ico iq-copy-link"
                                    tabIndex="0"
                                  >
                                    <i className="ri-links-fill"></i>
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <span>
                                <i className="ri-heart-fill"></i>
                              </span>
                              <span className="count-box">19+</span>
                            </li>
                            <li>
                              <span>
                                <i className="ri-add-line"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide as="li">
                      <div className="block-images position-relative">
                        <div className="img-box">
                          <img src={upcoming2} className="img-fluid" alt="" />
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title">
                            <Link to="/show-details">Shadow</Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                            <div className="badge badge-secondary p-1 mr-2">
                              22+
                            </div>
                            <span className="text-white">2h 15m</span>
                          </div>
                          <div className="hover-buttons">
                            <Link
                              to="/show-details"
                              role="button"
                              className="btn btn-hover iq-button"
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        <div className="block-social-info">
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li className="share">
                              <span>
                                <i className="ri-share-fill"></i>
                              </span>
                              <div className="share-box">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </Link>
                                  <Link
                                    to="https://twitter.com/intent/tweet?text=Currentlyreading"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-twitter-fill"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    className="share-ico iq-copy-link"
                                    tabIndex="0"
                                  >
                                    <i className="ri-links-fill"></i>
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <span>
                                <i className="ri-heart-fill"></i>
                              </span>
                              <span className="count-box">19+</span>
                            </li>
                            <li>
                              <span>
                                <i className="ri-add-line"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide as="li">
                      <div className="block-images position-relative">
                        <div className="img-box">
                          <img src={upcoming3} className="img-fluid" alt="" />
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title">
                            {" "}
                            <Link to="/show-details">Another Danger</Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                            <div className="badge badge-secondary p-1 mr-2">
                              25+
                            </div>
                            <span className="text-white">3h</span>
                          </div>
                          <div className="hover-buttons">
                            <Link
                              to="/show-details"
                              role="button"
                              className="btn btn-hover iq-button"
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        <div className="block-social-info">
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li className="share">
                              <span>
                                <i className="ri-share-fill"></i>
                              </span>
                              <div className="share-box">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </Link>
                                  <Link
                                    to="https://twitter.com/intent/tweet?text=Currentlyreading"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-twitter-fill"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    className="share-ico iq-copy-link"
                                    tabIndex="0"
                                  >
                                    <i className="ri-links-fill"></i>
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <span>
                                <i className="ri-heart-fill"></i>
                              </span>
                              <span className="count-box">19+</span>
                            </li>
                            <li>
                              <span>
                                <i className="ri-add-line"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide as="li">
                      <div className="block-images position-relative">
                        <div className="img-box">
                          <img src={upcoming4} className="img-fluid" alt="" />
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title">
                            <Link to="/show-details">1980</Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                            <div className="badge badge-secondary p-1 mr-2">
                              11+
                            </div>
                            <span className="text-white">2h 45m</span>
                          </div>
                          <div className="hover-buttons">
                            <Link
                              to="/show-details"
                              role="button"
                              className="btn btn-hover iq-button"
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        <div className="block-social-info">
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li className="share">
                              <span>
                                <i className="ri-share-fill"></i>
                              </span>
                              <div className="share-box">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </Link>
                                  <Link
                                    to="https://twitter.com/intent/tweet?text=Currentlyreading"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-twitter-fill"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    className="share-ico iq-copy-link"
                                    tabIndex="0"
                                  >
                                    <i className="ri-links-fill"></i>
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <span>
                                <i className="ri-heart-fill"></i>
                              </span>
                              <span className="count-box">19+</span>
                            </li>
                            <li>
                              <span>
                                <i className="ri-add-line"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide as="li">
                      <div className="block-images position-relative">
                        <div className="img-box">
                          <img src={upcoming5} className="img-fluid" alt="" />
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title">
                            <Link to="/show-details">Vugotronic</Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                            <div className="badge badge-secondary p-1 mr-2">
                              9+
                            </div>
                            <span className="text-white">2h 30m</span>
                          </div>
                          <div className="hover-buttons">
                            <Link
                              to="/show-details"
                              role="button"
                              className="btn btn-hover iq-button"
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        <div className="block-social-info">
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li className="share">
                              <span>
                                <i className="ri-share-fill"></i>
                              </span>
                              <div className="share-box">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </Link>
                                  <Link
                                    to="https://twitter.com/intent/tweet?text=Currentlyreading"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-twitter-fill"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                    className="share-ico iq-copy-link"
                                    tabIndex="0"
                                  >
                                    <i className="ri-links-fill"></i>
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <span>
                                <i className="ri-heart-fill"></i>
                              </span>
                              <span className="count-box">19+</span>
                            </li>
                            <li>
                              <span>
                                <i className="ri-add-line"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        <section>
          <div className="movie-desc-navigator">
            <button
              className={` ${
                activeBtn === "description"
                  ? "movie-desc-navigator__active-btn movie-desc-navigator-btn "
                  : "movie-desc-navigator-btn"
              }`}
              onClick={() => handleClick("description")}
            >
              {t("Description")}
            </button>
            <button
              className={` ${
                activeBtn === "review"
                  ? "movie-desc-navigator__active-btn movie-desc-navigator-btn"
                  : "movie-desc-navigator-btn"
              }`}
              onClick={() => handleClick("review")}
            >
              {t("Rate and Review")}
            </button>
          </div>
        </section>
        <section>
          {activeBtn === "description" && (
            <section className="container-fluid">
              <Row className=" fs-5 mt-5">
                <Col lg="12" md="12" className="overflow-hidden">
                  <span className="movie-desc-navigator__desc-text ">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                  </span>
                  <h4 className="mt-4 mb-4 movie-desc-crew-heading">
                    Crew Members
                  </h4>
                  <Row>
                    <Col xs="8" md="6">
                      <table style={{ borderCollapse: "collapse" }}>
                        <tr>
                          <td
                            style={{
                              padding: "10px 20px 10px 0",
                              color: "white",
                            }}
                          >
                            Director
                          </td>
                          <td
                            style={{
                              padding: "10px 0 10px 20px",
                              marginLeft: "5px",
                            }}
                          >
                            John Wick
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "10px 20px 10px 0",
                              color: "white",
                            }}
                          >
                            Actor
                          </td>
                          <td
                            style={{
                              padding: "10px 0 10px 20px",
                              marginLeft: "5px",
                            }}
                          >
                            John Cena
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "10px 20px 10px 0",
                              color: "white",
                            }}
                          >
                            Co-actor
                          </td>
                          <td
                            style={{
                              padding: "10px 0 10px 20px",
                              marginLeft: "5px",
                            }}
                          >
                            Mike Marshol
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
          )}
          {activeBtn === "review" && (
            <section className="mt-5 mb-5 container-fluid">
              <h4 className="mb-2 comments-heading">{t("Comments")}:</h4>

              <Comments generalContentId={id} />
              <AddComment generalContentId={id} />
            </section>
          )}
          {/* {activeBtn === "sources" && (
            <section className="intro">
              <div
                className="bg-image h-100"
                // style={{
                //   backgroundImage: url(
                //     https://mdbootstrap.com/img/Photos/new-templates/tables/img2.jpg"
                //   ),
                // }}
              >
                <div
                  className="mask d-flex align-items-center h-100"
                  // style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}
                >
                  <div className="container w-100">
                    <div className="row justify-content-center ">
                      <div className="col-12 ">
                        <div className="card shadow-2-strong movie-desc-navigator__table">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-borderless mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Links</th>
                                    <th scope="col">Quality</th>
                                    <th scope="col">Language</th>
                                    <th scope="col">Player</th>
                                    <th scope="col">Date Added</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <button>Play now</button>
                                    <td>1080p</td>
                                    <td>English </td>
                                    <td>MusicBee</td>
                                    <td>2021-12-04</td>
                                  </tr>
                                  <tr>
                                    <button>Play now</button>
                                    <td>1080p</td>
                                    <td>sEnglish</td>
                                    <td>MusicBee</td>
                                    <td>2021-12-04</td>
                                  </tr>
                                  <tr>
                                    <button>Play now</button>
                                    <td>1080p</td>
                                    <td>English </td>
                                    <td>MusicBee</td>
                                    <td>2021-12-04</td>
                                  </tr>
                                  <tr>
                                    <button>Play now</button>
                                    <td>1080p</td>
                                    <td>English </td>
                                    <td>MusicBee</td>
                                    <td>2021-12-04</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )} */}
          <div class="gradient-line"></div>
        </section>
      </div>
    </>
  );
};
export default ShowDetails;
