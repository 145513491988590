import React, { useState } from "react";
import clipboardCopy from "clipboard-copy";

const CopyLinkButton = ({ link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = async (e) => {
    console.log(link);
    try {
      await clipboardCopy(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Réinitialiser le message après 3 secondes
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <>
      <a
        href="#empty"
        onClick={(e) => {
          e.preventDefault();
          handleCopyLink();
        }}
        rel="noopener noreferrer"
        className="share-ico iq-copy-link"
        tabIndex="0"
      >
        <i class="ri-link"></i>
      </a>
      {copied && <p
        style={{
          position: "absolute",
          top: "-32px",
          right: "-60px",
          fontSize: "15px",
        }}
      >
        Lien copié !
      </p>}
    </>
  );
};

export default CopyLinkButton;
