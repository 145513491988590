import axios from "axios";
import CryptoJS from "crypto-js";
import generateRandomIv from "./generateRandomIv";

import { Buffer } from "buffer";

const btoa = (text) => {
  return Buffer.from(text, "binary").toString("base64");
};

const atob = (base64) => {
  return Buffer.from(base64, "base64").toString("binary");
};
const session = JSON.parse(localStorage.getItem("session"));
const currentUserToken = session?._id;
function confirmPassword(obj) {
  return new Promise((resolve, reject) => {
    let msg = JSON.stringify(obj);
    const i = generateRandomIv(16);
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(i);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    var transitmessage = JSON.stringify({
      iv: btoa(i),
      value: encrypted.toString(),
    });
    transitmessage = btoa(transitmessage);
    let request = {
      data: transitmessage,
    };
    const url = `${process.env.REACT_APP_API}/api/user/login`;

    axios
      .post(url, request)
      .then(async (response) => {
        resolve(true);
      })
      .catch((error) => {
        reject(false);
      });
  });
}
export default confirmPassword;
// import axios from "axios";
// import CryptoJS from "crypto-js";
// import generateRandomIv from "./generateRandomIv";
// import AuthSession from "./getSessionAuth";
// import { Buffer } from "buffer";

// const btoa = (text) => {
//   return Buffer.from(text, "binary").toString("base64");
// };

// const atob = (base64) => {
//   return Buffer.from(base64, "base64").toString("binary");
// };

// function confirmPassword(obj) {
//   let msg = JSON.stringify(obj);
//   const i = generateRandomIv(16);
//   const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
//   const iv = CryptoJS.enc.Utf8.parse(i);
//   const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//   });
//   var transitmessage = JSON.stringify({
//     iv: btoa(i),
//     value: encrypted.toString(),
//   });
//   transitmessage = btoa(transitmessage);
//   let request = {
//     data: transitmessage,
//   };
//   const url = `${process.env.REACT_APP_API}/api/user/login`;

//   axios
//     .post(url, request)
//     .then(async (response) => {
//       let res = atob(response.data.data);
//       let jsn = JSON.parse(res);
//       const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
//         mode: CryptoJS.mode.CBC,
//         iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
//       });
//       const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
//       return true;
//       // session request
//     })
//     .catch((error) => {
//     });
// }

// export default confirmPassword;
// import axios from "axios";
// import CryptoJS from "crypto-js";
// import generateRandomIv from "./generateRandomIv";
// import { Buffer } from "buffer";

// const btoa = (text) => {
//   return Buffer.from(text, "binary").toString("base64");
// };

// const atob = (base64) => {
//   return Buffer.from(base64, "base64").toString("binary");
// };

// function confirmPassword(obj) {
//   return new Promise((resolve, reject) => {
//     let msg = JSON.stringify(obj);
//     const i = generateRandomIv(16);
//     const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
//     const iv = CryptoJS.enc.Utf8.parse(i);
//     const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//     });
//     var transitmessage = JSON.stringify({
//       iv: btoa(i),
//       value: encrypted.toString(),
//     });
//     transitmessage = btoa(transitmessage);
//     let request = {
//       data: transitmessage,
//     };
//     const url = `${process.env.REACT_APP_API}/api/user/login`;

//     axios
//       .post(url, request)
//       .then(async (response) => {
//         let res = atob(response.data.data);
//         let jsn = JSON.parse(res);
//         const decrypted = CryptoJS.AES.decrypt(jsn.value, key, {
//           mode: CryptoJS.mode.CBC,
//           iv: CryptoJS.enc.Utf8.parse(atob(jsn.iv)),
//         });
//         const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

//         resolve(true);
//         // session request
//       })
//       .catch((error) => {
//         console.error(error);

//         reject(false);
//       });
//   });
// }
// export default confirmPassword;
