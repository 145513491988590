// import React, { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.scss";

// const MySwiper = () => {
//   const [activeIndex, setActiveIndex] = useState(0);

//   const handleSlideChange = (swiper) => {
//     setActiveIndex(swiper.activeIndex);
//   };

//   const data = [
//     { id: 1, heading: "Heading 1", slide: "Slide 1" },
//     { id: 2, heading: "Heading 2", slide: "Slide 2" },
//     { id: 3, heading: "Heading 3", slide: "Slide 3" },
//   ];

//   return (
//     <div>
//       <h1>{data[activeIndex].heading}</h1>
//       <Swiper onSlideChange={handleSlideChange}>
//         {data.map((item) => (
//           <SwiperSlide key={item.id}>{item.slide}</SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

// export default MySwiper;

// import React, { useState, useEffect } from "react";
// import { TVTopRatedApi } from "../../../Services/tvShowsApi";
// import { podCastLatestSliderApi } from "../../../Services/podCastApi";

// const MyComponent = () => {
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [topRatedData, setTopRatedData] = useState([]);
//   const [tvShowDetails, setTvShowDetails] = useState(null);

//   const fetchData = (id) => {
//     fetch(`${process.env.REACT_APP_API}/api/tv-shows/get-tvShow-details/${id}`)
//       .then((res) => res.json())
//       .then((result) => {
//         if (result && result.tvShowDetails) {
//           setTvShowDetails(result.tvShowDetails);
//         } else {
//           setTvShowDetails(null);
//         }
//       });
//   };

//   useEffect(() => {
//     podCastLatestSliderApi()
//       .then((data) => {
//         setTopRatedData(data.upcomingContent);
//       })
//       .catch((error) => {
//       });
//   }, []);

//   useEffect(() => {
//     if (selectedOption) {
//       fetchData(selectedOption.id);
//     }
//   }, [selectedOption]);

//   const handleOptionChange = (e) => {
//     const selectedId = e.target.value;
//     const selectedOption = topRatedData.find(
//       (option) => option._id === selectedId
//     );
//     setSelectedOption(selectedOption);
//   };

//   return (
//     <div>
//       <h1>TV Shows</h1>
//       <div>
//         <label>Select a TV show:</label>
//         <select value={selectedOption?.id || ""} onChange={handleOptionChange}>
//           <option value="">-- Select --</option>
//           {topRatedData.map((option) => (
//             <option key={option._id} value={option._id}>
//               {option.title}
//             </option>
//           ))}
//         </select>
//       </div>
//       {selectedOption && (
//         <div>
//           <h2>{selectedOption.title}</h2>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MyComponent;
import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { TVTopRatedApi } from "../../../Services/tvShowsApi";
import { podCastLatestSliderApi } from "../../../Services/podCastApi";

SwiperCore.use([Navigation, Pagination]);

const MyComponent = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [topRatedData, setTopRatedData] = useState([]);
  const [tvShowDetails, setTvShowDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = (id) => {
    setLoading(true);
    setError(null);

    fetch(`${process.env.REACT_APP_API}/api/tv-shows/get-tvShow-details/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch TV show details.");
        }
        return res.json();
      })
      .then((result) => {
        if (result && result.tvShowDetails) {
          setTvShowDetails(result.tvShowDetails);
        } else {
          setTvShowDetails(null);
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    podCastLatestSliderApi()
      .then((data) => {
        setTopRatedData(data.upcomingContent);
      })
      .catch((error) => {
        setError("Failed to fetch top-rated data.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedOption) {
      fetchData(selectedOption._id);
    }
  }, [selectedOption]);

  useEffect(() => {
    // Set the initial selected option when the component mounts
    if (topRatedData.length > 0) {
      setSelectedOption(topRatedData[0]);
    }
  }, [topRatedData]);

  return (
    <div>
      <h1>TV Shows</h1>
      {error && <p>Error: {error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <label>Select a TV show:</label>
          <Swiper
            slidesPerView={1}
            navigation
            spaceBetween={10}
            onSlideChange={(swiper) => {
              const activeIndex = swiper.realIndex;
              if (activeIndex >= 0 && activeIndex < topRatedData.length) {
                const selectedId = topRatedData[activeIndex]._id;
                const selectedOption = topRatedData.find(
                  (option) => option._id === selectedId
                );
                setSelectedOption(selectedOption);
              }
            }}
          >
            {topRatedData.map((option) => (
              <SwiperSlide key={option._id} value={option._id}>
                {option.title}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {selectedOption && (
        <div>
          {loading ? (
            <p>Loading TV show details...</p>
          ) : (
            <Swiper>
              <SwiperSlide>
                <h2>{tvShowDetails?.description}</h2>
                {/* Display other details of tvShowDetails */}
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      )}
    </div>
  );
};

export default MyComponent;
