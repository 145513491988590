import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import { Modal } from "react-bootstrap";
import ReactJWPlayer from "react-jw-player";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./moviePoster.css";
import { useTranslation } from "react-i18next";

import getGeneralContent from "./../Services/generalContent";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../views/backend/home/FavWatch";
import { currentUserToken } from "./../views/backend/home/home";
import {
  dateToWords,
  getDate,
} from "../Services/generalFunctions/generalFunctions";
import {
  showInfoFacebookShareUrl,
  showInfoTwitterShareUrl,
  showInfoWhatsappShareUrl,
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  genreFacebookShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
} from "../views/backend/home/ShareFunction";
const MoviePoster = ({
  thumbnail,
  title,
  description,
  seoTags,
  trailer,
  crewNames,
  crewRoles,
  jwTags,
  latestEpisodeId,
  mediaCategory,
  tvID,
  createdAt,
  seasons,
  dataRows,
  monetization,
}) => {
  const [toggler1, setToggler1] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  const [playerId, setPlayerId] = useState("");

  useEffect(() => {
    if (monetization === true) {
      setPlayerId("rGoke68A");
    } else {
      setPlayerId("rGoke68A");
    }
  }, [monetization]);

  const newDesc = description?.split(" ");
  let movieDesc = description;
  if (newDesc?.length > 40) {
    movieDesc = newDesc.slice(0, 40).join(" ") + "...";
  }

  const words = title?.split(" ");
  let movieTitle = title;
  if (words?.length > 4) {
    movieTitle = words.slice(0, 4).join(" ") + "...";
  }
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { id } = useParams();
  const history = useHistory();
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;
  // useEffect(() => {
  //   const generalContentId = {
  //     general_content_id: getGeneralContent({ id }),
  //   };
  // }, [id]);
  const source = "app";
  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );
  function handleFavClick(tvID, category) {
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }
    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: tvID,
      category: mediaCategory,
    };

    // Check if the item is already in the favorite list
    if (favoriteItems.includes(tvID)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(tvID);
      toast.info(t("Removed From Favorite list!"), {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== tvID));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, tvID]);
          } else {
            toast.error(t("Error occurred at adding Favourite List"));
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  function handleWatchList(id, category) {
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }
    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: mediaCategory,
    };

    // Check if the item is already in the watch list
    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at adding in WatchList"));
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }
    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response.data.map((item) => item.general_content_id);
        setData(data);
      } catch (error) {}
    };

    if (currentUserId) {
      const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
      fetchData(watchListUrl, setWatchListItems);

      const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
      fetchData(favoriteItemsUrl, setFavoriteItems);
    }
  }, [currentUserId]);

  function formatViewCount(viewCount) {
    if (viewCount >= 1000000) {
      // Convert to millions (e.g., 1.1m)
      return (viewCount / 1000000).toFixed(1) + "m";
    } else if (viewCount >= 1000) {
      // Convert to thousands (e.g., 1k)
      return (viewCount / 1000).toFixed(0) + "k";
    } else {
      return viewCount.toString();
    }
  }

  return (
    <>
      <ToastContainer
        toastStyle={{
          background: "black",
          color: "white",
        }}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          <ReactJWPlayer
            playerId={playerId}
            playerScript={`https://content.jwplatform.com/libraries/${playerId}.js`}
            playlist={`https://cdn.jwplayer.com/v2/media/${trailer}`}
            controls={false}
            isAutoPlay={false}
            licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
            // file="https://live-hls-web-aje.getaj.net/AJE/05.m3u8"
            // onReady={onReady}
          />
        </Modal.Body>
      </Modal>
      {deviceWidth > 550 ? (
        <div
          className={
            trailer === "" || mediaCategory === "podcast"
              ? "podcast-banner"
              : "movie-banner"
          }
        >
          <div className="season-trailer-container-main">
            {mediaCategory !== "podcast" && trailer !== "" && (
              <div
                className="season-trailer-container"
                onClick={() => handleShow()}
              >
                <img
                  src={thumbnail}
                  alt="Background"
                  className="trailer-image"
                />
                <div className="trailer-overlay"></div>
                <div className="season-trailer-text">{t("Watch Trailer")}</div>
              </div>
            )}
          </div>
          <div></div>
          <div
            className="poster"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          >
            <div className="overlay_poster"></div>
            <div className="content">
              <h5 className="trending-text big-title text-uppercase m-0">
                {mediaCategory}
              </h5>
              <h1 className="trending-text big-title text-uppercase m-0">
                {movieTitle}
              </h1>
              {seoTags?.map((tag, index) => (
                <span
                  key={index}
                  style={{
                    marginRight: "5px",
                    color: "#21a6e0",
                    fontWeight: 600,
                    fontSize: "1.1rem",
                  }}
                >
                  {tag.toUpperCase()}
                </span>
              ))}
              <div className="season-banner-description d-flex align-items-center text-white text-detail mt-2 mb-2">
                <span
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "0.5rem",
                  }}
                >
                  <i
                    class="ri-eye-line"
                    style={{
                      color: "#21a6e0",
                      fontSize: "17px",
                    }}
                  ></i>
                  {dataRows && dataRows?.length > 0 ? (
                    <span>
                      {formatViewCount(
                        dataRows.reduce((sum, row) => sum + row[3], 0)
                      )}{" "}
                      {t("Views")}
                    </span>
                  ) : (
                    <span>0 {t("Views")}</span>
                  )}
                </span>
                <span className="trending-year">
                  {seasons?.length} {t("Season")}
                  {seasons?.length > 1 ? "s" : ""}
                </span>
                <span className="trending-year">{dateToWords(createdAt)}</span>
              </div>

              <div
                style={
                  {
                    //   display: "flex",
                    //   justifyContent: "space-between",
                    //   gap: "20vh",
                  }
                }
              >
                <p
                  className="season-banner-dec"
                  style={{ color: "lightgray" }}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></p>
              </div>

              <div>
                <h4 className="movie-desc-crew-heading">{t("Crew mem")}</h4>
                <table style={{ borderCollapse: "collapse" }}>
                  {crewNames?.map((name, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px 20px 0 0", color: "white" }}>
                        {name}
                      </td>
                      <td
                        style={{
                          padding: "10px 0 0 20px",
                          marginLeft: "5px",
                          color: "lightgray",
                        }}
                      >
                        {crewRoles[index]}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <Link
                to={`/episode/${latestEpisodeId}`}
                className="video-open playbtn gap-5 mb-4"
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <a
                  href={`/episode/${latestEpisodeId}`}
                  class="play-new-btn"
                ></a>
                <span className="w-trailor">{t("Watch Latest Episode")}</span>
              </Link>
              <div className="icon-movies">
                <ul className="list-inline p-0 mt-2 share-icons music-play-lists">
                  <li className="share">
                    <span>
                      <i
                        className={`ri-add-line ${
                          watchListItems.includes(tvID) ? "red-heart" : ""
                        }`}
                        onClick={() => handleWatchList(tvID, mediaCategory)}
                        title={
                          watchListItems.includes(tvID)
                            ? t("Remove From WatchList")
                            : t("Add To WatchList")
                        }
                      ></i>
                    </span>
                    {/* <div className="favorite-box">
                      <div className="">Add to wishlist</div>
                    </div> */}
                  </li>

                  <li className="share">
                    <span>
                      <i
                        className={`ri-heart-fill ${
                          favoriteItems.includes(tvID) ? "red-heart" : ""
                        }`}
                        onClick={() => handleFavClick(tvID, mediaCategory)}
                        title={
                          favoriteItems.includes(tvID)
                            ? t("Remove From Favourite")
                            : t("Add To Favourite")
                        }
                      ></i>
                    </span>
                    {/* <div className="favorite-box">
                      <div className="">Add to favorites</div>
                    </div> */}
                  </li>
                  <li className="share">
                    <span>
                      <i className="ri-share-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        <a
                          href={showInfoFacebookShareUrl(
                            id,
                            mediaCategory,
                            title,
                            description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="share-ico"
                          tabIndex="0"
                        >
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a
                          href={showInfoTwitterShareUrl(
                            id,
                            mediaCategory,
                            title,
                            description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="share-ico"
                          tabIndex="0"
                        >
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a
                          href={showInfoWhatsappShareUrl(
                            id,
                            mediaCategory,
                            title,
                            description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="share-ico iq-copy-link"
                          tabIndex="0"
                        >
                          <i className="ri-whatsapp-fill"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {jwTags?.length > 0 && (
                <div className="d-flex movie-tags" style={{ width: "70%" }}>
                  <div>
                    <i className="fa fa-tags" style={{ fontSize: "17px" }}></i>
                    <span
                      style={{
                        color: "#21a6e0",
                        marginRight: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {t("tags").toUpperCase()}:
                    </span>
                  </div>
                  <div className="d-flex flex-wrap movie-tags-tag ">
                    {jwTags?.map((tag, index) => (
                      <span className="text-white" key={tag}>
                        {tag}
                        {index !== jwTags?.length - 1 && ","}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="banner-img-container-mobile ">
              <img src={thumbnail} alt="banner-thumbnail-mobile" />
              {mediaCategory !== "podcast" && trailer !== "" && (
                <div
                  className="mobile-watch-trailer-button video-open playbtn gap-2"
                  style={{
                    position: "absolute",
                    marginTop: "1rem",
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "40%",
                    left: "0%",
                    width: "100%",
                  }}
                  onClick={() => handleShow()}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="inner-container-watch-trailer-mbl"
                  >
                    <div href="" class="play-new-btn-mbl"></div>
                    <span className="w-trailor-mbl">{t("Watch Trailer")}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <h5 className="trending-text big-title text-uppercase m-0">
                {mediaCategory}
              </h5>
              <h1
                className="trending-text big-title text-uppercase m-0"
                style={{ fontSize: "3rem" }}
              >
                {movieTitle}
              </h1>
              {seoTags?.map((tag, index) => (
                <span
                  key={index}
                  style={{
                    marginRight: "5px",
                    color: "#21a6e0",
                    fontWeight: 600,
                    fontSize: "1.1rem",
                  }}
                >
                  {tag.toUpperCase()}
                </span>
              ))}
              <div className="season-banner-description d-flex align-items-center text-white text-detail mt-2 mb-2">
                <span className="">
                  {seasons?.length > 1
                    ? `${seasons?.length} Seasons`
                    : `${seasons?.length} Season`}
                </span>
                <span className="trending-year">{dateToWords(createdAt)}</span>
              </div>

              <div
                style={
                  {
                    //   display: "flex",
                    //   justifyContent: "space-between",
                    //   gap: "20vh",
                  }
                }
              >
                <p
                  className="season-banner-dec"
                  style={{ color: "lightgray" }}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></p>
              </div>

              {/* <div className="season-trailer-container-main">
            <div
              className="season-trailer-container"
              onClick={() => setToggler1(!toggler1)}
            >
              <img src={thumbnail} alt="Background" className="trailer-image" />
              <div className="trailer-overlay"></div>
              <div className="season-trailer-text">{t("Watch Trailer")}</div>
            </div>
          </div> */}
              <div>
                <h4 className="movie-desc-crew-heading">{t("Crew mem")}</h4>
                <table style={{ borderCollapse: "collapse" }}>
                  {crewNames?.map((name, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px 20px 0 0", color: "white" }}>
                        {name}
                      </td>
                      <td
                        style={{
                          padding: "10px 0 0 20px",
                          marginLeft: "5px",
                          color: "lightgray",
                        }}
                      >
                        {crewRoles[index]}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <div className="icon-movies">
                <ul className="list-inline p-0 mt-2 share-icons music-play-lists">
                  <li className="share">
                    <span>
                      <i
                        className={`ri-add-line ${
                          watchListItems.includes(tvID) ? "red-heart" : ""
                        }`}
                        onClick={() => handleWatchList(tvID, mediaCategory)}
                      ></i>
                    </span>
                    {/* <div className="favorite-box">
                      <div className="">Add to wishlist</div>
                    </div> */}
                  </li>

                  <li className="share">
                    <span>
                      <i
                        className={`ri-heart-fill ${
                          favoriteItems.includes(tvID) ? "red-heart" : ""
                        }`}
                        onClick={() => handleFavClick(tvID, mediaCategory)}
                      ></i>
                    </span>
                    {/* <div className="favorite-box">
                      <div className="">Add to favorites</div>
                    </div> */}
                  </li>
                  <li className="share">
                    <span>
                      <i className="ri-share-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        <a
                          href={showInfoFacebookShareUrl(
                            id,
                            mediaCategory,
                            title,
                            description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="share-ico"
                          tabIndex="0"
                        >
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a
                          href={showInfoTwitterShareUrl(
                            id,
                            mediaCategory,
                            title,
                            description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="share-ico"
                          tabIndex="0"
                        >
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a
                          href={showInfoWhatsappShareUrl(
                            id,
                            mediaCategory,
                            title,
                            description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="share-ico iq-copy-link"
                          tabIndex="0"
                        >
                          <i className="ri-whatsapp-fill"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="d-flex movie-tags">
                <div>
                  <i className="ri-price-tag-3-fill"></i>
                  {t("tags").toUpperCase()}:
                </div>
                <div className="d-flex flex-wrap movie-tags-tag ">
                  {jwTags?.map((tag, index) => (
                    <span
                      className="text-white"
                      key={index}
                      style={{ marginRight: "1px" }}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MoviePoster;
