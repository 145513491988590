import React from "react";
import "./paymentPage.scss";
import { useTranslation } from "react-i18next";
const PaymentPage = () => {
  const { t } = useTranslation();

  return (
    <div className="checkout-form">
      <div className="checkout-heading">
        <h5>{t("Add new card")}</h5>
      </div>

      <div id="input-container">
        <i class="fa fa-user"></i>
        <input
          type="text"
          placeholder="Card holder name"
          className="full-width"
        />
      </div>
      <div id="input-container">
        <i class="fa fa-address-card"></i>
        <input type="text" placeholder="Card Number" className="full-width" />
      </div>
      <div className="form-multiple-row">
        <div id="input-container">
          <i class="fa fa-info"></i>
          <input type="text" placeholder="Card Number" className="full-width" />
        </div>

        <input
          type="month"
          min="1900-01"
          max="9999-12"
          placeholder="Expiry Date"
          className="full-width"
        />
      </div>
      <div className="form-row">
        <button className="payment-button">Pay now</button>
      </div>
    </div>
  );
};

export default PaymentPage;
