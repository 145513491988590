import React, { useState, useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
// import SignupForm from "../signupform/SignupForm";
import logo from "../../../../assets/images/login/logo.png";
import OtpInput from "react-otp-input";
import { FcPrevious } from "react-icons/fc";
import postLoginData from "../../../../Services/postLoginData";
import postSignUpData from "../../../../Services/postSignUpData";
// multi lang
import { useTranslation } from "react-i18next";
import LoginMob from "./Login_mb";
import sendEmailVerification, { sendEmailVerificationWithoutToken } from "../../../../Services/sendEmailVerification";
import verifyEmailCode from "../../../../Services/verifiyEmailCode";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// stripe
import Stripe from "./stripe";
import FACodeSend from "../../../../Services/FACodeSend";
import login_tfa from "../../../../Services/login_tfa";
import verifyFACode from "../../../../Services/verifyFACode";
import sendPhoneVerification from "./../../../../Services/SendPhoneVerification";
import verifyFAPhoneCode from "../../../../Services/verifyFAPhoneCode";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../firebase/config";
import { Helmet } from "react-helmet";

// real file used for login for desktop
const Loginsignup = (props) => {
  // const CustomFlags = ({ countries, country, onSelect }) => {
  //   return (
  //     <div>
  //       {countries.map((countryObj) => (
  //         <button
  //           key={countryObj.country}
  //           onClick={() => {
  //             if (country.countryCode !== countryObj.countryCode) {
  //               onSelect(countryObj);
  //             }
  //           }}
  //           className={country.countryCode === countryObj.countryCode ? 'active' : ''}
  //         >
  //           <img src={countryObj.countryCode} alt={countryObj.country} />
  //         </button>
  //       ))}
  //     </div>
  //   );
  // };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // switch language
  const { t } = useTranslation();

  const [isSignUpActive, setIsSignUpActive] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpActive(true);
  };

  const handleSignInClick = () => {
    setIsSignUpActive(false);
  };

  // get the page number
  const [page, setPage] = useState(0);
  const [subscriptionForm, setSubscriptionForm] = useState(false);
  const [otp, setOtp] = useState(false);
  let history = useHistory("");
  // cards
  const [activeCard, setActiveCard] = useState(true);
  const handleCardClick = (cardIndex) => {
    setActiveCard(cardIndex);
  };

  // login form data
  const [loginMethod, setLoginMethod] = useState("email");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emptyObj, setEmptyObj] = useState(false);
  const [loginInput, setLoginInput] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginInput({ ...loginInput, [name]: value });
  };

  const handleEmailLoginChange = (e) => {

    const value = e.target.value;
    setLoginInput({ ...loginInput, email: value });
    if (value.length >= 3 && value.match(/[a-zA-Z]/) === null) {
      setIsEmail(false);
    }
  };

  const handlePhoneLoginChange = (value) => {
    setLoginInput({ ...loginInput, email: value });
    if (value.length < 3) {
      setIsEmail(true);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, email: value });
    if (value.length >= 3 && value.match(/[a-zA-Z]/) === null) {
      setIsEmail(false);
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, email: value });
    if (value.length < 3) {
      setIsEmail(true);
    }
  };

  const isValidEmail = (email) => {
    // regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // Phone Number Added
  const isValidPhoneNumber = (email) => {
    const cleanedPhoneNumber = email.replace(/\D/g, "");
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(cleanedPhoneNumber);
  };
  const isValidPassword = (password) => {
    const hasSpecialCharacter = /[!@#$%^&(),.?":{}|<>]/.test(password);
    const hasMinimumLength = password.length >= 8;
    const hasNumber = /\d/.test(password);

    return hasSpecialCharacter && hasMinimumLength && hasNumber;
  };

  // signup form data
  const [otpValue, setOtpValue] = useState("");
  // form data
  const [isEmail, setIsEmail] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    subscription: "free",
    //  otp: "",
  });
  const handleOtpChange = (otp) => {
    setOtpValue(otp);
    //  setFormData({ ...formData, otp: otp }); // Update the formData object with the new OTP value
  };
  //
  //  this is for handling the signup data (works on validate button) - giving it break for a while for email verification code api
  const [userExistError, setuserExistError] = useState("");
  // const [showError, setShowError] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const handleSignUp = async (event) => {
    formData["isEmail"] = isEmail;
    if (isEmail == false) {
      formData["phone"] = formData.email
        .replace(" ", "")
        .replace("+", "")
        .replace("-", "");
      formData["email"] = "";
    }

    const currentSelectedLanguage = localStorage.getItem("lang");
    formData["lang"] = currentSelectedLanguage;
    postSignUpData(
      formData,
      history,
      setuserExistError,
      setShowError,
      setShowLoginError,
      setLoginError,
      "/",
      setOtpValidated
    );
    // try {
    //   await createUserWithEmailAndPassword(auth, formData.email, formData.password);
    // } catch (err) {
    //   console.log(err.message);
    // }
  
  };

  // handle user exist on next button of signup form
  // const handleSignUpNext = () ={

  // }

  // SIGN UP : EMAIL VERIFICATION ==================================================================================
  const obj = {
    subject: "email verification",
    email: formData.email,
    name: formData.name,
  };
  const phoneobj = {
    phone: formData.email.replace("-", "").replace(" ", ""),
  };
  // when user clicks on next button send the email on emailverficiation api
  const handleEmailVerification = async () => {

    if(formData.password.length < 8){
      console.log("The password must be a string with at least 8 characters");
      setError("The password must be a string with at least 8 characters");
      setShowError(true);
      return false;
    }
    const email = formData.email;
    if (isEmail === false) {
      sendPhoneVerification(phoneobj);
      phoneobj["phone"] = "+" + phoneobj.phone.replace("+", "");
      setPage(page + 1);
      setSubscriptionForm(true);
      setTimer(180);
      return;
      // }
    } else if (isEmail === true) {
      if (!isValidEmail(email)) {
        setError("Invalid email");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      } else {

        if (formData.password !== formData.confirmPassword) {
          setError("Passwords do not match");
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
          return;
        }


        if (!isValidPassword(formData.password)) {
          let errorMessage = "Password must contain ";
          if (formData.password.length < 8) {
            errorMessage += "at least 8 characters";
            setError(errorMessage);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
            return;
          }
          if (!/[!@#$%^&(),.?":{}|<>]/.test(formData.password)) {
            errorMessage += "at least one special character";
            setError(errorMessage);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
            return;
          }
          if (!/\d/.test(formData.password)) {
            errorMessage += "at least one number";
            setError(errorMessage);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
            return;
          }
        }

        

        sendEmailVerificationWithoutToken(obj);
        setPage(page + 1);
        setSubscriptionForm(true);
        setTimer(60);
        return;
      }
    }
    // if (email.substring(0, 3).match(/^\d+$/)) {
    //   // First 3 characters are digits
    //   if (isValidPhoneNumber(email)) {
    //     // Phone number validation
    //     sendPhoneVerification(phoneobj);
    //     setPage(page + 1);
    //     setSubscriptionForm(true);
    //     setTimer(60);
    //     return;
    //   } else {
    //     // Invalid phone number format
    //     setError("Invalid phone number format");
    //     setShowError(true);
    //     setTimeout(() => {
    //       setShowError(false);
    //     }, 3000);
    //     return;
    //   }
    // } else if (email.includes(" ") || email.includes("@.com")) {
    //   if (isValidEmail(email)) {
    //     // Email validation
    //     sendEmailVerification(obj);
    //     setPage(page + 1);
    //     setSubscriptionForm(true);
    //     setTimer(60);
    //     return;
    //   } else {
    //     // Invalid phone number format
    //     setError("Invalid email format");
    //     setShowError(true);
    //     setTimeout(() => {
    //       setShowError(false);
    //     }, 3000);
    //     return;
    //   }
    // }

    // if (isValidEmail(email)) {
    //   // Email validation
    //   sendEmailVerification(obj);
    //   setPage(page + 1);
    //   setSubscriptionForm(true);
    //   setTimer(60);
    //   return;
    // } else if (isValidPhoneNumber(email)) {
    //   // Phone number validation
    //   sendPhoneVerification(phoneobj);
    //   setPage(page + 1);
    //   setSubscriptionForm(true);
    //   setTimer(60);
    //   return;
    // }

    // Invalid email or phone number
    setError("Invalid email or phone number");
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 3000);

   

   
    sendEmailVerificationWithoutToken(obj);
    setPage(page + 1);
    setSubscriptionForm(true);
    setTimer(60);
  };
  const [redirectngTimer, setRedirectingTimer] = useState(3);

  useEffect(() => {
    let interval;
    if (redirectngTimer > 0) {
      interval = setInterval(() => {
        setRedirectingTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Stop the interval when timer reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [redirectngTimer]);
  // when user enters the received code validate the code using email code and token from email verification API
  const verifyCode = {
    email: formData.email,
    code: otpValue,
    token: localStorage.getItem("email verification token"),
  };
  const verifyPhoneOtpCode = {
    phone: formData.email.replace("-", "").replace(" ", ""),
    code: otpValue,
    token: localStorage.getItem("Phone verification token"),
    // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6Iis5MjMyMTI0NTM0NjYiLCJjb2RlIjoyMjI3NzAsImlhdCI6MTY4ODk3ODQ1MywiZXhwIjoxNjg5MDY0ODUzfQ.Kaxyy34xwTGG6WylBSqUVDhBk3LccP6N_iOxZCLBUzw"
  };
  //  used to show error on otp page
  const [wrongEmailCode, setWrongEmailCode] = useState(false);
  const handleEmailCodeVerification = () => {
    verifyEmailCode(verifyCode)
      .then((result) => {
        if (result) {
          localStorage.removeItem("email verification token");
          setRedirectingTimer(3);
          handleSignUp();
          history.push("/");
        } else {
          setWrongEmailCode(true);
          setTimeout(() => {
            setWrongEmailCode(false);
          }, 3000);
        }
      })
      .catch((error) => {});
  };
  const handlePhoneCodeVerification = () => {
    verifyFAPhoneCode(verifyPhoneOtpCode)
      .then((result) => {
        if (result) {
          localStorage.removeItem("Phone verification token");
          setRedirectingTimer(3);
          handleSignUp();
          history.push("/");
        } else {
          setWrongEmailCode(true);
          setTimeout(() => {
            setWrongEmailCode(false);
          }, 3000);
        }
      })
      .catch((error) => {});
  };
  // stripe state
  const [showStripe, setShowStripe] = useState(false);
  const [passError, setPassError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // login with otp==============================================
  const [showLogin, setShowLogin] = useState(true);
  const [phoneOtpValue, setPhoneOtpValue] = useState("");

  const handlePhoneOtpChange = (otp) => {
    setPhoneOtpValue(otp);
  };
  const [userDetails, setUserDetails] = useState({});
  const loginSubmit = async (event) => {
    event.preventDefault();
    if (!loginInput.email || !loginInput.password) {
      setEmptyObj(true);
      setTimeout(() => {
        setEmptyObj(false);
      }, 3000);
      return;
    }

    if (!isValidEmail(loginInput.email) && isEmail == true) {
      setError("Invalid email address");
      setShowLoginError(true);
      return;
    }
    loginInput["isEmail"] = isEmail;
    if (isEmail == false) {
      loginInput["phone"] = loginInput.email
        .replace(" ", "")
        .replace("+", "")
        .replace("-", "");
      loginInput["email"] = "";
    }
    login_tfa(
      loginInput,
      setShowLoginError,
      history,
      setLoginError,
      setShowLogin,
      setUserDetails,
      "/"
    );

  
    setTimer(60);
  };

  const [wrongPhoneCode, setWrongPhoneCode] = useState(false);

  const verifyPhoneCode = async () => {
    const verifyObj = {
      email: loginInput.email,
      code: phoneOtpValue,
      token: localStorage.getItem("2fatoken"),
    };
    try {
      const result = await verifyFACode(verifyObj);
      if (result) {
        history.push("/");
        localStorage.setItem("token", localStorage.getItem("myToken"));
        localStorage.setItem("session", localStorage.getItem("mySession"));
        setWrongPhoneCode(false);
        setPhoneOtpValue("");
        localStorage.removeItem("2fatoken");
        setTimeout(() => {
          localStorage.removeItem("myToken");
          localStorage.removeItem("mySession");
        }, 2000);
      }
    } catch (error) {
      setWrongPhoneCode(true);
      setPhoneOtpValue("");
      setTimeout(() => {
        setWrongPhoneCode(false);
      }, 3000);
    }
  };
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Stop the interval when timer reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [timer]);

  const handleResendClick = (apiCall, obj) => {
    if (timer <= 0) {
      // Implement the logic to resend the one-time password here
      apiCall(obj);
      setTimer(60); // Reset the timer to 60 seconds
    }
  };
  return (
    <>
     <Helmet>
          <title>{`${t('meta.login.title')} | AFO Media`}</title>
          <meta property="og:title" content={`${t('meta.login.title')} | AFO Media`} />
          <meta property="og:description" content={t('meta.login.description')} />
          <meta property="og:url" content={`https://afomedia.com/login`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      {console.log(width)}
      {width && width >= 800 ? (
        <section className="sign-in-page">
          <div className="signinlogo">
            <Col md="3" xs="12">
              <Link to="/">  <img src={logo} alt="Logo" style={{ width: "50%" }} /></Link>
            </Col>
          </div>
          <div className="maincontainer">
            {!subscriptionForm ? (
              <div
                className={`login-signup-container ${
                  isSignUpActive ? "right-panel-active" : ""
                }`}
                id="container"
              >
                <div className="login-signup-form-container sign-up-container">
                  <div className="login-signup-form">
                    <h3>Create Account</h3>
                    <h4>Personal Details</h4>
                    {/* <div
                      className={`alert alert-danger ${
                        showError ? "" : "d-none"
                      }`}
                      role="alert"
                    ></div> */}
                    <div
                      className={`alert alert-danger ${
                        showError ? "" : "d-none"
                      }`}
                      role="alert"
                    >
                      {" "}
                      {error}
                    </div>
                    <div
                      className={`alert alert-danger ${
                        passError ? "" : "d-none"
                      }`}
                      role="alert"
                    >
                      Passwords do not match!
                    </div>
                    <input
                      type="text"
                      placeholder="Name"
                      value={formData.name} //setting the value of the form to the props value
                      onChange={
                        (e) =>
                          setFormData({ ...formData, name: e.target.value }) //setting the formData to the value input of the textfield
                      }
                    />
                    {/* {isEmail && (
                      <input
                        type="email"
                        placeholder="Email or Phone Number"
                        value={formData.email}
                        // onKeyDownCapture={isEmailValidate()}
                        onChange={async(e) => {
                        setFormData({ ...formData, email: e.target.value })
                          if(e.target.value.length >= 3 && e.target.value.match(/[a-zA-Z]/) == null ) {
                            setIsEmail(false)
                          }
                        }}
                      />
                    )}
                    {!isEmail && (
                    <PhoneInput
                      type="tel"
                      placeholder="Phone Number"
                      value={formData.email}
                      defaultCountry="ua"
                      // flagsComponent={CustomFlags}
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value })
                        if(e.target.value.length < 3 ) {
                          setIsEmail(true)
                        }
                      }}
                    />
                    )} */}
                    {isEmail ? (
                      <input
                        type="email"
                        placeholder="Email or Phone Number"
                        autoFocus
                        value={formData.email}
                        onChange={handleEmailChange}
                      />
                    ) : (
                      <PhoneInput
                        inputClass="phoneInput"
                        placeholder="Phone Number"
                        value={formData.email}
                        defaultCountry="ua"
                        onChange={handlePhoneChange}
                        autoFocus
                      />
                    )}
                    <div className="password-input">
                      {" "}
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={formData.password} //setting the value of the form to the props value
                        onChange={
                          (e) =>
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            }) //setting the formData to the value input of the textfield
                        }
                      />{" "}
                      <span
                        className="password-toggle"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>

                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                    <button
                      className="button"
                      onClick={() => {
                        handleEmailVerification();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
                {showLogin ? (
                  <>
                    {" "}
                    <div className="login-signup-form-container sign-in-container">
                      <form action="#" className="login-signup-form">
                        <h3>Sign In</h3>
                        <div
                          className={`alert alert-danger ${
                            showLoginError ? "" : "d-none"
                          }`}
                          role="alert"
                        >
                          {loginError}
                          {error}
                        </div>
                        <div
                          className={`alert alert-danger ${
                            emptyObj ? "" : "d-none"
                          }`}
                          role="alert"
                        >
                          Enter email and password
                        </div>
                        {/* <input
                          type="email"
                          placeholder="Email"
                          required
                          name="email"
                          value={loginInput.email || ""}
                          onChange={handleInputChange}
                        /> */}
                        {isEmail ? (
                          <input
                            type="email"
                            placeholder="Email"
                            required
                            name="email"
                            value={loginInput.email || ""}
                            onChange={handleEmailLoginChange}
                            // autoFocus={!isEmail}
                            autoFocus
                          />
                        ) : (
                          <PhoneInput
                            inputClass="phoneInput"
                            placeholder="Phone Number"
                            value={loginInput.email}
                            defaultCountry="ua"
                            onChange={handlePhoneLoginChange}
                            autoFocus
                            // inputProps={{ autoFocus: true }}
                            // ref={phoneInputRef}
                          />
                        )}
                        <div className="password-input">
                          {" "}
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            required
                            name="password"
                            value={loginInput.password || ""}
                            onChange={handleInputChange}
                          />{" "}
                          <span
                            className="password-toggle"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <Link to="/recover-password" className="f-link">
                          Forgot your password?
                        </Link>
                        <button
                          className="button"
                          onClick={loginSubmit}
                          // onClick={() => {
                          //   setShowLogin(false);
                          // }}
                          // onClick={() => {
                          //   // history.push("/");

                          // }}
                        >
                          Sign In
                        </button>
                      </form>
                    </div>
                    <div className="overlay-container">
                      <div className="overlay">
                        <div className="overlay-panel overlay-left">
                          <h3>Welcome Back!</h3>
                          <p>
                            To keep connected with us please login with your
                            personal info
                          </p>
                          <button
                            className="ghost button"
                            id="signIn"
                            onClick={handleSignInClick}
                          >
                            Sign In
                          </button>
                        </div>
                        <div className="overlay-panel overlay-right">
                          <h3>Hello, Friend!</h3>
                          <p>
                            Enter your personal details and start journey with
                            us
                          </p>
                          <button
                            className="ghost button"
                            id="signUp"
                            onClick={handleSignUpClick}
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className="form2"
                    style={{ zIndex: "10000", position: "absolute" }}
                  >
                    <div className="Subscription">
                      <div className="heading-icon">
                        <FcPrevious
                          className="prev-icon"
                          size={30}
                          onClick={() => {
                            setShowLogin(true);
                          }}
                        />
                        <div className="heading">
                          Please enter the One-Time Password to verify your
                          account
                        </div>
                      </div>
                      <div className="otpbody">
                        <div className="otp-body">
                          <span style={{ marginBottom: "2rem" }}>
                            We’ve sent an SMS message containing a code to your
                            mobile device.
                            {/* A one-Time Password has been sent to your phone
                            number {userDetails.phone} */}
                          </span>
                          <OtpInput
                            value={phoneOtpValue}
                            onChange={handlePhoneOtpChange}
                            numInputs={6}
                            isInputNum
                            renderInput={(inputProps) => (
                              <input {...inputProps} />
                            )}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.5rem",

                              borderRadius: 4,

                              outline: "none",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        {wrongPhoneCode && (
                          <span style={{ color: "red" }}>
                            Entered wrong code, please enter the code sent on
                            {userDetails.phone}
                          </span>
                        )}

                        <button
                          className="button"
                          // onClick={handleSignUp}

                          onClick={verifyPhoneCode}
                        >
                          Validate
                        </button>

                        <div className="otp-body">
                          <h4>
                            {timer === 0 ? (
                              <Link
                                onClick={() => {
                                  handleResendClick(FACodeSend, {
                                    email: loginInput.email,
                                    phone: userDetails.phone,
                                  });
                                }}
                              >
                                Resend One Time Password
                              </Link>
                            ) : (
                              <Link>
                                Resend One Time Password in {timer} seconds
                              </Link>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="form2">
                <div className="Subscription">
                  {!otpValidated ? (
                    <>
                      {" "}
                      <div className="heading-icon">
                        <FcPrevious
                          className="prev-icon"
                          size={30}
                          onClick={() => {
                            setPage(page - 1);
                            setOtp(false);
                            setSubscriptionForm(false);
                          }}
                        />
                        <div className="heading">
                          Please enter the One-Time Password to verify your
                          account
                        </div>
                      </div>
                      <div className="otpbody">
                        <div className="otp-body">
                          <span style={{ marginBottom: "2rem" }}>
                            A one-Time Password has been sent to{" "}
                            {formData.email}
                          </span>
                          <OtpInput
                            value={otpValue}
                            onChange={handleOtpChange}
                            numInputs={6}
                            isInputNum
                            renderInput={(inputProps) => (
                              <input {...inputProps} />
                            )}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.5rem",

                              borderRadius: 4,

                              outline: "none",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        {wrongEmailCode && (
                          <span style={{ color: "red" }}>
                            Entered wrong code, please enter the code sent on{" "}
                            {formData.email}{" "}
                          </span>
                        )}
                        {userExistError !== "" && (
                          <span style={{ color: "red" }}>{userExistError}</span>
                        )}
                        {isEmail ? (
                          <button
                            className="button"
                            // onClick={handleSignUp}

                            onClick={handleEmailCodeVerification}
                          >
                            Validate
                          </button>
                        ) : (
                          <button
                            className="button"
                            // onClick={handleSignUp}

                            onClick={handlePhoneCodeVerification}
                          >
                            Validate
                          </button>
                        )}

                        <div className="otp-body">
                          <h4>
                            {timer === 0 ? (
                              <Link
                                onClick={() => {
                                  handleResendClick(sendEmailVerificationWithoutToken, obj);
                                }}
                              >
                                Resend One Time Password
                              </Link>
                            ) : (
                              <Link>
                                Resend One Time Password in {timer} seconds
                              </Link>
                            )}
                          </h4>
                          {/* <Link
                          onClick={() => {
                            sendEmailVerificationWithoutToken(obj);
                            setOtpValue("");
                          }}
                        >
                          Resend One Time Password
                        </Link> */}

                          <span style={{ marginBottom: "2rem" }}>
                            <Link
                              onClick={() => {
                                setSubscriptionForm(false);
                                setOtp(false);
                              }}
                            >
                              Entered a Wrong Email?
                            </Link>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="otpbody" style={{ margin: "auto" }}>
                      <div className="success2FA">
                        <AiOutlineCheckCircle
                          style={{ color: "var(--iq-primary)" }}
                          size={30}
                        />
                        <h5 style={{ color: "var(--iq-primary)" }}>
                          Your account has been created successfully!
                        </h5>
                      </div>
                      <div className="otp-body">
                        <h6>Redirecting to home page in {redirectngTimer}</h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <LoginMob nextPath="/" />
      )}
    </>
  );
};

export default Loginsignup;
