// api.js
export async function fetchUserData() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/user/bank/get-details`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    // console.error("Error fetching data:", error);
    return null;
  }
}
