import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useTranslation } from "react-i18next";
import { TVShowListApi } from "../../../Services/tvShowsApi";
import {
  convertSecondsToHoursMinutes,
  limitTextToWords,
} from "./../../../Services/generalFunctions/generalFunctions";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
} from "../home/ShareFunction";

const AllTvShows = () => {
  const { t } = useTranslation();
  const currentSelectedLanguage = localStorage.getItem("lang");
  const source = "app";
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [dataView, setDataView] = useState(8);
  const location = useLocation();
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );

  useEffect(() => {
    fetchData(currentPage, query);
  }, [currentPage, query]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchData = async (page, query) => {
    try {
      const response = await TVShowListApi(currentSelectedLanguage);
      const allData = response.allTvShows;

      // Calculate start and end indices for the current page
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = page * itemsPerPage;

      // Slice the data based on the calculated indices
      const slicedData = allData.slice(startIndex, endIndex);

      // Update the state variables with the sliced data and total pages
      setData(slicedData);
      setTotalPages(Math.ceil(allData.length / itemsPerPage));
    } catch (error) {
      // console.error(error, "Error fetching data");
    }
  };

  useEffect(() => {
    fetchData(currentPage, query);
    const searchParams = new URLSearchParams(location.search);
    setQuery(searchParams.get("query") || "");
  }, [currentPage, location, query]);
  return (
    <>
      <br />
      <div style={{ marginTop: "80px" }}></div>

      <div style={{ minHeight: "60vh" }}>
        {data.length > 0 && (
          <section id="iq-favorites">
            <Container fluid>
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  className="overflow-hidden"
                >
                  <div
                    className="gallery-container"
                    style={{ marginTop: "20px", marginBottom: "10px" }}
                  >
                    <div className="">
                      <div id="upcoming-contents">
                        <ul className="list-inline mb-0 iq-rtl-direction row">
                          {data?.map((data) => (
                            <li
                              className="all-card-container slide-item  mb-4 col-lg-3 col-md-4 col-sm-6"
                              key={data._id}
                            >
                              <div className="block-images position-relative">
                                <div className="card-slider-badge">
                                  <div className="badge badge-secondary p-1 mr-2">
                                    {data?.category === "movie" ||
                                    data?.category === "video"
                                      ? convertSecondsToHoursMinutes(
                                          data?.media?.duration.toFixed(2)
                                        )
                                      : `${data?.episodes.length} ${
                                          data?.episodes.length === 1
                                            ? "Episode"
                                            : "Episodes"
                                        }`}
                                  </div>
                                </div>
                                <div className="img-box">
                                  <img
                                    src={data?.thumbnail?.banner_thumbnail_url}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="block-description">
                                  <h6 className="iq-title all-title-desc">
                                    {data?.title}
                                  </h6>
                                  <div className="card-slider-desc">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: limitTextToWords(
                                          data?.description,
                                          12
                                        ),
                                      }}
                                    ></span>
                                  </div>
                                  <div className="hover-buttons">
                                    <Link
                                      to={`/show-info/${data?._id}`}
                                      role="button"
                                      className="btn btn-hover iq-button btn-typ"
                                    >
                                      <i
                                        className="fa fa-play mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Play now")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="block-social-info">
                                  <ul className="list-inline p-0 m-0 music-play-lists">
                                    <li className="share">
                                      <span>
                                        <i className="ri-share-fill"></i>
                                      </span>
                                      <div className="share-box">
                                        <div className="d-flex align-items-center">
                                          {/* <a
                                          href={generateLinkedinShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-linkedin-fill"></i>
                                        </a>
                                        <a
                                          href={generateInstagramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-instagram-fill"></i>
                                        </a>
                                        <a
                                          href={generateSnapchatShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-snapchat-fill"></i>
                                        </a>
                                        <a
                                          href={generateTikTokShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-tiktok-fill"></i>
                                        </a> */}
                                          <a
                                            href={generateFacebookShareUrl(
                                              data,
                                              source,
                                              data?.category
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="share-ico"
                                            tabIndex="0"
                                          >
                                            <i className="ri-facebook-fill"></i>
                                          </a>
                                          <a
                                            href={generateTwitterShareUrl(
                                              data,
                                              source,
                                              data?.category
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="share-ico"
                                            tabIndex="0"
                                          >
                                            <i className="ri-twitter-fill"></i>
                                          </a>
                                          <a
                                            href={generateWhatsappShareUrl(
                                              data,
                                              source,
                                              data?.category
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="share-ico iq-copy-link"
                                            tabIndex="0"
                                          >
                                            <i className="ri-whatsapp-fill"></i>
                                          </a>
                                          {/* <a
                                          href={generatePinterestShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-pinterest-fill"></i>
                                        </a>
                                        <a
                                          href={generateTelegramShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-telegram-fill"></i>
                                        </a>
                                        <a
                                          href={generateRedditShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-reddit-fill"></i>
                                        </a>
                                        <a
                                          href={generateQuoraShareUrl(
                                            data,
                                            source,
                                            data?.category
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="share-ico"
                                          tabIndex="0"
                                        >
                                          <i className="ri-quora-fill"></i>
                                        </a> */}
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <span>
                                        <i
                                          className={`ri-heart-fill ${
                                            favoriteItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                          }`}
                                          //   onClick={() =>
                                          //     handleFavClick(
                                          //       data?._id,
                                          //       data?.category
                                          //     )
                                          //   }
                                          title={
                                            favoriteItems.includes(data?._id)
                                              ? t("Remove From Favourite")
                                              : t("Add To Favourite")
                                          }
                                        ></i>
                                      </span>
                                      {/* <span className="count-box">19+</span> */}
                                    </li>
                                    <li>
                                      <span>
                                        <i
                                          className={`ri-add-line ${
                                            watchListItems.includes(data?._id)
                                              ? "red-heart"
                                              : ""
                                          }`}
                                          //   onClick={() =>
                                          //     handleWatchList(
                                          //       data?._id,
                                          //       data?.category
                                          //     )
                                          //   }
                                          title={
                                            watchListItems.includes(data?._id)
                                              ? t("Remove From WatchList")
                                              : t("Add To WatchList")
                                          }
                                        ></i>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {data.length > 0 && (
          <div className="pagination-container">
            <Pagination className="custom-pagination">
              {" "}
              {/* Add the custom class */}
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        )}
      </div>
    </>
  );
};
export default AllTvShows;
