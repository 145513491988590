import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserFreeAccountSetting from "../views/backend/app/usermanagement/userfreeaccountsetting";
import checkUserCard from "../Services/checkUserCard";
import getPackageDetails from "../Services/package";
import AuthSession from "../Services/getSessionAuth";
import updateUserDetails from './../Services/updateUserDetails';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchDataFromAPI } from "../views/backend/app/bankdetails";

function SubscriptionLink() {
    const [apiBankResponse, setApiBankResponse] = useState('');  
    const { t } = useTranslation();
    // remove card=======================================================
    const [checkCardConnection, setCheckCardConnection] = useState(false);
    const [packageDetails, setPackageDetails] = useState();
    const currentUser = JSON.parse(localStorage.getItem("session"));
    const currentUserId = currentUser?._id;

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const getSessionData = () => {
        const userDetails_Session = JSON.parse(localStorage.getItem("session"));
        return {
            subscription: userDetails_Session.subscription,
            status: userDetails_Session?.status,
        };
    };
    const [userDetails, setUserDetails] = useState(getSessionData());
    const handleModalChange = () => {
        getPackageDetails().then((data) => {
            setPackageDetails(data?.package);
            setShowSubscriptionModal(true);
        });
    };
    useEffect(() => {
        checkUserCard(currentUserId).then((response) => {
            if (response.data.connected) {
                setCheckCardConnection(true);
            } else {
                setCheckCardConnection(false);
            }
        });
    }, [userDetails]);

    const handleUpdateData = (data) => {
        setUserDetails(data);
    };
    // const handleCancelSubscription = async () => {
    //     toast.success(t("Cancelling Membership !"), {
    //         position: "top-center",
    //         progressStyle: { background: "var(--iq-primary)" },
    //     });
    //     await updateUserDetails({ _id: userDetails._id, subscription: "free" });
    //     await AuthSession();
    //     const updatedUserDetails = getSessionData();
    //     setUserDetails(updatedUserDetails);
    //     toast.success(t("Membership Cancelled Successfully!"), {
    //         position: "top-center",
    //         progressStyle: { background: "var(--iq-primary)" },
    //     });
    // };
    return (
        <>
            <ToastContainer
                toastStyle={{
                    background: "black",
                    color: "white",
                }}
            />
            <div className="main-div">
                {userDetails?.subscription === "paid" || (apiBankResponse?.status === "400" && apiBankResponse?.message === "User already paid!")  ? (
                    <>
                        {" "}
                        <Row className="justify-content-between mb-3">
                            <Col md="12">
                                {/* <p>{t("premium")}</p> */}
                                <p><b>Already Premium Plan Selected!!! </b></p>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <h5 className="mb-3 pb-3 text-center-sub">{t("plan details")}</h5>
                        <Row className="justify-content-between mb-3">
                            <Col md="12" className="text-center-sub">
                                <p>{t("Free")}</p>
                            </Col>
                            <Col md="12" className="text-center-sub text-left">
                                <Button
                                    className="btn btn-typ btn-hover"
                                    style={{ padding: "6px 7px", opacity: 1 }}
                                    onClick={handleModalChange}
                                >
                                    {t("Upgrade to Premium")}
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
            <UserFreeAccountSetting
                updateData={handleUpdateData}
                showModal={showSubscriptionModal}
                setShowModal={setShowSubscriptionModal}
                packageDetails={packageDetails}
                apiBankResponse = {setApiBankResponse}
            />
        </>
    )
}

export default SubscriptionLink

