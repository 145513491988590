import axios from "axios";

export async function addToFavorites(currentUserId, id) {
  const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
  const data = {
    general_content_id: id,
  };

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      alert("Content added to Favorite list!");
      return response.json();
    } else {
      throw new Error("Something went wrong...");
    }
  });
}

export const addToHistory = async (obj, currentUserId) => {
  const apiUrl = `${process.env.REACT_APP_API}/api/user/add-to-history/${currentUserId}`;

  try {
    const response = await axios.post(apiUrl, obj);
  } catch (error) {}
};
