export const getBillingHistory = async (userId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/user/billing/history/${userId}`
    );
    if (!response.ok) {
      throw new Error("Request failed.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};
