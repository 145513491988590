import React, { useState } from "react";
import "./donate-form.css";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import donateMoneyApi from "../Services/donationApi";
import Stripe from "../views/backend/pages/auth/stripe";
import AuthSession from "../Services/getSessionAuth";
import checkUserCard from "../Services/checkUserCard";
import userSubscription from "../Services/userSubscription";
import updateUserDetails from "../Services/updateUserDetails";
import { postBankDonateApi } from "./DonateApi";
import { useTranslation } from "react-i18next";
import {
  fetchDataFromAPI,
  fetchSubscriptionApi,
} from "../views/backend/app/bankdetails";

const DonateForm = ({ handleDonationClose, setHomeStripeModal }) => {
  const [donationAmount, setDonationAmount] = useState("");
  const [showError, setShowError] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const { t } = useTranslation();
  const userId = currentUser?._id;

  const handleAmountClick = (amount) => {
    setDonationAmount(amount);
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [cardConnected, setCardConnected] = useState(false);
  const [showBankTransferDetails, setShowBankTransferDetails] = useState(false);
  const [cardDisconnected, setCardDisconnected] = useState(false);
  const [form1, setForm1] = useState(true);
  const donateFunction = (submitButton) => {
    const payload = {
      amount: donationAmount,
    };
    donateMoneyApi(payload, userId)
      .then(() => {
        // toast.success("Donation sent successfully.");

        // Enable the submit button
        submitButton.disabled = false;

        //close the modal

        setTimeout(() => {
          handleDonationClose();
        }, 10000);
      })
      .catch((error) => {
        // toast.error(error.message);
        // // Enable the submit button
        // submitButton.disabled = false;
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!donationAmount || donationAmount <= 0) {
      setShowError(true);
      return;
    }

    // Disable the submit button
    const submitButton = e.target.querySelector(".submit-donate-button");
    submitButton.disabled = true;

    const checkCardApiUrl = `${process.env.REACT_APP_API}/api/user/stripe/check-card/${userId}`;

    // Check if user's card is connected
    fetch(checkCardApiUrl)
      .then((response) => response.json())
      .then((data) => {
        setForm1(false);
        setHomeStripeModal(true);
        if (data?.connected) {
          // usr card is connected
          setCardConnected(true);
          donateFunction(submitButton);
        } else {
          // if user card is not connected
          setCardConnected(false);
          submitButton.disabled = false;
          // handleDonationClose();
        }
      })
      .catch((error) => {
        toast.error(t("An error occurred while checking card connection."));

        // Enable the submit button
        submitButton.disabled = false;
      });
  };
  const currentUserId = currentUser?._id;
  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));
    // Get the current date
    const currentDate = new Date();

    // Subtract 15 years from the current date
    currentDate.setFullYear(currentDate.getFullYear() - 15);

    // Format the date as "YYYY-MM-DD"
    const fifteenYearsAgo = currentDate.toISOString().substring(0, 10);
    return {
      _id: userDetails_Session._id,
      name: userDetails_Session.name,
      email: userDetails_Session.email,
      tfa: userDetails_Session.tfa,
      phone: userDetails_Session.phone ? userDetails_Session.phone : "N/A",
      // dob: userDetails_Session.dob
      //   ? userDetails_Session.dob.substring(0, 10)
      //   : "2021-09-02",
      dob: userDetails_Session.dob
        ? userDetails_Session.dob.substring(0, 10)
        : fifteenYearsAgo,
      language: userDetails_Session.language
        ? userDetails_Session.language
        : "unknown",
      gender: userDetails_Session.gender
        ? userDetails_Session.gender
        : "unknown",
      country: userDetails_Session.country
        ? userDetails_Session.country
        : "N/A",
      password: userDetails_Session.password
        ? userDetails_Session.password
        : "N/A",
      profileIMG: userDetails_Session?.profile_image?.url
        ? userDetails_Session?.profile_image?.url
        : "",
      subscription: userDetails_Session.subscription,
      brand: userDetails_Session.brand,
      last4: userDetails_Session.last4,
    };
  };
  const [userDetails, setUserDetails] = useState(getSessionData());
  const [userInfo, setUserInfo] = useState({
    _id: userDetails._id,
    subscription: "paid",
  });
  const [cardInfo, setCardInfo] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState("");
  const [storingBankDetails, setStoringBankDetails] = useState({});
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);

  const openBankTransferModal = async () => {
    // setShowPaymentMethodModal(false);
    // setShowBankTransferModal(true);
    try {
      const data = await fetchDataFromAPI();
      if (data) {
        setBankDetails(data);
        setShowBankTransferModal(true);
        setShowBankTransferDetails(true);
        setShowPaymentMethodModal(false);
      }
    } catch (error) {}
  };

  const handleSubmitDonation = async (event) => {
    setForm1(false);
    setShowStripe(true);
    setShowPaymentMethodModal(true);
  };

  const stripModal = async (event) => {
    setLoading(true);
    try {
      const response = await checkUserCard(currentUserId);
      setShowStripe(true);
      setShowPaymentMethodModal(false);
      setCardDisconnected(true);
      if (response.data.connected) {
        await userSubscription(currentUserId);
        setCardInfo(true);
        await updateUserDetails(userInfo);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
      } else {
        setCardInfo(false);
      }
    } catch (error) {}
    setLoading(false);
  };

  const [paymentStatus, setPaymentStatus] = useState(false);
  const [showBankTransferButton, setShowBankTransferButton] = useState(true);
  const [message, setMessage] = useState("");
  const handleBankDonation = async (donationAmount) => {
    try {
      const bankSubscriptionData = await postBankDonateApi(donationAmount);
      setStoringBankDetails(bankSubscriptionData);
      if (bankSubscriptionData && bankSubscriptionData.status === "review") {
        setPaymentStatus(true);
        // setTimeout(() => setShowBankTransferModal(false), 7000);
        // toast.info("Amount Successfully Donated, Thank you!", {
        //   position: "top-center",
        // });
        return bankSubscriptionData;
      } else {
        setPaymentStatus(false);
        // setTimeout(() => setShowBankTransferModal(false), 7000);
        // toast.info("Unable to Pay", {
        //   position: "top-center",
        // });
        return null;
      }
    } catch (error) {
      setShowBankTransferModal(false); // Close modal
      toast.error(t("An error occurred while processing your donation."), {
        position: "top-center",
      });
      return null;
    }
  };

  //   try {
  //     const bankSubscriptionData = await postBankDonateApi(currentUserId);
  //     if (bankSubscriptionData.status === "400") {
  //       setShowBankTransferModal(false);
  //       toast.info("Unable to Pay", {
  //         position: "top-center",
  //       });
  //       return bankSubscriptionData;
  //     } else {
  //       setShowBankTransferModal(false); // close modal
  //       toast.info("Amount Successfully Donated, Thankyou!", {
  //         position: "top-center",
  //       });
  //       return bankSubscriptionData;
  //     }
  //   } catch (error) {
  //   }
  // };
  return (
    <>
      <Modal
        className="threeModal"
        show={showBankTransferModal}
        onHide={() => {
          setShowBankTransferModal(false);
          setShowBankTransferDetails(false);
          setShowConfirmation(false);
          handleDonationClose(false);
          setForm1(true);
        }}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Bank Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showBankTransferDetails &&
            bankDetails &&
            bankDetails.bank_detail && (
              <div style={{ textAlign: "center" }}>
                {/* <h5>Bank Transfer - </h5> */}
                <div
                  dangerouslySetInnerHTML={{ __html: bankDetails.bank_detail }}
                />
              </div>
            )}
          {showConfirmation && (
            <>
              <div style={{ textAlign: "center" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: bankDetails.confirmation_msg,
                  }}
                />
              </div>
            </>
          )}
          {/* {/* <div style={{ textAlign: "center" }}>
          {/* {/* <div style={{ textAlign: "center" }}>
            <div>
              <h5>Bank Type - </h5>
              {storingBankDetails?.type || ""}
            </div>
            <div>
              <h5>Bank Title -</h5>
              {storingBankDetails?.title || ""}
            </div>
            <div>
              <h5>Bank Currency -</h5>
              {storingBankDetails?.currency || ""}
            </div>
          </div> */}
          {showBankTransferButton && (
            <button
              className="btn btn-primary delBtn Bank-Type"
              onClick={async () => {
                const bankSubscriptionData = await handleBankDonation(donationAmount);
                if (
                  bankSubscriptionData &&
                  bankSubscriptionData.status === "review"
                ) {
                  setMessage(bankDetails.confirmation_msg);
                  // setMessage("Amount Successfully Donated, Thankyou!");
                  setShowBankTransferButton(false);
                  setShowBankTransferDetails(false);
                  setShowStripe(false);
                  setShowPaymentMethodModal(false);
                  setShowConfirmation(true);
                } else {
                  setMessage("Unable to Pay");
                }
                // setTimeout(() => setShowBankTransferModal(false), 7000);
              }}
            >
              {t("Payment Made")}
            </button>
          )}
          {/* {message && (
            <div style={{ textAlign: "center" }}>
              <h5>{message}</h5>
            </div>
          )} */}
        </Modal.Body>
      </Modal>

      <div className="donate-container">
        {form1 ? (
          <div className="donate-box">
            <h5>{t("Together we can make a change!")}</h5>
            <form onSubmit={handleSubmit}>
              <div className={`donate-input-box ${showError ? "error" : ""}`}>
                <input
                  type="number"
                  name=""
                  required=""
                  value={donationAmount}
                  onChange={(e) => {
                    setDonationAmount(e.target.value);
                    setShowError(false);
                  }}
                  className="donate-input"
                />
                <label>{t("Amount")}</label>
                <span
                  style={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    color: "white",
                  }}
                >
                  $
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center mt-3">
                <Button
                  style={{
                    padding: "0.2rem 0.5rem",
                    borderRadius: "0.5rem",
                    background:
                      "linear-gradient(45deg, #1171b6, #1075bd, #21a6e0)",
                    border: "none",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => handleAmountClick(5)}
                >
                  <div
                    style={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    <span>5 USD</span>
                  </div>
                </Button>
                <Button
                  style={{
                    padding: "0.2rem 0.5rem",
                    borderRadius: "0.5rem",
                    background:
                      "linear-gradient(45deg, #1171b6, #1075bd, #21a6e0)",
                    border: "none",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => handleAmountClick(10)}
                >
                  <div
                    style={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    <span>10 USD</span>
                  </div>
                </Button>
                <Button
                  style={{
                    padding: "0.2rem 0.5rem",
                    borderRadius: "0.5rem",
                    background:
                      "linear-gradient(45deg, #1171b6, #1075bd, #21a6e0)",
                    border: "none",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => handleAmountClick(20)}
                >
                  <div
                    style={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    <span>20 USD</span>
                  </div>
                </Button>
              </div>
              <div className="button-donate-container">
                <button
                  className="submit-donate-button"
                  type="submit"
                  style={{ backgroundColor: "transparent", cursor: "pointer" }}
                  onClick={() => {
                    handleSubmitDonation();
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  {t("Support the cause")}
                </button>
              </div>
            </form>
          </div>
        ) : showPaymentMethodModal ? (
          <Modal
            className="deleteModal"
            show={showPaymentMethodModal}
            onHide={() => {
              setShowPaymentMethodModal(false);
              setForm1(true);
              // setShowStripe(false);
              // setShowConfirmation(false)
              // handleDonationClose(false)
            }}
            centered={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Select Payment Type")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="three-payment">
                <div className="three-pay-btn">
                  <button
                    className="btn btn-primary delBtn Payment-Type"
                    onClick={() => {
                      stripModal();
                    }}
                  >
                    {t("Card Payment")}
                  </button>
                </div>
                <div className="three-pay-btn">
                  <button
                    className="btn btn-primary delBtn Payment-Type"
                    onClick={() => {
                      stripModal();
                    }}
                  >
                    PayPal
                  </button>
                </div>
                <div className="three-pay-btn">
                  <button
                    className="btn btn-primary delBtn Payment-Type"
                    onClick={() => {
                      openBankTransferModal();
                    }}
                  >
                    {t("Bank Transfer")}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : cardConnected ? (
          <div className="donate-box">
            <h5>Together we can make a change!</h5>
            <div className="success2FA">
              <span style={{ color: "var(--iq-primary)" }}>
                {t("Amount Successfully Donated, Thank you!")}
              </span>
            </div>
          </div>
        ) : (
          cardDisconnected && (
            <Modal
              className="deleteModal"
              show={cardDisconnected}
              onHide={() => {
                setCardDisconnected(false);
                setForm1(true);
              }}
              centered={true}
            >
              <Modal.Body>
                <div className="stripe-Modal">
                  <Stripe
                    message={"Amount successfully donated, Thankyou"}
                    contentType="donation"
                    donateFn={donateFunction}
                    handleDonationClose={handleDonationClose}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default DonateForm;
