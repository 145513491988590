import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Nav,
  Button,
} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Card from "../../../Card";
import axios from "axios";

import CustomToggle from "../../../dropdowns";
// MULTI LANG HOOK
import { useTranslation } from "react-i18next";
import AuthSession from "../../../../Services/getSessionAuth";
//img
import { BsShopWindow } from "react-icons/bs";

import logoicon from "../../../../assets/images/logoicon.png";
import logo from "../../../../assets/images/logo.png";
import dummyLogin from "../../../../assets/images/dummy-login.png";
import defaultProfile from "../../../../assets/images/profile-default.png";
import thumb1 from "../../../../assets/images/notify/thumb-1.jpg";
import thumb2 from "../../../../assets/images/notify/thumb-2.jpg";
import thumb3 from "../../../../assets/images/notify/thumb-3.jpg";
import user from "../../../../assets/images/user/user.jpg";
// flags
import spain from "../../../../assets/images/flags/spain.png";
import uk from "../../../../assets/images/flags/uk.png";
import france from "../../../../assets/images/flags/france.png";
import german from "../../../../assets/images/flags/german.png";
import italy from "../../../../assets/images/flags/italy.png";
import poland from "../../../../assets/images/flags/poland.png";
import { useContext } from "react";
import { MyContext } from "../../../../store/context";
import { arrayUnion, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../../../firebase/config";
import audio from "../../../../assets/audios/livechat-129007.mp3";
export const languages = [
  { code: "fr", name: "French", icon: france },
  { code: "en", name: "English", icon: uk },
  // { code: "de", name: "Deutsch", icon: german },
  // { code: "es", name: "Espanol", icon: spain },
  // { code: "it", name: "Italiano", icon: italy },
  // { code: "pl", name: "Polski", icon: poland },
];

const HeaderStyle1 = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const languageCode = localStorage.getItem("lang");
  const [searchTerm, setSearchTerm] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [permissionData, setPermissionData] = useState({});

  const userDetails = JSON.parse(localStorage.getItem("session"));
  const profileImage = userDetails?.profile_image?.url;
  const location = useLocation();
  const pathname = location.pathname;

  const { category, setCategory } = useContext(MyContext);
  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     setShowInput(false);
  //   }
  // };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [notifications, setNotifications] = useState([]);
  const [uid, setUid] = useState(null);
  const audioRef = React.useRef(new Audio(audio));

  // useEffect(() => {
  //   const notificationsQuery = query(
  //     collection(db, 'notifications'),
  //     orderBy('createdAt', 'desc'),  // Assurez-vous d'avoir un champ 'timestamp' dans vos documents
  //     limit(5)
  //   );

  //   const unsubscribe = onSnapshot(
  //     notificationsQuery,
  //     (snapshot) => {
  //       const notificationsData = snapshot.docs.map(doc => ({
  //         id: doc.id,
  //         ...doc.data()
  //       }));

  //       // var audio = document.createElement("AUDIO")
  //       // document.body.appendChild(audio);
  //       // audio.src = "./../../../../assets/audios/livechat-129007.mp3"
  //       // audio.play()
  //        // Jouer le son si une nouvelle notification est ajoutée
  //        if (notifications.length > 0 && notificationsData.length > notifications.length) {
  //         audioRef.current.play().catch(error => {
              //   console.error("Audio playback failed:", error);
              // });
  //       }

  //       setNotifications(notificationsData);
  //     },
  //     (error) => {
  //       console.error("Error fetching notifications: ", error);
  //     }
  //   );

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);

  


  
  



  // useEffect(() => {
  //   const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       setUid(user.uid);
  //     } else {
  //       setUid(null);
  //     }
  //   });

  //   // Cleanup auth subscription on unmount
  //   return () => unsubscribeAuth();
  // }, []);

  const handleNotificationClick  = async (notificationId, link) => {
    if(uid){
      const notificationRef = doc(db, 'notifications', notificationId);

  try {
    // Récupérer le document existant
    const notificationDoc = await getDoc(notificationRef);

    // Vérifier si le document existe avant de le mettre à jour
    if (notificationDoc.exists()) {
      const notificationData = notificationDoc.data();

      // Vérifier si l'UID n'est pas déjà dans already_seen
      if (!notificationData.already_seen.includes(uid)) {
        // Mettre à jour le document pour ajouter l'UID à already_seen
        await updateDoc(notificationRef, {
          already_seen: arrayUnion(uid)
        });

        console.log(`UID ${uid} added to already_seen in notification ${notificationId}`);
      } else {
        console.log(`UID ${uid} already exists in already_seen for notification ${notificationId}`);
      }
    } else {
      console.log(`Notification document with ID ${notificationId} does not exist`);
    }
  } catch (error) {
    console.error("Error updating notification:", error);
  }
    }

    window.location.href = process.env.REACT_APP_URL + link;
    
  };

  // for search
  const [delayDebounceFn, setDelayDebounceFn] = useState(null);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the form submission or any other default behavior
      redirectToSearchPage();
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setDelayDebounceFn(
        setTimeout(() => {
          redirectToSearchPage();
        }, 1500)
      );
    }

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]);

  const redirectToSearchPage = () => {
    const encodedSearchTerm = encodeURIComponent(searchTerm).replace(
      /%20/g,
      "+"
    );
    const url = `/searchPage?languageCode=${languageCode}&term=${encodedSearchTerm}`;
    history.push(url);
  };

  //----------------------------------------

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const langFromLocalStorage = localStorage.getItem("lang") || "fr";
    const langIndex = languages.findIndex(
      (lang) => lang.code === langFromLocalStorage
    );
    return languages[langIndex];
  });


  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
    const langFromLocalStorage = localStorage.getItem("lang") || "fr";
    const selectedLang = languages.find((lang) => lang.code === langFromLocalStorage);
    setSelectedLanguage(selectedLang);
  }, []);
 
  const handleLanguageSelect = (eventKey) => {
    const selectedLang = languages.find((lang) => lang.name === eventKey);
    setSelectedLanguage(selectedLang);

    i18n.changeLanguage(selectedLang.code);
    localStorage.setItem("lang", selectedLang.code);
  };

  //Calling t and i18n method from useTranslation hook
  const { t, i18n } = useTranslation("translation");

  const [queryTxt, setQuery] = useState("");
  const history = useHistory();

  const handleClearSearch = () => {
    setQuery("");
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const handleDoubleClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  // handle click
  const handleprofileClick = async () => {
    const result = await AuthSession();
    if (result) {
      history.push("/home/setting");
    } else {
      history.push("/");
    }
  };

  const selectedLanguagee = localStorage.getItem("lang");
  const [menuItemsPermission, setMenuItemsPermission] = useState({});
  const selectedLanguageCode = selectedLanguage?.code;

  // Effect to fetch data from the API and set 'permissionData' and 'menuItemsPermission'
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API}/api/permission/get-permission`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setPermissionData(data?.permission);

  //       // Initialize the menuItemsPermission state based on the fetched data
  //       const permissionStatus = {};
  //       Object.keys(data?.permission).forEach((item) => {
  //         permissionStatus[item] =
  //           data?.permission[item][selectedLanguageCode] === true;
  //       });
  //       setMenuItemsPermission(permissionStatus);
  //     })
  //     .catch((error) => {
  //       // console.error("Error fetching data:", error);
  //     });
  // }, [selectedLanguagee]);

  const tempLanguage = localStorage.getItem("lang");

  // useEffect(() => {
  //   // Check if the language is set in local storage

  //   if (!tempLanguage) {
  //     // If the language is not set in local storage, set the default language
  //     i18n.changeLanguage("en");
  //     localStorage.setItem("lang", "en");
  //     const selectedLang = languages.find((lang) => lang.code === "en");
  //     setSelectedLanguage(selectedLang);
  //   } else {
  //     i18n.changeLanguage(tempLanguage);
  //     localStorage.setItem("lang", tempLanguage);
  //     const selectedLang = languages.find((lang) => lang.code === tempLanguage);
  //     setSelectedLanguage(selectedLang);
  //   }
  // }, [tempLanguage, location]);

  return (
    <>
      <header id="main-header">
        <div className="main-header">
          <Container fluid>
            <Row>
              <Col sm="12">
                <Navbar
                  expand="lg"
                  className="p-0"
                  aria-controls="responsive-navbar-nav"
                >
                  <Navbar.Toggle
                    className="c-toggler"
                    aria-controls="responsive-navbar-nav"
                  >
                    <div><span style={{color:"white", fontSize:"8px"}}>Menu</span></div>
                    <div className="navbar-toggler-icon">
                      
                      <span className="navbar-menu-icon navbar-menu-icon--top small"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                    </div>
                  </Navbar.Toggle>
                  <Navbar.Brand className="navbar-brand" href="/">
                    {width > 992 ? (
                      <img
                        className="img-fluid logo"
                        src={logoicon}
                        alt="AFO"
                      />
                    ) : (
                      <img className="img-fluid logo" src={logo} alt="AFO" />
                    )}
                  </Navbar.Brand>
                  <Dropdown className="mobile-more-menu">
                    <Dropdown.Toggle
                      to="#"
                      as={CustomToggle}
                      variant="more-toggle"
                    >
                      <i className="ri-more-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="more-menu">
                      <div className="navbar-right position-relative">
                        <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                          {/* <Button
                            style={{
                              padding: "0.2rem 0.5rem",
                              borderRadius: "0.5rem",
                              background:
                                "linear-gradient(45deg, #1171b6, #1075bd, #21a6e0)",
                              borderColor: "transparent",
                              marginRight: "0.5rem",
                            }}
                          >
                            <a
                              href="http://shop.afomedia.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                gap: "0.3rem",
                              }}
                            >
                              <BsShopWindow />
                              <span>{t("Visit our Shop")}</span>
                            </a>
                          </Button> */}
                          <Dropdown as="li" className="nav-item m-">
                            <Dropdown.Toggle
                              href="#"
                              as={CustomToggle}
                              variant="search-toggle"
                            >
                              <i className="ri-search-line"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="search-box iq-search-bar d-search p-0 m-0 dropdown-menu-right"
                              style={{ marginTop: "1rem" }}
                            >
                              <Form action="#" className="searchbox">
                                <div className="position-relative">
                                  <input
                                    autoFocus
                                    type="text"
                                    className="text search-input font-size-12"
                                    placeholder={t("Type here to search...")}
                                    autoComplete="off"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                    style={{ zIndex: "999" }}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <i
                                    className="ri-close-line close-link"
                                    onClick={() => {
                                      setSearchTerm("");
                                    }}
                                  ></i>
                                </div>
                              </Form>
                            </Dropdown.Menu>
                          </Dropdown>

                          <div className="select-lang">
                            <Dropdown as="li" className="nav-item nav-icon">
                              <Dropdown.Toggle as={CustomToggle}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={selectedLanguage.icon}
                                    alt="flag"
                                    className="img-fluid rounded-circle"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "0.6rem",
                                    }}
                                  />
                                  <span style={{ fontSize: "18px" }}>
                                    {selectedLanguage.code === 'en' ? "En" : "Fr"}
                                  </span>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="iq-sub-dropdown "
                                align="right"
                                style={{ color: "white", width: "170px" }}
                              >
                                {languages.map((lang) => (
                                  <Dropdown.Item
                                    key={lang.code}
                                    eventKey={lang.name}
                                    onSelect={handleLanguageSelect}
                                  >
                                    <img
                                      src={lang.icon}
                                      alt="flag"
                                      style={{
                                        width: "20%",
                                        marginRight: "11px",
                                      }}
                                    />
                                    {lang.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {isLoggedIn ? (
                            <Dropdown as="li" className="nav-item nav-icon">
                              <Dropdown.Toggle
                                href="#"
                                as={CustomToggle}
                                variant="search-toggle"
                              >
                                <div
                                  className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                                  data-toggle="search-toggle"
                                >
                                  {profileImage? (
                                    <img
                                      src={profileImage}
                                      className="img-fluid avatar-40 rounded-circle"
                                      alt="user"
                                    />
                                  ) : (
                                    <img
                                      src={defaultProfile}
                                      className=" avatar-40 rounded-circle"
                                      alt="user"
                                    />
                                  )}
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="iq-sub-dropdown iq-user-dropdown"
                                align="right"
                              >
                                <Card className="shadow-none m-0">
                                  <Card.Body className="p-0 pl-3 pr-3">
                                    <Link
                                      to="/profile/setting"
                                      className="iq-sub-card setting-dropdown"
                                    >
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-file-user-line text-primary"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">
                                            {t("Manage Profile")}
                                          </h6>
                                        </div>
                                      </div>
                                    </Link>

                                    <Link
                                      to="/login"
                                      className="iq-sub-card setting-dropdown"
                                      onClick={() => {
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("session");
                                      }}
                                    >
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-logout-circle-line text-primary"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Logout</h6>
                                        </div>
                                      </div>
                                    </Link>
                                  </Card.Body>
                                </Card>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            <Link to={"/login"}>{t("Login")}</Link>
                          )}
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <div className="mobile-more-menu">
                    <div >
                      <div className="navbar-right position-relative">
                        <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                         
                          <Dropdown as="li" className="nav-item m-">
                            <Dropdown.Toggle
                              href="#"
                              as={CustomToggle}
                              variant="search-toggle"
                            >
                              <i className="ri-search-line"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="search-box iq-search-bar d-search p-0 m-0 dropdown-menu-right"
                              style={{ marginTop: "1rem" }}
                            >
                              <Form action="#" className="searchbox">
                                <div className="position-relative">
                                  <input
                                    autoFocus
                                    type="text"
                                    className="text search-input font-size-12"
                                    placeholder={t("Type here to search...")}
                                    autoComplete="off"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                    style={{ zIndex: "999" }}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <i
                                    className="ri-close-line close-link"
                                    onClick={() => {
                                      setSearchTerm("");
                                    }}
                                  ></i>
                                </div>
                              </Form>
                            </Dropdown.Menu>
                          </Dropdown>

                          <div className="select-lang">
                            <Dropdown as="li" className="nav-item nav-icon">
                              <Dropdown.Toggle as={CustomToggle}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={selectedLanguage.icon}
                                    alt="flag"
                                    className="img-fluid rounded-circle"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "0.6rem",
                                    }}
                                  />
                                  <span style={{ fontSize: "18px" }}>
                                    {selectedLanguage.code === 'en' ? "En" : "Fr"}
                                  </span>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="iq-sub-dropdown "
                                align="right"
                                style={{ color: "white", width: "170px" }}
                              >
                                {languages.map((lang) => (
                                  <Dropdown.Item
                                    key={lang.code}
                                    eventKey={lang.name}
                                    onSelect={handleLanguageSelect}
                                  >
                                    <img
                                      src={lang.icon}
                                      alt="flag"
                                      style={{
                                        width: "20%",
                                        marginRight: "11px",
                                      }}
                                    />
                                    {lang.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {isLoggedIn ? (
                            <Dropdown as="li" className="nav-item nav-icon">
                              <Dropdown.Toggle
                                href="#"
                                as={CustomToggle}
                                variant="search-toggle"
                              >
                                <div
                                  className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                                  data-toggle="search-toggle"
                                >
                                  {profileImage? (
                                    <img
                                      src={profileImage}
                                      className="img-fluid avatar-40 rounded-circle"
                                      alt="user"
                                    />
                                  ) : (
                                    <img
                                      src={defaultProfile}
                                      className=" avatar-40 rounded-circle"
                                      alt="user"
                                    />
                                  )}
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="iq-sub-dropdown iq-user-dropdown"
                                align="right"
                              >
                                <Card className="shadow-none m-0">
                                  <Card.Body className="p-0 pl-3 pr-3">
                                    <Link
                                      to="/profile/setting"
                                      className="iq-sub-card setting-dropdown"
                                    >
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-file-user-line text-primary"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">
                                            {t("Manage Profile")}
                                          </h6>
                                        </div>
                                      </div>
                                    </Link>

                                    <Link
                                      to="/login"
                                      className="iq-sub-card setting-dropdown"
                                      onClick={() => {
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("session");
                                      }}
                                    >
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-logout-circle-line text-primary"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Logout</h6>
                                        </div>
                                      </div>
                                    </Link>
                                  </Card.Body>
                                </Card>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            <Link to={"/login"}>{t("Login")}</Link>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <Navbar.Collapse className="" id="responsive-navbar-nav">
                    <div className="menu-main-menu-container">
                      <Nav as="ul" id="top-menu" className="ml-auto">
                        <li
                          className={`menu-item ${
                            location.pathname === "/"
                              ? "active-navigations"
                              : ""
                          }`}
                        >
                          <Link
                            to="/"
                            style={{
                              color: location.pathname === "/" ? "#549dea" : "",
                            }}
                          >
                            {t("Home")}
                          </Link>
                        </li>

                        {/* <li className="menu-item">
                          <Link
                            to="/genre"
                            style={{
                              color:
                                location.pathname === "/genre" ? "#549dea" : "",
                            }}
                          >
                            {t("Category")}
                          </Link>
                        </li> */}
                        {/* {menuItemsPermission?.video && ( */}
                          <li className="menu-item">
                            <Link
                              to="/videos"
                              style={{
                                color:
                                  pathname.includes("/video") ||
                                  pathname.includes("/all-videos")
                                    ? "#549dea"
                                    : "",
                              }}
                            >
                              {t("Videos")}
                            </Link>
                          </li>
                        {/* )} */}
                        {/* <li className="menu-item">
                          <Link to="/videos">{t("Videos")}</Link>
                        </li> */}
                        {menuItemsPermission?.podcast && (
                          <li className="menu-item">
                            <Link
                              to="/podCast"
                              style={{
                                color:
                                  pathname.includes("/podCast") ||
                                  pathname.includes("/all-podcast") ||
                                  (pathname.includes("episode") &&
                                    category === "podcast") ||
                                  (pathname.includes("show-info") &&
                                    category === "podcast")
                                    ? "#549dea"
                                    : "",
                              }}
                            >
                              {t("Podcast")}
                            </Link>
                          </li>
                        )}
                        {/* {menuItemsPermission?.tvshow && (
                          <li className="menu-item">
                            <Link
                              to="/tv-shows"
                              style={{
                                color:
                                  pathname.includes("/tv-shows") ||
                                  pathname.includes("/all-tvshows") ||
                                  (pathname.includes("episode") &&
                                    category === "tvshow") ||
                                  (pathname.includes("show-info") &&
                                    category === "tvshow")
                                    ? "#549dea"
                                    : "",
                              }}
                            >
                              {t("Tv Shows")}
                            </Link>
                          </li>
                        )} */}
                        {/* {menuItemsPermission?.channel && (
                          <li className="menu-item">
                            <Link
                              to="/live-channel"
                              style={{
                                color: pathname.includes("/live")
                                  ? "#549dea"
                                  : "",
                              }}
                            >
                              {t("Live Channels")}{" "}
                            </Link>
                          </li>
                        )} */}
                      </Nav>
                    </div>
                  </Navbar.Collapse>
                  <div className="navbar-right menu-right">
                    <ul className="d-flex align-items-center list-inline m-0">
                      {/* <Button
                        style={{
                          padding: "0.2rem 0.5rem",
                          borderRadius: "0.5rem",
                          background:
                            "linear-gradient(45deg, #1171b6, #1075bd, #21a6e0)",

                          border: "none",
                          marginRight: "0.5rem",
                        }}
                      >
                        <a
                          href="http://shop.afomedia.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <BsShopWindow />
                          <span>{t("Visit our Shop")}</span>
                        </a>
                      </Button> */}
                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle
                          as={CustomToggle}
                          href=""
                          variant="search-toggle device-search"
                        >
                          <i className="ri-search-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="search-box iq-search-bar d-search p-0 m-0"
                          align="right"
                        >
                          <Form className="searchbox">
                            <Link
                              to={`/searchPage?query=${queryTxt}`}
                              onDoubleClick={handleDoubleClick}
                            >
                              <div className="position-relative">
                                <input
                                  autoFocus
                                  type="text"
                                  className="text search-input font-size-12"
                                  placeholder={t("Type here to search...")}
                                  autoComplete="off"
                                  value={searchTerm}
                                  onChange={handleInputChange}
                                  onKeyDown={handleKeyDown}
                                />
                                <i
                                  className="ri-close-line close-link"
                                  onClick={() => {
                                    setSearchTerm("");
                                  }}
                                  onDoubleClick={handleDoubleClick}
                                ></i>
                                <i
                                  className="ri-search-line search-link-button"
                                  // onClick={handleSearch}
                                ></i>
                              </div>
                            </Link>
                          </Form>
                        </Dropdown.Menu>
                      </Dropdown>

                      <div className="select-lang">
                        <Dropdown as="li" className="nav-item nav-icon">
                          <Dropdown.Toggle as={CustomToggle}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "45px",
                              }}
                            >
                              <img
                                src={selectedLanguage.icon}
                                alt="flag"
                                className="img-fluid rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "0.6rem",
                                }}
                              />
                              <span style={{ fontSize: "18px" }}>
                               {selectedLanguage.code === 'en' ? "EN" : "FR"}
                              </span>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="iq-sub-dropdown "
                            align="right"
                            style={{ color: "white", width: "170px" }}
                          >
                            {languages.map((lang) => (
                              <Dropdown.Item
                                key={lang.code}
                                eventKey={lang.name}
                                onSelect={handleLanguageSelect}
                              >
                                <img
                                  src={lang.icon}
                                  alt="flag"
                                  style={{
                                    width: "20%",
                                    marginRight: "11px",
                                  }}
                                />
                                {lang.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {isLoggedIn ? (
                        <>
                        <Dropdown as="li" className="nav-item nav-icon">
                          <Dropdown.Toggle
                            href="#"
                            as={CustomToggle}
                            variant="search-toggle"
                          >
                            <div
                              className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                              data-toggle="search-toggle"
                            >
                              {profileImage? (
                                <img
                                  src={profileImage}
                                  className="img-fluid avatar-40 rounded-circle"
                                  alt="user"
                                />
                              ) : (
                                <img
                                  src={defaultProfile}
                                  className=" avatar-40 rounded-circle"
                                  alt="user"
                                />
                              )}
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="iq-sub-dropdown iq-user-dropdown"
                            align="right"
                            onClick={handleToggle}
                          >
                            <Card className="shadow-none m-0">
                              <Card.Body className="p-0 pl-3 pr-3">
                                {/* {isOpen && ( */}
                                <a
                                  href="/profile/setting"
                                  // onClick={handleprofileClick}
                                  className="iq-sub-card setting-dropdown"
                                >
                                  <div className="media align-items-center">
                                    <div className="right-icon">
                                      <i className="ri-file-user-line text-primary"></i>
                                    </div>
                                    <div className="media-body ml-3">
                                      <h6 className="my-0 ">
                                        {t("Manage Profile")}
                                      </h6>
                                    </div>
                                  </div>
                                </a>

                                <a
                                  href="/login"
                                  className="iq-sub-card setting-dropdown"
                                  onClick={() => {
                                    localStorage.removeItem("token");
                                    localStorage.removeItem("session");
                                  }}
                                >
                                  <div className="media align-items-center">
                                    <div className="right-icon">
                                      <i className="ri-logout-circle-line text-primary"></i>
                                    </div>
                                    <div className="media-body ml-3">
                                      <h6 className="my-0 ">{t("Logout")}</h6>
                                    </div>
                                  </div>
                                </a>
                                {/* )} */}
                              </Card.Body>
                            </Card>
                          </Dropdown.Menu>
                        </Dropdown>


                        {/* <Dropdown as="li" className="nav-item nav-icon">
                          <Dropdown.Toggle
                            href="#"
                            as={CustomToggle}
                            variant="search-toggle"
                          >
                            <div
                              className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active notification-bell"
                              data-toggle="search-toggle"
                            >
                              <i class="ri-notification-line"></i>
                              {notifications.filter(n => !n.already_seen.includes(uid)).length > 0 && <span className="badge-notif">{notifications.filter(n => !n.already_seen.includes(uid)).length}</span>}
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="iq-sub-dropdown iq-user-dropdown"
                            align="right"
                            onClick={handleToggle}
                          >
                            <Card className="shadow-none m-0">
                              <Card.Body className="p-0 pl-3 pr-3">
                                
                                {notifications.map(notification => {
                                  return (
                                    <span
                                    onClick={() => handleNotificationClick(notification.id, notification.link)}
                                    className="iq-sub-card setting-dropdown notif"
                                  >
                                    <div className="media align-items-center">
                                      <div className="right-icon">
                                        <i className="ri-video-chat-fill text-primary"></i>
                                      </div>
                                      <div className="media-body ml-3">
                                        <h6 className={notification.already_seen.includes(uid)? "my-0 text-light" : "my-0"}>
                                           {notification.body}
                                        </h6>
                                      </div>
                                    </div>
                                  </span>
                                  )
                                })}
                              </Card.Body>
                            </Card>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        </>
                      ) : (
                        <a href={"/login"}>{t("Login")}</a>
                      )}
                    </ul>
                  </div>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle1;
