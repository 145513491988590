import axios from "axios";

function userPaid(user_id, obj) {
  const currentUserToken = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_API}/api/user/check-exclusive-content/${user_id}`;
  return axios
    .post(url, obj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
        source: "front",
      },
    })
    .then((response) => {
      return response.data.paid;
    })
    .catch((error) => {
      return [];
    });
}
export default userPaid;
