import axios from "axios";

function getAnalytics(obj) {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_API}/api/analytics/get-analysis-for-media`;

  return axios
    .post(url, obj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        source: "front",
      },
    })
    .then((response) => {
      
      return response.data.analysis;
    })
    .catch((error) => {
      return [];
    });
}

export default getAnalytics;
