import { adClickApi } from "../adsApi";

export function convertSecondsToHoursMinutes(seconds) {
  if (isNaN(seconds) || seconds === null) {
    return null;
  }
  seconds = Math.floor(seconds);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (seconds <= 60) {
    return `${seconds} second${seconds !== 1 ? "s" : ""}`;
  } else if (hours === 0) {
    return `0hr : ${minutes.toString().padStart(2, "0")}mins`;
  } else {
    return `${hours.toString().padStart(2, "0")}hr : ${minutes
      .toString()
      .padStart(2, "0")}mins`;
  }
}

export function getDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function limitTextToWords(text, limit) {
  if (!text || !limit) {
    return "";
  }

  const words = text.trim().split(" ");
  const limitedWords = words.slice(0, limit);
  const truncatedText = limitedWords.join(" ");

  if (words.length > limit) {
    return truncatedText + "...";
  }

  return truncatedText;
}

export function capitalizeFirstLetter(str) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function dateToWords(input) {
  const date = new Date(input);

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the month name using the month index
  const monthName = monthNames[monthIndex];

  // Construct the formatted date string
  const formattedDate = `${day} ${monthName} ${year}`;

  return formattedDate;
}

export const generalDateFormat = (dateString) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${month}/${day}/${year}`;
};

export const getNextBillingDate = () => {
  const user = JSON.parse(localStorage.getItem("session"));
  if (user.subscription_date) {
    let date = new Date(user?.subscription_date);
    date.setMonth(date.getMonth() + 1);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }
  return ``;
};

export const getNextMonthDate = (dateString) => {
  const parts = dateString.split("/");
  const month = parseInt(parts[0]);
  const day = parseInt(parts[1]);
  const year = parseInt(parts[2]);

  const currentDate = new Date(year, month - 1, day);
  currentDate.setMonth(currentDate.getMonth() + 1);

  const nextMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const nextYear = currentDate.getFullYear().toString().slice(-2);

  return `${nextMonth}/${day}/${nextYear}`;
};

export function formatViewCount(viewCount) {
  if (viewCount >= 1000000) {
    // Convert to millions (e.g., 1.1m)
    return (viewCount / 1000000).toFixed(1) + "m";
  } else if (viewCount >= 1000) {
    // Convert to thousands (e.g., 1k)
    return (viewCount / 1000).toFixed(0) + "k";
  } else {
    return viewCount.toString();
  }
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
  }
  return array;
}

export function assignAdToTarget(sourceArray, targetArray) {
  if (!Array.isArray(sourceArray) || !Array.isArray(targetArray)) {
    // console.error("Both arguments should be arrays.");
    return;
  }

  if (sourceArray.length === 0) {
    // console.error("Source array is empty.");
    return;
  }

  const randomIndex = Math.floor(Math.random() * sourceArray.length);
  const objectToAssign = sourceArray.splice(randomIndex, 1)[0]; // Get and remove the random object from the source array
  targetArray.push(objectToAssign); // Assign the object to the target array
}

export const handleAdClicked = (event, data) => {
  if (data?.category === "ads") {
    event.preventDefault(); // Prevent the default link behavior for ads category
    adClickApi(data?._id)
      .then(() => {
        window.open(data?.redirect_url, "_blank");
      })
      .catch((error) => {
        // console.error("Error handling after API call:", error);
      });
  }
};

export const handleAnchorClick = (event) => {
  event.stopPropagation(); // Prevent the click event from reaching the parent div
};
