import React, { useState, useEffect } from "react";
import {
  Col,
  Image,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  CommentsGetApi,
  deleteComment,
  updateComment,
  postCommentWithTvShowId,
} from "../Services/commentsApi";
import { dateToWords } from "../Services/generalFunctions/generalFunctions";

export default function Comments({
  generalID,
  mediaCategory,
  setCommentFlag,
  commentFlag,
}) {
  const [noOfComments, setNoOfComments] = useState(5);
  const [comments, setComments] = useState([]);
  const [commentIndex, setCommentIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedComment, setEditedComment] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("session"));
  const { t } = useTranslation();
  const currentUserId = currentUser?._id;
  const userName = currentUser?.name + (currentUser?.last_name ? " " + currentUser?.last_name : "");
  const userImage = currentUser?.profile_image?.url;
  const [commentToDelete, setCommentToDelete] = useState(null);

  const handleDelete = (id, index) => {
    setCommentToDelete(id);
    setCommentIndex(index);
  };

  const confirmDelete = () => {
    deleteComment(generalID, commentToDelete);

    comments.splice(commentIndex, 1);

    setCommentToDelete(null);
  };
  //   const handleDelete = (id) => {
  //     const confirmDelete = window.confirm(
  //       "Are you sure you want to delete this comment?"
  //     );
  //     if (confirmDelete) {
  //       deleteComment(generalContentId, id);
  //     }
  //   };

  const handleShowMore = () => {
    setNoOfComments(noOfComments + 5);
  };

  const handleHideComments = () => {
    if (noOfComments > 5) {
      setNoOfComments(noOfComments - 5);
    }
  };

  useEffect(() => {
    // if (mediaCategory === "movies") {
    CommentsGetApi(generalID, mediaCategory)
      .then((data) => {
        setComments(data.comments);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (commentFlag === true) {
      CommentsGetApi(generalID, mediaCategory)
        .then((data) => {
          setComments(data.comments);
          setCommentFlag(false);
        })
        .catch((error) => {});
    }
  }, [commentFlag]);

  // useEffect(() => {
  //   postCommentWithTvShowId(generalContentId, mediaCategory)
  //     .then((data) => {
  //       setComments(data.comments);
  //     })
  //     .catch((error) => {
  //     });
  // }, []);

  function getDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const startEditing = (id, comment) => {
    setIsEditing(true);
    setEditingCommentId(id);
    setEditedComment(comment);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditingCommentId(null);
    setEditedComment("");
  };

  const handleSave = (id) => {
    setIsEditing(false);
    setEditingCommentId(null);
    const updatedComment = { comment: editedComment };
    updateComment(generalID, id, updatedComment.comment)
      .then(() => {
        // Reload the comments from the server after the update
        CommentsGetApi(generalID, mediaCategory).then((data) => {
          setComments(data.comments);
        });
      })
      .catch((error) => {});
  };

  return (
    <section className="relative">
      <Modal
        show={commentToDelete !== null}
        onHide={() => setCommentToDelete(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Comment")}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCommentToDelete(null)}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {comments?.length === 0 ? (
        <p>{t("No comments found")}</p>
      ) : (
        comments?.slice(0, noOfComments).map((comment, index) => (
          <>
            <Row key={comment?._id} className="py-2 comments-section-row">
              <Col md="12" lg="10" xl="8">
                <Row>
                  <Col>
                    <div className="comments-section-main">
                      {comment?._id === editingCommentId && (
                        <div
                          className="edit-modal bg-dark"
                          style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
                        >
                          <h4 className="">{t("Edit your comment")}</h4>
                          <textarea
                            className="form-control"
                            value={editedComment}
                            onChange={(e) => setEditedComment(e.target.value)}
                            style={{
                              marginBottom: "10px",
                              transition: "all 0.2s ease-in-out",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              transition: "all 0.2s ease-in-out",
                            }}
                          >
                            <Button
                              onClick={() => handleSave(comment?._id)}
                              style={{ marginRight: "10px" }}
                            >
                              {t("Save")}
                            </Button>
                            <Button onClick={() => cancelEditing()}>
                              {t("Cancel")}
                            </Button>
                          </div>
                        </div>
                      )}

                    {comment?.user?.profile_image?.url !== '' ?
                      <img
                        className=" comments-image"
                        src={comment?.user?._id === currentUserId ? userImage : comment?.user?.profile_image?.url } 
                        alt="avatar"
                      />

                     : <img
                     className=" comments-image"
                     src="/static/media/profile-default.a8a83423cbf613fdd99d.png" 
                     alt="avatar"
                   />}

                      {/* {console.log(comment?.user)} */}
                      <div className="flex-grow-1 flex-shrink-1 comments-section-details">
                        <div className="">
                          <div className="comment-section-title d-flex align-items-center">
                          <p className="user-title">
                            {comment?.user?._id === currentUserId 
                              ? userName 
                              : comment?.user?.name + (comment?.user?.last_name ? " " + comment?.user?.last_name : "")
                            }
                          </p>
                            <span className="large user-comments-date">
                              {dateToWords(comment.createdAt)}
                            </span>
                            <div className="comment-section-actions">
                              {comment?.user?._id === currentUserId && (
                                <>
                                  <i
                                    className="ri-delete-bin-7-fill"
                                    onClick={() =>
                                      handleDelete(comment?._id, index)
                                    }
                                  ></i>
                                  <i
                                    className="ri-pencil-fill"
                                    onClick={() =>
                                      startEditing(
                                        comment?._id,
                                        comment.comment
                                      )
                                    }
                                  ></i>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="comments-section-comment">
                            <p className="small mb-0">{comment.comment}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ))
      )}

      <div className="d-flex justify-content-start mt-3">
        {noOfComments < comments?.length && (
          <Button
            className="btn btn-hover iq-button py-1"
            onClick={handleShowMore}
          >
            {t("Show more comments")}
          </Button>
        )}
        {/* {noOfComments > 5 && (
        <Button
          className={`${
            comments.length > 10 ? "ml-3" : ""
          } btn btn-hover iq-button py-1`}
          onClick={handleHideComments}
        >
          Hide comments
        </Button>
      )} */}
      </div>
    </section>
  );
}
