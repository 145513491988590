const getPackageDetails = async () => {
  const options = {
    method: "GET",
    headers: { accept: "application/json; charset=utf-8" },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/packages/get-all-package`,
    options
  );
  if (!response.ok) {
    throw new Error("Failed to fetch general content");
  }

  const data = await response.json();
  return data;
};

export default getPackageDetails;
