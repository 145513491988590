import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import countryList from "react-select-country-list";
import Select from "react-select";
// import AuthSession from "../../../../getSessionAuth";
import { Dropdown } from "react-bootstrap";
import user from "../../../../assets/images/user/user.jpg";
import { useTranslation } from "react-i18next";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { AiOutlineCheckCircle } from "react-icons/ai";
// favorite img
import fav1 from "../../../../assets/images/favorite/01.jpg";
import fav2 from "../../../../assets/images/favorite/02.jpg";
import fav3 from "../../../../assets/images/favorite/03.jpg";
import fav4 from "../../../../assets/images/favorite/04.png";
import fav5 from "../../../../assets/images/favorite/05.jpg";
import Modal from "react-bootstrap/Modal";
// swipper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import Enable2FA from "./2FA/Enable2FA";
import Disable2FA from "./2FA/Disable2FA";
import { HmacSHA256 } from "crypto-js";
import updateUserDetails from "../../../../Services/updateUserDetails";
import AuthSession from "../../../../Services/getSessionAuth";

//---
import { FcPrevious } from "react-icons/fc";
import postLoginData from "../../../../Services/postLoginData";
import postSignUpData from "../../../../Services/postSignUpData";
import Stripe from "./../../pages/auth/stripe";
import OtpInput from "react-otp-input";
import sendEmailVerification from "../../../../Services/sendEmailVerification";
import verifyEmailCode from "../../../../Services/verifiyEmailCode";
import checkUserCard from "../../../../Services/checkUserCard";
import userSubscription from "../../../../Services/userSubscription";
import { fetchDataFromAPI, fetchSubscriptionApi } from "./../bankdetails";
import { ToastContainer, toast } from "react-toastify";
//----

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const UserFreeAccountSetting = ({
  updateData,
  showModal,
  setShowModal,
  packageDetails,
  setBankStatus,
  subscriptionStatus,
  apiBankResponse
}) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: "100px",
      overflowY: "scroll",
    }),
  };
  const [width, setWidth] = useState(window.innerWidth);
  const [showBankTransferButton, setShowBankTransferButton] = useState(true);
  const [message, setMessage] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showBankTransferDetails, setShowBankTransferDetails] = useState(true);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleCloseLoginModal = () => {
    setShowModal(false);
    // setShowLoginModal(false);
    setShowStripe(false);
    setCardInfo(false);
  };
  const { t } = useTranslation();

  // stripe state
  const [showStripe, setShowStripe] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("session"));
  const currentUserId = currentUser?._id;

  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));
    // Get the current date
    const currentDate = new Date();

    // Subtract 15 years from the current date
    currentDate.setFullYear(currentDate.getFullYear() - 15);

    // Format the date as "YYYY-MM-DD"
    const fifteenYearsAgo = currentDate.toISOString().substring(0, 10);
    return {
      _id: userDetails_Session._id,
      name: userDetails_Session.name,
      email: userDetails_Session.email,
      tfa: userDetails_Session.tfa,
      phone: userDetails_Session.phone ? userDetails_Session.phone : "N/A",
      // dob: userDetails_Session.dob
      //   ? userDetails_Session.dob.substring(0, 10)
      //   : "2021-09-02",
      dob: userDetails_Session.dob
        ? userDetails_Session.dob.substring(0, 10)
        : fifteenYearsAgo,
      language: userDetails_Session.language
        ? userDetails_Session.language
        : "unknown",
      gender: userDetails_Session.gender
        ? userDetails_Session.gender
        : "unknown",
      country: userDetails_Session.country
        ? userDetails_Session.country
        : "N/A",
      password: userDetails_Session.password
        ? userDetails_Session.password
        : "N/A",
      profileIMG: userDetails_Session?.profile_image?.url
        ? userDetails_Session?.profile_image?.url
        : "",
      subscription: userDetails_Session.subscription,
      brand: userDetails_Session.brand,
      last4: userDetails_Session.last4,
    };
  };
  const [userDetails, setUserDetails] = useState(getSessionData());
  const [userInfo, setUserInfo] = useState({
    _id: userDetails._id,
    subscription: "paid",
  });
  const [cardInfo, setCardInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState();

  const [showBankTransferModal, setShowBankTransferModal] = useState(false);

  const openBankTransferModal = async () => {
    try {
      const data = await fetchDataFromAPI();
      if (data) {
        setBankDetails(data);
        setShowBankTransferModal(true);
        setShowPaymentMethodModal(false);
      }
    } catch (error) {
      // console.error("Error fetching bank details:", error);
    }
  };
  const [paymentStatus, setPaymentStatus] = useState(false);

  const handleBankSubcription = async (event) => {
    try {
      const bankSubscriptionData = await fetchSubscriptionApi(currentUserId);
      if (bankSubscriptionData.status === "400") {
        setPaymentStatus(false);
        console.log(apiBankResponse)
        apiBankResponse(bankSubscriptionData)
        setTimeout(() => setShowBankTransferModal(false), 5000);
        return bankSubscriptionData;
      } else {
        setTimeout(() => setShowBankTransferModal(false), 5000);
        setPaymentStatus(true);
        toast.info("Successfully paid.", {
          position: "top-center",
        });
        return bankSubscriptionData;
      }
    } catch (error) {
      // console.error('Error fetching subscription data:', error);
    }
  };
  const handleSubmit = async (event) => {
    setShowStripe(true);
    setShowPaymentMethodModal(true);
  };

  const stripModal = async (event) => {
    setLoading(true);
    try {
      const response = await checkUserCard(currentUserId);
      setShowStripe(true);
      setShowPaymentMethodModal(false);
      if (response.data.connected) {
        // if (userDetails.paid) {
        await userSubscription(currentUserId);
        // } else {
        //   toast.success("Already paid")
        // }
        setCardInfo(true);
        let ud = await updateUserDetails(userInfo);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
        updateData(updatedUserDetails);
      } else {
        setCardInfo(false);
      }
    } catch (error) {}
    setLoading(false);
  };
  return (
    <>
      <Modal
        className="threeModal"
        show={showBankTransferModal}
        onHide={() => {
          setShowBankTransferModal(false);
          setShowStripe(true);
          setShowPaymentMethodModal(true);
          // setBankDetails();
        }}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Bank Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showBankTransferDetails &&
          bankDetails &&
          bankDetails.bank_detail &&
          bankDetails.confirmation_msg ? (
            <div style={{ textAlign: "center" }}>
              {/* <h5>Bank Transfer - </h5> */}
              <div
                dangerouslySetInnerHTML={{ __html: bankDetails.bank_detail }}
              />
            </div>
          ) : null}
          {showConfirmation && (
            <>
              <div style={{ textAlign: "center" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: bankDetails.confirmation_msg,
                  }}
                />
              </div>
            </>
          )}
          <br />
          {showBankTransferButton && (
            <button
              className="btn btn-primary delBtn Bank-Type"
              onClick={async () => {
                setShowStripe(false);
                setShowPaymentMethodModal(false);
                setShowModal(false);
                const bankSubscriptionData = await handleBankSubcription();
                if (bankSubscriptionData?.status === "400") {
                  setMessage(bankDetails.confirmation_msg);
                } else {
                  setMessage(bankDetails.confirmation_msg);
                }
                setShowConfirmation(true); // Show the confirmation message after clicking the button
                setShowBankTransferDetails(false); // Hide the bank transfer details after clicking the button
                setShowBankTransferButton(false);
              }}
            >
              Payment Made
            </button>
          )}
          {/* {message && !showBankTransferButton && (
            <div style={{ textAlign: "center" }}>
              <p>{message}</p>
            </div>
          )} */}
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal}
        onHide={handleCloseLoginModal}
        className={`${showStripe ? "stripe-Modal" : "special_modal"} ${
          showLoginModal ? "no-scroll" : ""
        }`}
        size="md"
        centered={true}
      >
        {!showStripe ? (
          <Modal.Body>
            <div class="demo">
              <div class="container">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                  <div class="pricingTable blue">
                    <div class="pricingTable-header">
                      <i class="fa fa-diamond"></i>
                      <div class="price-value">
                        {" "}
                        ${packageDetails?.package_price.toFixed(2)}{" "}
                        <span class="month">{t("per month")}</span>{" "}
                      </div>
                    </div>
                    <h3 class="heading">{packageDetails?.package_name}</h3>
                    <div class="pricing-content">
                      <p
                        style={{ transform: "scale(0.7)" }}
                        dangerouslySetInnerHTML={{
                          __html: packageDetails?.package_description,
                        }}
                      ></p>
                    </div>
                    <div class="pricingTable-signup">
                      <button
                        className="pricingTable-signup"
                        type="submit"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSubmit()}
                      >
                        {t("Subscribe")}
                      </button>
                      {/* <Stripe /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : showPaymentMethodModal ? (
          <Modal
            className="threeModal"
            show={showPaymentMethodModal}
            onHide={() => {
              setShowPaymentMethodModal(false);
              setShowStripe(false);
            }}
            centered={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Payment Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="three-payment">
                <div className="three-pay-btn">
                  <button
                    className="btn btn-primary delBtn Payment-Type"
                    onClick={() => {
                      stripModal();
                    }}
                  >
                    Card Payment
                  </button>
                </div>
                <div className="three-pay-btn">
                  <button
                    className="btn btn-primary delBtn Payment-Type"
                    onClick={() => {
                      stripModal();
                    }}
                  >
                    PayPal
                  </button>
                </div>
                <div className="three-pay-btn">
                  <button
                    className="btn btn-primary delBtn Payment-Type"
                    onClick={() => {
                      openBankTransferModal();
                    }}
                  >
                    Bank Transfer
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : cardInfo ? (
          <>
            <Modal.Header>
              <Modal.Title>Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "0.5rem" }}>
              <div className="success2FA">
                <AiOutlineCheckCircle
                  style={{ color: "var(--iq-primary)" }}
                  size={30}
                />
                <span>Successfully Subscribed!</span>
              </div>
            </Modal.Body>{" "}
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseLoginModal}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          !showBankTransferModal && (
            <Stripe
              updateData={updateData}
              message={"Successfully Subscribed!"}
              subscription={"paid"}
            />
          )
        )}
      </Modal>
    </>
  );
};

export default UserFreeAccountSetting;
